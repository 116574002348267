import * as React from 'react';
import { define, dynamic, type } from 'superstruct';
import { initValues } from './CampaignEdit';
import CampaignWizardS2SelectMulti from './CampaignWizardS2SelectMulti';
import CampaignWizardS2SelectSingle from './CampaignWizardS2SelectSingle';
import useCampaignEdit from './useCampaignEdit';
import { contextWizard } from './useCampaignWizard';

export const CampaignWizardS2SelectRewardStruct = dynamic((values: any) => {
	const ids = (Array.isArray(values.rewardIds) ? values.rewardIds : [values.rewardIds]).filter(Boolean);
	const addReward = ids.filter((id: string) => ['item', 'coupon'].includes(id));
	const rewardIds = ids.filter((id: string) => !['item', 'coupon'].includes(id));

	return type({
		rewardIds: define<string[]>('rewardIds', () => {
			return (addReward.length > 0)
				|| (rewardIds.length > (values.rewardType === 'choice' ? 1 : 0))
				|| '特典を選択してください';
		}),
	});
});

interface CampaignWizardS2SelectRewarddProps {
	setAddReward: (rewardType: string) => void;
}

export const CampaignWizardS2SelectReward: React.FC<CampaignWizardS2SelectRewarddProps> = (props) => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const wizardValues = ctx.values;
	const { rewardType } = wizardValues;

	const {
		rewards
	} = useCampaignEdit({ initValues });

	if (!rewards) {
		return null; // loading
	}

	return (
		<>
			{rewardType === 'choice' && <CampaignWizardS2SelectMulti rewards={rewards} setAddReward={props.setAddReward} />}
			{rewardType === 'item' && <CampaignWizardS2SelectSingle rewards={rewards.filter(r => !r.isCoupon)} rewardType={rewardType} setAddReward={props.setAddReward} />}
			{rewardType === 'coupon' && <CampaignWizardS2SelectSingle rewards={rewards.filter(r => r.isCoupon)} rewardType={rewardType} setAddReward={props.setAddReward} />}
		</>
	);
}
export default CampaignWizardS2SelectReward;
