import * as React from 'react';
import ContactCompleted from './ContactCompleted';
import ContactConfirm from './ContactConfirm';
import ContactInput from './ContactInput';
import { useContact } from './useContact';


const initValues = {
	formType: 'adminContact',
	name: '',
	email: '',
	subject: '',
	content: '',
	orderNumber: '',
};
export type FormValues = typeof initValues;

export const Contact: React.FunctionComponent = () => {
	const {
		state,
		formType,
		methods,
		btnClass,
		handleClickConfirm,
		handleClickBack,
		handleClickSend,
	} = useContact(initValues);

	const shopName = state.shop?.name;

	return (
		<>
			{/* <h1 className="el_pageTtl">よくある質問</h1>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row bl_faqList">
							<ul className="mb_0">
								{articles.map((article) => 
									<li key={article.id}><span><a href={`${state.params.basePath}/faq/${article.id}`}>{article.titleShort}</a></span></li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div> */}
			<h1 className="el_pageTtl">メールでのお問い合わせ</h1>
			{formType === 'input' && <p className="el_pageDesc">下記フォームにご記入の上、「確認画面へ」ボタンを押してください。</p>}
			{formType === 'confirm' && <p className="el_pageDesc">入力内容確認後、「送信」ボタンを押してください。</p>}
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					{formType === 'input' &&
						<ContactInput methods={methods}
							shopName={shopName}
							onConfirm={handleClickConfirm}
						/>
					}
					{formType === 'confirm' &&
						<ContactConfirm methods={methods}
							shopName={shopName}
							onSend={handleClickSend}
							onBack={handleClickBack}
							btnClass={btnClass}
						/>
					}
					{formType === 'completed' &&
						<ContactCompleted basePath={state.params.basePath} />
					}
				</div>
			</div>
			{formType === 'input' && <>
				<h1 className="el_pageTtl">お電話でのお問い合わせ</h1>
				<p className="el_pageDesc">ご質問・ご要望など、お気軽にお問い合わせください。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__8">
						<div className="bl_panel bl_panel__bt">
							<div className='fs_18 txt_bold'>086-239-0344</div>
							<div className="fs_12 mt_8">受付時間 11:00 ~ 17:30（土・日・祝日を除く）</div>
						</div>
					</div>
				</div>
			</>}
		</>
	);
}
export default Contact;