import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../atoms/Button';
import { ErrorMessage, Form, FormGroup, Input, InputPassword } from '../../organisms/Form';
import useLogin from './useLogin';

const initValues = {
	mail: '',
	password: '',
	code: '',
};

export const Login: React.FunctionComponent = () => {
	const {
		state,
		methods,
		handleClickLogin,
		handleTwoFactorAuthSubmit,
		shakeMessage,
		isTwoFactorAuth,
	} = useLogin(initValues);

	if (!('user' in state)) {
		return <div>Session loading...</div>;
	}

	return (
		<div className="ly_login">
			<div className="ly_entry">
				<h1 className="el_entryTitle"><img src="/assets/img/logo-w-2.svg" alt="ラクラクあつまレビュー" /></h1>
			</div>
			<main className="ly_loginForm">
				<div className="ly_loginForm_inner">
					{state.login?.message &&
						<p key={shakeMessage} className={`bl_loginForm_logoutNote ${shakeMessage <= 0 ? '' : 'shake'}`}>{state.login.message}</p>
					}
					<div>
					{!isTwoFactorAuth ? (
							<Form methods={methods} onSubmit={handleClickLogin}>
								<FormGroup>
									<label className="bl_blueLabel" htmlFor="mail">メールアドレス</label>
									<Input id="mail" type="text" name="mail" placeholder="aaa@aa.co.jp" rules={{ required: 'メールアドレスを入力してください' }} />
									<ErrorMessage name="mail" />
								</FormGroup>
								<FormGroup className="mb_16">
									<label className="bl_blueLabel" htmlFor="password">パスワード</label>
									<InputPassword id="password" name="password" placeholder="***********" rules={{ required: 'パスワードを入力してください' }} />
									<ErrorMessage name="password" />
								</FormGroup>
								<FormGroup>
									<Link className="txt_blue" to="/account/recover">パスワードを忘れた方はこちらから</Link>
								</FormGroup>
								<div className="el_largeBtnWrap">
									<Button className="el_largeBtn el_largeBtn__fullWidth" type="submit">ログイン</Button>
								</div>
							</Form>
						) : (
							<>
								<Form methods={methods} onSubmit={handleTwoFactorAuthSubmit}>
									<div className="bl_loginFormRow">
										<p className="txt_blue">認証アプリに表示された認証コードを入力してください。</p>
									</div>
									<FormGroup>
										<label className="bl_blueLabel" htmlFor="code">認証コード</label>
										<Input id="code" name="code" type="text" placeholder="" rules={{ required: '認証コードを入力してください' }} />
										<ErrorMessage name="code" />
									</FormGroup>
									<div className="el_largeBtnWrap">
										<Button className="el_largeBtn el_largeBtn__fullWidth" type="submit">コードを送信</Button>
									</div>
								</Form>
							</>
						)}
					</div>
				</div>
			</main>
		</div>
	);
};
export default Login;