
import { APIResponses } from '@sasagase/api-caller';
import { getPageInfoAll } from '@sasagase/types';
import * as React from 'react';
import { useAPI, useAPIDataTableLoad, useAPILoad, useAppState } from '../../../context';
import { useListSelector } from '../../organisms/DataTable';

interface ExecutingSort {
	first: APIResponses.GetRewardsResponseValues[],
	end: APIResponses.GetRewardsResponseValues[],
}
export interface CampaignProgresses {
	ready: Set<string>,
	inProgress: Set<string>,
}

export const useRewardList = () => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const urlParamsTab = new URLSearchParams(location.search).get("tab");
	const [tab, setTab] = React.useState<string>(urlParamsTab === 'present' || urlParamsTab === 'coupon' ? urlParamsTab : 'present');
	const [presents, setPresent] = React.useState<undefined|APIResponses.GetRewardsResponseValues[]>(undefined);
	const [coupons, setCoupons] = React.useState<undefined|APIResponses.GetRewardsResponseValues[]>(undefined);

	const [campaignMap] = useAPILoad<APIResponses.GetCampaignListResponse, Map<string, CampaignProgresses>, any>(
		callAPI.yReview.getCampaignList,
		{
			initValue: new Map(),
			apiParams: { shopId: state.params.shopId },
			onFetch: (data) => {
				const map = new Map<string, CampaignProgresses>();
				data.forEach(campaign => {
					const state = campaign.state;
					const name = campaign.name;

					for (const itemReward of campaign.rewards) {
						let campaignProgresses = map.get(itemReward.id);
						if (!campaignProgresses) {
							campaignProgresses = { ready: new Set<string>(), inProgress: new Set<string>() };
							map.set(itemReward.id, campaignProgresses)
						}
						if (state === "ready") {
							campaignProgresses.ready.add(name);
						} else if (state === "inProgress") {
							campaignProgresses.inProgress.add(name);
						}
					}
				});
				return map;
			},
		}
	);

	const [rows, { filter, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getRewards,
		{
			initValue: [],
			apiParams: { shopId: state.params.shopId },
			onFetch: (data) => {
				return [data, undefined];
			},
		}
	);

	const {
		getChecks,
		reset,
		refresh,
	} = useListSelector();

	React.useEffect(() => {
		if (!rows || !campaignMap) {
			return;
		}

		const sortObj: ExecutingSort = { first: [], end: [] };
		for (const reward of rows) {
			if (campaignMap.has(reward.id)) {
				sortObj.first.push(reward);
			} else {
				sortObj.end.push(reward);
			}
		}
		const sortedReward = [...sortObj.first, ...sortObj.end];
		setPresent(sortedReward.filter(reward => !reward.isCoupon));
		setCoupons(sortedReward.filter(reward => reward.isCoupon));
		refresh();
	}, [rows, campaignMap]);

	React.useEffect(() => {
		// タブ切り替えの際、閉じたタブ内のチェックボックスの値をクリアするため空のオブジェクトを指定
		reset({});
	}, [tab]);

	const handleClickDelete = async () => {
		if (!presents || !coupons) {
			return;
		}
		const checks = getChecks();

		const allReward = [...presents, ...coupons];
		const checkIds = Object.entries(checks).filter(([, check]) => check).map(([id,]) => id);
		const names = allReward.filter(reward => checkIds.includes(reward.id)).map(reward => reward.name);
		if (names.length <= 0) {
			alert(`削除するプレゼント品・クーポンを選択してください。`);
			return;
		}

		if (window.confirm(`以下のプレゼント品・クーポンを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			let seq;
			for (const [id, check] of Object.entries(checks)) {
				if (!check) {
					continue;
				}
				const param = { shopId: state.params.shopId, rewardId: id };
				const res = await callAPI.yReview.deleteReward(param).catch(() => null);
				if (!res || !res.data) {
					const reward = [...presents, ...coupons].find((reward) => reward.id === id);
					if (reward) {
						const rewardType = reward.isCoupon ? 'クーポン' : 'プレゼント品';
						alert(`${rewardType} ${reward.name} の削除に失敗しました。この${rewardType}は使用中です。`);
					}
				} else {
					seq = res.data.seq;
				}
			}
			filter({ seq }, []);
			reset();
		}
	};

	const toRewardEditURL = (rewardId: string): string => {
		return state.params.basePath + '/reward/reward/' + rewardId + '?tab=' + tab;
	};
	const toRewardCopyURL = (rewardId: string): string => {
		return state.params.basePath + '/reward/reward/' + rewardId + '/copy?tab=' + tab;
	};
	const tabClass = {
		present: tab == 'present' ? 'is_active' : '',
		coupon: tab == 'coupon' ? 'is_active' : '',
	};

	return {
		tab,
		setTab,
		tabClass,
		presentTableProps: {
			rows: presents,
			pageInfo: getPageInfoAll(presents?.length || 0),
			isLoading,
		},
		couponTableProps: {
			rows: coupons,
			pageInfo: getPageInfoAll(coupons?.length || 0),
			isLoading,
		},
		state,
		handleClickDelete,
		campaignMap,
		toRewardEditURL,
		toRewardCopyURL,
	};
};
export default useRewardList;
