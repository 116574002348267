import * as React from 'react';
import NoticeListItem from './NoticeListItem';
import ReviewSummary from './ReviewSummary';
import TotalReviewTable from './TotalReviewTable';
import WarningList from './WarningList';
import useTop from './useTop';

export const Top: React.FunctionComponent = () => {
	const {
		rows,
		pageInfo,
		isLoading,
		notices,
		reviewSummaryData
	} = useTop();

	return (
		<div className="ly_main__top">
			<WarningList />
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">お知らせ</h2>
						{notices &&
							<ul className="bl_noticeList">
								{notices.map(notice => <NoticeListItem key={notice.id} notice={notice} />)}
							</ul>
						}
					</div>
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop el_panel_ttlTop__simple">実施中のキャンペーン累計レビュー獲得件数</h2>
						<TotalReviewTable rows={rows} pageInfo={pageInfo} isLoading={isLoading} />
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<ReviewSummary summaryData={reviewSummaryData} />
				</div>
			</div>
		</div>
	);
}
export default Top;