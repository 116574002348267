import { RakutenItem } from '@sasagase/types';
import * as React from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useFormContext } from 'react-hook-form';
import { useAppState } from '../../../context';
import { useItemGroupEdit } from '../../../hooks';
import { nonNegative } from '../../../lib';
import { GroupEditRowFormValues, getGroupTag, groupTagName, nameList } from './useGroupEditRow';

const CONFIRM_MANAGE_NUMBWER_NUM = 10;	// ItemAPI経由で取得した商品コードをフォーム追加前に確認する件数

interface SelectProductProps {
	groupNames: Record<string, string>;
	type: 'include' | 'exclude';
	names: Record<keyof GroupEditRowFormValues, string>;
	handleSearchItems: (itemName: string, lower: number, upper: number) => Promise<RakutenItem[]>;
	disabled?: boolean;	// 全商品を対象にした際の対象商品側の入力制限に使用
}

export function SelectProduct(props: SelectProductProps): React.ReactElement {
	const { type, names, handleSearchItems, disabled } = props;
	const { register, watch, setValue, getValues, trigger } = useFormContext();

	const {
		sanitizeSku,
	} = useItemGroupEdit();

	const [state] = useAppState();
	const [tab, setTab] = React.useState('group');
	const [btnState, setBtnState] = React.useState(false);
	const refSku = React.useRef<null|HTMLDivElement>(null);

	const childGroupIds: GroupEditRowFormValues['childGroupIds'] = watch(names.childGroupIds);
	const excludeChildGroupIds: GroupEditRowFormValues['excludeChildGroupIds'] = watch(names.excludeChildGroupIds);

	const targetName = type === 'include' ? '対象商品' : '除外商品';
	const swChildGroupIds = type === 'include' ? childGroupIds : excludeChildGroupIds;
	const swNameChildGroupIds = type === 'include' ? names.childGroupIds : names.excludeChildGroupIds;
	const swNameInputSkus = type === 'include' ? names.inputSkus : names.inputExcludeSkus;
	const swNameGroup = type === 'include' ? names.group : names.excludeGroup;
	const swNameSkus = type === 'include' ? names.skus : names.excludeSkus;
	const swNameItemName = type === 'include' ? names.itemName : names.excludeItemName;
	const swNameUpper = type === 'include' ? names.upper : names.excludeUpper;
	const swNameLower = type === 'include' ? names.lower : names.excludeLower;

	const triggerAll = () => {
		trigger(nameList.map(name => names[name]));
	};
	const handleClickRemoveGroup = (ev: any) => {
		const target = ev.target.closest('[data-removeid]');
		if (!target) {
			return;
		}

		const removeId = target.dataset.removeid;
		const modified = swChildGroupIds.filter(id => id != removeId);
		setValue(swNameChildGroupIds, modified);

		const prev: string = getValues(swNameInputSkus);
		setValue(swNameInputSkus, prev.replace(getGroupTag(removeId, props.groupNames[removeId]), ''));
		triggerAll();
	};
	const handleClickAdd = async () => {
		if (tab == 'group') {
			const addingIds = Object.entries(watch(swNameGroup)) // getValues(names.group) だと上手く取得できないので
				.filter(([, checked]) => checked)
				.map(([id]) => id);

			setValue(swNameChildGroupIds, [...swChildGroupIds, ...addingIds]);
			setValue(swNameGroup, Object.fromEntries(addingIds.map(id => [id, false])));
			const prev = getValues(swNameInputSkus);
			setValue(swNameInputSkus, prev + addingIds.map(id => getGroupTag(id, props.groupNames[id])).join(''));
		} else {
			if (!state.params.shopId) {
				return;
			}
			const itemName = getValues(swNameItemName);
			const upper = getValues(swNameUpper);
			const lower = getValues(swNameLower);
			if (!itemName) {
				alert('商品名を入力してください');
				return;
			} else if (!upper && !lower) {
				alert('指定金額を入力してください');
				return;
			}
			if ((Number(lower) || Infinity) < (Number(upper) || -Infinity)) {
				return;
			}

			// YahooのAPI経由で商品情報を取得し、対象商品に追記
			setBtnState(true);
			const items: RakutenItem[] = await handleSearchItems(itemName, Number(upper), Number(lower));
			if (items.length > 0) {
				const manageNumbers = items.slice(0, CONFIRM_MANAGE_NUMBWER_NUM).map((item) => item.manageNumber).join(`\n`) +
										(items.length > CONFIRM_MANAGE_NUMBWER_NUM ? `\n... 他${items.length - CONFIRM_MANAGE_NUMBWER_NUM}件` : ``);
				if (window.confirm(`以下${items.length}件の商品を追加します。よろしいですか？\n\n${manageNumbers}`)) {
					let skus = getValues(swNameSkus);
					skus = (skus ? skus + `,` : '') + items.map((item) => item.manageNumber).join(`,`);
					setValue(swNameSkus, skus);
					let inputSkus = getValues(swNameInputSkus);
					inputSkus = (inputSkus ? inputSkus + `,` : '') + items.map((item) => item.manageNumber).join(`,`);
					setValue(swNameInputSkus, inputSkus);
					alert(`${items.length}件の商品を追加しました`);
				}
			} else {
				alert('該当する商品がありませんでした');
			}
			setBtnState(false);
		}
		triggerAll();
	};

	const filterSkus = (nodes?: NodeListOf<ChildNode>) => {
		const skus: string[] = [];
		if (!nodes) return skus;
		nodes.forEach(child => {
			if (child.nodeName !== groupTagName.toUpperCase()) {
				skus.push(String(child.nodeName == '#text' ? child.nodeValue : child.textContent));
			}
		});
		return skus;
	};

	const handleChangeSku = (ev: ContentEditableEvent) => {
		const skus = filterSkus(refSku.current?.childNodes);
		const groupTags = Array.from(refSku.current?.querySelectorAll(groupTagName) || []);
		const groupIds = groupTags.map(g => g.dataset.groupid).filter(Boolean);

		setValue(swNameChildGroupIds, groupIds);
		setValue(swNameGroup, Object.fromEntries(groupIds.map(id => [id, false])));
		setValue(swNameSkus, skus.join(',').split(/\s*[,\t\n]\s*/).filter(Boolean).join(','));
		setValue(swNameInputSkus, ev.target.value);
	};

	const handlePaste = (ev: React.ClipboardEvent<HTMLDivElement>) => {
		ev.preventDefault();

		const text = ev.clipboardData.getData("text/plain");

		const selection = window.getSelection();
		if (!selection || !selection.rangeCount) {
			return;
		}
		selection.deleteFromDocument();

		const range = selection.getRangeAt(0);
		const node = document.createTextNode(text);
		range.insertNode(node);
		range.setStartAfter(node);
		range.setEndAfter(node);
		selection.removeAllRanges();
		selection.addRange(range);
	};

	const handleBlur = () => {
		const swNameSkus = type === 'include' ? names.skus : names.excludeSkus;
		const swNameInputSkus = type === 'include' ? names.inputSkus : names.inputExcludeSkus;

		// 入力した商品コード(HTML含む)をサニタイズ
		const sanitizedValue = sanitizeSku(String(refSku.current?.innerHTML));
		setValue(swNameInputSkus, sanitizedValue);

		// サニタイズ後の文字列から商品コードを抽出
		const dom = new DOMParser().parseFromString(sanitizedValue, 'text/html');
		const skus = filterSkus(dom.querySelector('body')?.childNodes);
		setValue(swNameSkus, skus.join(',').split(/\s*[,\t\n]\s*/).filter(Boolean).join(','));
	};

	const btnName = btnState ? '検索中' : '追加';

	return (
		<>
			<div className="bl_selectProduct">
				<div className="bl_selectProduct_selected">
					<ContentEditable
						innerRef={refSku}
						className={`textarea ${disabled && 'disabled'}`}
						html={disabled ? '' : watch(swNameInputSkus)}
						onChange={handleChangeSku}
						onClick={handleClickRemoveGroup}
						onPaste={handlePaste}
						onBlur={handleBlur}
						placeholder={`${targetName}の【商品コード】を入力`}
						disabled={disabled}
						/>
					<p className="fs_12 txt_blue mt_8">※半角改行またはコンマ区切り</p>
				</div>
				<div className="bl_selectProduct_selection">
					<ul className="bl_selectProduct_selectionTab">
						<li className={tab == 'group' ? 'is_active' : ''} data-type="group" onClick={() => setTab('group')}>登録グループを追加</li>
						{/* <li className={tab == 'price' ? 'is_active' : ''} data-type="price" onClick={() => setTab('price')}>金額を指定して追加</li> */}
					</ul>
					{tab == 'group' &&
						<div className="bl_selectProduct_selectionInner bl_selectProduct_selectionInner__group is_active">
							<ul className="bl_selectProduct_group">
								{Object.entries(props.groupNames).map(([id, name]) =>
									(!childGroupIds.includes(id) && !excludeChildGroupIds.includes(id)) &&
									<li key={id}><input className="el_checkMark" type="checkbox" name={`${swNameGroup}.${id}`} ref={register} disabled={disabled} />{name}</li>
								)}
							</ul>
						</div>
					}
					{tab == 'price' &&
						<div className="bl_selectProduct_selectionInner bl_selectProduct_selectionInner__price is_active">
							<div className="bl_selectProduct_price">
								<span><input className="el_inlineInputL textL" type="text" name={swNameItemName} ref={register} disabled={disabled} />商品名(部分一致)</span>
								<span><input className="el_inlineInputL" type="text" name={swNameUpper} onChange={nonNegative} ref={register} disabled={disabled} />円(税込)以上</span>
								<span><input className="el_inlineInputL" type="text" name={swNameLower} onChange={nonNegative} ref={register} disabled={disabled} />円(税込)以下</span>
								<span>指定した商品名と金額の範囲の商品を検索し追加します</span>
							</div>
						</div>
					}
					<div className="txt_alignRight">
						<button className="el_btnInv mt_8" type="button" onClick={handleClickAdd} disabled={btnState || disabled}>{btnName}</button>
					</div>
				</div>
			</div>
		</>
	);
}
export default SelectProduct;