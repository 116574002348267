import * as React from 'react';
import { Link } from 'react-router-dom';
import SideDrawerItem from './SideDrawerItem';
import { SideElement } from './SideElement';
import { PAGE_TITLES } from './const';
import { useSide } from './useSide';

export interface SideProps {
	isOpen: boolean;
	isOpenMobile: boolean;
	basePath: string;
	onClose?: () => void;
}

export const Side: React.FunctionComponent<SideProps> = (props) => {
	const {
		state,
		open,
		classNameIsOpen,
		setCapMap,
		handleClickMenu
	} = useSide(props);

	const elementProps = {
		entityId: state.params.shopId,
		user: state.user
	}

	return (
		<aside className="ly_side">
			<ul className={"bl_drawer menu" + classNameIsOpen}>
				<SideDrawerItem className="bl_drawer_item__top" title="TOP" onClickMenu={ handleClickMenu('top') }/>
				<SideElement {...elementProps} caps={ setCapMap.privilege }><SideDrawerItem className="bl_drawer_item__privilege" title="レビュー特典" isOpen={(open === 'privilege')} onClickMenu={ handleClickMenu('privilege') }>
					<ul className="bl_drawer_subList">
						<SideElement {...elementProps} caps={ setCapMap.reviewWaiting_r }><li><Link to={props.basePath + '/reward/target'}>{PAGE_TITLES.get('/reward/target')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.rewardApplying_r }><li><Link to={props.basePath + '/reward/request'}>{PAGE_TITLES.get('/reward/request')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.rewardRegister_r }><li><Link to={props.basePath + '/reward/reward'}>{PAGE_TITLES.get('/reward/reward')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.itemGroup_r }><li><Link to={props.basePath + '/reward/group'}>{PAGE_TITLES.get('/reward/group')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.campaignRegister_r }><li><Link to={props.basePath + '/reward/campaign'}>{PAGE_TITLES.get('/reward/campaign')}</Link></li></SideElement>
					</ul>
				</SideDrawerItem></SideElement>
				<SideElement {...elementProps} caps={ setCapMap.mail }><SideDrawerItem className="bl_drawer_item__mail" title="メール設定" isOpen={(open === 'mail')} onClickMenu={ handleClickMenu('mail') }>
					<ul className="bl_drawer_subList">
						<SideElement {...elementProps} caps={ setCapMap.mailSetting_r }><li><Link to={props.basePath + '/mail/smtp-setting'}>{PAGE_TITLES.get('/mail/smtp-setting')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.mailSetting_r }><li><Link to={props.basePath + '/mail/setting'}>{PAGE_TITLES.get('/mail/setting')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.mailTemplate_r }><li><Link to={props.basePath + '/mail/template'}>{PAGE_TITLES.get('/mail/template')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.mailSetting_r }><li><Link to={props.basePath + '/mail/not-covered'}>{PAGE_TITLES.get('/mail/not-covered')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.mailExclude_r }><li><Link to={props.basePath + '/mail/exclusion'}>{PAGE_TITLES.get('/mail/exclusion')}</Link></li></SideElement>
					</ul>
				</SideDrawerItem></SideElement>
				<SideElement {...elementProps} caps={ setCapMap.setting }><SideDrawerItem className="bl_drawer_item__setting" title="各種設定" isOpen={(open === 'setting')} onClickMenu={ handleClickMenu('setting') }>
					<ul className="bl_drawer_subList">
						<SideElement {...elementProps} caps={ setCapMap.userSetting_r }><li><Link to={props.basePath + '/setting/user'}>{PAGE_TITLES.get('/setting/user')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.shopSetting_r }><li><Link to={props.basePath + '/account/yahoo-setting'}>{PAGE_TITLES.get('/account/yahoo-setting')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.shopSetting_r }><li><Link to={props.basePath + '/account/shop-setting'}>{PAGE_TITLES.get('/account/shop-setting')}</Link></li></SideElement>
						<SideElement {...elementProps} caps={ setCapMap.systemAlert_r }><li><Link to={props.basePath + '/account/system-alert'}>{PAGE_TITLES.get('/account/system-alert')}</Link></li></SideElement>
					</ul>
				</SideDrawerItem></SideElement>
			</ul>
		</aside>
	);
};
export default Side;