import { ReactHelpMarkdown } from '@sasagase/react-help-markdown';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { setModal, useAppState } from "../../../context";
import { modifyGuideAnchor } from "./markdownModify";

interface GuideMainProps {
	path: string,
	title: string,
	folderPath: string,
	basePath: string,
}

export const GuideMain: React.FunctionComponent<GuideMainProps> = (props) => {
	const [, setState] = useAppState();
	const [md, setMd] = React.useState('');
	const location = useLocation();
	const { basePath } = props;

	const pageScroll = (): void => {
		const searchId = decodeURIComponent(location.hash)?.slice(1) ?? "";
		const targetElement = document.getElementById(searchId);

		if (!searchId || !targetElement) {
			return;
		}
		const elementPosition = targetElement.getBoundingClientRect();
		const xPotition = window.pageXOffset + (elementPosition?.left ?? 0);
		const yPotition = window.pageYOffset + (elementPosition?.top ?? 0);
		window.scrollTo(xPotition, yPotition);
	};

	React.useEffect(() => {
		async function getMd() {
			const res = await fetch(props.path);
			return res.text();
		}
		void getMd()
			.then(md => {
				setMd(md);
			});
	}, [props.path]);

	React.useEffect(pageScroll, [md]);
	React.useEffect(pageScroll, [location.hash]);

	const mdCallback = {
		showLightBox: (src?: string) => setState(setModal(Boolean(src))),
		load: pageScroll,
	};

	return (
		<div className="bl_panel bl_panel__ttlInv">
			<h2 className="el_lv2Heading">{props.title}</h2>
			<ReactHelpMarkdown
				md={md}
				modifyAnchor={modifyGuideAnchor(basePath)}
				callback={mdCallback}
				mainColor="#338751"
				/>
		</div>
	);
};
export default GuideMain;