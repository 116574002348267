import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, InputDate, SubmitButton, Textarea } from '../../organisms/Form';
import useMailSetting from './useMailSetting';

const initValues = {
	signature: '',
	sendFollowAfterShippingDays: '3',
	datePicker: new Date(0,0,0,12,0)
};
export type FormValues = typeof initValues;

export const MailSetting: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		numberLimit,
	} = useMailSetting(initValues);

	return (
		<>
			<PageTitle>メール送信設定</PageTitle>
			<PageDesc>メール送信に関わる署名やフォローメール送信タイミング等の設定を行います。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<Form methods={methods} onSubmit={handleSave}>
							<RequiredDesc />
							<FormGroup>
								<FormLabel>署名</FormLabel>
								<Textarea name="signature" placeholder="ぷりふあ人形
〒700-0976 岡山県岡山市北区辰巳29-113
(TEL) 086-250-0092 (e-mail) yahoo@prefer.jp" />
								<ErrorMessage name="signature" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>フォローメール送信タイミング</FormLabel>
								<div>
									<span>商品発送後</span>
									<Input className="el_inlineInputS" type="text" name="sendFollowAfterShippingDays" onChange={numberLimit} rules={{ required: 'フォローメール送信タイミングの日付を入力してください' }} />
									<span>日後</span>
									<span style={{display: 'inline-block'}}>
										<InputDate
											mode='time'
											name="datePicker"
											rules={{ required: 'フォローメール送信タイミングの時間を入力してください' }}
											/>
									</span>
									<span> に送信</span>
									<ErrorMessage name="sendFollowAfterShippingDays" />
									<ErrorMessage name="datePicker" />
								</div>
							</FormGroup>
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default MailSetting;