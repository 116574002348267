import * as React from 'react';
import { setModal, useAppState } from '../../../context';

interface ModalProps {
	className?: string;
	onClickBackground?: React.MouseEventHandler;
	children?: React.ReactNode;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Modal: React.FC<ModalProps> = (props) => {
	const [, setState] = useAppState();

	React.useEffect(() => {
		setState(setModal(true));
		return () => setState(setModal(false));
	}, []);

	const handleClickBackground = (ev: React.MouseEvent) => {
		if (!(ev.target instanceof HTMLElement)) {
			return;
		}
		if (ev.target.classList.contains('bl_modalBg')) {
			props.onClickBackground?.(ev);
		}
	};

	const className = props.className || '';

	return (
		<div className="bl_modalBg" onClick={handleClickBackground}>
			<div className={`bl_modal ${className}`}>
				{props.children}
			</div>
		</div>
	);
}
export default Modal;