import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface InputCheckProps extends React.InputHTMLAttributes<HTMLInputElement> {
	children?: React.ReactNode;
	rules?: Record<string, unknown>;
	className?: string;
}
export const InputCheck: React.FC<InputCheckProps> = (props) => {
	const { register } = useFormContext();

	const { children, rules, ...inputProps } = props;

	if (!children) {
		return <input type='checkbox' {...inputProps} ref={register(rules ?? {})} />;
	}

	return (
		<label className="bl_label">
			<input type='checkbox' {...inputProps} ref={register(rules ?? {})} />
			<span>{children}</span>
		</label>
	);
}
export default InputCheck;
