import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { boolean, define, dynamic, enums, is, type } from 'superstruct';
import { Button } from '../../atoms/Button';
import CampaignWizardS3InputSkus from './CampaignWizardS3InputSkus';
import CampaignWizardS3SelectGroupType, { CampaignWizardS3SelectGroupTypeStruct } from './CampaignWizardS3SelectGroupType';
import { contextWizard } from './useCampaignWizard';

export const campaignWizardS3InitValues: CampaignWizardS3FormValue = {
	groupType: '',
	isAll: false,
	skus: '',
	excludeSkus: '',
};

export interface CampaignWizardS3FormValue {
	groupType: string;	// 'include' | 'all' | 'exclude'
	isAll: boolean;
	skus: string;
	excludeSkus: string;
}

export const CampaignWizardS3Struct = dynamic((values: any) => {
	const groupType = values.groupType || '';
	const requireSkus = groupType === 'include';
	const requireExcludeSkus = groupType === 'exclude';

	return type({
		groupType: define<string>('groupType', () => is(groupType, enums(['include', 'all', 'exclude'])) || 'レビュー特典の対象商品の指定方法を選択してください'),
		isAll: boolean(),
		skus: define<string>('skus', (val) => !requireSkus || val != '' || '対象商品の商品コードを入力してください'),
		excludeSkus: define<string>('excludeSkus', (val) => !requireExcludeSkus || val != '' || '除外商品の商品コードを入力してください'),
	});
});

interface CampaignWizardS3Props {
	onPrev: () => void;
	onClose: () => void;
}

export const CampaignWizardS3: React.FC<CampaignWizardS3Props> = (props) => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const { onPrev } = props;
	const { watch, getValues, setValue } = useFormContext();
	const wizardValues = ctx.values;

	const stepType = ['include', 'exclude'].includes(wizardValues.groupType) ? 'inputSkus' : 'selectGroupType';

	const canNext = React.useMemo(() => {
		if (stepType === 'selectGroupType') {
			return is(getValues(), CampaignWizardS3SelectGroupTypeStruct);
		} else {
			return is(getValues(), CampaignWizardS3Struct);
		}
	}, [watch('groupType'), watch('skus'), watch('excludeSkus'), stepType]);

	const handleNext = async () => {
		const values = getValues();
		const { groupType } = values;
		if (stepType === 'selectGroupType') {
			const isAll = ['all', 'exclude'].includes(groupType);
			ctx.setWizardValues(prev => ({
				...prev,
				groupType,
				isAll
			}));
			setValue('isAll', isAll);

			if (is(values, CampaignWizardS3Struct)) {
				ctx.setStepIdx(idx => idx + 1);
			}
		} else if (stepType === 'inputSkus') {
			if (is(values, CampaignWizardS3Struct)) {
				ctx.setStepIdx(idx => idx + 1);
				ctx.setWizardValues(prev => ({
					...prev,
					skus: values.skus,
					excludeSkus: values.excludeSkus
				}));
				setValue('skus', values.skus);
				setValue('excludeSkus', values.excludeSkus);
			}
		}
	};

	const handlePrev = () => {
		if (stepType === 'selectGroupType') {
			onPrev && onPrev();
		} else {
			ctx.setWizardValues(prev => ({
				...prev,
				...campaignWizardS3InitValues
			}));
		}
	};

	return (
		<>
			<div className="bl_panel__wizardMain">
				{stepType == 'selectGroupType' && <CampaignWizardS3SelectGroupType />}
				{stepType == 'inputSkus' && <CampaignWizardS3InputSkus />}
			</div>
			<div className="bl_panel__wizardFooter">
				<Button className="el_largeBtn el_largeBtn__prev" onClick={handlePrev}>戻る</Button>
				<Button className="el_largeBtn" onClick={handleNext} disabled={!canNext}>次へ</Button>
				<Button className="el_largeBtn el_largeBtn__prev el_largeBtn__right" onClick={props.onClose}>キャンセル</Button>
			</div>
		</>
	);
}
export default CampaignWizardS3;
