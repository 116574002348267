import * as React from 'react';
import { Link } from 'react-router-dom';
import { GuideMenu } from './GuideMenu';

interface GuideSubTopProps {
	title: string;
	basePath: string;
	folderPath: string;
}

export const GuideSubTop: React.FunctionComponent<GuideSubTopProps> = (props) => {
	const guidePath = props.folderPath + "/registration_setting/use_flow";

	return (
		<>
			<h2 className="el_lv2Heading">{props.title}</h2>
			<div className="bl_guide">
				<h3 id="ラクラクあつまレビューとは">ラクラクあつまレビューとは</h3>
				<p>
					Yahooショップの運営会社が開発したYahoo!ショッピング出店者様向け “レビュー施策” システムです。
					レビュー施策における煩雑な作業を自動化し、店舗様の扱う商材に合わせた施策を手間をかけずに打ち出すことができます。
				</p>
				<Link to={guidePath} className="banner"><img src="/assets/img/banner-first-guid.svg" width="384" height="94" alt="はじめてご利用の方はこちら" /></Link>
				<span style={{ display: 'block' }} className="txt_blue">初期設定からキャンペーン運用までの流れをご案内します</span>
				<GuideMenu basePath={props.basePath} folderPath={props.folderPath} />
			</div>
		</>
	);
};
export default GuideSubTop;