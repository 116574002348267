import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI, useAppState, userLogoutSetter } from '../../../context';


export const useHeader = (basePath: string) => {
	const [state, setState] = useAppState();
	const callAPI = useAPI();
	const navigate = useNavigate();
	const refOpen = React.useRef<EventTarget>();
	const [open, setOpen] = React.useState('');

	React.useEffect(() => {
		const docClickHandler = (ev: MouseEvent) => {
			if (ev.target == refOpen.current) {
				return;
			}
			setOpen('');
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, []);

	const handleClickMenu = (name: string) => (ev: React.MouseEvent) => {
		if (open == name) {
			setOpen('');
		} else {
			setOpen(name);
			refOpen.current = ev.target;
		}
	};
	const handleClickShop = (shopId: string) => (ev: React.MouseEvent) => {
		ev.preventDefault();
		const rebasePath = basePath.split('/');
		if (!rebasePath.length) {
			return;
		}

		rebasePath[rebasePath.length - 1] = shopId;
		navigate(rebasePath.join('/'));
	};
	const handleClickManagement = (ev: React.MouseEvent) => {
		ev.preventDefault();
		navigate(basePath + '/management');
	};
	const handleClickLogout = (ev: React.MouseEvent) => {
		ev.preventDefault();
		callAPI.user.userLogout({}, (err, result) => {
			if (result.data) {
				setState(userLogoutSetter());
				navigate('/');
			}
		});
	};
	const handleClickGuide = (ev: React.MouseEvent) => {
		ev.preventDefault();
		navigate(basePath + '/guide/use_guide/top');
	};
	const handleClickContact = (ev: React.MouseEvent) => {
		ev.preventDefault();
		navigate(basePath + '/contact');
	};

	return {
		state,
		open,
		handleClickMenu,
		handleClickShop,
		handleClickManagement,
		handleClickLogout,
		handleClickGuide,
		handleClickContact,
	};
};