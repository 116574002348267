import { css } from '@emotion/react';
import * as React from 'react';
import { cssVariable } from '../../../css/variable';
import { Headline } from '../../atoms/Headline';

const REQUIRED_MARK = '*';

interface FormLabelProps {
	required?: boolean;
	headline?: boolean;
	item?: boolean;
	id?: string;
	children?: React.ReactNode;
	className?: string;
}
export const FormLabel: React.FC<FormLabelProps> = (props) => {

	const requiredStyle = css`
		color: ${cssVariable.red} !important;
		vertical-align: middle;
	`;

	const required = props.required ? <span css={requiredStyle}> {REQUIRED_MARK}</span> : '';
	const as = props.headline ? `h2` :
				props.item ? `h4` : `h3`;
	const classNames = [props.className];
	classNames.push(props.headline ? `el_lv2Heading` :
				props.item ? `el_lv4Heading` : `el_lv3Heading`);

	return <Headline as={as} id={props.id} className={classNames.filter(Boolean).join(' ')}>{props.children}{required}</Headline>;
}
export default FormLabel;
