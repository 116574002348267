import { Notices } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAPI, useAppState } from "../../../context";
import { FormValues } from './SystemAlert';

export const ALERT_DEST_MAIL_DELIMITER = ',';

export const useSystemAlert = (initValues: FormValues) => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const { setValue, watch, reset } = formMethods;

	const sortMails = (value?: string): string[] => {
		return (value ?? '').trim().split(/\s*,\s*/).filter((ad: string) => Boolean(ad));
	};

	const toSaveAttr = (values: Partial<FormValues>): Partial<Notices.ShopEntityAttr> => {
		return {
			isSendAlert: Boolean(values.isSendAlert),
			alertContentType: values.alertContentType,
			alertDestMail: sortMails(values.alertDestMail),
			daysBeforeTokenExpire: values.daysBeforeTokenExpire ? Number(values.daysBeforeTokenExpire) : undefined,
			daysBeforePublicKeyExpire: values.daysBeforePublicKeyExpire ? Number(values.daysBeforePublicKeyExpire) : undefined,
			daysNotUploadedReviewCsv: values.daysNotUploadedReviewCsv ? Number(values.daysNotUploadedReviewCsv) : undefined,
		};
	};

	const toValues = (shopEntity: Notices.ShopEntityAttr): FormValues => {
		return {
			...initValues,
			isSendAlert: shopEntity.isSendAlert,
			alertContentType: shopEntity.alertContentType,
			alertDestMail: shopEntity.alertDestMail.join(ALERT_DEST_MAIL_DELIMITER),
			daysBeforeTokenExpire: String(shopEntity.daysBeforeTokenExpire),
			daysBeforePublicKeyExpire: String(shopEntity.daysBeforePublicKeyExpire),
			daysNotUploadedReviewCsv: String(shopEntity.daysNotUploadedReviewCsv),
		};
	};

	React.useEffect(() => {
		return callAPI.notice.getShop({ shopId: state.params.shopId }, (err, result) => {
			if (err || result.data === null) {
				return;
			}
			if (result.data) {
				const shop = new Notices.ShopEntity(result.data);
				reset(toValues(shop));
			}
		});
	}, [state.params.shopId]);

	const handleSave: SubmitHandler<FormValues> = async (values) => {
		try {
			const params = {
				shopId: state.params.shopId,
				shop: toSaveAttr(values),
			};
			const res = await callAPI.notice.putShop(params);
			return res ? Boolean(res.data) : false;
		} catch (err) {
			return false;
		}
	};

	const handleOnChange = (type: string) => {
		if (type == 'isSendAlert') {
			if (!watch(type)) {
				setValue('alertContentType[]', []);
			}
		} else {
			const types = watch('alertContentType');
			setValue('isSendAlert', ((Array.isArray(types) ? types : [types]).length > 0));
		}
	};
	const isDisabled = (type: string) => {
		if (type == 'isSendAlert') {
			return !watch(type);
		} else {
			const types = watch('alertContentType');
			return !(Array.isArray(types) ? types : [types]).includes(type);
		}
	};

	return {
		state,
		methods: formMethods,
		handleSave,
		sortMails,
		handleOnChange,
		isDisabled,
	};
};
export default useSystemAlert;