import * as React from 'react';
import { MouseEvent } from "react";
import { Button } from '../../atoms/Button';
import useYahooIDConnect from "./useYahooIDConnect";

interface YahooIdConnectProps {
	extend?: boolean;
}

export const YahooIDConnect: React.FC<YahooIdConnectProps> = (props): JSX.Element => {
	const { extend } = props;
	const { openYahooAuthorization } = useYahooIDConnect();

	const handleClickLink = (ev: MouseEvent) => {
		ev.preventDefault();
		void openYahooAuthorization();
	};

	const btnText = extend ? 'Yahoo! ID連携を更新（有効期限を延長）' : 'Yahoo! ID連携を実行';

	return (
		<Button className="el_btn" type="button" onClick={handleClickLink}>{btnText}</Button>
	);
}
export default YahooIDConnect;
