import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormLabel } from '../../molecules/FormLabel';
import { LinkGuide } from '../../molecules/LinkGuide';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, SubmitButton } from '../../organisms/Form';
import { GroupEditRow } from '../../organisms/GroupEditRow';
import useGroupEdit from './useGroupEdit';

const initValues = {
	name: '',
	isAll: 'false',
	inputSkus: '',
	skus: '',
	inputExcludeSkus: '',
	excludeSkus: '',
	childGroupIds: [],
	excludeChildGroupIds: [],
	group: {},
	excludeGroup: {},
	itemName: '',
	upper: '',
	lower: '',
	excludeItemName: '',
	excludeUpper: '',
	excludeLower: '',
};
export interface FormValues {
	name: string;
	isAll: string;
	inputSkus: string;
	skus: string;
	inputExcludeSkus: string;
	excludeSkus: string;
	childGroupIds: string[];
	excludeChildGroupIds: string[];
	group: Record<string, boolean>;
	excludeGroup: Record<string, boolean>;
	itemName: string;
	upper: string;
	lower: string;
	excludeItemName: string;
	excludeUpper: string;
	excludeLower: string;
}

export const GroupEdit: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		returnListUrl,
		groupNames,
	} = useGroupEdit({ initValues });

	return (
		<>
			<PageTitle>対象商品グループ登録</PageTitle>
			<PageDesc>キャンペーン対象商品グループの新規登録、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<FormLabel headline>
							対象商品グループ登録
							<LinkGuide guidePath='registration_setting/from_review_privilege_up_to_campaign_register' link='対象商品グループの登録' className='el_linkIcon__guideF' />
						</FormLabel>
						<RequiredDesc />
						<Form methods={methods} onSubmit={handleSave}>
							<FormGroup>
								<FormLabel required>対象商品グループ名</FormLabel>
								<Input type="text" name="name" placeholder="【グループ名】を入力" rules={{ required: 'グループ名を入力してください' }} />
								<ErrorMessage name="name" />
							</FormGroup>
							<GroupEditRow groupNames={groupNames} />
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
							<Link className="el_backLink" to={returnListUrl}>≪一覧へ戻る</Link>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default GroupEdit;