import * as React from 'react';
import { ListSelectorContext } from '../../organisms/DataTable';
import { RequestList as RequestListBase } from './RequestList';

export const RequestList: React.FunctionComponent = () => {
	return (
		<>
			<ListSelectorContext>
				<RequestListBase />
			</ListSelectorContext>
		</>
	);
}
export default RequestList;