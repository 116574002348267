import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, FormIndent, Input, InputRadio, SubmitButton } from '../../organisms/Form';
import { MailEditRow } from '../../organisms/MailEditRow';
import useTemplateEdit from './useTemplateEdit';

const initValues = {
	name: '',
	category: 'review_request',
	bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
	bodyElement: [],
	signature: '',
	email: '',
	isFavorite: 'false'
};

export const TemplateEdit: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		returnListUrl,
	} = useTemplateEdit({ initValues });

	return (
		<>
			<PageTitle>送信メールテンプレート</PageTitle>
			<PageDesc>キャンペーン用メールテンプレートの新規作成、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<FormLabel headline>設定</FormLabel>
						<FormIndent><RequiredDesc /></FormIndent>
						<Form methods={methods} onSubmit={handleSave}>
							<FormGroup><FormIndent>
								<FormLabel required>テンプレート名</FormLabel>
								<Input type="text" name="name" rules={{ required: 'テンプレート名を入力してください' }} />
								<ErrorMessage name="name" />
							</FormIndent></FormGroup>
							<FormGroup><FormIndent>
								<FormLabel required>メールカテゴリ</FormLabel>
								<InputRadio name="category" value="review_request" label="フォローメール" rules={{ required: 'メールカテゴリを選択してください' }} />
								<InputRadio name="category" value="review_completed" label="レビュー完了" rules={{ required: 'メールカテゴリを選択してください' }} />
								<InputRadio name="category" value="application_completed" label="申込受付完了" rules={{ required: 'メールカテゴリを選択してください' }} />
								<ErrorMessage name="category" />
							</FormIndent></FormGroup>
							<MailEditRow />
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
							<Link className="el_backLink" to={returnListUrl}>≪一覧へ戻る</Link>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default TemplateEdit;