import { Transition } from 'history';
import * as React from 'react';
import { useBlocker } from './useBlocker';

export const PROMPT_CONFIRM_MESSAGE = 'このページから移動すると入力フォームの内容が削除されます。よろしいですか？';

export function usePrompt(message: string, when = true) {
	const blocker = React.useCallback((tx: Transition) => {
		if (window.confirm(message)) {
			tx.retry();
		}
	}, [message]);

	useBlocker(blocker, when);
}