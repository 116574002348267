import * as React from 'react';
import { ListSelectorContext } from '../../organisms/DataTable';
import { GroupList as GroupListBase } from './GroupList';

export const GroupList: React.FunctionComponent = () => {
	return (
		<>
			<ListSelectorContext>
				<GroupListBase />
			</ListSelectorContext>
		</>
	);
}
export default GroupList;