import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import LightBox from './LightBox';

interface MarkdownProps {
	md: string;
	modifyAnchor?: (props: {children: any, href: any}) => React.ReactElement;
	callback?: {
		showLightBox?: (src?: string) => void;
		load?: () => void;
	};
}

export const Markdown = (props: MarkdownProps): React.ReactElement | null => {
	const { md, modifyAnchor, callback } = props;
	const imagesNumRef = React.useRef(0);
	const loadImagesNumRef = React.useRef(0);

	const loadHandler = () => {
		loadImagesNumRef.current++;
		if (loadImagesNumRef.current === imagesNumRef.current) {
			callback?.load?.();
		}
	};

	const modifyHeadingElement = React.useCallback(({ node, children }: {node: any, children?: React.ReactNode}) => {
		const el = React.Children.map(children, (child) => {
			const Heading = node.tagName as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
			return <Heading id={child?.toString()}>{child}</Heading>;
		});

		return <>{el}</>
	}, []);

	const modifyLiElement = React.useCallback(({ children }: {children?: React.ReactNode}) => {
		const childrenToArray = React.Children.toArray(children).filter(child => typeof child !== 'string' || child.trim() !== '');

		let className;
		const el = React.Children.map(childrenToArray, child => {
			if (React.isValidElement(child)) {
				if (child.type === 'span' && child.props.className) {
					className = child.props.className;
				}

				return child;
			} else {
				return <span>{child}</span>;
			}
		});

		return <li className={className}>{el}</li>;
	}, []);

	const modifyAnchorElement = React.useCallback((props) => {
		const { children, href } = props;
		if (href) {
			return modifyAnchor ? modifyAnchor(props) : <span><Link to={href}>{children}</Link></span>;
		}

		return <>{children}</>;
	}, []);

	const modifyParagraphElement = React.useCallback(({ children }: {children?: React.ReactNode}) => {
		const childrenToArray = React.Children.toArray(children).filter(child => typeof child !== 'string' || child.trim() !== '');

		const el = React.Children.map(childrenToArray, child => {
			if (React.isValidElement(child)) {
				if (child.type === 'img') {
					imagesNumRef.current++;
					return <LightBox src={child.props.src} handlEvent={loadHandler} lightBoxCallback={callback?.showLightBox} />;
				}
			} else {
				if (child?.toString().startsWith("※")) {
					return <p className="comments">{child}</p>;
				}
			}

			return <span>{child}</span>;
		});

		return <>{el}</>;
	}, []);

	return (
		<>
			<ReactMarkdown
				rehypePlugins={[rehypeRaw]}
				remarkPlugins={[remarkGfm]}
				components={{
					h1: modifyHeadingElement,
					h2: modifyHeadingElement,
					h3: modifyHeadingElement,
					h4: modifyHeadingElement,
					h5: modifyHeadingElement,
					h6: modifyHeadingElement,
					li: modifyLiElement,
					a: modifyAnchorElement,
					p: modifyParagraphElement,
				}}
				>{md}</ReactMarkdown>
		</>
	);
}
export default Markdown;