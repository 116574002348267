import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { PageInfo, mailCategory } from '@sasagase/types';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Column, DataTable } from '../../organisms/DataTable';

export const filterInitValue = {
	filterCategory: ''
};
type FilterFormValue = typeof filterInitValue;

interface TemplateListTableProps {
	rows?: APIResponses.GetTemplatesResponse;
	pageInfo?: PageInfo;
	isLoading: boolean;
	handleFilter: () => void;
	methodFilter: UseFormMethods<FilterFormValue>;
	toEditURL: (rewardId: string) => string,
	toCopyURL: (rewardId: string) => string,
}

export const TemplateListTable: React.FunctionComponent<TemplateListTableProps> = (props) => {
	const { toEditURL, toCopyURL } = props;

	// テーブル固有のCSS指定
	const tableStyle = css`
		height: 687px;

		td:nth-of-type(2) {
			padding-left: 48px;
		}

		.is_favorite {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 8px;
				width: 16px;
				height: 16px;
				background-image: url(${require('../../../img/star.svg')});
				background-repeat: no-repeat;
				transform: translateY(-50%);
			}
		}
	`;

	const toTemplateCategoryString = (category: string) => {
		return {
			review_request: 'フォローメール',
			review_completed: 'レビュー完了',
			application_completed: '申込受付完了',
		}[category] || category;
	};
	const favoriteTemplate = (rowData: any) => {
		return rowData.isFavorite ? 'is_favorite' : '';
	}
	const categoryFilterElement = (options: any): JSX.Element => {
		const filterForm = <>
			<select name="filterCategory" ref={options.register} onChange={() => options.handleFilterToClose()}>
				<option value="">すべて表示</option>
				{mailCategory.map((category) =>
					<option key={category} value={category}>{toTemplateCategoryString(category)}</option>
				)}
			</select>
		</>;
		return options.createFilterSection(filterForm);
	}
	const categoryBodyTemplate = (rowData: any) => {
		return <>
			<span>{toTemplateCategoryString(rowData.category)}</span>
			<div className="bl_table_btnWrap">
				<Link className="el_btnInv" type="button" to={toEditURL(rowData.id)}>編集</Link>
				<Link className="el_btnInv" type="button" to={toCopyURL(rowData.id)}>複製</Link>
			</div>
		</>;
	};
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	};

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				handleFilter={props.handleFilter} methodFilter={props.methodFilter}
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 0 48px center center" field="id" listSelector></Column>
				<Column style="0 1 1176px center flex-start" tdClassNameTemplate={favoriteTemplate} field="name">テンプレート名</Column>
				<Column style="0 0 312px left center" field="category"
					filterable
					filterElement={categoryFilterElement}
					thClassName="bl_table_filterHead__beforeBtn"
					bodyTemplate={categoryBodyTemplate}
					>メールカテゴリ</Column>
			</DataTable>
		</>
	);
}
export default TemplateListTable;