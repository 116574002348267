import * as React from 'react';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequestDetail } from './RequestDetail';
import { RequestListTable } from './RequestListTable';
import { RequestTransactModal } from './RequestTransactModal';
import { useRequestList } from './useRequestList';

export const RequestList: React.FunctionComponent = () => {
	const {
		tableProps,
		register,
		handleFilter,
		detailId,
		handleDetailClose,
		isShowTransact,
		checkedNum,
		handleClickTransact,
		handleCloseTransactModal
	} = useRequestList();

	return (
		<>
			{detailId &&
				<RequestDetail requestId={detailId} onClose={handleDetailClose} />
			}
			{isShowTransact &&
				<RequestTransactModal onClose={handleCloseTransactModal} />
			}
			<PageTitle>特典申込受付</PageTitle>
			<PageDesc>特典申込受付の確認ができます。また、申込者の送り先情報をダウンロードすることができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<button className="el_btn mr_8" type="button" onClick={handleClickTransact}>送り先情報CSVダウンロード</button>
							<span className="el_selectedCount mr_32">選択中 {checkedNum()}件</span>
							<span className="el_selectBorderLessWrap">
								<select className="el_selectBorderLess" name="sort" ref={register} onChange={handleFilter}>
									<option value="new">新着順</option>
									<option value="old">古い順</option>
								</select>
							</span>
						</div>
						<RequestListTable
							{...tableProps}
							handleFilter={handleFilter}
							/>
						<div className="bl_panel_headerFooter">
							<button className="el_btn mr_8" type="button" onClick={handleClickTransact}>送り先情報CSVダウンロード</button>
							<span className="el_selectedCount">選択中 {checkedNum()}件</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default RequestList;