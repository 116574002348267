import { css } from "@emotion/react";
import * as React from 'react';

interface DescProps {
	className?: string;
	noMargin?: boolean;
	children: React.ReactNode;
}
export const Desc: React.FC<DescProps> = (props) => {

	const style = css`
		margin-bottom: 24px !important;
	`;

	return <p css={props.noMargin || style} className={props.className}>{props.children}</p>;
}
export default Desc;
