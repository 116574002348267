type Exclusive<
		T extends Record<PropertyKey, unknown>,
		U extends Record<PropertyKey, unknown>
	> =
		| (T & { [k in Exclude<keyof U, keyof T>]?: never })
		| (U & { [k in Exclude<keyof T, keyof U>]?: never });

export type WindowOpenOptions = {
		url?: string | URL;
		target?: string;
		popup?: boolean;
		noopener?: boolean;
		noreferrer?: boolean;
	} &
	Exclusive<{ width?: number; }, { innerWidth ?: number; }> &
	Exclusive<{ height?: number; }, { innerHeight ?: number; }> &
	Exclusive<{ left?: number; }, { screenX ?: number; }> &
	Exclusive<{ top?: number; }, { screenY ?: number; }>;

const notFeaturesOptions = ['url', 'target'];
const boolOptions = ['popup', 'noopener', 'noreferrer'];

export function windowOpen(opt: WindowOpenOptions): WindowProxy | null {
	const features = Object.entries(opt)
			.flatMap(([key, val]) => {
				if (notFeaturesOptions.includes(key)) {
					return [];
				}
				if (boolOptions.includes(key)) {
					return val ? `${key}` : [];
				}
				return `${key}=${val}`;
			})
			.join(',');
	return window.open(opt.url, opt.target, features);
}
export default windowOpen;
