import { MailContent } from '@sasagase/types';
import * as React from 'react';
import { setModal, useAppState } from '../../../context';

interface PreviewProps {
	values: MailContent;
	onClose: () => void;
	onApply?: () => void;
}

export function Preview(props: PreviewProps): React.ReactElement {
	const [, setState] = useAppState();

	React.useEffect(() => {
		setState(setModal(true));
		return () => setState(setModal(false));
	}, []);

	const handleClickClose = () => {
		props.onClose();
	};

	const handleClickApply = () => {
		if (props.onApply) {
			props.onApply();
		}
	};

	return (
		<>
			<div className="bl_modalBg">
				<div className="bl_modal bl_modal__mailPreview">
					<div className="bl_modal_header">
						<h1 className="bl_modal_ttl">メールプレビュー</h1>
						<button className="el_closeBtn" onClick={handleClickClose}></button>
					</div>
					<div className="bl_modal_mailHeader">
						<dl className="bl_modal_mailHeader_row">
							<dt>差出人</dt>
							<dd>{props.values.from}</dd>
						</dl>
						<dl className="bl_modal_mailHeader_row">
							<dt>件名</dt>
							<dd>{props.values.getSubject()}</dd>
						</dl>
						<dl className="bl_modal_mailHeader_row">
							<dt>宛先</dt>
							<dd>{props.values.to}</dd>
						</dl>
					</div>
					<div className="bl_modal_mailBody">
						<div className="bl_modal_mailBodyInner">
							{props.values.getBody()}
						</div>
					</div>
					{props.onApply &&
						<div className="bl_panel_row mt_16 mb_0">
							<button className="el_btnInv" type="button" onClick={handleClickApply}>適用</button>
						</div>
					}
				</div>
			</div>
		</>
	);
}
export default Preview;