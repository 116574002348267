import { User } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAPI, useAppState } from "../../../context";
import { FormValues } from './UserEdit';

type AccountSettingEditParams = {
	userId?: string;
}

const capMaps: Record<string, Record<string, string | null>> = {
	yReview_reviewCheckTool: {//レビューチェックツール
		'hide': null,
		'read': 'yReview_reviewCheckTool_read',
	},
	yReview_labor: {//レビュー待ち状況・特典申込受付
		'hide': null,
		'read': 'yReview_labor_read',
		'write': 'yReview_labor_write',
	},
	yReview_management: {//レビュー特典登録・メール設定
		'hide': null,
		'read': 'yReview_management_read',
		'write': 'yReview_management_write',
	},
	yReview_system: {//ユーザーアカウント設定・Yahooシステム設定
		'hide': null,
		'write': 'yReview_system_write',
	},
};

interface useUserEditProps {
	initValues: FormValues;
}

export const useUserEdit = (props: useUserEditProps) => {
	const params = useParams<AccountSettingEditParams>();
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
		shouldUnregister: false,
	});
	const { reset } = formMethods;

	const toCapabilities = (values: Record<string, any>): Record<string, any>[] => {
		const disabled = values.account_state === 'active' ? false : true;
		const expire = values.account_expired === 'enabled' ? new Date(values.expired_at).getTime() : undefined;

		const capabilities: Record<string, any>[] = [];
		for (const [capType, capMap] of Object.entries(capMaps)) {
			const capName = capMap[values[capType]];
			if (capName) {
				capabilities.push({
					name: capName,
					attributes: { disabled, expire },
				});
			}
		}
		capabilities.push({
			name: 'yReview_login',
			attributes: { disabled, expire },
		});
		return capabilities;
	};

	const toValues = (shopId: string, user: User): FormValues => {
		const userState = user.getState(shopId);
		let expiredAt = null;
		if (['active', 'expired'].includes(userState)) {
			const ex = user.getExpire(shopId);
			expiredAt = ex ? new Date(ex) : null;
		}
		const values: FormValues = {
			name: user.name || '',
			mail: user.mail || '',
			yReview_reviewCheckTool: user.hasEnableCaps('yReview_reviewCheckTool_read', shopId) ? 'read' : 'hide',
			yReview_labor: 
				user.hasEnableCaps('yReview_labor_write', shopId) ? 'write':
				user.hasEnableCaps('yReview_labor_read', shopId) ? 'read': 'hide',
			yReview_management: 
				user.hasEnableCaps('yReview_management_write', shopId) ? 'write':
				user.hasEnableCaps('yReview_management_read', shopId) ? 'read': 'hide',
			yReview_system: user.hasEnableCaps('yReview_system_write', shopId) ? 'write' : 'hide',
			account_expired: expiredAt ? 'enabled': 'disabled',
			expired_at: expiredAt,
			account_state: userState === 'suspend' ? 'suspend' : 'active',
		}
		return values;
	};

	React.useEffect(() => {
		if (!params.userId) {
			return;
		}
		const param = {
			shopId,
			userId: params.userId
		}
		return callAPI.user.getEntityUser(param, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const user = User.create(resData as Record<string, unknown>);
				reset(toValues(shopId, user));
			}
		});
	}, [shopId, params.userId]);

	const handleSave: SubmitHandler<FormValues> = async (values): Promise<boolean> => {
		try {
			if (params.userId) {
				const res = await callAPI.user.putEntityUserCapability({
						shopId,
						userId: params.userId,
						capabilities: toCapabilities(values)
					});
				return Boolean(res && res.data);
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	const returnListUrl = `${state.params.basePath}/setting/user`;

	return {
		methods: formMethods,
		handleSave,
		returnListUrl,
	};
};
export default useUserEdit;