import { YahooReward } from '@sasagase/types';
import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputInlineDesc } from '../../molecules/InputInlineDesc';
import { FormGroup, InputCheck } from '../../organisms/Form';

interface CampaignWizardS2SelectMultiProps {
	rewards: YahooReward[];
	setAddReward: (rewardType: string) => void;
}
export const CampaignWizardS2SelectMulti: React.FC<CampaignWizardS2SelectMultiProps> = (props) => {

	const handleAddReward = (rewardType: string) => {
		props.setAddReward(rewardType);
	}

	return (
		<>
			<FormGroup>
				<FormLabel>レビュー特典を２つ以上選択してください</FormLabel>
				{props.rewards.map(reward =>
					<><InputCheck className="el_checkMark" key={reward.id} name="rewardIds" value={reward.id}>{reward.name}</InputCheck><br /></>
				)}
				<p className='mt_8'>
					<InputInlineDesc>※新しいプレゼント品・クーポンを追加する場合はこちらから</InputInlineDesc><br />
					<Button className='el_btnTxt' onClick={() => handleAddReward('item')}>⇒新しいプレゼント品を追加する</Button><br />
					<Button className='el_btnTxt' onClick={() => handleAddReward('coupon')}>⇒新しいクーポンを追加する</Button><br />
				</p>
			</FormGroup>
		</>
	);
}
export default CampaignWizardS2SelectMulti;
