import * as React from 'react';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../hooks';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { PasswordEdit } from './PasswordEdit';
import { UserEdit } from './UserEdit';

const initFormIsDirtys = {
	userEdit: false,
	passwordEdit: false,
};
export type FormIsDirtys = typeof initFormIsDirtys;

export const Management: React.FunctionComponent = () => {
	const [formIsDirtys, setFormIsDirtys] = React.useState<FormIsDirtys>(initFormIsDirtys);
	usePrompt(PROMPT_CONFIRM_MESSAGE, Object.values(formIsDirtys).some(v => v));

	return (
		<>
			<PageTitle>ユーザー管理</PageTitle>
			<PageDesc>ユーザー名やメールアドレスの確認・編集を行うことができます。<br className="show_mobile" />また、パスワードの再設定ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__4">
					<UserEdit setFormIsDirtys={setFormIsDirtys} />
				</div>
				<div className="bl_col bl_col__4">
					<PasswordEdit setFormIsDirtys={setFormIsDirtys} />
				</div>
			</div>
		</>
	);
};
export default Management;