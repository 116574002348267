import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, InputFile, InputRadio, SubmitButton } from '../../organisms/Form';
import useShopSetting from './useShopSetting';

const initValues = {
	shopName: '',
	companyName: '',
	logoImage: '',
	shopUrl: '',
	contactType: '',
	contactPhone: '',
	contactMail: '',
};
export type FormValues = typeof initValues;

export const ShopSetting: React.FunctionComponent = () => {
	const {
		methods,
		imageUrl,
		handleChangeFile,
		handleClickDeleteImage,
		handleSave,
		handleClickPreview,
	} = useShopSetting(initValues);

	const { getValues } = methods;

	return (
		<>
			<PageTitle>店舗設定</PageTitle>
			<PageDesc>お客様がレビュー特典申込の URL にアクセスした際などにこちらで設定した店舗情報が反映されます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<Form methods={methods} onSubmit={handleSave}>
							<RequiredDesc />
							<FormGroup>
								<FormLabel required>店舗名</FormLabel>
								<Input type="text" name="shopName" rules={{ required: '店舗名を入力してください' }} />
								<ErrorMessage name="shopName" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>運営会社名</FormLabel>
								<Input type="text" name="companyName" rules={{ required: '運営会社名を入力してください' }} />
								<ErrorMessage name="companyName" />
							</FormGroup>
							<FormGroup>
								<FormLabel>店舗ロゴ</FormLabel>
								<div className="bl_imgUpload">
									<div className="bl_imgUpload_logoimgWrap">
										{imageUrl &&
											<img src={imageUrl} />
										}
									</div>
									<div className="bl_imgUpload_txtWrap">
										<InputFile name="logoImage" onChange={handleChangeFile} />
										<Button className="el_btnTxt" onClick={handleClickDeleteImage('logoImage')}>削除</Button>
										<InputDesc>※画像サイズは280px×100pxを推奨</InputDesc>
									</div>
								</div>
								<ErrorMessage name="logoImage" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>店舗URL</FormLabel>
								<Input type="text" name="shopUrl" rules={{ required: '店舗URLを入力してください' }} />
								<ErrorMessage name="shopUrl" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>問い合わせ先</FormLabel>
								<InputRadio name="contactType" value="phone" label="電話番号を設定する" rules={{ required: '問い合わせ先を選択してください'}} />
								<div className='pl_26'>
									<Input type="text" name="contactPhone" rules={{
										validate: (v: string) => (getValues('contactType') == 'phone' && v == '') ? '問い合わせ先の電話番号を入力してください' : undefined
									}} />
									<ErrorMessage name="contactPhone" />
								</div>
								<InputRadio name="contactType" value="mail" label="メールアドレスを設定する" />
								<div className='pl_26'>
									<Input type="text" name="contactMail" rules={{
										validate: (v: string) => (getValues('contactType') == 'mail' && /\S+@\S+.\S+/.test(v) == false) ? '問い合わせ先のメールアドレスを入力してください' : undefined
									}} />
									<ErrorMessage name="contactMail" />
								</div>
								<ErrorMessage name="contactType" />
							</FormGroup>
							<FormGroup>
								<FormLabel>申込みフォームのプレビュー</FormLabel>
								<Button className="el_btnBlueInv mr_8" onClick={() => handleClickPreview('unapplied')}>申込み前のプレビューを表示</Button>
								<Button className="el_btnBlueInv" onClick={() => handleClickPreview('applied')}>申込み後のプレビューを表示</Button>
							</FormGroup>
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default ShopSetting;