import { Notices } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';

interface NoticeListItemProps {
	notice: Notices.Content;
}

export function NoticeListItem(props: NoticeListItemProps): React.ReactElement {
	const date = new Date(props.notice.date);
	const dateId = date.toISOString();
	const dateString = dayjs(date).format('YYYY.MM.DD');

	const toTitle = (notice: Notices.Content) => {
		if (!notice.link) {
			return notice.title;
		}
		return <a className="el_noticeLink" href={notice.link} rel="noopener noreferrer" target="_blank">{notice.title}</a>;
	}

	return (
		<li>
			<time dateTime={dateId}>{dateString}</time>
			<p>{toTitle(props.notice)}</p>
		</li>
	);
}
export default NoticeListItem;