import * as React from 'react';
import MarkDown from 'react-markdown';
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { GuideNavigationProps } from "./GuideNavigation";

interface GuideNavigationIndexProps extends GuideNavigationProps {
	naviPath: string;
}

export const GuideNavigationIndex: React.FunctionComponent<GuideNavigationIndexProps> = (props) => {
	if (!props.isMarkdown || props.naviPath != props.paramPath) {
		return <></>;
	}

	const [md, setMd] = React.useState('');

	React.useEffect(() => {
		async function getMd() {
			const res = await fetch(props.path);
			return res.text();
		}
		void getMd()
			.then(md => {
				setMd(md);
			});
	}, [props.path]);

	const modifyH3Element = React.useCallback(({ children }: {children?: React.ReactNode}) => {
		const el = React.Children.map(children, (child) => {
			return <li className="bl_guideNav_item_index index_h3"><Link to={props.folderPath + "/" + props.naviPath + "#" + child?.toString()}>{child}</Link></li>
		});

		return <>{el}</>
	}, []);

	const modifyH4Element = React.useCallback(({ children }: {children?: React.ReactNode}) => {
		const el = React.Children.map(children, (child) => {
			return <li className="bl_guideNav_item_index index_h4"><Link to={props.folderPath + "/" + props.naviPath + "#" + child?.toString()}>{child}</Link></li>
		});

		return <>{el}</>
	}, []);

	return (
		<ul>
			<MarkDown
				rehypePlugins={[rehypeRaw]}
				allowedElements={['h3', 'h4']}
				components={{
					h3: modifyH3Element,
					h4: modifyH4Element,
				}}>{md}</MarkDown>
		</ul>
	);
};
export default GuideNavigationIndex;