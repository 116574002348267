import { superstructResolver } from '@hookform/resolvers/superstruct';
import { CampaignState, ItemGroup, MailTemplate, NIL, YahooCampaign, YahooReward, YahooRewardBase, YahooShopEntity, campaignStateInfos } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Struct, intersection, is } from 'superstruct';
import { v4 as uuid } from 'uuid';
import { useAPI, useAppState } from "../../../context";
import { useItemGroupEdit } from '../../../hooks';
import { toDate, toDateFields, toDaysField } from '../../organisms/Form';
import CampaignEditFormStep1 from './CampaignEditStep1';
import CampaignEditFormStep2 from './CampaignEditStep2';
import CampaignEditFormStep3 from './CampaignEditStep3';
import CampaignEditFormStep4 from './CampaignEditStep4';
import { CampaignEditFormStep1FormValue, CampaignEditFormStep1Struct, toCutCheck } from './useCampaignEditStep1';
import { CampaignEditFormStep2FormValue, CampaignEditFormStep2Struct } from './useCampaignEditStep2';
import { CampaignEditFormStep3FormValue, CampaignEditFormStep3Struct } from './useCampaignEditStep3';
import { CampaignEditFormStep4FormValue, CampaignEditFormStep4Struct, MailFormValue } from './useCampaignEditStep4';

type StepComponent = React.FC<{
	rewards: YahooReward[];
	groups: ItemGroup[];
	templates: MailTemplate[];
	reviewFormCond: () => Record<string, boolean>;
	campaign: YahooCampaign;
}>;
export interface Step {
	component: StepComponent;
	name: string;
	title: string;
	struct: Struct<any, any>;
	isError?: boolean;
}

export const schema = intersection([
	CampaignEditFormStep1Struct,
	CampaignEditFormStep2Struct,
	CampaignEditFormStep3Struct,
	CampaignEditFormStep4Struct,
]);

let STEPS: Step[] = [
	{ component: CampaignEditFormStep1, name: 'Step1', title: '基本設定', struct: CampaignEditFormStep1Struct },
	{ component: CampaignEditFormStep2, name: 'Step2', title: '特典設定', struct: CampaignEditFormStep2Struct },
	{ component: CampaignEditFormStep3, name: 'Step3', title: '対象商品設定', struct: CampaignEditFormStep3Struct },
	{ component: CampaignEditFormStep4, name: 'Step4', title: 'メール設定', struct: CampaignEditFormStep4Struct },
	// { component: CampaignEditFormStep5, name: 'Step5', title: 'バナー・ページ設定', struct: CampaignEditFormStep5Struct },
	// 本来なら確認・保存はStep6になるが、バナー・ページ設定を非表示にしているのでnameプロパティをStep5へ変更している
	// { component: CampaignEditFormStep6, name: 'Step5', title: '確認・保存', struct: schema },
	// タブ形式に変更するので確認・保存は不要
];

export type CampaignEditFormValues =
	CampaignEditFormStep1FormValue &
	CampaignEditFormStep2FormValue &
	CampaignEditFormStep3FormValue &
	CampaignEditFormStep4FormValue;

type CampaignEditParams = {
	shopId?: string;
	campaignId?: string;
	flag?: string;
}

interface useCampaignEditProps {
	initValues: CampaignEditFormValues;
	params?: CampaignEditParams;
	disableNavigateAfterSubmitted?: boolean;
}

export const useCampaignEdit = (props: useCampaignEditProps) => {
	const params = props.params || useParams<CampaignEditParams>();
	const isNew = params.campaignId == 'new';
	const isCopy = params.flag === 'copy';

	const [state] = useAppState();
	const shopId = state.params.shopId;
	const location = useLocation();
	const urlParamSeq = new URLSearchParams(location.search).get("seq");
	const callAPI = useAPI();
	const navigate = useNavigate();
	const [beforeCampaign, setBeforeCampaign] = React.useState<YahooCampaign | null>(null);
	const [rewards, setRewards] = React.useState<YahooReward[] | null>(null);
	const [groups, setGroups] = React.useState<ItemGroup[] | null>(null);
	const [templates, setTemplates] = React.useState<null|MailTemplate[]>(null);
	const [validateCheck, setValidateCheck] = React.useState(false);
	const formMethods = useForm<CampaignEditFormValues>({
		defaultValues: props.initValues,
		shouldUnregister: false,
		mode: 'onBlur',
		resolver: superstructResolver(schema),
	});
	const { getValues, watch, reset, trigger } = formMethods;

	const { sanitizeSku } = useItemGroupEdit();

	const toMail = (values: MailFormValue | undefined): YahooCampaign['followMail'] => {
		const bodys = [
			{ type: "header" as const, content: values ? values.bodyHeader : "" },
			...(values?.bodyElement ?? [])
		];
		const mail = {
			disable: values ? values.disable === 'true' : undefined,
			subject: values ? values.subject : "",
			body: bodys,
			signature: values ? values.signature : "",
		};
		return mail;
	};

	const toInstance = (id: string, values: CampaignEditFormValues, groups: ItemGroup[] | null, rewards: YahooReward[] | null): YahooCampaign | null => {
		if (!groups || !rewards) {
			return null;
		}
		const groupMap = new Map(groups.map(group => [group.id, group]));
		const rewardMap = new Map(rewards.map(reward => [reward.id, reward]));

		const isNeverEnd = values.isNeverEnd == 'true';
		const isCutDate = values.isCutCheck == 'date';
		const isCutDays = values.isCutCheck == 'days';
		const shouldCloseApplication = values.shouldCloseApplication == 'true';
		const isDraft = !is(values, schema);

		const campaign = YahooCampaign.create({
			id,
			itemGroup: ItemGroup.create({
				id: NIL,
				name: `キャンペーン ${values.name}`,
				isRef: true,
				isAll: values.itemGroup.isAll == 'true',
				sku: values.itemGroup.isAll == 'true' ? [] :
					values.itemGroup.skus.split(/[,\t\n]/).map(str => sanitizeSku(str.trim())).filter(Boolean),
				excludeSku: values.itemGroup.excludeSkus.split(/\s*[,\t\n]\s*/).map(str => sanitizeSku(str.trim())).filter(Boolean),
				childGroups: values.itemGroup.isAll == 'true' ? [] :
					values.itemGroup.childGroupIds.map(id => groupMap.get(id)),
				excludeChildGroups: values.itemGroup.excludeChildGroupIds?.map(id => groupMap.get(id)),
			}),
			rewards: values.rewards.filter(reward => reward.val).map(reward => rewardMap.get(reward.val)),
			priority: values.priority,
			name: values.name,
			beginDate: toDate(values.begin).getTime() || undefined,
			endDate: isNeverEnd ? undefined : (toDate(values.end).getTime() || undefined),
			reviewDeadlineDate: isCutDate ? (toDate(values.deadlineDate).getTime() || undefined) : undefined,
			reviewDeadlineDays: isCutDays ? Number(values.deadlineDays) || undefined : undefined,
			isNeverEnd,
			isCutDate,
			isCutDays,
			isItemReviewRequired: values.reviewRequired == 'item' || values.reviewRequired == 'both',
			isShopReviewRequired: values.reviewRequired == 'shop' || values.reviewRequired == 'both',
			isAnyReviewRequired: values.reviewRequired == 'any',
			canManyRewards: values.canManyRewards == 'true',
			canChooseDest: values.destType == 'form',
			defaultDestType: ['shipping', 'orderer'].includes(values.destType) ? values.destType : 'shipping',
			applicationClosingDays: shouldCloseApplication ? Number(values.applicationClosingDays) : undefined,
			shouldCloseApplication,
			followMail: toMail(values.followMail),
			requestMail: toMail(values.requestMail),
			receivedMail: toMail(values.receivedMail),
			isDraft,

			inProgressDate: isDraft ? undefined : values.inProgressDate,
			waitingReviewDate: isDraft ? undefined : values.waitingReviewDate,
			finishedDate: isDraft ? undefined : values.finishedDate,
			cancelledDate: isDraft ? undefined : values.cancelledDate,
		});
		return campaign;
	};

	const toMailValues = (mail: YahooCampaign['followMail']): MailFormValue => {
		const bodyHeaderData = mail.body.find((val) => val.type === "header");
		return {
			disable: mail.disable ? 'true' : 'false',
			template: '',
			subject: mail.subject,
			bodyHeader: bodyHeaderData ? bodyHeaderData.content : '',
			bodyElement: mail.body.filter((val) => val.type !== "header"),
			signature: mail.signature,
			email: '',
		};
	};

	const toValues = (campaign: YahooCampaign): CampaignEditFormValues => {
		const toBoolString = (bool: boolean) => bool ? 'true' as const : 'false' as const;
		const destType = campaign.canChooseDest ? 'form' as const : 
			campaign.defaultDestType === 'shipping' ? 'shipping' as const : 'orderer' as const;

		const values = {
			priority: campaign.priority,
			name: campaign.name,
			begin: toDateFields(campaign.beginDate),
			end: toDateFields(campaign.endDate),
			deadlineDate: toDateFields(campaign.reviewDeadlineDate),
			deadlineDays: toDaysField(campaign.reviewDeadlineDays),
			isNeverEnd: toBoolString(campaign.isNeverEnd),
			isCutCheck: toCutCheck(campaign),
			reviewRequired:
				campaign.isAnyReviewRequired ? 'any' as const :
				campaign.isItemReviewRequired && campaign.isShopReviewRequired ? 'both' as const :
				campaign.isShopReviewRequired ? 'shop' as const : 'item' as const,
			canManyRewards: toBoolString(campaign.canManyRewards),

			inProgressDate: campaign.inProgressDate,
			waitingReviewDate: campaign.waitingReviewDate,
			finishedDate: campaign.finishedDate,
			cancelledDate: campaign.cancelledDate,

			rewards: campaign.rewards.map(r => ({ val: r.id })),
			isCouponReward: campaign.rewards.some(reward => reward.isCoupon),
			isManyRewardOriginal: campaign.rewards.length > 1 ? true : false,
			destType: destType,
			destTypeOriginal: destType,
			applicationClosingDays: String(campaign.applicationClosingDays ?? ''),
			shouldCloseApplication: toBoolString(campaign.shouldCloseApplication),
			itemGroup: {
				isAll: campaign.itemGroup.isAll ? 'true' : 'false',
				inputSkus: campaign.itemGroup.sku.join(','),
				skus: campaign.itemGroup.sku.join(','),
				inputExcludeSkus: campaign.itemGroup.excludeSku.join(','),
				excludeSkus: campaign.itemGroup.excludeSku.join(','),
				childGroupIds: campaign.itemGroup.childGroups.map(group => group.id),
				excludeChildGroupIds: campaign.itemGroup.excludeChildGroups.map(group => group.id),
				group: {},
				excludeGroup: {},
				itemName: '',
				upper: '',
				lower: '',
				excludeItemName: '',
				excludeUpper: '',
				excludeLower: '',
			},
			followMail: toMailValues(campaign.followMail),
			requestMail: toMailValues(campaign.requestMail),
			receivedMail: toMailValues(campaign.receivedMail),
		};
		if (values.rewards.length <= 0) {
			values.rewards.push({ val: '' });
		}
		return values;
	};

	React.useEffect(() => {
		if (isNew || !shopId || !params.campaignId || !state.shop) {
			return;
		}
		const email = String(state.user?.mail || '');
		const apiParams = {
			shopId,
			campaignId: params.campaignId,
			seq: urlParamSeq ?? undefined
		};
		return callAPI.yReview.getCampaign(apiParams, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const campaign = YahooCampaign.create(result.data);
				setBeforeCampaign(campaign);

				const values = toValues(campaign);
				if (isCopy) {
					values.priority = Date.now();
					values.name += ' のコピー';
					values.inProgressDate = undefined;
					values.waitingReviewDate = undefined;
					values.finishedDate = undefined;
					values.cancelledDate = undefined;
				}
				values.followMail.email = email;
				values.requestMail.email = email;
				values.receivedMail.email = email;
				reset(values);
				setValidateCheck(true);
			}
		});
	}, [shopId, params.campaignId, state.shop]);

	React.useEffect(() => {
		if (!state.shop || !isNew) {
			return;
		}
		const email = String(state.user?.mail || '');
		return callAPI.yReview.getShop({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = YahooShopEntity.create(result.data);
			const vals = props.initValues;
			vals.priority = Date.now();
			vals.followMail.signature = shopEntity.signature;
			vals.followMail.email = email;
			vals.requestMail.signature = shopEntity.signature;
			vals.requestMail.email = email;
			vals.receivedMail.signature = shopEntity.signature;
			vals.receivedMail.email = email;
			reset(vals);
		});
	}, [state.shop]);

	React.useEffect(() => {
		if (!params.shopId) {
			return;
		}
		return callAPI.yReview.getRewards({ shopId }, (err, result) => {
			if (err) {
				return null;
			}
			const rewards: YahooReward[] = result.data.map((obj: Record<string, unknown>) => YahooRewardBase.create(obj));
			setRewards(rewards);
		});
	}, [params.shopId]);

	React.useEffect(() => {
		if (!params.shopId) {
			return;
		}
		return callAPI.yReview.getGroups({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const groups: ItemGroup[] = result.data.map((obj: Record<string, unknown>) => ItemGroup.create(obj));
			setGroups(groups);
		});
	}, [params.shopId]);

	React.useEffect(() => {
		if (!params.shopId) {
			return;
		}
		return callAPI.yReview.getTemplates({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const templates = result.data.map((obj: Record<string, unknown>) => MailTemplate.create(obj));
			setTemplates(templates);
		});
	}, [params.shopId]);

	// 入力チェックの実行
	React.useEffect(() => {
		if (validateCheck) {
			const values = getValues();
			void trigger(Object.keys(values));
			setValidateCheck(false);
		}
	}, [validateCheck]);

	const handleSave: SubmitHandler<CampaignEditFormValues> = async (values: CampaignEditFormValues): Promise<boolean> => {
		if (!groups || !rewards || !params.shopId || !params.campaignId) {
			return false;
		}

		try {
			const id = isNew || isCopy ? uuid() : params.campaignId;
			const campaign = toInstance(id, values, groups, rewards);
			if (!campaign) {
				return false;
			}
			if (isNew || isCopy) {
				const res = await callAPI.yReview.postCampaign({ shopId, campaign });
				if (res && !props.disableNavigateAfterSubmitted) {
					const params = new URLSearchParams({ seq: res.data.seq });
					const to = `${state.params.basePath}/reward/campaign/${id}?` + params.toString();
					navigate(to, {state: {newId: id}, replace: true});
				}
			} else {
				const campaignId = params.campaignId;
				await callAPI.yReview.putCampaign({ shopId, campaignId, campaign });
			}

			// リセットされるため、保存後にvalidationを再実行
			//   →この更新で再描画
			//      →メール設定のフォームの入力内容が元にもどる（DBへは反映されてるのでリロードすれば変更後の値が表示される）ので一旦処理止める
			// setValidateCheck(true);

			return true;
		} catch (err) {
			return false;
		}
	};

	const toStateString = (state: CampaignState) => {
		return campaignStateInfos[state]?.name || state;
	};
	const reviewFormCond = () => {
		const rewardIds = (watch('rewards') as { val: string }[]).map(r => r.val);
		const rewardSelects = (rewards || []).filter(reward => rewardIds.includes(reward.id));
		const canChooseReward = rewardSelects.length > 1;
		const hasItemReward = rewardSelects.some(reward => !reward.isCoupon);
		const canChooseDest = hasItemReward && watch('destType') == 'form';
		return {
			canChooseReward,
			canChooseDest,
			hasItemReward,
			disabledReviewForm: !canChooseReward && !canChooseDest,
			disabledDestType: canChooseReward || !hasItemReward,
		};
	};

	const returnListUrl = `${state.params.basePath}/reward/campaign`;
	const isUpdate = !isNew && !isCopy;
	const editingCampaign = toInstance(NIL, getValues(), groups, rewards);
	STEPS = STEPS.map(step => ({ ...step, isError: !is(getValues(), step.struct) }));

	return {
		methods: formMethods,
		handleSave,
		returnListUrl,
		STEPS,
		rewards,
		groups,
		templates,
		toStateString,
		reviewFormCond,
		isUpdate,
		beforeCampaign,
		editingCampaign,
	};
};
export default useCampaignEdit;