import { addPageParams, Api, APIMethodCaller, ExtractedPageParams } from '@sasagase/api-caller';
import { getPageInfo, getPageInfoEmpty, PageInfo } from '@sasagase/types';
import * as React from 'react';
import { ContextState } from './state';

export * from './setter';
export * from './state';

type CanArray<T> = T | T[];
export type SetStateActions = CanArray<React.SetStateAction<ContextState>>;
type AppDispatch = React.Dispatch<SetStateActions>;
export type StateSetTuple = [ContextState, AppDispatch];

function defaultFunc(...args: unknown[]) {
	console.error('Not under the provider.', ...args);
}

const context = React.createContext<StateSetTuple>([{}, defaultFunc]);
const callAPIContext = React.createContext<Api>(new Api(defaultFunc));

export const providers = {
	state: context.Provider,
	api: callAPIContext.Provider,
};

export function useAPI(): Api {
	return React.useContext(callAPIContext);
}

type APILoadConverter<T> = (all: T | undefined, current: T | undefined) => T | undefined;
type APIDataTableLoadConverter<T> = (all: T | undefined, current: T | undefined) => T | undefined;

interface APILoadController<T> {
	reload: ((initValue: T) => void) & (() => void);
	setConverter: ((callback: APILoadConverter<T>) => void);
	count: number;
	isLoading: boolean;
}
interface APIDataTableLoadController<T, P> {
	filter: (params: Record<string, unknown>, initValue: T) => void;
	reload: ((initValue: T) => void) & (() => void);
	loadMore: () => void;
	reRender: ((initValue: T) => void) & (() => void);
	pageInfo?: PageInfo;
	requestedParams?: P,
	count: number;
	isLoading: boolean;
}
type UseAPILoadRet<T> = [T, APILoadController<T>];
type UseAPIDataTableLoadRet<T, P> = [T, APIDataTableLoadController<T, P>];

interface APILoadParameter<T, U, P> {
	initValue?: T;
	apiParams: P;
	onFetch: (data: U) => T;
	onConverter?: APILoadConverter<T>;
}
interface APIDataTableLoadParameter<T, U, P> {
	initValue?: T;
	apiParams: P;
	page?: number;
	perPage?: number;
	onFetch: (data: U) => [T, PageInfo | undefined];
	disableInitLoad?: boolean;
}

function defaultConverter<T>(all: T | undefined, current: T | undefined): T | undefined {
	return all || current;
}
function loadMoreConverter<T>(all: T | undefined, current: T | undefined): any {
	if (Array.isArray(all) && Array.isArray(current)) {
		return [ ...current, ...all ];
	}
	return defaultConverter(all, current);
}

export function useAPILoad<U, T, P extends Record<string, unknown>>(api: APIMethodCaller<U, P> | null, params: APILoadParameter<T, U, P>): UseAPILoadRet<T | undefined> {
	const { initValue, apiParams, onFetch, onConverter } = params;

	const hasApiRequest = Boolean(api);

	interface State {
		apiParams: P,
		count: number;
		all: T | undefined;
		value: T | undefined;
		isLoading: boolean;
		onConverter: APILoadConverter<T>;
	}
	const [state, setState] = React.useState<State>({
		apiParams,
		count: 0,
		all: initValue,
		value: initValue,
		isLoading: hasApiRequest,
		onConverter: onConverter || defaultConverter,
	});

	React.useEffect(() => {
		if (!api) {
			return;
		}

		return api(apiParams, (err, result) => {
			if (err) {
				setState(prev => ({
					...prev,
					isLoading: false,
				}));
				return;
			}

			const value = onFetch(result.data);
			setState(prev => ({
				...prev,
				all: value,
				value: prev.onConverter(value, prev.value),
				isLoading: false,
			}));
		});
	}, [state.count, hasApiRequest]);

	const reload = React.useCallback((initValue?: T) => {
		setState(prev => ({
			...prev,
			count: prev.count + 1,
			value: initValue === undefined ? prev.value : prev.onConverter(initValue, prev.value),
			isLoading: hasApiRequest,
		}));
	}, [setState, hasApiRequest]);

	const setConverter = React.useCallback((callback: APILoadConverter<T>) => {
		setState(prev => ({
			...prev,
			value: callback(prev.all, prev.value),
			onConverter: callback,
		}));
	}, [setState]);

	const ret = React.useMemo<UseAPILoadRet<T | undefined>>(() => {
		const controller = {
			reload,
			setConverter,
			count: state.count,
			isLoading: state.isLoading,
		};
		return [state.value, controller];
	}, [state]);

	return ret;
}

export function useAPIDataTableLoad<U, T, P extends Record<string, unknown>>(api: APIMethodCaller<U, P & ExtractedPageParams | P> | null, params: APIDataTableLoadParameter<T, U, P>): UseAPIDataTableLoadRet<T | undefined, P & ExtractedPageParams | P> {
	const { initValue, apiParams, page, perPage, onFetch, disableInitLoad } = params;

	const hasApiRequest = Boolean(api);

	interface State {
		initParams: P, 
		apiParams: P,
		count: number;
		all: T | undefined;
		value: T | undefined;
		pageInfo?: PageInfo;
		requestedParams?: P,
		isLoading: boolean;
		defaultConverter: APIDataTableLoadConverter<T>;
		loadMoreConverter: APIDataTableLoadConverter<T>;
	}
	const [state, setState] = React.useState<State>({
		initParams: apiParams,
		apiParams,
		count: disableInitLoad ? 0 : 1,
		all: initValue,
		value: initValue,
		pageInfo: perPage ? (page ? getPageInfo(perPage, page, 0) : getPageInfoEmpty(perPage)) : undefined,
		isLoading: hasApiRequest,
		defaultConverter: defaultConverter,
		loadMoreConverter: loadMoreConverter,
	});

	React.useEffect(() => {
		if (!api || state.count <= 0) {
			return;
		}

		const requestParams = addPageParams(state.apiParams, state.pageInfo);
		return api(requestParams, (err, result) => {
			if (err) {
				setState(prev => ({
					...prev,
					isLoading: false,
				}));
				return;
			}

			const [value, pageInfo] = onFetch(result.data);
			setState(prev => ({
				...prev,
				all: value,
				value: pageInfo ?
					prev.loadMoreConverter(value, prev.value) : prev.defaultConverter(value, prev.value),
				pageInfo: pageInfo,
				requestedParams: requestParams,
				isLoading: false,
			}));
		});
	}, [state.count, hasApiRequest]);

	const filter = React.useCallback((params?: Record<string, unknown>, initValue?: T) => {
		setState(prev => ({
			...prev,
			apiParams: {
				...prev.initParams,
				...params
			},
			count: prev.count + 1,
			value: initValue === undefined ? prev.value : prev.defaultConverter(initValue, prev.value),
			pageInfo: prev.pageInfo ? { ...prev.pageInfo, page: 1 } : undefined,
			isLoading: hasApiRequest,
		}));
	}, [setState, hasApiRequest]);

	const reload = React.useCallback((initValue?: T) => {
		setState(prev => ({
			...prev,
			count: prev.count + 1,
			value: initValue === undefined ? prev.value : prev.defaultConverter(initValue, prev.value),
			pageInfo: prev.pageInfo ? { ...prev.pageInfo, page: 1 } : undefined,
			isLoading: hasApiRequest,
		}));
	}, [setState, hasApiRequest]);

	const loadMore = React.useCallback(() => {
		if (state.isLoading || !state.pageInfo || !state.pageInfo.hasNextPage) {
			return;
		}
		setState(prev => ({
			...prev,
			count: prev.count + 1,
			pageInfo: prev.pageInfo ? { ...prev.pageInfo, page: prev.pageInfo.page + 1 } : undefined,
			isLoading: hasApiRequest,
		}));
	}, [state]);

	const reRender = React.useCallback((initValue?: T) => {
		setState(prev => ({
			...prev,
			value: initValue === undefined ? prev.value : prev.defaultConverter(initValue, prev.value),
		}));
	}, [setState, hasApiRequest]);

	const ret = React.useMemo<UseAPIDataTableLoadRet<T | undefined, P & ExtractedPageParams | P>>(() => {
		const controller = {
			filter,
			reload,
			loadMore,
			reRender,
			pageInfo: state.pageInfo,
			requestedParams: state.requestedParams,
			count: state.count,
			isLoading: state.isLoading,
		};
		return [state.value, controller];
	}, [state]);

	return ret;
}

export function useAppState(): StateSetTuple {
	return React.useContext(context);
}
