import { MailShopEntity, MailShopEntityAttr, smtpAuthValidator, smtpEncryptValidator } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAPI, useAppState } from "../../../context";
import { FormValues, TestMailFormValues } from './SmtpSetting';

const SEPARATOR = ',';

export const useSmtpSetting = (initValues: FormValues) => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [storedValues, setStoredValues] = React.useState<FormValues|null>(null);
	const [resultSend, setResultSend] = React.useState<string>('');
	const [isSendable, setIsSendable] = React.useState<boolean>(false);
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const testMailFormMethods = useForm<TestMailFormValues>();
	const { reset } = formMethods;

	const toSaveAttr = (values: Partial<FormValues>): MailShopEntityAttr => {
		const smtpAuth = String(values.smtpAuth);
		const smtpEncrypt = String(values.smtpEncrypt);
		return {
			mail: values.mail,
			mailSenderName: values.mailSenderName || undefined,
			smtpHost: values.smtpHost,
			smtpPort: values.smtpPort ? parseInt(values.smtpPort, 10) : undefined,
			smtpAuth: smtpAuthValidator(smtpAuth) ? smtpAuth : undefined,
			smtpEncrypt: smtpEncryptValidator(smtpEncrypt) ? smtpEncrypt : undefined,
			smtpUser: values.smtpUser,
			smtpPass: values.smtpPass || undefined,
			mailCc: values.mailCc?.split(SEPARATOR) || undefined,
			mailBcc: values.mailBcc?.split(SEPARATOR) || undefined,
		};
	};

	const toStoredValues = (shop: MailShopEntity): FormValues => {
		return {
			...initValues,
			mail: shop.mail || '',
			mailSenderName: shop.mailSenderName || '',
			smtpHost: shop.smtpHost || '',
			smtpPort: String(shop.smtpPort),
			smtpAuth: shop.smtpAuth || 'plain',
			smtpEncrypt: shop.smtpEncrypt || 'none',
			smtpUser: shop.smtpUser || '',
			smtpPass: shop.smtpPass || '',
			mailCc: shop.mailCc?.join(SEPARATOR) || '',
			mailBcc: shop.mailBcc?.join(SEPARATOR) || '',
		};
	};

	const toValues = (shop: MailShopEntity): FormValues => {
		return {
			...initValues,
			mail: shop.mail || '',
			mailSenderName: shop.mailSenderName || '',
			smtpHost: shop.smtpHost || '',
			smtpPort: String(shop.smtpPort),
			smtpAuth: shop.smtpAuth || 'plain',
			smtpEncrypt: shop.smtpEncrypt || 'none',
			smtpUser: shop.smtpUser || '',
			smtpPass: '',
			mailCc: shop.mailCc?.join(SEPARATOR) || '',
			mailBcc: shop.mailBcc?.join(SEPARATOR) || '',
		};
	};

	React.useEffect(() => {
		return callAPI.mail.getShop({ shopId: state.params.shopId }, (err, result) => {
			if (err || result.data === null) {
				return;
			}
			if (result.data) {
				const shop = MailShopEntity.create(result.data);
				setIsSendable(shop.isSendableMail());
				setStoredValues(toStoredValues(shop));
				reset(toValues(shop));
			}
		});
	}, [state.params.shopId]);

	const handleSave: SubmitHandler<FormValues> = async (values) => {
		try {
			const params = {
				shopId: state.params.shopId,
				shop: toSaveAttr(values),
			};
			const res = await callAPI.mail.putShop(params);
			return res ? Boolean(res.data) : false;
		} catch (err) {
			return false;
		}
	};

	const handleSend: SubmitHandler<TestMailFormValues> = async (values) => {
		const res = callAPI.mail.sendTestMail({
			shopId: state.params.shopId,
			to: values.testMail,
			subject: '【ラクラクあつまレビュー】SMTPサーバ設定>テスト送信',
			body: 'ラクラクあつまレビューからのテスト送信です'
		});
		setIsSendable(false);
		const result = await res;
		const msg = (result && result.data && result.data.result) ?
			'テストメールを送信しました' :
			`テストメールの送信に失敗しました（${result?.data.error}）`;
		setResultSend(msg);
		setIsSendable(true);
	};

	return {
		state,
		storedValues,
		methods: formMethods,
		testMailMethods: testMailFormMethods,
		handleSave,
		isSendable,
		handleSend,
		resultSend,
	};
};
export default useSmtpSetting;