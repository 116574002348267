import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../../context';
import { SideProps } from './Side';

export const useSide = (props: SideProps) => {
	const [state] = useAppState();
	const navigate = useNavigate();
	const [open, setOpen] = React.useState('');

	const setCapMap: { [index: string]: any } = {
		'privilege': ['yReview_reviewCheckTool_read', 'yReview_labor_read', 'yReview_management_read', 'yReview_management_read'],
		'reviewCheckTool_r': ['yReview_reviewCheckTool_read'],
		'reviewWaiting_r': ['yReview_labor_read'],
		'rewardApplying_r': ['yReview_labor_read'],
		'rewardRegister_r': ['yReview_management_read'],
		'itemGroup_r': ['yReview_management_read'],
		'campaignRegister_r': ['yReview_management_read'],
		'mail': ['yReview_management_read'],
		'mailSetting_r': ['yReview_management_read'],
		'mailTemplate_r': ['yReview_management_read'],
		'mailExclude_r': ['yReview_management_read'],
		'setting': ['yReview_system_write'],
		'userSetting_r': ['yReview_system_write'],
		'shopSetting_r': ['yReview_system_write'],
		'systemAlert_r': ['yReview_system_write'],
	};

	React.useEffect(() => {
		const docClickHandler = (ev: MouseEvent) => {
			if (!(ev.target instanceof HTMLElement)) {
				return;
			}
			let target = ev.target;
			let hasClass = target.classList.contains('menu');

			/*
				クリックイベントの範囲の判定
				hasClass == true
					・バーガーメニュー
					・サイドメニュー（サイドメニュー内のリストは除く）
			*/
			while (!hasClass && target.parentNode instanceof HTMLElement) {
				target = target.parentNode;

				if (target === null || target.parentNode === null) {
					break;
				} else {
					if (target.classList.contains('bl_drawer_subList')) {
						break;
					}
					hasClass = target.classList.contains('menu');
				}
			}

			// バーガーメニュー開閉時のメニューの動き
			if (ev.target.classList.contains('el_navToggle')) {
				if (props.isOpen) {
					setOpen('');
				}
			}
			if(!hasClass) {
				if (!props.isOpen) {
					setOpen('');
				}
				props.onClose?.();
			}
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, [props.isOpen]);

	const handleClickMenu = (name: string) => () => {
		if (name === 'top') {
			// PC版：ハンバーガーメニューが閉じている状態でTOPをクリック
			if (!props.isOpen) {
				setOpen('');
			}
			// モバイル版：ハンバーガーメニューが開いている状態でTOPをクリック
			if (props.isOpenMobile) {
				props.onClose?.();
			}

			navigate(props.basePath);
			return;
		}

		if (open === name) {
			setOpen('');
		} else {
			setOpen(name);
		}
	}

	return {
		state,
		open,
		classNameIsOpen: (props.isOpen ? ' is_open' : '') + (props.isOpenMobile ? ' is_open_mobile' : ''),
		setCapMap,
		handleClickMenu
	};
};