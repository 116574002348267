import { css } from "@emotion/react";
import * as React from 'react';
import { cssVariable } from '../../../css/variable';
import { Desc } from '../../atoms/Desc';

interface InputDescProps {
	className?: string;
	alert?: boolean;
	noMargin?: boolean;
	children: React.ReactNode;
}
export const InputDesc: React.FC<InputDescProps> = (props) => {

	const margin = props.noMargin || css`margin-top: 8px !important;`;

	const style = css`
		font-size: 12px !important;
		${margin}
		color: ${props.alert ? cssVariable.error : cssVariable.main1} !important;
	`;

	return <Desc css={style} noMargin={props.noMargin} className={props.className}>{props.children}</Desc>;
}
export default InputDesc;
