import * as React from 'react';
import { Link } from 'react-router-dom';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { TemplateListTable } from './TemplateListTable';
import { useTemplateList } from './useTemplateList';

export const TemplateList: React.FunctionComponent = () => {
	const {
		tableProps,
		handleClickDelete,
		handleClickFavorite,
		toEditURL,
		toCopyURL,
	} = useTemplateList();

	return (
		<>
			<PageTitle>送信メールテンプレート</PageTitle>
			<PageDesc>キャンペーン用メールテンプレートの新規作成、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<Link className="el_btn el_btn__plus" type="button" to={toEditURL('new')}>新規追加</Link>
							<button className="el_btnInv" type="button" onClick={handleClickFavorite}>お気に入り</button>
							<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
						</div>
						<TemplateListTable
							{...tableProps}
							toEditURL={toEditURL}
							toCopyURL={toCopyURL}
							/>
					</div>
				</div>
			</div>
		</>
	);
}
export default TemplateList;