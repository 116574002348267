import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { DestType, PageInfo } from '@sasagase/types';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, UseFormMethods } from 'react-hook-form';
import { Column, DataTable } from '../../organisms/DataTable';
registerLocale('ja', ja);

const DEST_NAME: Record<DestType, string> = {
	'shipping': '同じ',
	'orderer': '注文者住所',
	'other': '指定',
} as const;

export const filterInitValue = {
	filterOrderNumber: '',
	filterRewardId: '',
	filterRewardName: '',
	filterDl: '',
	filterTransactDateFrom: null,
	filterTransactDateTo: null,
};
type FilterFormValue = typeof filterInitValue;

interface RequestListTableProps {
	rows?: APIResponses.GetRewardRequestsResponseValues[];
	pageInfo?: PageInfo;
	isLoading: boolean;
	handleFilter: () => void;
	methodFilter: UseFormMethods<FilterFormValue>;
	loadMore: () => void;
	rewardMap?: Map<string, APIResponses.GetRewardsResponseValues>;
	handleClickDetail: (requestId: string) => () => void;
	toOrderDetailURL: (orderNumber: string) => string;
	isTransactable: (rowData: APIResponses.GetRewardRequestsResponseValues) => boolean;
}

export const RequestListTable: React.FunctionComponent<RequestListTableProps> = (props) => {

	const { control } = props.methodFilter;

	// テーブル固有のCSS指定
	// TODO ここ以外で指定できないか要検討
	const tableStyle = css`
		height: 629px;

		td > .el_btnTxt__underLine {
			margin-left: 32px;
		}
	`;

	const toDateString = (date?: number) => {
		if (!date) {
			return '';
		}
		return dayjs(date).format('YYYY-MM-DD');
	};
	const receivedMailDateBodyTemplate = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		return <>{toDateString(rowData.receivedMailDate)}</>;
	};
	const orderNumberBodyTemplate = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		return <a key={rowData.order.number} href={props.toOrderDetailURL(rowData.order.number)} target="_blank" rel="noreferrer">{rowData.order.number}</a>;
	};
	const rewardNameBodyTemplate = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		const reward = props.rewardMap && props.rewardMap.size > 0 && props.rewardMap.get(rowData.selectedRewardId);
		return <>{reward && reward.name}</>;
	};
	const rewardNameFilterElement = (options: any): JSX.Element => {
		const rewardIdForm = <>
			<select name="filterRewardId" ref={options.register} onChange={() => options.handleFilterToClose()}>
				<option value="">すべて表示</option>
				{props.rewardMap && <>
					{[...props.rewardMap.entries()].map(([rewardId, reward]) => 
						<option key={rewardId} value={rewardId}>{reward.name}</option>
					)}
				</>}
			</select>
		</>;
		const rewardNameForm = options.createTextFilterForm('filterRewardName');
		return <>
			{options.createFilterSection(rewardIdForm)}
			{options.createFilterSection(rewardNameForm, '検索')}
		</>;
	};
	const ordererNameBodyTemplate = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		return <>{rowData.order.orderer.ordererName}</>;
	};
	const destTypeBodyTemplate = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		return <>
			{DEST_NAME[rowData.destType]}<button className="el_btnTxt el_btnTxt__underLine js_showDetail" type="button" onClick={props.handleClickDetail(rowData.id)}>詳細</button>
		</>;
	};
	const isTransactedFilterElement = (options: any): JSX.Element => {
		const filterForm = <>
			<select name="filterDl" ref={options.register} onChange={() => options.handleFilterToClose()}>
				<option value="">すべて表示</option>
				<option value="1">済</option>
				<option value="0">未</option>
			</select>
		</>
		return options.createFilterSection(filterForm);
	};
	const isTransactedBodyTemplate = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		return <>{rowData.isTransacted ? '済' : '未'}</>;
	};
	const transactDateFilterElement = (options: any): JSX.Element => {
		const filterForm = <>
			開始日 <Controller
				control={control}
				name="filterTransactDateFrom"
				render={({ onChange, value }) => (
					<ReactDatePicker
						locale="ja"
						onChange={date => {
							onChange(date);
							options.handleFilter();
						}}
						onBlur={() => options.handleFilter()}
						selected={value}
						dateFormat="yyyy/MM/dd"
						isClearable
						/>
				)}
			/><br />
			終了日 <Controller
				control={control}
				name="filterTransactDateTo"
				render={({ onChange, value }) => (
					<ReactDatePicker
						locale="ja"
						onChange={date => {
							onChange(date);
							options.handleFilter();
						}}
						onBlur={() => options.handleFilter()}
						selected={value}
						dateFormat="yyyy/MM/dd"
						isClearable
						/>
				)}
			/>
		</>
		return options.createFilterSection(filterForm);
	};
	const transactDateBodyTemplate = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		return <>{toDateString(rowData.transactDate)}</>;
	};
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	};

	const isDisabled = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		return !props.isTransactable(rowData);
	};

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				loadMore={props.loadMore}
				handleFilter={props.handleFilter} methodFilter={props.methodFilter}
				paginationType="infiniteScroll"
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 0 48px center center" field="id" listSelector isCheckDisabled={isDisabled}></Column>
				<Column style="0 0 118px center center" field="receivedMailDate" bodyTemplate={receivedMailDateBodyTemplate}>申込日</Column>
				<Column style="0 0 138px center center" field="filterOrderNumber"
					filterable
					bodyTemplate={orderNumberBodyTemplate}
					>注文番号</Column>
				<Column style="1 1 655px center flex-start" field="filterRewardId"
					filterable
					filterElement={rewardNameFilterElement}
					bodyTemplate={rewardNameBodyTemplate} 
					>プレゼント品・クーポン</Column>
				<Column style="0 0 160px center center" field="ordererName" bodyTemplate={ordererNameBodyTemplate}>注文者</Column>
				<Column style="0 0 132px flex-start center" field="destType" bodyTemplate={destTypeBodyTemplate}>配送先</Column>
				<Column style="0 0 168px center center" field="isTransacted"
					filterable
					filterFormAlign="right"
					filterElement={isTransactedFilterElement}
					bodyTemplate={isTransactedBodyTemplate}
					>送り先情報DL状況</Column>
				<Column style="0 0 118px center center" field="transactDate"
					filterable
					filterFormAlign="right"
					filterElement={transactDateFilterElement}
					bodyTemplate={transactDateBodyTemplate}
					>処理日</Column>
			</DataTable>
		</>
	);
}
export default RequestListTable;