import { APIResponses } from '@sasagase/api-caller';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAPIDataTableLoad, useAppState } from '../../../context';
import { useYahoo } from '../../../hooks';

interface FormValues {
	select_campaign: string;
	orderNumber: string;
}

interface useExclusionOrderProps {
	initValues: FormValues
}

export const useExclusionOrder = (props: useExclusionOrderProps) => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const [campaigns, setCampaigns] = React.useState<APIResponses.GetCampaignListResponseValues[]>([]);
	const [targets, setTargets] = React.useState<APIResponses.GetTargetsResponseValues[]>();

	const formMethods = useForm({
		defaultValues: props.initValues
	});
	const { getValues, setValue } = formMethods;

	React.useEffect(() => {
		return callAPI.yReview.getCampaignList({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const camps: APIResponses.GetCampaignListResponseValues[] = result.data;
			// 実施中もしくは実施済みに絞る
			setCampaigns(camps.filter(camp => ['inProgress', 'waitingReview', 'finished', 'cancelled'].includes(camp.state) ));
		});
	}, [shopId]);

	const [rows, { filter, loadMore, pageInfo, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getTargets,
		{
			initValue: [],
			apiParams: { shopId, pagination: "1" },
			perPage: 1000,
			onFetch: (data) => {
				return [data.data, data.pageInfo];
			},
			disableInitLoad: true,
		}
	);

	React.useEffect(() => {
		if (rows == undefined) {
			return;
		}
		setTargets(rows);
	}, [rows]);

	const handleFilter = () => {
		const { select_campaign, orderNumber } = getValues();
		const filterParams: Record<string, unknown> = {};
		if (select_campaign) filterParams['campaignId'] = select_campaign;
		if (orderNumber) filterParams['orderNumber'] = orderNumber;
		// キャンペーンの指定がある場合のみ絞り込み
		if (select_campaign) {
			filter(filterParams, []);
		}
	};

	const handleClickChangeCampaign = async () => {
		const campaignId = getValues('select_campaign');
		if (!campaignId) {
			return;
		}
		setValue('orderNumber', '');
		handleFilter();
	};

	const handleChangeCanSendMail = async (target: APIResponses.GetTargetsResponseValues, canSendMail: boolean) => {
		if (!rows) {
			return;
		}

		for (const order of target.orders) {
			const param = {
				shopId: state.params.shopId,
				orderNumber: order.number,
				canSendingMail: canSendMail
			};
			const res = await callAPI.yReview.putOrderCanSendingMail(param);
			if (!res || !res.data) {
				alert(`メール送信の変更に失敗しました`);
			}
		}
		// 変更が検知されないので再生成してセット
		const updatedRows = rows.map(row => {
			if (row.id === target.id) {
				return {
					...row,
					orders: row.orders.map(order => ({
						...order,
						canSendingMail: canSendMail
					}))
				};
			}
			return row;
		});
		setTargets(updatedRows);
	};

	const {
		toOrderDetailURL,
	} = useYahoo(state.shop);

	return {
		methods: formMethods,
		campaigns,
		handleClickChangeCampaign,
		tableProps: {
			rows: targets, 
			pageInfo,
			isLoading,
			handleFilter,
			methodFilter: formMethods,
			loadMore,
			onChangeCanSendMail: handleChangeCanSendMail,
			toOrderDetailURL,
		},
	};
};
export default useExclusionOrder;
