import { YahooShopEntity, YahooShopEntityAttr } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAPI, useAppState } from "../../../context";
import { previewPath, reviewFormUrl } from '../../../lib/reviewForm';
import { FormValues } from './ShopSetting';

export const useShopSetting = (initValues: FormValues) => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const { register, getValues, setValue, watch, reset } = formMethods;

	const toSaveAttr = (values: Partial<FormValues>): Partial<YahooShopEntityAttr> => {
		return {
			shopName: values.shopName,
			companyName: values.companyName,
			logoImage: values.logoImage,
			shopUrl: values.shopUrl,
			contactType: values.contactType,
			contactPhone: values.contactType === 'phone' ? values.contactPhone : undefined,
			contactMail: values.contactType === 'mail' ? values.contactMail : undefined,
		};
	};

	const toValues = (shopEntity: YahooShopEntity): FormValues => {
		return {
			...initValues,
			shopName: shopEntity.shopName || '',
			companyName: shopEntity.companyName || '',
			logoImage: shopEntity.logoImage || '',
			shopUrl: shopEntity.shopUrl || '',
			contactType: shopEntity.contactType || '',
			contactPhone: shopEntity.contactPhone || '',
			contactMail: shopEntity.contactMail || ''
		};
	};

	React.useEffect(() => {
		return callAPI.yReview.getShop({ shopId }, (err, result) => {
			if (err || result.data === null) {
				return;
			}
			if (result.data) {
				const shop = YahooShopEntity.create(result.data);
				reset(toValues(shop));
			}
		});
	}, [state.params.shopId]);

	React.useEffect(() => {
		register('logoImage');
	}, [register]);

	const handleSave: SubmitHandler<FormValues> = async (values) => {
		try {
			const params = {
				shopId,
				shop: toSaveAttr(values),
			};
			await callAPI.yReview.putShop(params);
			return true;
		} catch (err) {
			return false;
		}
	};

	const handleChangeFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.files?.length) {
			return;
		}
		const format = /^((.{0,20}).*)(\.(?:jpg|jpeg|png|gif))$/i;
		if (!format.test(ev.target.files[0].name)) {
			alert('規定外のファイル形式です。画像ファイル(gif、jpg、jpeg、png)を選択してください。');
			return;
		}
		const id = await callAPI.storage.postImage({
				shopId,
				file: ev.target.files[0]
			});
		if (id) {
			if (ev.target.name === 'logoImage') {
				setValue('logoImage', id.data);
			}
		}
		ev.target.value = '';
	};
	const handleClickDeleteImage = (name: keyof FormValues) => () => {
		setValue(name, '');
	};

	const handleClickPreview = (status: string) => {
		const values = {
			...getValues(),
			service: 'y-review',
			status,
			shopId,
		};
		const url = new URL(previewPath, reviewFormUrl);
		url.search = '?' + new URLSearchParams(values).toString();
		const www = window.open(url, "_blank", "noopener,noreferrer");
		if (www) www.opener = null;
	};

	const imageUrl = watch('logoImage') ? `/storage/${shopId}/${watch('logoImage')}` : undefined;

	return {
		state,
		methods: formMethods,
		imageUrl,
		handleChangeFile,
		handleClickDeleteImage,
		handleSave,
		handleClickPreview,
	};
};
export default useShopSetting;