import * as React from 'react';
import { Link } from 'react-router-dom';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RewardListTable } from './RewardListTable';
import { useRewardList } from './useRewardList';

export const RewardList: React.FunctionComponent = () => {
	const {
		tab,
		setTab,
		tabClass,
		presentTableProps,
		couponTableProps,
		handleClickDelete,
		state,
		campaignMap,
		toRewardEditURL,
		toRewardCopyURL,
	} = useRewardList();

	return (
		<>
			<PageTitle>レビュー特典登録</PageTitle>
			<PageDesc>プレゼント品・クーポンの新規登録、確認・編集ができます。</PageDesc>
			<ul className="bl_panelTab">
				<li className={`bl_panelTab_item ${tabClass.present}`} data-privilege="present" onClick={() => setTab('present')}>プレゼント品</li>
				<li className={`bl_panelTab_item ${tabClass.coupon}`} data-privilege="coupon" onClick={() => setTab('coupon')}>クーポン</li>
			</ul>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<Link className="el_btn el_btn__plus" type="button" to={toRewardEditURL('new')}>新規追加</Link>
							<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
						</div>
						{tab === 'present' &&
							<RewardListTable
								{...presentTableProps}
								state={state}
								campaignMap={campaignMap}
								toRewardEditURL={toRewardEditURL}
								toRewardCopyURL={toRewardCopyURL}
								/>
						}
						{tab === 'coupon' &&
							<RewardListTable
								isCoupon
								{...couponTableProps}
								state={state}
								campaignMap={campaignMap}
								toRewardEditURL={toRewardEditURL}
								toRewardCopyURL={toRewardCopyURL}
								/>
						}
					</div>
				</div>
			</div>
		</>
	);
}
export default RewardList;