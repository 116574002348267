export * from './toJapaneseDate';

export interface EraData {
	begin: Date;
	name: string;
}
export interface EraYear {
	era: string;
	year: number;
}

const KANJI = [
	['', '一', '二', '三', '四', '五', '六', '七', '八', '九'],
	['', '十', '二十', '三十', '四十', '五十', '六十', '七十', '八十', '九十'],
	['', '百', '二百', '三百', '四百', '五百', '六百', '七百', '八百', '九百'],
	['', '千', '二千', '三千', '四千', '五千', '六千', '七千', '八千', '九千'],
];

const eras: EraData[] = [
	{ begin: new Date(1868, 0, 25), name: '明治' },
	{ begin: new Date(1912, 6, 30), name: '大正' },
	{ begin: new Date(1926, 11, 25), name: '昭和' },
	{ begin: new Date(1989, 0, 8), name: '平成' },
	{ begin: new Date(2019, 4, 1), name: '令和' },
];
export default eras;

export function getEraYear(date: Date): EraYear {
	let prev: EraData | null = null;
	for (const era of eras) {
		if (date < era.begin) {
			break;
		}
		prev = era;
	}
	return eraYear(prev, date);
}

function eraYear(era: EraData | null, date: Date): EraYear {
	if (!era) {
		return {
			era: '西暦',
			year: date.getFullYear(),
		};
	}
	return {
		era: era.name,
		year: date.getFullYear() - era.begin.getFullYear() + 1,
	};
}

export function toKanji(num: number, isYear = false): string {
	if (num <= 0 || 10000 <= num) {
		throw new Error('範囲外の数値が指定されました');
	}

	if (isYear && num == 1) {
		return '元';
	}

	let keta = 0;
	let res = '';
	while (num) {
		res = KANJI[keta][num % 10] + res;
		num = Math.floor(num / 10);
		keta = (keta + 1) % 4
	}
	return res;
}

export function toKanjiDate(date: Date, spacer = ' '): string {
	const eraYear = getEraYear(date);
	return [
		`${eraYear.era}${toKanji(eraYear.year, true)}年`,
		`${toKanji(date.getMonth() + 1)}月`,
		`${toKanji(date.getDate())}日`
	].join(spacer);
}

export function toKanjiDateParts(date: Date, types: string[]): string[] {
	const eraYear = getEraYear(date);
	return types.map((type) => {
		if (type === 'era') {
			return eraYear.era;
		} else if (type === 'year') {
			return toKanji(eraYear.year, true);
		} else if (type === 'month') {
			return toKanji(date.getMonth() + 1);
		} else if (type === 'day') {
			return toKanji(date.getDate());
		} else if (type === 'rest') {
			return [
				`${toKanji(eraYear.year, true)}年`,
				`${toKanji(date.getMonth() + 1)}月`,
				`${toKanji(date.getDate())}日`
			].join('');
		}
		return '';
	});
}