import { yahoo } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { FormGroup } from '../../organisms/Form';
import { YahooIDConnect } from '../../organisms/YahooIDConnect';

interface YahooIDLinkageProps {
	token: yahoo.GetShopTokenExpirationResponse | null,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const YahooIDLinkage: React.FunctionComponent<YahooIDLinkageProps> = (props) => {
	const { token } = props;

	const toAuthStatus = (token: yahoo.GetShopTokenExpirationResponse | null) => {
		if (!token || !token.refreshToken) {
			return '認可されていない';
		} else if (dayjs(token.refreshToken).isBefore(dayjs())) {
			return '有効期限切れ';
		}
		return '認可済み';
	};

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>Yahoo! ID連携</FormLabel>
				<PageDesc>
					注文情報を取得するために必要な設定です。<br />
					下記より、Yahoo! ID連携を行ってください。
				</PageDesc>
				{!token?.refreshToken &&
					<YahooIDConnect />
				}
				{token?.refreshToken && <>
					<FormGroup>
						<FormLabel>認可の状態</FormLabel>
						{toAuthStatus(token)}
					</FormGroup>
					<FormGroup>
						<FormLabel>有効期限</FormLabel>
						{dayjs(token?.refreshToken).format('YYYY/MM/DD HH:mm:ss')}
					</FormGroup>
					<FormGroup>
						<YahooIDConnect extend />
					</FormGroup>
				</>}
			</div>
		</>
	);
};
export default YahooIDLinkage;