import * as React from 'react';
import { FormLabel } from '../../../component/molecules/FormLabel';
import { FormResult } from '../../../component/molecules/FormResult';
import { PageDesc } from '../../../component/molecules/PageDesc';
import { PageTitle } from '../../../component/molecules/PageTitle';
import { ErrorMessage, Form, FormGroup, Input, InputPassword, SubmitButton } from '../../organisms/Form';
import useTwoFactorAuthEdit from './useTwoFactorAuthEdit';

const initValues = {
	code: '',
	password: '',
};

export interface FormValues {
	code: string;
	password: string;
}

export const TwoFactorAuthEdit: React.FunctionComponent = () => {
	const {
		methods,
		handleClickDisabledSave,
		handleClickSave,
		handleClickBack,
		isTwoFactorAuth,
		qrCodeUrl,
		secretKey,
		result,
	} = useTwoFactorAuthEdit({ initValues });

	return (
		<>
			<PageTitle>2段階認証設定</PageTitle>
			<PageDesc>
				設定が完了すると、ログイン時に、Google Authenticatorなどの認証アプリで生成されたコードの入力が必要となります。<br />
				2段階認証についての詳細はご利用ガイドページをご覧ください。。
			</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						{(isTwoFactorAuth != null && isTwoFactorAuth) &&
							<Form methods={methods} onSubmit={handleClickDisabledSave} disableBlock>
								<FormGroup>
									<FormLabel headline>2段階認証が設定中</FormLabel>
									<p>
										解除する場合は現在のパスワードを入力してください
									</p>
									<FormGroup>
										<FormLabel>現在のパスワード</FormLabel>
										<InputPassword name="password"
											rules={{
												minLength: {
													value: 8,
													message: 'パスワードは8文字以上で入力してください。',
												},
											}} />
										<ErrorMessage name="newpasswordPass" />
										<FormResult>{result}</FormResult>
									</FormGroup>
									<div className="el_largeBtnWrap">
										<SubmitButton>解除</SubmitButton>
									</div>
								</FormGroup>
							</Form>
						}
						{(isTwoFactorAuth != null && !isTwoFactorAuth) &&
							<>
								<Form methods={methods} onSubmit={handleClickSave} disableBlock>
									<FormGroup>
										<FormLabel headline>認証用アプリをダウンロード</FormLabel>
										<p>
											2段階認証を設定する際に、認証アプリのGoogle Authenticatorが必要となります。<br />
											お手持ちのスマートフォンまたはタブレットへGoogle Authenticatorをダウンロードしてください。<br />
											Google Authenticatorは
											<span className='el_externalLink'>
												<a className='el_externalLink' href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" rel="noopener noreferrer" target="_blank">Google Play ストア</a>
											</span>
											または
											<span className='el_externalLink'>
												<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" rel="noopener noreferrer" target="_blank">iOS App Store </a>
											</span>
											からダウンロードできます。
										</p>
									</FormGroup>
									<FormGroup>
										<FormLabel headline>バーコードをスキャン</FormLabel>
										<FormLabel>スマートフォンのカメラでスキャンする</FormLabel>
										<p>
											Google Authenticatorで[+]アイコンをタップします。<br />
											スマートフォンのカメラを使って下記の画像をスキャンしてください。
										</p>
										{qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" style={{ width: '200px' }} />}
										<FormLabel>スキャンできない場合</FormLabel>
										<p>
											上記のバーコードがスキャンできない場合、手動で入力することができます。<br />
											1. Google Authenticatorで、[+] &gt; [セットアップ キーを入力] をタップします。<br />
											2. 以下のキーを入力します。<br />
											{secretKey && <span className='ml_16'>{secretKey}</span>}<br />
											3. [時間ベース] が選択されていることを確認します<br />
											4. [追加] をタップして終了します<br />
										</p>
									</FormGroup>
									<FormGroup>
										<FormLabel headline>認証コードの入力</FormLabel>
										<FormLabel required>スキャン後、アプリに表示される６桁のコードを入力してください</FormLabel>
										<FormGroup className="bl_panel_row__indent">
											<Input type="text" name="code" rules={{
												required: '確認コードを入力してください',
												maxLength: {
													value: 6,
													message: '確認コードは6文字で入力してください。',
												},
												minLength: {
													value: 6,
													message: '確認コードは6文字で入力してください。',
												},
											}} />
											<ErrorMessage name="code" />
											<FormResult>{result}</FormResult>
										</FormGroup>
										<div className="el_largeBtnWrap">
											<SubmitButton>保存</SubmitButton>
										</div>
									</FormGroup>
								</Form>
							</>
						}
						<a className="el_backLink" href="#" onClick={handleClickBack}>≪戻る</a>
					</div>
				</div>
			</div>
		</>
	);
}
export default TwoFactorAuthEdit;