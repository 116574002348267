import { css } from '@emotion/react';
import iconClose01White from './img/icon-close01-white.svg';
import iconFull from './img/icon-full.svg';

export const styleModalBg = css`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(36, 36, 36, .8);
	z-index: 35;
`;

export const styleLightBoxModal = css`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0);
	transform: translate(-50%, -50%);

	.react-transform-component{
		>div {
			cursor: zoom-in;
		}
		>.bl_guideModal_zoomed {
			cursor: grab;
		}
		>.bl_guideModal_zoomed:active {
			cursor: grabbing;
		}
	}

	.react-transform-component {
		>div {
			padding: 8px;
			border-radius: 6px;
			background-color: #FFF;
			>img {
				max-height: 94vh;
				max-width: 100%;
				left: 50%;
				border: 1px solid #E6E6E6;
				object-fit: contain;
			}
		}
	}

	button {
		position: absolute;
		top: 5px;
		right: 15px;
		display: inline-block;
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(${iconClose01White});
		z-index: 10;
	}
`;

export const styleMarkdown = (mainColor: string) => css`

	span {
		display: inline;
	}

	> span {
		margin: 16px 0;
		line-height: 1.9;
		font-weight: 500;
		font-size: 14px;
	}

	.comments {
		margin-top: -8px;
		text-indent: -1em;
		padding-left: 1em;
		margin-bottom: 1em;
	}

	figure {
		position: relative;
		margin: 0;
		padding: 8px;
		border: 1px solid #E6E6E6;
		cursor: pointer;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			width: 32px;
			height: 32px;
			border-style: solid;
			border-color: #E6E6E6;
			border-width: 1px 0 0 1px;
			background-repeat: no-repeat;
			background-position: 3px 3px;
			background-size: 24px;
			background-image: url(${iconFull});
			background-color: #FFF;
			pointer-events: none;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
			margin: 0 auto;
		}
	}

	h3 {
		margin-top: 0;
		margin-bottom: 16px;
		padding: 8px;
		border-left: 8px solid ${mainColor};
		background-color: #F7FBFF;
		line-height: 1.6;
		font-weight: 700;
		font-size: 18px;
		color: ${mainColor};
	}

	h4 {
		margin-top: 0;
		margin-bottom: 16px;
		padding: 8px 8px 8px 27px;
		background-color: ${mainColor};
		line-height: 1.6;
		text-indent: -19px;
		font-weight: 700;
		font-size: 16px;
		color: #FFF;

		&::before {
			content: "";
			display: inline-block;
			width: 11px;
			height: 11px;
			margin-right: 8px;
			border-radius: 50%;
			background-color: #FFF;
		}
	}

	h5 {
		margin-bottom: 16px;
		padding: 4px 4px 4px 27px;
		background-color: ${mainColor};
		line-height: 1.6;
		text-indent: -19px;
		font-weight: 700;
		font-size: 14px;
		color: #FFF;
	}

	a {
		font-weight: 700;
		color: ${mainColor};
		text-decoration: underline;
	}

	blockquote {
		position: relative;
		padding: 10px 15px 10px 15px;
		box-sizing: border-box;
		font-style: italic;
		background: #f5f5f5;
		color: #777777;
		border-left: 4px solid ${mainColor};
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
	}

	blockquote p {
		padding: 0;
		margin: 7px 0;
		line-height: 1.7;
	}

	blockquote cite {
		display: block;
		text-align: right;
		color: #888888;
		font-size: 0.9em;
	}

	pre {
		padding: 1em;
		background-color: #f5f5f5;
		color: #333333;
	}

	code {
		background-color: #f5f5f5;
		color: #333333;
		font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
	}

	// 順番ありリスト1
	> ol, > div > div > ol {
		counter-reset: ordered-list;
		line-height: 1.9;

		> li.ma_0 {
			margin: 0;
		}

		> li {
			margin: 16px 0;

			> span, p:not(.comments) {
				position: relative;
				display: block;
				padding-left: 28px;
				line-height: 1.9;
				text-indent: -28px;
				font-weight: 700;
				font-size: 14px;

				&::before {
					counter-increment: ordered-list;
					content: counter(ordered-list);
					display: inline-flex;
					justify-content: center;
					align-items: center;
					width: 24px;
					height: 24px;
					margin-right: 4px;
					background-color: ${mainColor};
					text-indent: 0;
					color: #FFF;
				}

				&::after {
					position: absolute;
					left: 0;
					bottom: 0;
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background-color: ${mainColor};
				}
			}

			> div {
				margin: 16px 0;
			}

			> .comments {
				text-indent: calc(-28px + 1em);
				padding-left: calc(28px + 1em);
			}

			// 順番ありリスト2
			ol {
				counter-reset: ordered-child-list;
				padding-left: 24px;

				figure {
					margin: 16px 0;
				}

				> li.ma_0 {
					margin: 0;
				}

				> li {
					margin: 16px 0;

					> span, p:not(.comments) {
						position: relative;
						display: block;
						padding-left: 24px;
						line-height: 1.9;
						font-weight: 700;
						font-size: 14px;

						&::before {
							counter-increment: ordered-child-list;
							content: counter(ordered-child-list);
							position: absolute;
							top: 2px;
							left: 0;
							flex: 0 0 auto;
							display: inline-flex;
							justify-content: center;
							align-items: center;
							width: 20px;
							height: 20px;
							margin-right: 4px;
							border-radius: 50%;
							background-color: ${mainColor};
							font-weight: 700;
							font-size: 12px;
							color: #FFF;
						}
					}

					> .comments {
						padding-left: 38px;
					}

					span + .comments {
						margin-top: 6px;
					}

					// 順番なしリスト3~
					ul {
						padding-left: 24px;

						> li.ma_0 {
							margin: 0;
						}

						> li {
							margin: 16px 0;

							> span, p:not(.comments) {
								position: relative;
								display: block;
								padding-left: 12px;
								line-height: 1.9;
								font-weight: 500;
								font-size: 14px;

								&::before {
									position: absolute;
									top: 8px;
									left: 0;
									content: "";
									display: inline-block;
									width: 8px;
									height: 8px;
									border-radius: 50%;
									margin-right: 4px;
									margin-bottom: 1px;
									background-color: ${mainColor};
								}

								+ * {
									margin-top: 6px;
								}
							}

							> .comments {
								text-indent: -12px;
								padding-left: 24px;
							}
						}
					}
				}
			}

			// 順番なしリスト2
			ul {
				padding-left: 24px;

				figure {
					margin: 16px 0;
				}

				> li.ma_0 {
					margin: 0;
				}

				> li {
					margin: 16px 0;

					> span, p:not(.comments) {
						position: relative;
						display: block;
						margin: calc(16px - .8em) 0;
						padding-left: 16px;
						line-height: 1.9;
						font-weight: 700;
						font-size: 14px;

						&::before {
							position: absolute;
							top: 6px;
							left: 0;
							content: "";
							display: inline-block;
							width: 12px;
							height: 12px;
							border-radius: 50%;
							margin-right: 4px;
							background-color: ${mainColor};
						}
					}

					> .comments {
						padding-left: 38px;
					}

					span + .comments {
						margin-top: 6px;
					}

					// 順番なしリスト3~
					ul {
						padding-left: 24px;

						> li.ma_0 {
							margin: 0;
						}

						> li {
							margin: 8px 0;

							> span, p:not(.comments) {
								position: relative;
								display: block;
								padding-left: 12px;
								line-height: 1.9;
								font-weight: 500;
								font-size: 14px;

								&::before {
									position: absolute;
									top: 8px;
									left: 0;
									content: "";
									display: inline-block;
									width: 8px;
									height: 8px;
									border-radius: 50%;
									margin-right: 4px;
									margin-bottom: 1px;
									background-color: ${mainColor};
								}

								+ * {
									margin-top: 6px;
								}
							}

							> .comments {
								text-indent: -12px;
								padding-left: 24px;
							}
						}
					}
				}
			}
		}
	}

	// 順番なしリスト1
	> ul, > div > div > ul {

		figure {
			margin: 16px 0;
		}

		> li.ma_0 {
			margin: 0;
		}

		> li {
			margin: 8px 0;

			> span, p:not(.comments) {
				position: relative;
				display: block;
				margin: calc(16px - .8em) 0;
				padding-left: 16px;
				line-height: 1.9;
				font-weight: 700;
				font-size: 14px;

				&::before {
					position: absolute;
					top: 6px;
					left: 0;
					content: "";
					display: inline-block;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					margin-right: 4px;
					background-color: ${mainColor};
				}
			}

			// 順番なしリスト2~
			ul {
				padding-left: 16px;

				> li.ma_0 {
					margin: 0;
				}

				> li {
					> span, p:not(.comments) {
						position: relative;
						display: block;
						padding-left: 12px;
						line-height: 1.9;
						font-weight: 700;
						font-size: 14px;

						&::before {
							position: absolute;
							top: 8px;
							left: 0;
							content: "";
							display: inline-block;
							width: 8px;
							height: 8px;
							margin-right: 4px;
							margin-bottom: 1px;
							border-radius: 50%;
							background-color: #B3B3B3;
						}
					}
				}
			}
		}
	}

	// 横並び
	div {
		display: flex;
		margin: 0;

		> div {
			display: block;

			&:first-child {
				flex: 1 1 576px;
				margin-right: 24px;
			}

			&:last-child {
				flex: 1 1 568px;
			}

			> ol, ul {
				padding-left: 0;

				> li {
					&:first-child {
						margin-top: 0;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		div {
			display: block;

			div {
				&:first-child {
					margin-right: 0;
					margin-bottom: 32px;
				}
			}
		}
	}

	.bl_orderedList {
		display: block !important;
		margin: 32px 0;

		> ol {
			counter-reset: color-ordered-list;

			> li {
				margin: 16px 0;

				> span {
					position: relative;
					display: flex;
					align-items: center;
					padding-left: 24px;
					line-height: 1.9;
					font-weight: 700;
					font-size: 14px;

					&::before {
						counter-increment: color-ordered-list;
						content: counter(color-ordered-list);
						position: absolute;
						top: 2px;
						left: 0;
						flex: 0 0 auto;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						width: 20px;
						height: 20px;
						margin-right: 4px;
						border-radius: 50%;
						background-color: #FF9F2F;
						text-indent: 0;
						font-weight: 700;
						font-size: 12px;
						color: #FFF;
					}
				}

				ul {
					padding-left: 24px;

					> li {
						margin: calc(16px - .8em) 0;

						> span {
							position: relative;
							display: flex;
							align-items: center;
							padding-left: 12px;
							line-height: 1.9;
							font-weight: 500;
							font-size: 14px;

							&::before {
								position: absolute;
								top: 8px;
								left: 0;
								flex: 0 0 auto;
								content: "";
								display: inline-block;
								width: 8px;
								height: 8px;
								margin-right: 4px;
								border-radius: 50%;
								background-color: #B3B3B3;
								text-indent: 0;
							}
						}
					}
				}
			}
		}

		&.bl_orderedList__pink {
			> ol {
				> li {
					> span {
						&::before {
							background-color: #F88080;
						}
					}
				}
			}
		}
	}

	.bl_guidTable > table {
		// width: 100%;
		margin-bottom: 24px;
		white-space: nowrap;
		overflow: auto;

		> thead th {
			border-top: 2px solid ${mainColor};
			border-bottom: 2px solid ${mainColor};
			color: ${mainColor};
		}

		> tbody td {
			border-bottom: 1px solid #E6E6E6;
		}

		tr {
			width: 100%;
		}

		th,
		td {
			padding: 24px 16px;
			background-color: #FFFFFF;
			white-space: normal;
			word-break: break-all;
		}

		th {
			background-color: #F7FBFF;
			font-weight: bold;
			font-size: 14px;
		}

		td {
			align-items: center;
		}
	}
`;
