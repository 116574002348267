import * as React from 'react';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import ExclusionMail from './ExclusionMail';
import ExclusionOrder from './ExclusionOrder';

export const Exclusion: React.FunctionComponent = () => {
	return (
		<div className="ly_main__exclusion">
			<PageTitle>メール送信除外設定</PageTitle>
			<PageDesc>フォローメールの送信除外設定を行うことができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__4">
					<ExclusionOrder />
				</div>
				<div className="bl_col bl_col__4">
					<ExclusionMail />
				</div>
			</div>
		</div>
	);
}
export default Exclusion;