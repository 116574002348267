import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { define, dynamic, enums, is, type } from 'superstruct';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { ErrorMessage, FormGroup, InputRadio } from '../../organisms/Form';
import { contextWizard } from './useCampaignWizard';

export const campaignWizardS4InitValues: CampaignWizardS4FormValue = {
	reviewRequired: 'item' as const,
};

export interface CampaignWizardS4FormValue {
	reviewRequired: 'item' | 'shop' | 'both' | 'any';
}

export const CampaignWizardS4Struct = dynamic(() => {
	return type({
		reviewRequired: define<'item' | 'shop' | 'both' | 'any'>('reviewRequired',
			(val) => is(val, enums(['item', 'shop', 'both', 'any'])) || 'レビューの条件を選択してください'),
	});
});

interface CampaignWizardS4Props {
	onPrev: () => void;
	onClose: () => void;
}

export const CampaignWizardS4: React.FC<CampaignWizardS4Props> = (props) => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const { onPrev } = props;
	const { watch, getValues } = useFormContext();

	const canNext = React.useMemo(() => {
		return is(getValues(), CampaignWizardS4Struct);
	}, [watch('reviewRequired')]);

	const handleNext = () => {
		const values = getValues();
		if (is(values, CampaignWizardS4Struct)) {
			ctx.setStepIdx(idx => idx + 1);
			ctx.setWizardValues(prev => ({
				...prev,
				reviewRequired: values.reviewRequired
			}));
		}
	};

	return (
		<>
			<div className="bl_panel__wizardMain">
				<FormGroup>
					<FormLabel>レビューの条件を選択してください</FormLabel>
					<InputRadio name="reviewRequired" value="item" label="商品レビューを記入" /><br />
					<InputRadio name="reviewRequired" value="shop" label="ショップレビューを記入" /><br />
					<InputRadio name="reviewRequired" value="both" label="商品・ショップレビューの両方を記入" /><br />
					<InputRadio name="reviewRequired" value="any" label="商品・ショップレビューのどちらかを記入" />
					<ErrorMessage name='reviewRequired' />
				</FormGroup>
			</div>
			<div className="bl_panel__wizardFooter">
				<Button className="el_largeBtn el_largeBtn__prev" onClick={onPrev}>戻る</Button>
				<Button className="el_largeBtn" onClick={handleNext} disabled={!canNext}>次へ</Button>
				<Button className="el_largeBtn el_largeBtn__prev el_largeBtn__right" onClick={props.onClose}>キャンセル</Button>
			</div>
		</>
	);
}
export default CampaignWizardS4;
