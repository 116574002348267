import * as React from 'react';
import { ErrorMessage, Form, Input, InputPassword, SubmitButton } from '../../organisms/Form';
import useAccountPasswordReset from './useAccountPasswordReset';

const initValues = {
	password: '',
	password_confirm: '',
};
export type FormValues = typeof initValues;

export const AccountPasswordReset: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		handleClickStart,
		user,
		saveResult,
	} = useAccountPasswordReset({initValues});

	if (!user) {
		return null;
	}

	const { getValues } = methods;

	const message = saveResult ?
		'パスワードの再設定が完了しました。' :
		`パスワードをお忘れの方は、メールアドレスをお確かめのうえ、パスワードを再設定してください。`;

	return (
		<>
			<div className="bl_inviteForm">
				<div className="bl_loginFormRow">
					<p className="txt_blue">{message}</p>
				</div>
				{!saveResult &&
					<Form methods={methods} onSubmit={handleSave}>
						<div className="bl_loginFormRow">
							<label className="bl_blueLabel">メールアドレス</label>
							<Input type="text" readOnly={true} value={user.mail} />
						</div>
						<div className="bl_loginFormRow">
							<label className="bl_blueLabel">新しいパスワード</label>
							<InputPassword name="password"
								rules={{
										required: '新しいパスワードを入力してください。',
										minLength: {
											value: 8,
											message: '新しいパスワードは8文字以上で入力してください。',
										},
									}}
								/>
							<ErrorMessage name="password" />
						</div>
						<div className="bl_loginFormRow">
							<label className="bl_blueLabel">新しいパスワード（確認用）</label>
							<InputPassword name="password_confirm"
								rules={{
										required: '確認用の新しいパスワードを入力してください。',
										validate: (input: string) => input === getValues("password") || '入力した新しいパスワードと一致していません。',
									}}
								/>
							<ErrorMessage name="password_confirm" />
						</div>
						<SubmitButton className="el_largeBtn__fullWidth">新しいパスワードを登録する</SubmitButton>
					</Form>
				}
				{saveResult &&
					<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClickStart}>ラクラクあつまレビュー を始める</button>
				}
			</div>
		</>
	);
};
export default AccountPasswordReset;