import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { FormGroup, InputRadio } from '../../organisms/Form';
import { SelectProduct } from './SelectProduct';
import { useGroupEditRow } from './useGroupEditRow';

interface GroupEditRowProps {
	groupNames: Record<string, string>;
	toName?: (name: string) => string;
}

export function GroupEditRow(props: GroupEditRowProps): React.ReactElement {
	const {
		names,
		isAll,
		handleSearchItems
	} = useGroupEditRow(props);

	return (
		<>
			<FormGroup>
				<FormLabel id="isAll" required>対象商品指定方法</FormLabel>
				<InputRadio name={names.isAll} value="false" label="個別で商品を指定する" /><br />
				<InputRadio name={names.isAll} value="true" label="全商品を対象にする" />
			</FormGroup>
			<FormGroup>
				<FormLabel id="include">対象商品</FormLabel>
				<SelectProduct
					groupNames={props.groupNames}
					type='include'
					names={names}
					handleSearchItems={handleSearchItems}
					disabled={isAll}
					/>
			</FormGroup>
			<FormGroup>
				<FormLabel id="exclude">除外商品</FormLabel>
				<SelectProduct
					groupNames={props.groupNames}
					type='exclude'
					names={names}
					handleSearchItems={handleSearchItems}
					/>
			</FormGroup>
		</>
	);
}
export default GroupEditRow;