import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface SelectProps {
	name: string;
	options: Record<string, string>;
	rules?: Record<string, unknown>;
	className?: string;
	defaultValue?: string;
	disabled?: boolean;
	onChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
}
export const Select: React.FC<SelectProps> = (props) => {
	const { register } = useFormContext();

	return (
		<>
			<select className={props.className}
				name={props.name}
				defaultValue={props.defaultValue}
				ref={props.rules? register(props.rules) : register}
				disabled={props.disabled}
				onChange={props.onChange}
				>
				{Object.entries(props.options).map(([key, value]) =>
					<option key={key} value={key} disabled={!key} hidden={!key}>{value}</option>
				)}
			</select>
		</>
	);
}
export default Select;
