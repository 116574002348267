import { Reward, ShopEntity, YahooCouponReward, YahooItemReward } from '@sasagase/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { define, dynamic, enums, number, optional, string, type } from 'superstruct';
import { useAPI, useAppState } from '../../../context';
import { previewPath, reviewFormUrl } from '../../../lib/reviewForm';

export const ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED = 'キャンペーン実施中に、申込みフォームの有効・無効が切り替わる変更はできません';

export interface CampaignEditFormStep2FormValue {
	rewards: {
		val: string;
	}[],
	destType: 'form' | 'shipping' | 'orderer',
	applicationClosingDays: string,
	shouldCloseApplication: 'true' | 'false',
	inProgressDate?: number;
	waitingReviewDate?: number;
	destTypeOriginal?: string;

}

function requiredNumericString(val: unknown): boolean {
	return isFinite(parseInt(String(val), 10));
}

export const CampaignEditFormStep2Struct = dynamic((values: any) => {
	const shouldCloseApplication = values.shouldCloseApplication == 'true';
	const isStarted = Boolean(values.inProgressDate);
	const isFinishedCamp = Boolean(values.waitingReviewDate);

	return type({
		rewards: define<CampaignEditFormStep2FormValue['rewards']>('rewards', (val) => {
			const isSelect = Array.isArray(val) && val.every(reward => reward.val != '') ? true : '特典を選択してください';
			let isRewardChange: boolean | string = true;
			if (isStarted && !isFinishedCamp) {
				// 変更前の特典送り先が申込みフォーム以外の場合、フォームの有効無効の変更不可
				if (values.destTypeOriginal !== "form") {
					// 変更前の特典が複数の場合、単一に変更不可
					if (values.isManyRewardOriginal) {
						isRewardChange = Array.isArray(val) && val.length > 1 ?
						true : ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED;
					} else {
						// 変更前が単一の場合、複数に変更不可
						isRewardChange = Array.isArray(val) && val.every(reward => reward.val != '') && val.length == 1 ?
						true : ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED;
					}
				}
			}
			return isRewardChange === true ? isSelect === true ? true : isSelect : isRewardChange;
		}),
		applicationClosingDays: shouldCloseApplication ? define<string>('applicationClosingDays', (val) => requiredNumericString(val) || '受付期間を入力してください') : string(),
		destType: enums(['form', 'shipping', 'orderer']),
		shouldCloseApplication: enums(['true', 'false']),
		inProgressDate: optional(number()),
		waitingReviewDate: optional(number()),
		destTypeOriginal: optional(string()),
	});
});

interface useCampaignEditStep2Props {
	rewards: Reward[];
}

export const useCampaignEditStep2 = (props: useCampaignEditStep2Props) => {
	const callAPI = useAPI();
	const { control, watch, trigger, getValues, setValue } = useFormContext();
	const { fields: rewardFields, remove, append } = useFieldArray({ control, name: 'rewards' });
	const [state] = useAppState();

	const shouldCloseApplication = watch('shouldCloseApplication') == 'true';

	const handleClickAddReward = () => {
		append({ val: '' });
		void trigger('rewards');
	};
	const handleClickPreview = async () => {
		const res = await callAPI.yReview.getShop({ shopId: state.params.shopId });
		if (!res || !res.data) {
			return;
		}
		const shop = ShopEntity.create(res.data);
		const rewardIds = (watch('rewards') as { val: string }[]).map(r => r.val);
		const rewards = props.rewards.filter(reward => rewardIds.includes(reward.id));

		const values = {
			status: "unapplied",
			shopId: shop.id,
			shopName: shop.shopName ?? '',
			companyName: shop.companyName ?? '',
			logoImage: shop.logoImage ?? '',
			shopUrl: shop.shopUrl ?? '',
			contactType: shop.contactType ?? '',
			contactPhone: shop.contactPhone ?? '',
			contactMail: shop.contactMail ?? '',
			rewardTypes: [] as string[],
			rewardNames: [] as string[],
			rewardImages: [] as string[],
			rewardDescriptions: [] as string[],
			rewardKifuda: [] as string[],
			rewardMaruKifuda: [] as string[],
			rewardMaruKifudaM: [] as string[],
			rewardNamaebata: [] as string[],
			rewardNamaekonori: [] as string[],
			service: 'y-review',
		};

		for (const reward of rewards) {
			const opt = reward instanceof YahooItemReward ? reward.optionAttributes ?? {} : {};
			values.rewardTypes.push(reward.isCoupon ? 'coupon' : 'item');
			values.rewardNames.push(reward.name);
			values.rewardImages.push(
				reward instanceof YahooCouponReward ? (reward.couponImageName ?? '') :
				reward instanceof YahooItemReward ? (reward.image ?? '') : ''
			);
			values.rewardDescriptions.push(reward.description);
			values.rewardKifuda.push(opt.kifuda ? 'true' : 'false');
			values.rewardMaruKifuda.push(opt.maruKifuda ? 'true' : 'false');
			values.rewardMaruKifudaM.push(opt.maruKifudaM ? 'true' : 'false');
			values.rewardNamaebata.push(opt.namaebata ? 'true' : 'false');
			values.rewardNamaekonori.push(opt.namaekonori ? 'true' : 'false');
		}

		const url = new URL(previewPath, reviewFormUrl);

		const www = window.open('about:blank', 'blank');
		if (www) www.opener = null;

		const form = document.createElement('form');
		form.target = 'blank';
		form.action = url.toString();
		form.method = 'POST';

		const input = document.createElement('input');
		input.type = 'hidden';
		input.name = 'json';
		input.value = JSON.stringify(values);
		form.appendChild(input);

		const body = document.getElementsByTagName('body')[0];
		body.appendChild(form);
		form.submit();
		body.removeChild(form);
	};
	const handleRemoveReward = (idx: number) => () => {
		if (rewardFields.length > 1) {
			remove(idx);
		}
	};

	const chooseRewardIds: string[] = (watch('rewards') as { val: string }[]).map(r => r.val);
	const handleChangeReward = (_: number) => () => {
		const isCouponReward = props.rewards.some(reward => chooseRewardIds.includes(reward.id) && reward.isCoupon);
		setValue("isCouponReward", isCouponReward);
		// バリデーションの実行
		void trigger('rewards');
	};


	const isManyRewardOriginal = getValues("isManyRewardOriginal");

	return {
		rewardFields,
		shouldCloseApplication,
		isManyRewardOriginal,
		handleClickAddReward,
		handleClickPreview,
		handleRemoveReward,
		handleChangeReward,
	};
}
export default useCampaignEditStep2;