const defaultOption: Intl.DateTimeFormatOptions = {
	era: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric',
};

function isInvalidDate(date: Date): boolean {
	return Number.isNaN(date.getTime());
}

export const toJapaneseDate = (date: Date | string, option: Intl.DateTimeFormatOptions = defaultOption) => {
	date = new Date(date);
	if (isInvalidDate(date)) {
		return '';
	}
	return new Intl.DateTimeFormat('ja-JP-u-ca-japanese', option).format(date);
};

/**
 * date で指定した日付を和暦に変換して、日付を構成する文字列をパーツに分けて types で指定した順に配列で返す。
 * types に指定できるtypeの種類は、`Intl.DateTimeFormat#formatToParts`が返すtypeを参照のこと。
 *
 * この関数で独自に追加されたtypeの `'rest'` は、未指定の残りのパーツを全て結合した文字列になる。このtypeは指定してもパーツを消化しない。
 * 
 * @see https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts
 */
export const toJapaneseDateParts = (date: Date | string, types: string[], option: Intl.DateTimeFormatOptions = defaultOption): string[] => {
	date = new Date(date);
	if (isInvalidDate(date)) {
		return types.map(() => '');
	}

	const parts = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', option).formatToParts(date);
	return types.map((type) => {
		if (type === 'rest') {
			return parts.map((part) => part.value).join('');
		}
		const idx = parts.findIndex((part) => part.type === type);
		if (idx < 0) {
			return '';
		}
		const [part] = parts.splice(idx, 1);
		return part.value;
	});
};
