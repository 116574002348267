import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTimer } from '../../../lib';
import { Button } from '../../atoms/Button';
import { contextForm } from './Form';
import { ERROR_CLASSNAME, SUCCESS_CLASSNAME } from './const';

interface SubmitButtonProps {
	className?: string;
	disabled?: boolean;
	children?: React.ReactNode;
}
export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
	const context = React.useContext(contextForm);
	const { formState: { isSubmitting } } = useFormContext();
	const [resultClass, setResultClass] = React.useState<string>('');
	const setTimer = useTimer();

	React.useEffect(() => {
		if (context?.saveResult == null) {
			return;
		}

		setResultClass(context?.saveResult ? SUCCESS_CLASSNAME : ERROR_CLASSNAME);
		if (context?.saveResult) {
			setTimer(() => setResultClass(''), 6000);
		}
	}, [context?.saveResult]);

	const classList = ['el_largeBtn', props.className, resultClass].filter(c => Boolean(c)).join(' ');

	return (
		<Button className={classList}
			type='submit'
			disabled={props.disabled || isSubmitting}
			>{props.children ?? '保存'}</Button>
	);
}
export default SubmitButton;
