/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { styleLightBoxModal, styleModalBg } from './style';

interface ImageTempProps {
	src?: string;
	alt?: string;
	handlEvent?: () => void;
	lightBoxCallback?: (src?: string) => void;
}
const LightBox = (props: ImageTempProps): React.ReactElement => {
	const DEFAULT_MAX_SCALE = 2;

	const { handlEvent, lightBoxCallback, ...imgProps } = props;
	const [showLightBox, setShowLightBox] = React.useState<boolean>(false);
	const [lightBoxSrc, setLightBoxSrc] = React.useState<string>("");
	const [enableLightBoxZoom, setEnableLightBoxZoom] = React.useState<boolean>(false);
	const [enableMouseMove, setEnableMouseMove] = React.useState<boolean>(false);
	const [maxScale, setMaxScale] = React.useState<number>(DEFAULT_MAX_SCALE);

	const setLightBox = (src?: string): void => {
		setShowLightBox(Boolean(src));
		lightBoxCallback?.(src);
		setLightBoxSrc(src ?? "");
		if (src) {
			setEnableLightBoxZoom(false);
		}
	};
	const openLightBox = () => {
		setLightBox(props.src);
	};
	const imgRef = React.useCallback((ref: HTMLImageElement) => {
		if (!ref) {
			return;
		}
		const naturalHeight = ref.naturalHeight ?? 0;
		const imageHeight = ref.height ?? 0;
		const maxScale = naturalHeight * DEFAULT_MAX_SCALE / imageHeight;

		setMaxScale(maxScale);
	}, []);
	const clickHandler = (ev: React.MouseEvent<HTMLDivElement>, centerView: (scale: number) => void) => {
		if (!enableMouseMove) {
			if (enableLightBoxZoom) {
				// zoomOut;
				centerView(1)
				setEnableLightBoxZoom(false);
			} else {
				// zoomIn;
				centerView(maxScale)
				setEnableLightBoxZoom(true);
			}
		}
		ev.stopPropagation();
	};

	React.useEffect(() => {
		const keyPressEvent = (pushKey: KeyboardEvent) => {
			if (pushKey.key === "Escape") {
				setLightBox();
			}
		}
		window.addEventListener("keydown", keyPressEvent)
		return () => window.removeEventListener("keydown", keyPressEvent);
	}, []);

	return (
		<>
			<div>
				<figure>
					<img {...imgProps} onClick={openLightBox} onLoad={handlEvent} onError={handlEvent} />
				</figure>
			</div>
			{showLightBox && (
				<div css={styleModalBg} onClick={() => setLightBox()}>
					<figure css={styleLightBoxModal}>
						<button type="button" aria-label="閉じる"></button>
						<div style={{ marginTop: "30px" }}>
							<TransformWrapper doubleClick={{ disabled: true }} wheel={{ disabled: true }} centerOnInit={true} maxScale={maxScale}>
								{({ centerView }) => (
									<>
										<TransformComponent wrapperStyle={{ height: "94vh", width: "100%" }}>
											<div className={enableLightBoxZoom ? 'bl_guideModal_zoomed' : ''} onClick={(ev) => clickHandler(ev, centerView)} onMouseDown={() => setEnableMouseMove(false)} onMouseMove={() => enableMouseMove || setEnableMouseMove(true)}>
												<img src={lightBoxSrc} ref={imgRef} alt="" />
											</div>
										</TransformComponent>
									</>
								)
								}
							</TransformWrapper>
						</div>
					</figure>
				</div>
			)}
		</>
	);
}
export default LightBox;