import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { define, dynamic, enums, is, type } from 'superstruct';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { ErrorMessage, FormGroup, InputRadio } from '../../organisms/Form';
import { contextWizard } from './useCampaignWizard';

export const campaignWizardS1InitValues: CampaignWizardS1FormValue = {
	rewardType: '',
};

export interface CampaignWizardS1FormValue {
	rewardType: string;	// 'choice' | 'item' | 'coupon'
}

export const CampaignWizardS1Struct = dynamic((values: any) => {
	const rewardType = values.rewardType || '';

	return type({
		rewardType: define<string>('rewardType', () => is(rewardType, enums(['choice', 'item', 'coupon'])) || 'レビュー特典の指定方法を選択してください'),
	});
});

interface CampaignWizardS1Props {
	onClose: () => void;
}

export const CampaignWizardS1: React.FC<CampaignWizardS1Props> = (props) => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const { watch, getValues } = useFormContext();

	const canNext = React.useMemo(() => {
		return is(getValues(), CampaignWizardS1Struct);
	}, [watch('rewardType')]);

	const handleNext = () => {
		const values = getValues();
		const { rewardType } = values;
		if (is(values, CampaignWizardS1Struct)) {
			ctx.setStepIdx(idx => idx + 1);
			ctx.setWizardValues(prev => ({
				...prev,
				rewardType,
			}));
		}
	};

	return (
		<>
			<div className="bl_panel__wizardMain">
				<FormGroup>
					<FormLabel>レビュー特典の指定方法を下記の中から選択してください</FormLabel>
					<InputRadio name="rewardType" value="choice" label="複数のレビュー特典の中からお客様に選んでもらう" /><br />
					<InputRadio name="rewardType" value="item" label="１つのプレゼント品に固定する" /><br />
					<InputRadio name="rewardType" value="coupon" label="１つのクーポンに固定する" /><br />
					<ErrorMessage name='rewardType' />
				</FormGroup>
			</div>
			<div className="bl_panel__wizardFooter">
				<Button className="el_largeBtn" onClick={handleNext} disabled={!canNext}>次へ</Button>
				<Button className="el_largeBtn el_largeBtn__prev el_largeBtn__right" onClick={props.onClose}>キャンセル</Button>
			</div>
		</>
	);
}
export default CampaignWizardS1;
