import * as React from 'react';
import { ListSelectorContext } from '../../organisms/DataTable';
import { NotCoveredList as NotCoveredListBase } from './NotCoveredList';

export const NotCoveredList: React.FunctionComponent = () => {
	return (
		<>
			<ListSelectorContext>
				<NotCoveredListBase />
			</ListSelectorContext>
		</>
	);
}
export default NotCoveredList;