import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { ItemGroup, PageInfo } from '@sasagase/types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { InsertSeparator, toBR } from "../../../lib";
import { Column, DataTable } from '../../organisms/DataTable';
import { CampaignProgresses } from './useGroupList';

interface GroupListTableProps {
	rows?: APIResponses.GetGroupsResponse;
	pageInfo?: PageInfo;
	isLoading: boolean;
	campaignMap?: Map<string, CampaignProgresses>
	toEditURL: (rewardId: string) => string,
	toCopyURL: (rewardId: string) => string,
	getItemGroupRequireDesc: (group: ItemGroup) => string[];
}

export const GroupListTable: React.FunctionComponent<GroupListTableProps> = (props) => {
	const { campaignMap, toEditURL, toCopyURL, getItemGroupRequireDesc } = props;

	// テーブル固有のCSS指定
	const tableStyle = css`
		height: 691px;
	`;

	const requireBodyTemplate = (rowData: any) => {
		return toBR(getItemGroupRequireDesc(rowData).join('\n'));
	};
	const campaignBodyTemplate = (rowData: any) => {
		const nestTableStyle = css`
			td {
				flex: initial !important;
			}
		`;
		return <>
			{campaignMap &&
				<table className="bl_nestTable" css={nestTableStyle}>
					<tbody>
						<tr>
							<th>実施前</th>
							<td>
								<InsertSeparator>
									{campaignMap.get(rowData.id)?.ready}
								</InsertSeparator>
							</td>
						</tr>
						<tr>
							<th>実施中</th>
							<td>
								<InsertSeparator>
									{campaignMap.get(rowData.id)?.inProgress}
								</InsertSeparator>
							</td>
						</tr>
					</tbody>
				</table>
			}
			<div className="bl_table_btnWrap">
				<Link className="el_btnInv" type="button" to={toEditURL(rowData.id)}>編集</Link>
				<Link className="el_btnInv" type="button" to={toCopyURL(rowData.id)}>複製</Link>
			</div>
		</>;
	};
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	};

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 0 48px center center" field="id" listSelector></Column>
				<Column style="1 1 480px center flex-start" field="name">対象商品グループ名</Column>
				<Column style="1 0 200px center flex-start" field="require" bodyTemplate={requireBodyTemplate}>設定内容</Column>
				<Column style="1 0.4 808px center center" tdClassName="bl_nestTableWrap" field="campaign" bodyTemplate={campaignBodyTemplate}>登録されているキャンペーン</Column>
			</DataTable>
		</>
	);
}
export default GroupListTable;