import * as React from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { ColumnProps } from './Column';
import { DataTableBodyTd } from './DataTableBodyTd';

interface DataTableBodyProps<T> {
	/** カラム情報 */
	columnProps: ColumnProps[];
	/** 一覧表示用データ */
	rows: T | undefined;
	/** 一覧表示用データの主キー */
	dataKey: string;
	/** 読み込み中フラグ */
	isLoading: boolean;
	/** 並び替え可否フラグ ※全件表示 or ページネーションでの表示のみ利用可 */
	reorderable?: boolean;
	/** 並び替え後呼び出し関数 */
	handleDragEnd?: OnDragEndResponder;
}

export const DataTableBody = <T,>(props: DataTableBodyProps<T>): React.ReactElement => {

	const createTableBody = () => {
		if (props.reorderable && props.handleDragEnd) {
			return (
				<DragDropContext onDragEnd={props.handleDragEnd}>
					<Droppable droppableId='campaign'>
						{(provided) => (
							<tbody className="bl_table_body" ref={provided.innerRef} {...provided.droppableProps}>
								{props.rows && <>
									{(Array.isArray(props.rows) ? props.rows : [props.rows]).map((row, idx) => 
										<Draggable key={row[props.dataKey]} draggableId={row[props.dataKey]} index={idx}>
											{(provided) => (
												<tr ref={provided.innerRef} {...provided.draggableProps}>
													<DataTableBodyTd
														{...props}
														row={row}
														idx={idx}
														provided={provided}
														/>
												</tr>
											)}
										</Draggable>
									)}
									{provided.placeholder}
								</>}
							</tbody>
						)}
					</Droppable>
				</DragDropContext>
			);
		} else {
			return (
				<tbody className="bl_table_body">
					{props.rows && <>
						{(Array.isArray(props.rows) ? props.rows : [props.rows]).map((row, idx) => 
							<tr key={idx}>
								<DataTableBodyTd
									{...props}
									row={row}
									idx={idx}
									/>
							</tr>
						)}
					</>}
				</tbody>
			);
		}
	};

	return createTableBody();
}
export default DataTableBody;