import { MailTemplate, YahooCampaign } from '@sasagase/types';
import * as React from 'react';
import MailSection from './MailSection';
import useCampaignEditStep4 from './useCampaignEditStep4';

interface CampaignEditFormStep4Props {
	templates: MailTemplate[];
	reviewFormCond: () => Record<string, boolean>;
	campaign: YahooCampaign;
}

export function CampaignEditFormStep4(props: CampaignEditFormStep4Props): React.ReactElement {
	const {
		errors,
		categories,
		isCouponReward
	} = useCampaignEditStep4(props);

	const { canChooseReward, canChooseDest, hasItemReward, disabledReviewForm } = props.reviewFormCond();
	const alert = (hasItemReward && !canChooseDest) ? '申込みフォームを利用しないため' :
		!canChooseReward && '申込みフォームを利用しないため'

	// レビュー完了メールの送信必須(特典が複数 or クーポン以外の特典で特典の送り先を申込みフォーム選択)
	const sendRequiredRequestMail = canChooseReward || canChooseDest ? true : false;
	const requiredReasonRequestMail = sendRequiredRequestMail ? '申込みフォームが設定されているため、レビュー完了メールの送信は必須となります' : '';
	// 申込受付完了メールの送信必須
	const sendRequiredReceivedMail = isCouponReward ? true : false;
	const requiredReasonReceivedMail = sendRequiredReceivedMail ? 'レビュー特典にクーポンが含まれるため、申込受付完了メールの送信は必須となります' : '';

	return (
		<>
			<ul className="bl_pageLink">
				<li className="bl_pageLink_item"><a href="#followMail">フォローメール</a></li>
				<li className="bl_pageLink_item"><a href="#requestMail">レビュー完了メール</a></li>
				<li className="bl_pageLink_item"><a href="#receivedMail">申込受付完了メール</a></li>
			</ul>
			<MailSection templates={categories.review_request || []} name="followMail" errors={errors.followMail} campaign={props.campaign} sendRequired={false}>フォローメール</MailSection>
			<MailSection
				templates={categories.review_completed || []}
				name="requestMail"
				errors={errors.requestMail}
				message={disabledReviewForm ? `※この項目は現在の設定では使用されません（${alert}）` : ''}
				disabled={disabledReviewForm}
				campaign={props.campaign}
				sendRequired={sendRequiredRequestMail}
				sendRequiredReason={requiredReasonRequestMail}
				>レビュー完了メール</MailSection>
			<MailSection templates={categories.application_completed || []} name="receivedMail" className="mb_48" errors={errors.receivedMail} campaign={props.campaign} sendRequired={sendRequiredReceivedMail} sendRequiredReason={requiredReasonReceivedMail}>申込受付完了メール</MailSection>
		</>
	);
}
export default CampaignEditFormStep4;