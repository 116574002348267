import { YahooCampaign } from '@sasagase/types';
import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { FormGroup, FormIndent, Input, Textarea } from '../../organisms/Form';
import { InputBodyDetail } from './InputBodyDetail';
import { InputBodySingle } from './InputBodySingle';
import Preview from './Preview';
import { MailEditRowFormValues, useMailEditRow } from './useMailEditRow';

interface MailEditRowProps {
	toName?: (name: string) => string;
	applyValues?: Partial<MailEditRowFormValues>;
	disabled?: boolean;
	campaign?: YahooCampaign;
	isBodyOnlyTextarea?: boolean;
}

export function MailEditRow(props: MailEditRowProps): React.ReactElement {
	const {
		bodyElementTypeSelect,
		names,
		fields,
		previewValues,
		isClick,
		handleClickAddBodyElement,
		handleClickDeleteBodyElement,
		handleOnDragEnd,
		handleClickOpenPreview,
		handleClickClosePreview,
		handleChangeType,
		handleClickSendTest,
		sendTestButtonString,
		toBodyElements
	} = useMailEditRow(props);

	return (
		<>
			{previewValues && <Preview values={previewValues} onClose={handleClickClosePreview} />}
			<FormIndent>
				{props.isBodyOnlyTextarea &&
					<InputBodySingle
						{...props}
						names={names}
						/>
				}
				{!props.isBodyOnlyTextarea &&
					<InputBodyDetail
						{...props}
						bodyElementTypeSelect={bodyElementTypeSelect}
						names={names}
						fields={fields}
						handleClickAddBodyElement={handleClickAddBodyElement}
						handleClickDeleteBodyElement={handleClickDeleteBodyElement}
						handleOnDragEnd={handleOnDragEnd}
						handleChangeType={handleChangeType}
						toBodyElements={toBodyElements}
						/>
				}
				<FormGroup>
					<FormLabel>署名</FormLabel>
					<Textarea className="el_textarea" name={names.signature} rows={3} placeholder="ぷりふあ人形&#010;〒700-0976 岡山県岡山市北区辰巳29-113&#010;(TEL) 086-250-0092　 (e-mail) yahoo@prefer.jp" disabled={props.disabled} />
				</FormGroup>
			</FormIndent>
			<FormGroup className="mb_16">
				<Button className="el_btnBlueInv" type="button" onClick={handleClickOpenPreview} disabled={props.disabled}>プレビューを表示</Button>
			</FormGroup>
			<FormGroup className="bl_mailTemp_test">
				<Button className="el_btnInv" type="button" onClick={handleClickSendTest} disabled={isClick || props.disabled}>{sendTestButtonString}</Button>
				<Input type="email" name={names.email} disabled={props.disabled}/>
			</FormGroup>
		</>
	);
}
export default MailEditRow;