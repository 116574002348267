import * as React from 'react';
import { useAppState } from '../context';

interface WhenProps {
	not?: boolean;
	loggedin?: boolean;
	children?: React.ReactNode;
}
const When: React.FunctionComponent<WhenProps> = (props) => {
	const [state] = useAppState();

	let isMatch = true;
	if (props.loggedin) {
		isMatch = isMatch && state.user != null;
	}
	if (props.not) {
		isMatch = !isMatch;
	}

	if (isMatch) {
		return <>{props.children}</>;
	} else {
		return null;
	}
};
export default When;