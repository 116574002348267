import { ItemGroup } from '@sasagase/types';
import * as React from 'react';
import { ErrorMessage, FormGroup } from '../../organisms/Form';
import { GroupEditRow } from '../../organisms/GroupEditRow';
import useCampaignEditStep3 from './useCampaignEditStep3';

interface CampaignEditFormStep3Props {
	groups: ItemGroup[];
}

export function CampaignEditFormStep3(props: CampaignEditFormStep3Props): React.ReactElement {
	const {
		groupNames,
		toName
	} = useCampaignEditStep3(props);

	return (
		<>
			<ul className="bl_pageLink">
				<li className="bl_pageLink_item"><a href="#isAll">対象商品指定方法</a></li>
				<li className="bl_pageLink_item"><a href="#include">対象商品</a></li>
				<li className="bl_pageLink_item"><a href="#exclude">除外商品</a></li>
			</ul>
			<FormGroup>
				<GroupEditRow groupNames={groupNames} toName={toName} />
				<ErrorMessage name={toName('skus')} />
			</FormGroup>
		</>
	);
}
export default CampaignEditFormStep3;