import { BodyElement, BodyElementType, MailTemplate, YahooCampaign, bodyElementType } from '@sasagase/types';
import { toArrayMap } from '@sasagase/util';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { array, define, dynamic, enums, string, type } from 'superstruct';

export interface MailFormValue {
	template: string;
	disable: string;
	subject: string;
	bodyHeader: string;
	bodyElement: BodyElement[];
	signature: string;
	email: string;
}

export interface CampaignEditFormStep4FormValue {
	followMail: MailFormValue,
	requestMail: MailFormValue,
	receivedMail: MailFormValue,
	destType: 'form' | 'shipping' | 'orderer',
}

const BodyElementStruct = type({
	type: enums<BodyElementType>(bodyElementType),
	content: string(),
});

const MailFormValueStruct = type({
	disable: string(),
	subject: define<string>('subject', (val) => String(val).length > 0 || '件名を入力してください'),
	template: string(),
	bodyHeader: string(),
	bodyElement: array(BodyElementStruct),
	signature: string(),
	email: string(),
});

const ApplyFormMailFormValueStruct = type({
	disable: string(),
	subject: define<string>('subject', (val) => String(val).length > 0 || '件名を入力してください'),
	template: string(),
	bodyHeader: string(),
	bodyElement: define<BodyElement[]>('bodyElement', (val) => {
		const bodyElement = val as typeof BodyElementStruct[];
		if (bodyElement === undefined) {
			return true;
		}
		const hasType = bodyElement.some(element => element.type === 'apply_form_url');
		return hasType || '本文に申込みフォームURLを追加してください';
	}),
	signature: string(),
	email: string(),
});

const CouponMailFormValueStruct = type({
	disable: define<string>('disable', (val) => String(val) === 'false' || 'レビュー特典にクーポンが含まれるため、申込受付完了メールの送信は必要です'),
	subject: define<string>('subject', (val) => String(val).length > 0 || '件名を入力してください'),
	template: string(),
	bodyHeader: string(),
	bodyElement: define<BodyElement[]>('bodyElement', (val) => {
		const bodyElement = val as typeof BodyElementStruct[];
		if (bodyElement === undefined) {
			return true;
		}
		const hasType = bodyElement.some(element => element.type === 'coupon_url');
		return hasType || '本文にクーポン URLを追加してください';
	}),
	signature: string(),
	email: string(),
});

const MailFormEmptyValueStruct = type({
	disable: string(),
	subject: string(),
	template: string(),
	bodyHeader: string(),
	bodyElement: array(BodyElementStruct),
	signature: string(),
	email: string(),
});

export const CampaignEditFormStep4Struct = dynamic((values: any) => {
	const canChooseReward = values.rewards.length > 1;
	const enableFollowMail = values.followMail.disable !== "true";
	const enableReceivedMail = values.receivedMail.disable !== "true";
	const isCouponReward = values.isCouponReward;
	const destType = values.destType;
	const canChooseDest = !isCouponReward && destType === 'form';
	return type({
		followMail: enableFollowMail ? MailFormValueStruct : MailFormEmptyValueStruct,
		requestMail: canChooseReward || canChooseDest ? ApplyFormMailFormValueStruct : MailFormEmptyValueStruct,
		receivedMail: isCouponReward ? CouponMailFormValueStruct : enableReceivedMail ? MailFormValueStruct : MailFormEmptyValueStruct,
		destType: enums(['form', 'shipping', 'orderer']),
	});
});

interface useCampaignEditStep4Props {
	templates: MailTemplate[];
	reviewFormCond: () => Record<string, boolean>;
	campaign: YahooCampaign;
}

export const useCampaignEditStep4 = (props: useCampaignEditStep4Props) => {
	const { errors, getValues } = useFormContext();
	const categories = React.useMemo(() => {
		return toArrayMap(props.templates, (template) => template.category);
	}, [props.templates]);
	const isCouponReward = getValues("isCouponReward");

	return {
		errors,
		categories,
		isCouponReward
	};
}
export default useCampaignEditStep4;