import { RewardTargetsSummary } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';

interface TargetSummaryProps {
	summary: RewardTargetsSummary | null;
	reviewCheckDate: number | null;
}

export const TargetSummary: React.FunctionComponent<TargetSummaryProps> = (props) => {

	const toSummaryValue = (key: keyof RewardTargetsSummary) => {
		return props.summary ? props.summary[key].toLocaleString() : 0;
	};

	const toSummaryRate = (key: keyof RewardTargetsSummary) => {
		return props.summary
			? Number.isFinite(props.summary[key]) ? (props.summary[key] * 100).toFixed(1) : "-"
			: '-';
	};

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>レビューデータ</FormLabel>
				<p className="fs_12 mb_24">最終レビューチェック日時 <time>{props.reviewCheckDate ? (dayjs(props.reviewCheckDate)).format("YYYY-MM-DD HH:mm") : ""}</time></p>
				<ul className="bl_reviewData">
					<li className="bl_reviewData_item">
						<h3 className="bl_reviewData_heading">対象商品数</h3>
						<p>{toSummaryValue('targetProducts')}<span className="el_unit">件</span></p>
					</li>
					<li className="bl_reviewData_item">
						<h3 className="bl_reviewData_heading">レビューあり</h3>
						<p>{toSummaryValue('anyReviewed')}<span className="el_unit">件({toSummaryRate('anyReviewRate')}%)</span></p>
					</li>
					<li className="bl_reviewData_item">
						<h3 className="bl_reviewData_heading">片方レビュー </h3>
						<p>{toSummaryValue('singleReviewed')}<span className="el_unit">件</span></p>
					</li>
					<li className="bl_reviewData_item">
						<h3 className="bl_reviewData_heading">申込内容記入済み</h3>
						<p>{toSummaryValue('done')}<span className="el_unit">件({toSummaryRate('appliedRate')}%)</span></p>
					</li>
					<li className="bl_reviewData_item">
						<h3 className="bl_reviewData_heading">申込内容記入待ち</h3>
						<p>{toSummaryValue('waitingAppling')}<span className="el_unit">件</span></p>
					</li>
				</ul>
			</div>
		</>
	);
};
export default TargetSummary;