import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SERVICE_NAME } from '../../../const';
import { useAPI } from "../../../context";
import { FormValues } from './AccountRecover';

interface useAccountRecoverProps {
	initValues: FormValues;
}
export const useAccountRecover = (props: useAccountRecoverProps) => {
	const callAPI = useAPI();
	const [result, setResult] = React.useState<boolean|null>(null);
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
	});

	const handleRecover: SubmitHandler<FormValues> = async (values) => {
		const res = await callAPI.user.recoverPassword({ mail: values.mail, service: SERVICE_NAME });
		setResult(Boolean(res && res.data));
	};

	return {
		methods: formMethods,
		handleRecover,
		result,
	};
};
export default useAccountRecover;