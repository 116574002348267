import * as React from 'react';

interface FileSelectorProps {
	onSelectFiles: (files: FileList) => void;
	accept?: HTMLInputElement['accept'];
	multiple?: HTMLInputElement['multiple'];
	children?: React.ReactNode;
}

export const FileSelector: React.FC<FileSelectorProps> = (props) => {
	const handleDragover: React.DragEventHandler = (ev) => {
		ev.preventDefault();
	};
	const handleDrop: React.DragEventHandler = (ev) => {
		ev.preventDefault();

		const files = ev.dataTransfer.files;
		if (! files) {
			return;
		}
		props.onSelectFiles(files);
	};
	const handleClick = () => {
		const fileSelector = document.createElement('input');
		fileSelector.type = 'file';
		if (props.accept != null) {
			fileSelector.accept = props.accept;
		}
		if (props.multiple != null) {
			fileSelector.multiple = props.multiple;
		}
		fileSelector.addEventListener('change', (ev) => {
			const elm = ev.currentTarget;
			if (!(elm instanceof HTMLInputElement) || !elm.files || !elm.files.length) {
				return;
			}
			props.onSelectFiles(elm.files);
		});
		fileSelector.click();
	};

	return (
		<div onDragOver={handleDragover} onDrop={handleDrop} onClick={handleClick}>
			{props.children}
		</div>
	);
};