import * as React from 'react';

interface FormGroupProps {
	className?: string;
	children: React.ReactNode;
}
export const FormGroup: React.FC<FormGroupProps> = (props) => {
	const classList = [`bl_panel_row`, props.className];
	return (
		<div className={classList.filter(Boolean).join(' ')}>
			{props.children}
		</div>
	);
}
export default FormGroup;
