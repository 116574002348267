import * as React from 'react';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { ReviewUpload } from './ReviewUpload';
import { TargetListTable } from './TargetListTable';
import { TargetSummary } from './TargetSummary';
import useTargetList from './useTargetList';

export const TargetList: React.FunctionComponent = () => {
	const {
		tableProps,
		summary,
		checkDate,
		handleSelectFiles
	} = useTargetList();

	return (
		<div className="ly_main__reviewStatus">
			<PageTitle>レビュー待ち状況</PageTitle>
			<PageDesc>レビュー待ち状況やレビューデータの確認ができます。また、レビュー待ち状況一覧よりフォローメールの送信除外設定を行うことができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<TargetListTable {...tableProps} />
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<TargetSummary summary={summary} reviewCheckDate={checkDate} />
				</div>
				<div className="bl_col bl_col__4">
					<ReviewUpload handleSelectFiles={handleSelectFiles} />
				</div>
			</div>
		</div>
	);
}
export default TargetList;