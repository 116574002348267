import { MailShopEntity, MailTemplate, YahooShopEntity } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useAPI, useAppState } from "../../../context";

export interface FormValues {
	name: string;
	category: string;
	bodyHeader: string;
	bodyElement: string[];
	signature: string;
	email: string;
	isFavorite: string;
}

type TemplateEditParams = {
	templateId?: string;
	flag?: string;
}

interface useTemplateEditProps {
	initValues: FormValues;
}

export const useTemplateEdit = (props: useTemplateEditProps) => {
	const params = useParams<TemplateEditParams>();
	const isNew = params.templateId == 'new';
	const isCopy = params.flag === 'copy';
	const { templateId } = params;

	const [state] = useAppState();
	const shopId = state.params.shopId;
	const location = useLocation();
	const urlParamSeq = new URLSearchParams(location.search).get("seq");
	const callAPI = useAPI();
	const [shopMail, setShopMail] = React.useState<string|undefined>(undefined);
	const navigate = useNavigate();
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
		shouldUnregister: false,
	});
	const { reset } = formMethods;

	const toInstance = (values: Partial<FormValues>, id: string): MailTemplate => {
		const bodys = [
			{ type: "header", content: values.bodyHeader },
			...(values.bodyElement || [])
		];
		return MailTemplate.create({
			id,
			name: values.name,
			category: values.category,
			body: bodys,
			signature: values.signature,
			isFavorite: values.isFavorite == 'true'
		});
	};

	const toValues = (template: MailTemplate | undefined, isNew: boolean, isCopy: boolean): FormValues => {
		if (isNew || !template) {
			return props.initValues;
		}
		const bodyHeaderData: any = template.body.find((val: any) => val.type === "header");
		return {
			...props.initValues,
			name: isCopy ? template.name + ' のコピー' : template.name,
			category: template.category,
			bodyHeader: bodyHeaderData ? bodyHeaderData.content : '',
			bodyElement: template.body.filter((val: any) => val.type !== "header") as [],
			signature: template.signature,
			isFavorite: template.isFavorite ? 'true' : 'false'
		};
	};

	React.useEffect(() => {
		if (shopMail !== undefined) {
			return;
		}
		return callAPI.mail.getShop({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const shop = new MailShopEntity(resData);
				setShopMail(shop.mail || '');
			}
		});
	}, [shopId]);

	React.useEffect(() => {
		if (shopMail === undefined || !templateId || isNew) {
			return;
		}
		return callAPI.yReview.getTemplate({ shopId, templateId, seq: urlParamSeq ?? undefined }, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const template = new MailTemplate(result.data);
				reset({
					...toValues(template, isNew, isCopy),
					email: shopMail,
				});
			}
		});
	}, [shopMail, shopId, templateId]);

	React.useEffect(() => {
		if (shopMail === undefined || !isNew) {
			return;
		}
		return callAPI.yReview.getShop({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const shopEntity = new YahooShopEntity(resData);
				reset({
					...props.initValues,
					signature: shopEntity.signature,
					email: shopMail,
				});
			}
		});
	}, [shopMail]);

	const handleSave: SubmitHandler<FormValues> = async (values): Promise<boolean> => {
		if (!templateId) {
			return false;
		}

		try {
			const id = isNew || isCopy ? uuid() : templateId;
			const template = toInstance(values, id);

			if (isNew || isCopy) {
				const res = await callAPI.yReview.postTemplate({ shopId, template });
				if (res) {
					const params = new URLSearchParams({ seq: res.data.seq });
					const to = `${state.params.basePath}/mail/template/${id}?` + params.toString();
					navigate(to, {state: {newId: id}, replace: true});
					return true;
				}
			} else {
				const res = await callAPI.yReview.putTemplate({ shopId, templateId, template });
				return Boolean(res && res.data);
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	const returnListUrl = `${state.params.basePath}/mail/template`;

	return {
		methods: formMethods,
		handleSave,
		returnListUrl
	};
};
export default useTemplateEdit;