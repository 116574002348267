import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { ErrorMessage, FormGroup, FormIndent, FormInputWrap, InputDate, InputDays, InputRadio } from '../../organisms/Form';
import useCampaignEditStep1, { DEADLINEDAYS_MAX } from './useCampaignEditStep1';

export function CampaignEditFormStep1(): React.ReactElement {
	const {
		isNeverEnd,
		isCutDate,
		isCutDays,
		isStarted,
		isFinishedCamp,
		isFinishedAll,
	} = useCampaignEditStep1();

	return (
		<>
			<ul className="bl_pageLink">
				<li className="bl_pageLink_item"><a href="#isNeverEnd">キャンペーン実施期間</a></li>
				<li className="bl_pageLink_item"><a href="#isCutCheck">レビュー受付期間</a></li>
				<li className="bl_pageLink_item"><a href="#reviewRequired">対象条件</a></li>
				<li className="bl_pageLink_item"><a href="#canManyRewards">特典の取扱い</a></li>
			</ul>
			<FormGroup>
				<FormLabel id="isNeverEnd" required>キャンペーン実施期間</FormLabel>
				<InputRadio name="isNeverEnd" value="false" label="期限を設定する" disabled={isFinishedCamp} /><br />
				{!isNeverEnd &&
					<FormIndent>
						<FormInputWrap>
							<span>開始</span>
							<InputDate mode="dateTime" name="begin" minDate={new Date()} disabled={isStarted} />
						</FormInputWrap>
						<ErrorMessage name="begin" />

						<FormInputWrap>
							<span>終了</span>
							<InputDate mode="dateTime" name="end" minDate={new Date()} disabled={isFinishedCamp} />
						</FormInputWrap>
						<ErrorMessage name="end" />
					</FormIndent>
				}
				<InputRadio name="isNeverEnd" value="true" label="無期限" disabled={isFinishedCamp} />
				{isNeverEnd &&
					<FormIndent>
						<FormInputWrap>
							<span>開始</span>
							<InputDate mode="dateTime" name="begin" minDate={new Date()} disabled={isStarted} />
						</FormInputWrap>
						<ErrorMessage name="begin" />
					</FormIndent>
				}
			</FormGroup>
			<FormGroup>
				<FormLabel id="isCutCheck" required>レビュー受付期間</FormLabel>
				<InputRadio name="isCutCheck" value="date" label="期限を設定する（対象日時まで）" disabled={isFinishedAll} /><br />
				{isCutDate &&
					<FormIndent>
						<FormInputWrap>
							<span>終了</span>
							<InputDate mode="dateTime" name="deadlineDate" minDate={new Date()} disabled={isFinishedAll} />
						</FormInputWrap>
						<ErrorMessage name="deadlineDate" />
					</FormIndent>
				}
				<InputRadio name="isCutCheck" value="days" label="期限を設定する（商品発送後の指定日数期間）" disabled={isFinishedAll} /><br />
				{isCutDays &&
					<FormIndent>
						<FormInputWrap>
							<span>商品発送後 </span>
							<InputDays name="deadlineDays" maxDate={DEADLINEDAYS_MAX} minDate={0} disabled={isFinishedAll} />
						</FormInputWrap>
						<ErrorMessage name="deadlineDays" />
						<InputDesc noMargin>※指定日数は14〜700日の範囲で入力してください。</InputDesc>
					</FormIndent>
				}
				<InputRadio name="isCutCheck" value="never" label="無期限" disabled={isFinishedAll} />
			</FormGroup>
			<FormGroup>
				<FormLabel id="reviewRequired" required>対象条件</FormLabel>
				<InputRadio name="reviewRequired" value="item" label="商品レビューを記入" /><br />
				<InputRadio name="reviewRequired" value="shop" label="ショップレビューを記入" /><br />
				<InputRadio name="reviewRequired" value="both" label="商品・ショップレビューの両方を記入" /><br />
				<InputRadio name="reviewRequired" value="any" label="商品・ショップレビューのどちらかを記入" />
			</FormGroup>
			<FormGroup>
				<FormLabel id="canManyRewards" required>特典の取扱い</FormLabel>
				<p className='mb_16'>同一の特典対象商品を1つの注文で2つ以上購入された場合</p>
				<InputRadio name="canManyRewards" value="false" label="購入分のうち１つのみを特典対象とする" /><br />
				<InputRadio name="canManyRewards" value="true" label="購入分すべてを特典対象とする" />
			</FormGroup>
		</>
	);
}
export default CampaignEditFormStep1;
