import { yahoo } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useYahoo } from '../../../hooks';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { PageDesc } from '../../molecules/PageDesc';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, InputDate, SubmitButton, Textarea } from '../../organisms/Form';

export const initValue = {
	publicKey: '',
	publicKeyVersion: '',
	publicKeyExpiration: 0,
}
export type FormValue = {
	publicKey: string;
	publicKeyVersion: string;
	publicKeyExpiration: number | Date;
};

interface YahooPublicKeyProps {
	shop: yahoo.GetShopResponse | null;
	methods: UseFormMethods;
	handleSave: SubmitHandler<FormValue>;
	handleInit: () => Promise<boolean>;
}

export const YahooPublicKey: React.FunctionComponent<YahooPublicKeyProps> = (props) => {
	const {
		publicKeyURL,
	} = useYahoo(props.shop);

	const handleClickInit = async () => {
		if (window.confirm("Yahoo!ストアクリエイターpro暗号鍵設定 を初期化します。よろしいですか？")) {
			await props.handleInit();
		}
	};
	const handleClickYahoo = () => {
		window.open(publicKeyURL, '_blank');
	}

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>公開鍵設定</FormLabel>
				<PageDesc>
					注文情報を取得するために必要な設定です。<br />
					ストアクリエイターProで発行した公開鍵情報を入力してください。
				</PageDesc>
				<Form methods={props.methods} onSubmit={props.handleSave}>
					<FormGroup>
						<Button className="el_btnInv" type="button" onClick={handleClickYahoo}>ストアクリエイターProで発行する</Button>
					</FormGroup>
					<RequiredDesc />
					<FormGroup>
						<FormLabel required>公開鍵</FormLabel>
						<Textarea name="publicKey" rows={5} rules={{ required: '公開鍵を入力してください' }} />
						<ErrorMessage name="publicKey" />
					</FormGroup>
					<FormGroup>
						<FormLabel required>バージョン</FormLabel>
						<Input type="number" name="publicKeyVersion" rules={{ required: 'バージョンを入力してください' }} />
						<ErrorMessage name="publicKeyVersion" />
					</FormGroup>
					<FormGroup>
						<FormLabel required>有効期限</FormLabel>
						<InputDate
							mode="date"
							name="publicKeyExpiration"
							rules={{ required: '有効期限を入力してください' }}
							popperClassName="datePickerPopper"
							minDate={new Date()}
							/>
						<ErrorMessage name="publicKeyExpiration" />
					</FormGroup>
					<FormGroup>
						<InputDesc>公開鍵を用いた認証を行うことで、Yahoo!JAPAN IDによる再認可までの期間を延長することが可能です。（12時間 → 28日）</InputDesc>
					</FormGroup>
					<div className="el_largeBtnWrap">
						<SubmitButton />
						<Button className="el_largeBtnBlueInv" type='button' onClick={handleClickInit}>初期化</Button>
					</div>
				</Form>
			</div>
		</>
	);
};
export default YahooPublicKey;