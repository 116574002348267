import * as React from 'react';
import useAccountActivate from './useAccountActivate';

export const AccountActivate: React.FunctionComponent = () => {
	const {
		handleClickStart
	} = useAccountActivate();

	return (
		<>
			<div className="bl_inviteForm">
				<div className="bl_loginFormRow">
					<p className="txt_blue">
						ラクラクあつまレビュー のメンバーとして登録されました。
					</p>
				</div>
				<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClickStart}>ラクラクあつまレビュー を始める</button>
			</div>
		</>
	);
};
export default AccountActivate;