import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ERROR_CLASSNAME } from './const';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	rules?: Record<string, unknown>;
	className?: string;
}
export const Input: React.FC<InputProps> = (props) => {
	const { register, errors } = useFormContext();

	const classNames = [
		props.className,
		errors[props.name ?? ''] ? ERROR_CLASSNAME : undefined
	];

	const inputProps = {
		...props,
		ref: props.rules? register(props.rules) : register,
		className: classNames.filter(Boolean).join(' '),
	};
	return (
		<input {...inputProps} />
	);
}
export default Input;
