
import { getPageInfoAll, MailNotCovered } from '@sasagase/types';
import { useAPI, useAPIDataTableLoad, useAppState } from '../../../context';
import { useListSelector } from '../../organisms/DataTable';

export const useNotCoveredList = () => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();

	const [rows, { filter, reload, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getNotCovereds,
		{
			initValue: [],
			apiParams: { shopId },
			onFetch: (data) => {
				return [data, undefined];
			},
		}
	);

	const {
		getChecks,
		reset,
	} = useListSelector();

	const handleClickDelete = async () => {
		if (!rows) {
			return;
		}
		const checks = getChecks();
		const names: string[] = Object.entries(checks)
			.filter(([id, check]) => check && id)
			.map(([id]) => {
				const nc = rows.find(row => row.id === id);
				return `・${nc?.name}`;
			});

		if (names.length <= 0) {
			alert(`削除する特典対象外商品用メールを選択してください。`);
			return;
		}

		let seq;
		if (window.confirm(`以下の特典対象外商品用メールを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			for (const [id, check] of Object.entries(checks)) {
				if (!check) {
					continue;
				}
				const param = { shopId, notCoveredId: id };
				const res = await callAPI.yReview.deleteNotCovered(param);
				if (!res || !res.data) {
					const nc = rows.find((row) => row.id === id);
					if (nc) {
						alert(`特典対象外商品用メール ${nc.name} の削除に失敗しました。この特典対象外商品用メールは使用中です。`);
					}
				} else {
					seq = res.data.seq;
				}
			}
		}
		filter({ seq }, []);
		reset();
	};

	const handleChangeEnable = async (notCoveredId: string, notCovered: Partial<MailNotCovered>) => {
		if (!rows) {
			return;
		}
		const param = { shopId, notCoveredId, notCovered }
		const res = await callAPI.yReview.putNotCovered(param);
		if (!res || !res.data) {
			reload();
		} else {
			const seq = res.data.seq;
			filter({ seq }, []);
		}
		reset();
	}

	const toEditURL = (id: string): string => {
		return state.params.basePath + '/mail/not-covered/' + id;
	};
	const toCopyURL = (id: string): string => {
		return state.params.basePath + '/mail/not-covered/' + id + '/copy';
	};

	return {
		tableProps: {
			rows,
			pageInfo: getPageInfoAll(rows?.length || 0),
			isLoading,
		},
		handleClickDelete,
		handleChangeEnable,
		toEditURL,
		toCopyURL,
	};
};
export default useNotCoveredList;
