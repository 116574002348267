
import { User, getPageInfoAll } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAPI, useAPIDataTableLoad, useAppState } from '../../../context';
import { FormValues } from './UserList';

interface useUserListProps {
	initValues: FormValues;
}

export const useUserList = (props: useUserListProps) => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const [users, setUsers] = React.useState<undefined|User[]>(undefined);
	const formMethods = useForm({
		defaultValues: props.initValues,
		shouldUnregister: false,
	});

	const [rows, { reload, isLoading }] = useAPIDataTableLoad(
		callAPI.user.getEntityUsers,
		{
			initValue: [],
			apiParams: { shopId },
			onFetch: (data) => {
				return [data, undefined];
			},
		}
	);

	React.useEffect(() => {
		if (!rows) {
			return;
		}

		const users = rows.map(obj => User.create(obj));
		setUsers(users);
	}, [rows]);

	const getShopName = (shopId: string) => state.shops?.[shopId]?.name || shopId;

	const handleInvite: SubmitHandler<FormValues> = async (values) => {

		try {
			const inputs: string[] = values['email'].split(',').map((val: string) => val.trim());
			const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
			const targets: string[] = inputs.filter((val) => reg.test(val));
			if (!targets.length) {
				alert("招待するユーザーのメールアドレスを入力してください。");
			}
			const invited = [];
			const failed = [];
			for (const target of [...new Set(targets)]) {
				const mailOption = {
					shop_name: getShopName(state.params.shopId),
					invite_name: state.user?.name || '',
				};
				const result = await callAPI.user.postEntityUser({
						shopId,
						mail: target,
						capabilities: ['yReview_login', 'pending'],
						mailOption
					});
				if (result && result.data) {
					invited.push(target);
				} else {
					failed.push(target);
				}
			}
			const msg = [];
			if (invited.length > 0) {
				msg.push(`${invited.length}件のアカウントを招待しました。`);
				reload();
			}
			if (failed.length > 0) {
				msg.push(`${failed.length}件のアカウントの招待に失敗しました。`);
			}
			if (msg.length > 0) {
				alert(msg.join("\n"));
			}
		} catch (err) {
			console.log(err)
			return false;
		}
	};

	const handleClickDelete = async (userId: string) => {
		const user = users?.find((u) => u.id === userId);
		if (user) {
			if (window.confirm(`${user.mail} アカウントを削除してよろしいですか？`)) {
				const res = await callAPI.user.deleteEntityUser({
						shopId,
						userId: user.id
					});
				if (res) {
					reload();
				}
			}
		}
	};

	const toEditURL = (id: string): string => {
		return state.params.basePath + '/setting/user/' + id;
	};

	return {
		methods: formMethods,
		handleInvite,
		tableProps: {
			rows: users,
			pageInfo: getPageInfoAll(users?.length || 0),
			isLoading,
			shopId,
			toEditURL,
			handleClickDelete,
		},
	};
};
export default useUserList;
