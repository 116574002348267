import { css } from "@emotion/react";
import * as React from 'react';
import { cssVariable } from '../../../css/variable';

export const Loading: React.FunctionComponent = () => {

	const lodingStyle = css`
		position: relative;
		display: inline-block;
		width: 50px;
		height: 5px;
		border: 1px solid ${cssVariable.main1};
		overflow: hidden;
		border-radius: 5px;
		background-repeat: repeat-x;
		background-size: 10px 100%;
		background-image: -webkit-gradient(linear, left top, right top, from(${cssVariable.main1}), color-stop(0.5, ${cssVariable.main1}), color-stop(0.51, transparent), to(transparent) );
		background-image: -webkit-linear-gradient(left,${cssVariable.main1} 5px,transparent 0);
		background-image: linear-gradient(to left,${cssVariable.main1} 5px,transparent 0);
		vertical-align: middle;
		-webkit-animation: animation21 2s linear infinite;
		animation: animation21 2s linear infinite;
	
		@-webkit-keyframes animation21 {
			0% {
				background-position: 0;
			}
			100% {
				background-position: 100%;
			}
			}
			@keyframes animation21 {
			0% {
				background-position: 0;
			}
			100% {
				background-position: 100%;
			}
		}
	`;

	return <div css={lodingStyle}></div>;
};
export default Loading;