import * as React from 'react';
import { Button } from '../../atoms/Button';
import { InputDesc } from '../../molecules/InputDesc';
import { InputFile } from '../../organisms/Form';

interface ImageUploadProps {
	imageUrl?: string;
	name: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onDelete?: () => void;
	inputDesc?: string | React.ReactNode;
	wrapClassName?: string;
}
export const ImageUpload: React.FC<ImageUploadProps> = (props) => {

	return (
		<div className={`bl_imgUpload ${props.wrapClassName}`}>
			<div className="bl_imgUpload_imgWrap">
				{props.imageUrl &&
					<img src={props.imageUrl} />
				}
			</div>
			<div className="bl_imgUpload_txtWrap">
				<InputFile name={props.name} onChange={props.onChange} />
				{props.onDelete && <Button className="el_btnTxt" onClick={props.onDelete}>削除</Button>}
				{props.inputDesc && <InputDesc>
					{props.inputDesc}
				</InputDesc>}
			</div>
		</div>
	);
}
export default ImageUpload;