import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { CampaignState, campaignStateInfos, campaignStateList, PageInfo } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ContextState } from '../../../context';
import { Column, DataTable, ReorderResult } from '../../organisms/DataTable';

interface CampaignListTableProps {
	state: ContextState;
	rows?: APIResponses.GetCampaignListResponse;
	pageInfo?: PageInfo;
	isLoading: boolean;
	handleFilter: () => void;
	methodFilter: UseFormMethods;
	handleReorder: (result: ReorderResult) => void;
}

export const CampaignListTable: React.FunctionComponent<CampaignListTableProps> = (props) => {
	const { state } = props;

	// テーブル固有のCSS指定
	// TODO ここ以外で指定できないか要検討
	const tableStyle = css`
		height: 691px;

		td:nth-of-type(5) {
			text-align: center;
		}

		td:nth-of-type(1) {
			padding: 0;
		}
		.el_dragIndicator {
			background-image: url(${require('../../../img/drag_indicator_black_24dp.svg')});
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			height: 100%;
		}
	`;

	const toStateString = (state: CampaignState) => {
		return campaignStateInfos[state]?.name || state;
	};
	const toDateString = (milliepoc: number | undefined): string => {
		if (!milliepoc) {
			return '';
		}
		return dayjs(milliepoc).format('YYYY年MM月DD日');
	};
	const toCampaignEditURL = (campaignId: string) => {
		return `${state.params.basePath}/reward/campaign/${campaignId}`;
	};
	const toCampaignCopyURL = (campaignId: string) => {
		return `${state.params.basePath}/reward/campaign/${campaignId}/copy`;
	};

	const stateFilterElement = (options: any): JSX.Element => {
		const filterForm = <>
			<select name="state" ref={options.register} onChange={() => options.handleFilterToClose()}>
				<option value="">すべて表示</option>
				{campaignStateList.map((state) =>
					<option key={state} value={state}>{toStateString(state)}</option>
				)}
			</select>
		</>;
		return options.createFilterSection(filterForm);
	}
	const nameBodyTemplate = (rowData: any) => {
		return <>
			<span>
				{rowData.isDraft &&
					<span className="el_incomplete">(未入力アリ)</span>
				}
				{rowData.name}
			</span>
		</>;
	}
	const dateBodyTemplate = (rowData: any) => {
		return <>{toDateString(rowData.beginDate)}～<br />{toDateString(rowData.endDate)}</>;
	}
	const rewardBodyTemplate = (rowData: any) => {
		return <>{rowData.rewards.map((r: any) => r.name).join(', ')}</>;
	}
	const stateBodyTemplate = (rowData: any) => {
		return <>
			<span>{toStateString(rowData.state)}</span>
			<div className="bl_table_btnWrap">
				<Link className="el_btnInv" type="button" to={toCampaignEditURL(rowData.id)}>編集</Link>
				<Link className="el_btnInv" type="button" to={toCampaignCopyURL(rowData.id)}>複製</Link>
			</div>
		</>;
	}
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	}

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				handleFilter={props.handleFilter} methodFilter={props.methodFilter}
				reorderable handleReorder={props.handleReorder}
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 0 32px center center" rowReorder field="dragIndicator"></Column>
				<Column style="0 0 48px center center" listSelector field="id"></Column>
				<Column style="1 1 468px center flex-start" field="name"
					filterable
					bodyTemplate={nameBodyTemplate}
					>キャンペーン名</Column>
				<Column style="0 0 160px center center" field="date" bodyTemplate={dateBodyTemplate}>実施期間</Column>
				<Column style="0 0 160px center center" field="empty"></Column>
				<Column style="1 1 460px center flex-start" field="reward" bodyTemplate={rewardBodyTemplate}>特典詳細</Column>
				<Column style="0 0 240px left space-between" field="state"
					filterable
					filterElement={stateFilterElement}
					thClassName="bl_table_filterHead__beforeBtn"
					bodyTemplate={stateBodyTemplate}
					>状況</Column>
			</DataTable>
		</>
	);
}
export default CampaignListTable;