/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Markdown from './Markdown';
import { styleMarkdown } from './style';

const DEFAULT_MAIN_COLOR = '#3F81CB';

interface ReactHelpMarkdownProps {
	md: string;
	modifyAnchor?: (props: {children: any, href: any}) => React.ReactElement;
	callback?: {
		showLightBox?: (src?: string) => void;
		load?: () => void;
	};
	mainColor?: string;
}

export const ReactHelpMarkdown = (props: ReactHelpMarkdownProps): React.ReactElement => {
	const { mainColor = DEFAULT_MAIN_COLOR } = props;

	return (
		<div css={styleMarkdown(mainColor)}>
			<Markdown {...props} />
		</div>
	);
}
export default ReactHelpMarkdown;