import * as React from 'react';
import GuideSubTop from './GuideSubTop';
import GuideSubUseFlow from './GuideSubUseFlow';

interface GuideSubProps {
	path: string,
	title: string,
	folderPath: string,
	basePath: string,
}

export const GuideSub: React.FunctionComponent<GuideSubProps> = (props) => {
	return (
		<div className="bl_panel bl_panel__ttlInv">
			{props.path == 'use_guide/top' &&
				<GuideSubTop title={props.title} basePath={props.basePath} folderPath={props.folderPath} />
			}

			{props.path == 'registration_setting/use_flow' &&
				<GuideSubUseFlow title={props.title} basePath={props.basePath} folderPath={props.folderPath} />
			}
		</div>
	);
};
export default GuideSub;