import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { FormResult } from '../../molecules/FormResult';
import { InputDesc } from '../../molecules/InputDesc';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, InputPasswordOptional, InputRadio, SubmitButton } from '../../organisms/Form';
import useSmtpSetting from './useSmtpSetting';

const initValues = {
	mail: '',
	mailSenderName: '',
	smtpHost: '',
	smtpPort: '',
	smtpAuth: 'plain',
	smtpEncrypt: 'none',
	smtpUser: '',
	smtpPass: '',
	mailCc: '',
	mailBcc: '',
};
export type FormValues = typeof initValues;
const testMailInitValues = {
	testMail: '',
};
export type TestMailFormValues = typeof testMailInitValues

export const SmtpSetting: React.FunctionComponent = () => {
	const {
		storedValues,
		methods,
		testMailMethods,
		handleSave,
		isSendable,
		handleSend,
		resultSend,
	} = useSmtpSetting(initValues);

	const { errors } = methods;

	return (
		<>
			<PageTitle>SMTPサーバ設定</PageTitle>
			<PageDesc>メールを送信するために必要な設定を行います。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<Form methods={methods} onSubmit={handleSave}>
							<RequiredDesc />
							<FormGroup>
								<FormLabel required>店舗メールアドレス</FormLabel>
								<Input type="text" name="mail" rules={{ required: '店舗メールアドレスを入力してください' }} />
								<ErrorMessage name="mail" />
							</FormGroup>
							<FormGroup>
								<FormLabel>店舗メール差出人名</FormLabel>
								<Input type="text" name="mailSenderName" />
								<ErrorMessage name="mailSenderName" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>メール送信ホスト</FormLabel>
								<Input type="text" name="smtpHost" rules={{ required: 'メール送信ホストを入力してください' }} />
								<ErrorMessage name="smtpHost" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>メール送信ポート</FormLabel>
								<Input type="number" name="smtpPort" rules={{ required: 'メール送信ポートを入力してください' }} />
								<ErrorMessage name="smtpPort" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>SMTP認証方式</FormLabel>
								<InputRadio name="smtpAuth" value="plain" label="PLAIN" rules={{ required: 'SMTP認証方式を選択してください' }} />
								<InputRadio name="smtpAuth" value="login" label="LOGIN" rules={{ required: 'SMTP認証方式を選択してください' }} />
								<InputRadio name="smtpAuth" value="cram-md5" label="CRAM-MD5" rules={{ required: 'SMTP認証方式を選択してください' }} />
								<ErrorMessage name="smtpAuth" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>暗号化</FormLabel>
								<InputRadio name="smtpEncrypt" value="none" label="なし" rules={{ required: 'メール送信の際の暗号化方式を選択してください' }} />
								<InputRadio name="smtpEncrypt" value="starttls" label="STARTTLS" rules={{ required: 'メール送信の際の暗号化方式を選択してください' }} />
								<InputRadio name="smtpEncrypt" value="ssltls" label="SSL/TLS" rules={{ required: 'メール送信の際の暗号化方式を選択してください' }} />
								<ErrorMessage name="smtpEncrypt" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>SMTP認証ユーザ</FormLabel>
								<Input type="text" name="smtpUser" rules={{ required: 'SMTP認証ユーザを入力してください' }} />
								<ErrorMessage name="smtpUser" />
							</FormGroup>
							<FormGroup>
								<FormLabel>SMTP認証パスワード</FormLabel>
								<InputPasswordOptional name="smtpPass" errors={errors} hide storedValue={storedValues?.smtpPass}/>
								<ErrorMessage name="smtpPass" />
							</FormGroup>
							<FormGroup>
								<FormLabel>Cc</FormLabel>
								<Input type="text" name="mailCc" />
								<ErrorMessage name="mailCc" />
								<InputDesc>※複数のメールアドレスへ送信したい場合はカンマ区切り(,) で指定することができます</InputDesc>
							</FormGroup>
							<FormGroup>
								<FormLabel>Bcc</FormLabel>
								<Input type="text" name="mailBcc" />
								<ErrorMessage name="mailBcc" />
								<InputDesc>※複数のメールアドレスへ送信したい場合はカンマ区切り(,) で指定することができます</InputDesc>
							</FormGroup>
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
						</Form>
						<Form methods={testMailMethods} onSubmit={handleSend}>
							<FormGroup>
								<FormLabel headline>テストメールを送信する</FormLabel>
								<PageDesc>保存されたSMTPサーバ情報を使用して、上記のメールアドレス宛にテストメールを送信します。</PageDesc>
								<FormResult>{resultSend}</FormResult>
								<FormGroup>
									<FormLabel>送信先メールアドレス</FormLabel>
									<Input type="text" name="testMail" rules={{ required: 'テストメール送信先のメールアドレスを入力してください' }} />
									<ErrorMessage name="testMail" />
								</FormGroup>
								<SubmitButton disabled={!isSendable}>テスト送信</SubmitButton>
							</FormGroup>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default SmtpSetting;