import { yahoo } from '@sasagase/types';

export const useYahoo = (shop?: yahoo.GetShopResponse | null) => {
	const SHOP_HOST = 'pro.store.yahoo.co.jp';
	const TEST_SHOP_HOST = 'test.pro.store.yahoo.co.jp';

	const storeURL = (shop: yahoo.GetShopResponse, path: string): string => {
		const host = shop.isTestShop ? TEST_SHOP_HOST : SHOP_HOST;
		return `https://${host}/pro.${shop.yahooStoreAccount}/${path}`;
	}

	const toOrderDetailURL = (orderNumber: string): string => {
		if (!shop) {
			return '';
		}

		return storeURL(shop, `order/manage/detail/${orderNumber}`);
	};

	const publicKeyURL = shop ? storeURL(shop, 'setting/key_manage') : '';

	return {
		toOrderDetailURL,
		publicKeyURL,
	};
}
export default useYahoo;