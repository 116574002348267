import * as React from 'react';

export const Notfound: React.FunctionComponent = () => {
	return (
		<>
			<div className="ly_reissue">
				<div className="ly_reissue_inner">
					<div className="bl_inviteForm">
						<div className="bl_loginFormRow">
							<p className="el_errorMessage">
								指定されたページは見つかりません<br />
								URLを確認してください
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Notfound;