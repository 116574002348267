import { Review, yahoo } from '@sasagase/types';
import { Options, parse } from 'csv-parse';
import options, { ParseReviewOptions } from './options';

const CSV_BUFF_ENCODING = 'Shift_JIS';

function parsePromise<T extends Record<string, unknown>>(input: string | Buffer, opt: Options): Promise<T[]> {
	return new Promise((resolve, reject) => {
		parse(input, opt, (err, records) => {
			if (err) {
				return reject(err);
			}
			resolve(records);
		});
	});
}

async function csvToReviews<T>(csv: string, opt: ParseReviewOptions<T>): Promise<T[]> {
	const records = await parsePromise(csv, opt.parseOptions);

	const reviews = records.map(opt.toReview);
	return reviews;
}

export async function parseReviewCSV(buff: ArrayBuffer): Promise<Review[]> {
	const decoder = new TextDecoder(CSV_BUFF_ENCODING);
	const csv = decoder.decode(buff);
	const reviews = await csvToReviews(csv, options.rakuten);
	return reviews;
}
export default parseReviewCSV;

// TODO: 別関数にするよりyahooかどうかなどを引数で渡すようにしたい……😖
export async function parseYahooReviewCSV(buff: ArrayBuffer): Promise<yahoo.Review[]> {
	const decoder = new TextDecoder(CSV_BUFF_ENCODING);
	const csv = decoder.decode(buff);
	const reviews = await csvToReviews(csv, options.yahoo);
	return reviews;
}
