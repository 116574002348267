import * as React from 'react';
import { useTopWarnings } from './useTop';
import WarningListItem from './WarningListItem';

export const WarningList: React.FunctionComponent = () => {
	const { warnings, basePath } = useTopWarnings();

	if (!warnings || warnings.length <= 0) {
		return null;
	}

	return (
		<>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__warning">
						<h2 className="el_lv2Heading">設定内容をご確認ください</h2>
						<ul className="bl_warningList">
							{warnings.map((content) => <WarningListItem key={content.id} basePath={basePath} content={content} />)}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}
export default WarningList;