import { BodyElementType, MailContent, MailTemplate, YahooCampaign, bodyElementType } from '@sasagase/types';
import * as React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { array, define, dynamic, enums, string, type } from 'superstruct';
import { useAPI, useAppState } from '../../../context';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { ErrorMessage, FormGroup, Input, InputRadio, Select } from '../../organisms/Form';
import { MailEditRow, Preview } from '../../organisms/MailEditRow';
import { MailFormValue } from './useCampaignEditStep4';

const MailFormValueStruct = type({
	disable: string(),
	subject: define<string>('subject', (val) => String(val).length > 0 || '件名を入力してください'),
	template: string(),
	bodyHeader: string(),
	bodyElement: array(type({
		type: enums<BodyElementType>(bodyElementType),
		content: string(),
	})),
	signature: string(),
	email: string(),
});

const MailFormEmptyValueStruct = type({
	disable: string(),
	subject: string(),
	template: string(),
	bodyHeader: string(),
	bodyElement: array(type({
		type: enums<BodyElementType>(bodyElementType),
		content: string(),
	})),
	signature: string(),
	email: string(),
});

export const CampaignEditFormStep4Struct = dynamic((values: any) => {
	const canChooseReward = values.rewards.length > 1;
	const enableFollowMail = values.followMail.disable !== "true";
	const enableRequestMail = values.requestMail.disable !== "true";
	const enableReceivedMail = values.receivedMail.disable !== "true";
	return type({
		followMail: enableFollowMail ? MailFormValueStruct : MailFormEmptyValueStruct,
		requestMail: (canChooseReward && enableRequestMail) ? MailFormValueStruct : MailFormEmptyValueStruct,
		receivedMail: enableReceivedMail ? MailFormValueStruct : MailFormEmptyValueStruct,
	});
});

function toTemplateValues(template: MailTemplate): Partial<MailFormValue> {
	const bodyHeaderData = template.body.find((val) => val.type === "header");
	return {
		bodyHeader: bodyHeaderData ? bodyHeaderData.content : '',
		bodyElement: template.body.filter((val) => val.type !== "header"),
		signature: template.signature,
	};
}

interface MailSectionProps {
	templates: MailTemplate[];
	name: string;
	isOpen?: boolean;
	className?: string;
	errors?: FieldErrors;
	message?: string;
	disabled?: boolean;
	children?: React.ReactNode;
	campaign: YahooCampaign;
	sendRequired: boolean;
	sendRequiredReason?: string;
}

const MailSection: React.FC<MailSectionProps> = (props) => {
	const [state] = useAppState();
	const methods = useFormContext();
	const { getValues } = methods;
	const callAPI = useAPI();
	const [isOpen, setOpen] = React.useState(props.isOpen ?? true);
	const [previewValues, setPreviewValues] = React.useState<MailContent | null>(null);
	const toName = React.useCallback((name: string) => {
		return `${props.name}.${name}`;
	}, [props.name]);
	const [applyValues, setApplyValues] = React.useState<Partial<MailFormValue>|undefined>(undefined);

	const handleClickHeader = () => {
		setOpen(prev => !prev);
	};
	const handleTemplateApply = () => {
		const templateId = getValues(toName('template'));
		const template = props.templates.find(template => template.id == templateId);
		if (!template) {
			return;
		}
		setApplyValues(toTemplateValues(template));
		setPreviewValues(null);
	}
	const handleClickTemplateApply: React.MouseEventHandler<HTMLButtonElement> = () => {
		handleTemplateApply();
	};
	const handleClickSendPreview = async () => {
		const templateId = getValues(toName('template'));
		const template = props.templates.find(template => template.id == templateId);
		if (!template) {
			return;
		}
		const values = toTemplateValues(template);
		const res = await callAPI.user.getReviewMail({
				shopId: state.params.shopId,
				styleType: 'preview',
				opt: values
			});
		if (res && res.data) {
			const mailContent = new MailContent(res.data);
			setPreviewValues(mailContent);
		}
	};
	const handleClickClosePreview = () => {
		setPreviewValues(null);
	};

	return (
		<>
			{previewValues && <Preview values={previewValues} onClose={handleClickClosePreview} onApply={handleTemplateApply} />}
			<section className={"bl_mailTemp " + (props.className || '')}>
				<h2 className={`bl_mailTemp_ttl ${isOpen ? 'is_active' : ''}`} id={props.name} onClick={handleClickHeader}>{props.children}設定</h2>
				<div className="bl_mailTemp_body">
					{props.message &&
						<FormGroup className="bl_panel_row__indent">
							<span className="fs_14" style={{ color: 'red' }}>{props.message}</span>
						</FormGroup>
					}
					<FormGroup className={props.disabled ? 'bl_panel_row__disabled' : ''}>
						<FormGroup className="bl_panel_row__indent">
							<FormLabel required>メール送信設定</FormLabel>
							<InputRadio name={toName('disable')} value="false" label={`${props.children}を送信する`} disabled={props.disabled} />
							<InputRadio name={toName('disable')} value="true" label={`${props.children}を送信しない`} disabled={props.disabled || props.sendRequired} />
							<ErrorMessage name={toName('disable')} />
							{props.sendRequiredReason &&
								<p style={{ color: 'red' }}>※{props.sendRequiredReason}</p>
							}
						</FormGroup>
						<FormGroup className="bl_panel_row__indent">
							<FormLabel>テンプレートを選択</FormLabel>
							<div className="bl_mailTemp_selectTemp">
								<Select name={toName('template')}
									options={Object.fromEntries([['', '']].concat(props.templates.map(t => [t.id, t.name])))}
									disabled={props.disabled}
									/>
								<Button className="el_note" type="button" onClick={handleClickSendPreview} disabled={props.disabled}>プレビュー</Button>
								&nbsp;
								&nbsp;
								&nbsp;
								<Button className="el_note" type="button" onClick={handleClickTemplateApply} disabled={props.disabled}>適用</Button>
							</div>
						</FormGroup>
						<FormGroup className="bl_panel_row__indent">
							<FormLabel required>メール件名</FormLabel>
							<Input type="text" placeholder="テキスト" name={toName('subject')} disabled={props.disabled} />
							<ErrorMessage name={toName('subject')} />
						</FormGroup>
						<MailEditRow toName={toName} applyValues={applyValues} disabled={props.disabled} campaign={props.campaign} />
					</FormGroup>
				</div>
			</section>
		</>
	);
};
export default MailSection;