import { css } from "@emotion/react";
import * as React from 'react';
import { cssVariable } from '../../../css/variable';

interface InputInlineDescProps {
	children: React.ReactNode;
	alert?: boolean;
	className?: string;
}
export const InputInlineDesc: React.FC<InputInlineDescProps> = (props) => {

	const style = css`
		font-size: 12px !important;
		color: ${props.alert ? cssVariable.error : cssVariable.main1} !important;
	`;

	return <span css={style} className={props.className}>{props.children}</span>;
}
export default InputInlineDesc;
