import { ItemGroup } from '@sasagase/types';
import sanitizeHtml from 'sanitize-html';

export const useItemGroupEdit = () => {

	const sanitizeSku = (html: string) => {
		return sanitizeHtml(html, {
			disallowedTagsMode: 'escape',
			allowedTags: [ 'span', 'br', 'div'],
			allowedAttributes: {
				span: [ 'contenteditable', 'data-*' ]
			},
		});
	};

	const getItemGroupRequireDesc = (group: ItemGroup): string[] => {
		const reqs = group.getRequires();

		const desc: string[] = [];
		if (reqs.some(req => req.isAll)) {
			desc.push(`対象:全商品`);
		} else {
			const skus = reqs.reduce((sum, req) => new Set([...sum, ...req.sku]), new Set());
			if (skus.size) desc.push(`対象:${skus.size}商品`);
		}
		if (reqs.some(req => req.excludeSku.size || req.excludeRequires.length)) {
			desc.push(`除外:条件あり`);
		}

		return desc;
	};

	return {
		sanitizeSku,
		getItemGroupRequireDesc,
	};
}