import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { date, define, dynamic, enums, is, number, string, type, union } from 'superstruct';
import { nonNegative } from '../../../lib';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { DateFields, DaysField, ErrorMessage, FormGroup, FormIndent, FormInputWrap, Input, InputDate, InputDays, InputRadio, toDate } from '../../organisms/Form';
import { initValues } from './CampaignEdit';
import useCampaignEdit from './useCampaignEdit';
import { DEADLINEDAYS_MAX, DEADLINEDAYS_MIN, cutCheck } from './useCampaignEditStep1';
import { CampaignWizardFormValues, contextWizard } from './useCampaignWizard';

export const campaignWizardS5InitValues: CampaignWizardS5FormValue = {
	begin: '',
	end: '',
	isNeverEnd: 'false' as const,
	deadlineDate: '',
	deadlineDays: '',
	isCutCheck: 'never' as const,
	applicationClosingDays: '',
	shouldCloseApplication: 'false' as const,
};

export interface CampaignWizardS5FormValue {
	begin: DateFields;
	end: DateFields;
	isNeverEnd: 'true' | 'false';
	deadlineDate: DateFields;
	deadlineDays: DaysField;
	isCutCheck: cutCheck;
	applicationClosingDays: string;
	shouldCloseApplication: 'true' | 'false';
}

const dateValidator = (targetDate: Date, compareResult: boolean, ErrorMessage: string) => {
	if (targetDate.getTime()) {
		return compareResult || ErrorMessage;
	} else {
		return '有効な日付を入力してください';
	}
}

const requiredNumericString = (val: unknown) => {
	return isFinite(parseInt(String(val), 10));
}

export const CampaignWizardS5Struct = dynamic((values: any) => {
	const isNeverEnd = values.isNeverEnd == 'true';
	const isDeadlineDate = values.isCutCheck == 'date';
	const isDeadlineDays = values.isCutCheck == 'days';

	const begin = toDate(values.begin);
	const end = toDate(values.end);
	const deadlineDate = toDate(values.deadlineDate);
	const deadlineDays = Number(values.deadlineDays);

	const anyDateField = union([string(), date(), number()]);
	const anyDaysField = union([string(), number()]);

	const isStarted = Boolean(values.inProgressDate);
	const isFinishedCamp = Boolean(values.waitingReviewDate);
	const isFinishedAll = Boolean(values.finishedDate || values.cancelledDate);

	const shouldCloseApplication = values.shouldCloseApplication == 'true';

	return type({
		begin: isStarted ? anyDateField :
			define<DateFields>('begin', () =>
				dateValidator(begin, begin > new Date(), '現在以降の日時を指定してください')
			),
		end: isFinishedCamp || isNeverEnd ? anyDateField :
			define<DateFields>('end', () =>
				dateValidator(end, end >= begin, '開始日以降の日時を指定してください')
			),
		deadlineDate: isFinishedAll || !isDeadlineDate ? anyDateField :
			define<DateFields>('deadline', () =>
				dateValidator(deadlineDate, deadlineDate >= end, 'キャンペーン終了以降の日時を指定してください')
			),
		deadlineDays: isFinishedAll || !isDeadlineDays ? anyDaysField :
			define<DaysField>('deadline', () =>
				!deadlineDays ? "有効な日数を入力してください" :
				deadlineDays < DEADLINEDAYS_MIN ? '最小指定日数よりも短い期間は指定できません。14日以上を指定してください。' :
				deadlineDays > DEADLINEDAYS_MAX ? '最大指定日数よりも長い期間は指定できません。700日以下を指定してください。' : true
			),
		isNeverEnd: enums(['true', 'false']),
		isCutCheck: enums(['date', 'days', 'never']),
		applicationClosingDays: shouldCloseApplication ? define<string>('applicationClosingDays', (val) => requiredNumericString(val) || '受付期間を入力してください') : string(),
		shouldCloseApplication: enums(['true', 'false']),
	});
});

interface CampaignWizardS5Props {
	onPrev: () => void;
	onClose: () => void;
}

export const CampaignWizardS5: React.FC<CampaignWizardS5Props> = (props) => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const { onPrev } = props;
	const { watch, getValues } = useFormContext();
	const wizardValues = ctx.values;

	const {
		rewards
	} = useCampaignEdit({ initValues });

	const canNext = React.useMemo(() => {
		return is(getValues(), CampaignWizardS5Struct);
	}, [watch]);

	const handleNext = () => {
		const values = getValues();
		if (is(values, CampaignWizardS5Struct)) {
			ctx.setStepIdx(idx => idx + 1);
			ctx.setWizardValues((prev: CampaignWizardFormValues)  => ({
				...prev,
				begin: values.begin,
				end: values.end,
				isNeverEnd: values.isNeverEnd,
				deadlineDate: values.deadlineDate,
				deadlineDays: values.deadlineDays,
				isCutCheck: values.isCutCheck,
			}));
		}
	};

	if (!rewards) {
		return null; // loading
	}

	const isNeverEnd = watch('isNeverEnd') == 'true';
	const isCutDate = watch('isCutCheck') == 'date';
	const isCutDays = watch('isCutCheck') == 'days';
	const isStarted = Boolean(watch('inProgressDate'));
	const isFinishedCamp = Boolean(watch('waitingReviewDate'));
	const isFinishedAll = Boolean(watch('finishedDate') || watch('cancelledDate'));
	const shouldCloseApplication = watch('shouldCloseApplication') == 'true';

	const { rewardIds } = wizardValues;
	const rewardSelects = (rewards || []).filter(reward => rewardIds.includes(reward.id));
	const canChooseReward = rewardSelects.length > 1;
	const disabledReviewForm = !canChooseReward;

	return (
		<>
			<div className="bl_panel__wizardMain">
				<FormGroup>
					<FormLabel>レビュー特典付与の対象とする注文の対象期間(キャンペーン実施期間)を入力してください</FormLabel>
					<InputRadio name="isNeverEnd" value="false" label="期限を設定する" disabled={isFinishedCamp} /><br />
					{!isNeverEnd &&
						<FormIndent>
							<FormInputWrap>
								<span>開始</span>
								<InputDate popperClassName="datePickerPopper" mode="dateTime" name="begin" minDate={new Date()} disabled={isStarted} />
							</FormInputWrap>
							<ErrorMessage name="begin" />

							<FormInputWrap>
								<span>終了</span>
								<InputDate popperClassName="datePickerPopper" mode="dateTime" name="end" minDate={new Date()} disabled={isFinishedCamp} />
							</FormInputWrap>
							<ErrorMessage name="end" />
						</FormIndent>
					}
					<InputRadio name="isNeverEnd" value="true" label="無期限" disabled={isFinishedCamp} />
					{isNeverEnd &&
						<FormIndent>
							<FormInputWrap>
								<span>開始</span>
								<InputDate popperClassName="datePickerPopper" mode="dateTime" name="begin" minDate={new Date()} disabled={isStarted} />
							</FormInputWrap>
							<ErrorMessage name="begin" />
						</FormIndent>
					}
				</FormGroup>
				<FormGroup>
					<FormLabel>レビューの受付期間を入力してください</FormLabel>
					<InputRadio name="isCutCheck" value="date" label="期限を設定する（対象日時まで）" disabled={isFinishedAll} /><br />
					{isCutDate &&
						<FormIndent>
							<FormInputWrap>
								<span>終了</span>
								<InputDate popperClassName="datePickerPopper" mode="dateTime" name="deadlineDate" minDate={new Date()} disabled={isFinishedAll} />
							</FormInputWrap>
							<ErrorMessage name="deadlineDate" />
						</FormIndent>
					}
					<InputRadio name="isCutCheck" value="days" label="期限を設定する（商品発送後の指定日数期間）" disabled={isFinishedAll} /><br />
					{isCutDays &&
						<FormIndent>
							<FormInputWrap>
								<span>商品発送後 </span>
								<InputDays name="deadlineDays" maxDate={DEADLINEDAYS_MAX} minDate={0} disabled={isFinishedAll} />
							</FormInputWrap>
							<ErrorMessage name="deadlineDays" />
							<InputDesc noMargin>※指定日数は14〜700日の範囲で入力してください。</InputDesc>
						</FormIndent>
					}
					<InputRadio name="isCutCheck" value="never" label="無期限" disabled={isFinishedAll} />
				</FormGroup>
				{!disabledReviewForm && <>
					<FormGroup>
						<FormLabel>特典の申込受付期間を入力してください</FormLabel>
						<InputRadio name="shouldCloseApplication" value="true" label={<>
								<FormInputWrap>
									<span>期限を設定する&#12288;レビュー完了メール送付後から</span><Input className="el_inlineInputM" type="text" name="applicationClosingDays" onChange={nonNegative} disabled={disabledReviewForm} /><span> 日間有効</span>
								</FormInputWrap>
							</>} disabled={disabledReviewForm} /><br />
						{shouldCloseApplication &&
							<ErrorMessage name="applicationClosingDays" />
						}
						<InputRadio name="shouldCloseApplication" value="false" label="無期限" disabled={disabledReviewForm} />
					</FormGroup>
				</>}
			</div>
			<div className="bl_panel__wizardFooter">
				<Button className="el_largeBtn el_largeBtn__prev" onClick={onPrev}>戻る</Button>
				<Button className="el_largeBtn" onClick={handleNext} disabled={!canNext}>次へ</Button>
				<Button className="el_largeBtn el_largeBtn__prev el_largeBtn__right" onClick={props.onClose}>キャンセル</Button>
			</div>
		</>
	);
}
export default CampaignWizardS5;
