import * as React from 'react';

interface InputFileProps {
	name: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	uploadButtonName?: string;
}
export const InputFile: React.FC<InputFileProps> = (props) => {
	return (
		<label className="el_btnTxt">
			<input type="file" name={props.name} onChange={props.onChange} />
			{props.uploadButtonName ?? '画像のアップロード'}
		</label>
	);
}
export default InputFile;
