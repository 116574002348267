import * as React from 'react';

interface FormInputWrapProps {
	className?: string;
	children: React.ReactNode;
}
export const FormInputWrap: React.FC<FormInputWrapProps> = (props) => {
	const classList = [`bl_inputFormWrap`, props.className];
	return (
		<div className={classList.filter(Boolean).join(' ')}>
			{props.children}
		</div>
	);
}
export default FormInputWrap;
