import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface InputRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string | React.ReactNode;
	rules?: Record<string, unknown>;
	className?: string;
}
export const InputRadio: React.FC<InputRadioProps> = (props) => {
	const { register } = useFormContext();

	const inputProps = {
		...props,
		ref: props.rules? register(props.rules) : register,
	};
	return (
		<label className="bl_label">
			<input type='radio' {...inputProps} />
			<span>{props.label}</span>
		</label>
	);
}
export default InputRadio;
