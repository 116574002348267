import { MASTER_ENTITY_ID, Notices, yahoo } from '@sasagase/types';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { setParams, shopSetter, shopsSetter, useAPI, useAppState } from '../../../context';

type LoggedInParams = {
	shopId: string;
}
export const useLoggedIn = () => {
	const [state, setState] = useAppState();
	const callAPI = useAPI();
	const param = useParams<LoggedInParams>();
	const location = useLocation();
	const [alert, setAlert] = React.useState<Notices.Content|null>(null);
	const [isOpen, setIsOpen] = React.useState(true);
	const [isOpenMobile, setIsOpenMobile] = React.useState(false);

	React.useEffect(() => {
		return callAPI.notice.getAlerts({ shopId: param.shopId ?? ''}, (err, res) => {
			if (err) {
				return;
			}
			const contents: Notices.Content[] = res.data.map((obj: Record<string, unknown>) => Notices.Content.create(obj));
			setAlert(contents.shift() || null);
		});
	}, [location.pathname]);

	React.useEffect(() => {
		// 例えばpath='/review/:shopId/' の子供の path='/' ならshopIdが取得できるが、
		// 子供の path='/aaa' からは useParamsなどでも取得できないのでcontextに設定する
		setState(setParams({
			shopId: param.shopId,
			basePath: `/review/${param.shopId ?? ''}`,
		}));
	}, [param.shopId]);

	React.useEffect(() => {
		return callAPI.yahoo.getShops({}, (err, result) => {
			if (err) {
				return;
			}
			const shops = Object.fromEntries(Object.entries(result.data).map(([id, obj]) => [id, yahoo.schemaGetShopResponse.parse(obj)]))
			setState(shopsSetter(shops));
		});
	}, [state.user?.capabilities]);

	// 選択中のShopをstate.shopに設定する
	const shop = state.shops?.[param.shopId || ''];
	React.useEffect(() => {
		setState(shopSetter(shop))
	}, [shop]);

	React.useEffect(() => {
		if (!state.user?.hasEnableCaps('admin_login', MASTER_ENTITY_ID)) {
			return;
		}
		const pShopId = param.shopId;
		if (shop || !pShopId) {
			return;
		}
		return callAPI.yahoo.getShop({ shopId: pShopId }, (err, result) => {
			if (err || !result.data) {
				return;
			}
			const shop = yahoo.schemaGetShopResponse.parse(result.data);
			setState(shopsSetter({
				...state.shops,
				[pShopId]: shop,
			}));
			setState(shopSetter(shop));
		});
	}, [shop, param.shopId]);

	React.useEffect(() => {
		const isState = (val: unknown): val is Record<string, unknown> => typeof location.state == 'object' && location.state != null;
		if (isState(location.state) && location.state.newId) {
			return;
		}

		if (!location.hash) {
			window.scrollTo(0, 0);
		}
	}, [location.pathname]);

	const handleClickBurger = () => {
		setIsOpen(v => !v);
		setIsOpenMobile(v => !v);
	};
	const handleSizeClose = () => {
		setIsOpenMobile(false);
	};

	return {
		state,
		param,
		alert,
		isOpen,
		isOpenMobile,
		handleClickBurger,
		handleSizeClose
	};
};