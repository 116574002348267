import * as chartjs from "chart.js";
import * as React from 'react';
import { Bar } from 'react-chartjs-2';

interface Legends {
	prev: string;
	now: string;
}

interface ChartBarProps {
	labels: string[];
	data: number[];
	legend: Legends;
}
export const ChartBar: React.FC<ChartBarProps> = (props) => {
	const chartRef = React.useRef<Bar|null>(null);
	const [legendStyle, setLegendStyle] = React.useState({
		prev: {
			bottom: '',
			left: '',
		},
		now: {
			bottom: '',
			left: '',
		}
	});

	React.useEffect(() => {
		if (chartRef.current) {
			handleResize(chartRef.current.chartInstance);
		}
	}, []);

	const handleResize = (chart: any) => {
		const CHART_PADDING_BOTTOM = 26;
		const LABEL_PADDING_BOTTOM = 16;

		const labelItems = chart.scales["x-axis-0"]._labelItems;
	
		if (labelItems.length < 2) {
			return;
		}
	
		const prevX  = labelItems[0].x;
		const nowX   = labelItems[1].x;
		const chartBottom = chart.boxes[0].bottom;
		const labelY = chartBottom + CHART_PADDING_BOTTOM + LABEL_PADDING_BOTTOM;

		setLegendStyle({
			prev: {
				bottom: labelY + 'px',
				left: prevX + 'px',
			},
			now: {
				bottom: labelY + 'px',
				left: nowX + 'px',
			}
		});
	};

	const chartData = {
		labels: props.labels,
		datasets: [
			{
				data: props.data,
				backgroundColor: ['#3F81CB', '#F3680B'],
				hoverBackgroundColor: ['#3F81CB', '#F3680B'],
				barPercentage: 0.9,
				barThickness: "flex" as const,
				maxBarThickness: 60,
			},
		],
	};

	const chartOptions: chartjs.ChartOptions = {
		// 凡例非表示
		legend: {
			display: false,
		},
		// アニメーション無効化
		animation: {
			duration: 0
		},
		hover: {
			animationDuration: 0,
		},
		responsiveAnimationDuration: 0,
		// デフォルトのツールチップを無効化
		tooltips: {
			enabled: false,
		},
		scales: {
			xAxes: [
				{
					ticks: {
						fontFamily: "'Yu Gothic', 'YuGothic', 'sans-serif'",
						fontStyle: "bold",
						fontSize: 14,
					},
					gridLines: {
						display: false,
					},
				}
			],
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						stepSize: 1,
						maxTicksLimit: 5,
						padding: 8,
						fontFamily: "'Yu Gothic', 'YuGothic', 'sans-serif'",
						fontStyle: "bold",
						fontSize: 14,
						callback: function(value: any) {
							return value + "%";
						}
					}
				}
			]
		},
		onResize: handleResize,
	};

	return (
		<div id="topBarWrap" className="bl_canvasWrap">
			<Bar height={302}
				ref={chartRef}
				data={chartData}
				options={chartOptions}
				/>
				<span className="el_barChartLegend el_barChartLegend__prev" style={legendStyle.prev}>{props.legend.prev}</span>
				<span className="el_barChartLegend el_barChartLegend__now" style={legendStyle.now}>{props.legend.now}</span>
		</div>
	);
}
export default ChartBar;