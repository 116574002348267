import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { ErrorMessage, Form, Input } from '../../organisms/Form';
import { UserListTable } from './UserListTable';
import { useUserList } from './useUserList';

const initValues = {
	email: ''
};
export type FormValues = typeof initValues;

export const UserList: React.FunctionComponent = () => {
	const {
		methods,
		handleInvite,
		tableProps,
	} = useUserList({ initValues });

	return (
		<>
			<PageTitle>ユーザーアカウント設定</PageTitle>
			<PageDesc>ユーザーアカウントの新規作成、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row">
							<Form methods={methods} onSubmit={handleInvite}>
								<h2 className="el_lv3Heading">ユーザーアカウントを新規追加</h2>
								<Input type="text" name="email" placeholder="aaaaaaaa@aaa.co.jp" rules={{ required: '招待するユーザーのメールアドレスを入力してください。\n複数ある場合はカンマ区切りで入力可能です。' }} />
								<ErrorMessage name="email" />
								<Button className="el_btnInv mt_16" type="submit">招待メールを送信</Button>
							</Form>
						</div>
						<FormLabel required>ユーザーアカウント管理</FormLabel>
						<UserListTable
							{...tableProps}
							/>
					</div>
				</div>
			</div>
		</>
	);
}
export default UserList;