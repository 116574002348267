import { CSVFormat } from '@sasagase/types';
import * as React from 'react';
import { isUserCSVField } from '../../../lib/shopCustom';
import { ErrorMessage, Input } from '../../organisms/Form';
import { FormValues } from './useRewardEdit';

interface RewardItemCSVUserSettingEditProps {
	names: Record<keyof FormValues, string>;
	csv: CSVFormat;
}

export const RewardItemCSVUserSettingEdit: React.FunctionComponent<RewardItemCSVUserSettingEditProps> = (props) => {
	const { names, csv } = props;
	const [csvUserOpen, setCsvUserOpen] = React.useState<boolean>(false);

	return (
		<>
			<div className="bl_panel_indent">
				<h4 className={`el_lv4Heading bl_acc_ttl ${csvUserOpen ? 'is_active' : ''}`} onClick={() => setCsvUserOpen(prev => !prev)}>{csv.name}</h4>
				<div className="bl_acc_body">
					{csv.columns.filter(isUserCSVField).map(col =>
						<div key={col.name}>
							<span>{col.name}</span>
							<Input className="el_inlineInput textL" type="text" name={`${names.csvUserSettings}.${csv.id}.${col.name}`} />
							<ErrorMessage name={`${names.csvUserSettings}.${csv.id}.${col.name}`} />
						</div>
					)}
				</div>
			</div>
		</>
	);
};
export default RewardItemCSVUserSettingEdit;