import dayjs from 'dayjs';
import * as React from 'react';
import { ChartBar } from '../../organisms/ChartBar';
import { ChartDoughnut } from '../../organisms/ChartDoughnut';

export interface SummaryData {
	// レビュー獲得件数
	numberOfReviews: {
		yesterday: number,
		thisMonth: number,
	};
	// 申込み件数(記入済み・待ち)
	reviewForm: {
		completed: number,
		waiting: number,
	};
	// レビュー投稿率
	reviewPostingRate: {
		lastMonth: number,
		thisMonth: number,
	};
	// キャンペーン獲得比率
	campaignAcquistionRate: {
		campaigns: {
			campaignId: string,
			campaignName: string,
			number: number,
		}[]
	};
}
interface Summary {
	yesterday: number;
	thisMonth: number;
	numberOfReviews: {
		yesterday: number,
		thisMonth: number,
	};
	reviewForm: {
		completed: number,
		waiting: number,
	};
	reviewPostingRate: {
		m2mBasis?: number,
		chartBar: {
			labels: string[],
			data: number[],
			legend: {
				prev: string,
				now: string,
			}
		},
	};
	campaignAcquistionRate: {
		top3Labels: string[],
		chartDoughnut: {
			labels: string[],
			data: number[],
		},
	};
}

function toSummary(
		summaryData: SummaryData | null,
	): Summary | null {
	if (!summaryData) {
		return null;
	}
	const now = new Date();
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() -1);
	const lastMonth = new Date(now.getFullYear(), now.getMonth() -1, 1);
	const { reviewPostingRate: rpr, campaignAcquistionRate } = summaryData;
	const { campaigns } = campaignAcquistionRate;

	// レビュー投稿率
	const m2mBasisPostingRate = (rpr.lastMonth > 0)
									? Math.round((rpr.thisMonth - rpr.lastMonth) * 100 / rpr.lastMonth) : undefined;

	// キャンペーン獲得比率(キャンペーンごとの受注に対するレビュー数での比率) 
	let sum = 0;
	const carArray = campaigns.map(camp => {
		sum += camp.number;
		return {
			...camp,
			percentage: 0,
		};
	});
	carArray.map(val => {
		val.percentage = Math.round(val.number * 100 / sum);
		return val;
	});

	const top3Reviews = carArray.sort((a,b) => {
		return b.number - a.number;
	}).slice(0, 3);
	const doughnutData = [ ...top3Reviews ];
	if (carArray.length > 3) {
		doughnutData.push({
			campaignId: '',
			campaignName: 'その他',
			number: 0,
			percentage: 100 - top3Reviews.reduce((sum, rev) => sum + rev.percentage, 0),
		});
	}

	return {
		...summaryData,
		yesterday: yesterday.getTime(),
		thisMonth: now.getMonth() + 1,
		reviewPostingRate: {
			m2mBasis: m2mBasisPostingRate,
			chartBar: {
				labels: [(lastMonth.getMonth() + 1) + "月", (now.getMonth() + 1) + "月"],
				data: [rpr.lastMonth, rpr.thisMonth],
				legend: {
					prev: rpr.lastMonth + "%",
					now: rpr.thisMonth + "%",
				}
			},
		},
		campaignAcquistionRate: {
			top3Labels: top3Reviews.map(rev => rev.campaignName),
			chartDoughnut: {
				labels: doughnutData.map(rev => rev.campaignName),
				data: doughnutData.map(rev => rev.percentage),
			},
		}
	};
}

interface ReviewSummaryProps {
	summaryData: SummaryData | null;
}

export function ReviewSummary(props: ReviewSummaryProps): React.ReactElement | null {
	const summary = React.useMemo(() => {
		return toSummary(props.summaryData);
	}, [props.summaryData]);

	const toDateString = (milliepoc: number | undefined): string => {
		if (!milliepoc) {
			return '';
		}
		return dayjs(milliepoc).format('M月DD日');
	};
	const toPlusMinusString = (value: number | string): string => {
		if (typeof value == 'string') {
			return value;
		} else if (value >= 0) {
			return "+" + parseInt(value.toString());
		} else {
			return value.toString();
		}
	};

	if (!summary) {
		return null;
	}

	const { yesterday, thisMonth, numberOfReviews, reviewForm, reviewPostingRate, campaignAcquistionRate } = summary;

	// NOTE: プラスの場合上向き▲、マイナスは逆 is_plus, is_minus
	const plusMinusClass = reviewPostingRate.m2mBasis ?
							reviewPostingRate.m2mBasis >= 0 ? 'is_plus' : 'is_minus' :
							'';

	return (
		<>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop hp_justified">昨日のレビュー獲得件数<span>{toDateString(yesterday)}</span></h2>
						<p className="el_panel_totalNum">{numberOfReviews.yesterday}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">{thisMonth}月間累計レビュー獲得件数</h2>
						<p className="el_panel_totalNum">{numberOfReviews.thisMonth}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">申込内容記入済み件数</h2>
						<p className="el_panel_totalNum">{reviewForm.completed}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">申込内容記入待ち件数</h2>
						<p className="el_panel_totalNum">{reviewForm.waiting}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">{thisMonth}月レビュー投稿率(前月比)</h2>
						<ChartBar {...reviewPostingRate.chartBar} />
						<p className={`el_panel_totalNum hp_justified hp_alignBaseline ${plusMinusClass}`}>
							<span className="fs_18 txt_blue">前月比</span>{toPlusMinusString(reviewPostingRate.m2mBasis ? reviewPostingRate.m2mBasis : '-')}%
						</p>
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">{thisMonth}月キャンペーン獲得比率</h2>
						<ChartDoughnut {...campaignAcquistionRate.chartDoughnut} />
						<ul className="bl_rank">
							{campaignAcquistionRate.top3Labels.map((label, index) =>
								<li key={`campaignAcquistionRate_${index}`} className={`bl_rank_item bl_rank_item__${index+1}`}>{label}</li>
							)}
						</ul>
						{campaignAcquistionRate.top3Labels.length > 0 &&
							<div className="bl_panel_headerFooter">
								<p>※実施中のキャンペーン上位3位まで表示</p>
							</div>
						}
					</div>
				</div>
				
			</div>
		</>
	);
}
export default ReviewSummary;