import { Options } from "csv-parse";
import rakuten from "./rakuten";
import yahoo from "./yahoo";

export type ParseReviewOptions<T> = {
	parseOptions: Options,
	toReview: (record: Record<string, unknown>) => T,
};
export type OptionName = keyof typeof options;
export type ReviewType<N extends OptionName> = typeof options[N] extends ParseReviewOptions<infer R> ? R : never;

export const options = {
	yahoo,
	rakuten,
} satisfies Record<string, ParseReviewOptions<any>>;
export default options;
