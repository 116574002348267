import * as React from 'react';
import { ListSelectorContext } from '../../organisms/DataTable';
import { UserList as UserListBase } from './UserList';

export const UserList: React.FunctionComponent = () => {
	return (
		<>
			<ListSelectorContext>
				<UserListBase />
			</ListSelectorContext>
		</>
	);
}
export default UserList;