import * as React from 'react';

export const Footer: React.FunctionComponent = () => {
	return (
		<footer className="ly_footer">
			{/* <!-- Begin Yahoo! JAPAN Web Services Attribution Snippet --> */}
			<span style={{ margin: `15px 15px 15px 15px` }}><a href="https://developer.yahoo.co.jp/sitemap/">Web Services by Yahoo! JAPAN</a></span>
			{/* <!-- End Yahoo! JAPAN Web Services Attribution Snippet --> */}
		</footer>
	);
};
export default Footer;