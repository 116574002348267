import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { ErrorMessage, Form, FormGroup, Input, InputCheck } from '../../organisms/Form';
import useExclusionMail from './useExclusionMail';

const initValues = {
	email: '',
	deleteEmail: '',
};

export const ExclusionMail: React.FunctionComponent = () => {

	const {
		methods,
		excludeMails,
		handleClickAddMail,
		handleClickDeleteMail,
	} = useExclusionMail({ initValues });

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>メールアドレスから設定</FormLabel>
				<Form methods={methods} disableBlock>
					<FormGroup>
						<FormLabel>除外メールアドレス追加</FormLabel>
						<div className="mb_8">
							<Input type="text" name="email" rules={{
									required: 'メールアドレスを入力してください',
									pattern: {
										value: /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
										message: 'メールアドレスを入力してください'
									}
								}} />
							<ErrorMessage name="email" />
						</div>
						<Button className="el_btnInv" type="button" onClick={handleClickAddMail}>設定</Button>
					</FormGroup>
					<FormGroup>
						<FormLabel>除外メールアドレス一覧</FormLabel>
						<div className="bl_checkListWrap mb_8">
							<ul className="bl_checkList">
								{excludeMails.map((mail, idx) => 
									<li key={idx} className="bl_checkList_item">
										<InputCheck className="el_checkMark" name="deleteEmail" value={mail}>{mail}</InputCheck>
									</li>
								)}
							</ul>
						</div>
					</FormGroup>
					<Button className="el_btnBlueInv" type="button" onClick={handleClickDeleteMail}>削除</Button>
				</Form>
			</div>
		</>
	);
};
export default ExclusionMail;