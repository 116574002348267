import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../atoms/Button';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { CampaignListTable } from './CampaignListTable';
import CampaignWizardModal from './CampaignWizardModal';
import { useCampaignList } from './useCampaignList';

export const CampaignList: React.FunctionComponent = () => {
	const {
		state,
		tableProps,
		handleClickDelete,
	} = useCampaignList();

	const [wizard, setWizard] = React.useState<boolean>(false);

	return (
		<>
			{wizard && <CampaignWizardModal onClose={() => setWizard(false)} />}
			<PageTitle>キャンペーン登録</PageTitle>
			<PageDesc>キャンペーンの新規登録、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<Link className="el_btn el_btn__plus" to={`${state.params.basePath}/reward/campaign/new`}>新規追加</Link>
							{/* <Button className="el_btn el_btn__plus" type="button" onClick={() => setWizard(true)}>簡易ウィザード</Button> */}
							<Button className="el_btnBlueInv ml_16" type="button" onClick={handleClickDelete}>削除</Button>
						</div>
						<CampaignListTable
							{...tableProps}
							state={state}
							/>
					</div>
				</div>
			</div>
		</>
	);
}
export default CampaignList;