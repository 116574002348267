import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { Form, Select } from '../../organisms/Form';
import TargetListTable from './TargetListTable';
import useExclusionOrder from './useExclusionOrder';

const initValues = {
	select_campaign: '',
	orderNumber: '',
};

export const ExclusionOrder: React.FunctionComponent = () => {

	const {
		methods,
		campaigns,
		handleClickChangeCampaign,
		tableProps,
	} = useExclusionOrder({ initValues });

	const { watch } = methods;

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>注文番号から設定</FormLabel>
				<Form methods={methods} disableBlock>
					<div className="bl_panel_row mb_24">
						<FormLabel>実施中のキャンペーンから選択</FormLabel>
						<div className="el_selectWrap mb_8">
							{campaigns && <>
								<Select name="select_campaign"
									options={Object.fromEntries([['', 'キャンペーンを選択してください']].concat(campaigns.map(t => [t.id, t.name])))}
									/>
							</>}
						</div>
						<Button className="el_btnInv" type="button" onClick={handleClickChangeCampaign} disabled={watch('select_campaign') === ''}>表示</Button>
					</div>
					<TargetListTable
						{...tableProps}
						/>
				</Form>
			</div>
		</>
	);
};
export default ExclusionOrder;