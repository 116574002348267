import { APIResponses } from '@sasagase/api-caller';
import dayjs from 'dayjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAPIDataTableLoad, useAPILoad, useAppState } from '../../../context';
import { useYahoo } from '../../../hooks';
import { isRetransactableCSVFormat } from '../../../lib/shopCustom';
import { useListSelector } from '../../organisms/DataTable';
import { filterInitValue } from './RequestListTable';
import { RequestTransactModalCloseEvent } from './RequestTransactModal';
import { saveCSVFile, saveZIPFiles } from './saveCSVFile';


export const useRequestList = () => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [detailId, setDetailId] = React.useState('');
	const [isShowTransact, setIsShowTransact] = React.useState(false);
	const methodFilter = useForm({
		defaultValues: filterInitValue,
	});
	const { getValues, register } = methodFilter;

	const [rewardMap] = useAPILoad<APIResponses.GetRewardsResponseValues[], Map<string, APIResponses.GetRewardsResponseValues>, any>(
		callAPI.yReview.getRewards,
		{
			initValue: new Map(),
			apiParams: { shopId: state.params.shopId },
			onFetch: (data) => {
				return new Map(data.map(reward => [reward.id, reward]));
			},
		}
	);
	const [rows, { filter, reload, loadMore, pageInfo, requestedParams, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getRewardRequests,
		{
			initValue: [],
			apiParams: { shopId: state.params.shopId, pagination: "1" },
			perPage: 100,
			onFetch: (data) => {
				return [data.data, data.pageInfo];
			},
		}
	);

	const {
		getChecks,
		reset,
		refresh,
		setRecords,
		checkedNum,
		isCheckedExceptRecords,
	} = useListSelector();

	React.useEffect(() => {
		refresh();
		if (rows && pageInfo && rewardMap) {
			// ダウンロード不可のデータは対象外とする
			const transactableRows = rows.filter(row => {
				return isTransactable(row);
			});
			setRecords(transactableRows, pageInfo);
		}
	}, [rows]);

	const handleFilter = () => {
		const {
			filterOrderNumber,
			filterRewardId,
			filterRewardName,
			filterDl,
			filterTransactDateFrom,
			filterTransactDateTo
		} = getValues();
		const filterParams: Record<string, unknown> = {};
		if (filterOrderNumber) filterParams['orderNumber'] = filterOrderNumber;
		if (filterRewardId) filterParams['rewardId'] = filterRewardId;
		if (filterRewardName) filterParams['rewardName'] = filterRewardName;
		if (filterDl) filterParams['isTransacted'] = filterDl === '1';
		if (filterTransactDateFrom) filterParams['transactDateFrom'] = dayjs(filterTransactDateFrom).valueOf();
		if (filterTransactDateTo) filterParams['transactDateTo'] = dayjs(filterTransactDateTo).valueOf();
		filter(filterParams, []);
	};

	const handleClickDetail = (requestId: string) => () => {
		setDetailId(requestId);
	};
	const handleDetailClose = () => {
		setDetailId('');
	};
	const handleClickTransact = async () => {
		if (!rows || !rewardMap) {
			return;
		}
		const checks = getChecks();
		if (Object.values(checks).every(checked => !checked)) {
			alert("対象が選択されておりません");
			return;
		}
		setIsShowTransact(true);
	};

	const handleCloseTransactModal = async (ev: RequestTransactModalCloseEvent) => {
		setIsShowTransact(false);
		if (ev.isCancel) {
			return;
		}

		const checks = getChecks();
		const requestIds = rows?.filter((req) => checks[req.id]).map(req => req.id) ?? [];

		let result;
		try {
			const param = {
				shopId: state.params.shopId,
				requestIds,
				pageInfo,
				requestedParams,
				isRequestAfterPage: isCheckedExceptRecords && pageInfo?.hasNextPage,
				csvFormat: ev.csvFormat,
				isFormatGroup: String(ev.isFormatGroup),
			};
			result = await callAPI.yReview.putRewardRequestTransact(param);
		} catch (err) {
			alert(`特典の受付処理に失敗しました`);
			return;
		}
		if (result && Object.keys(result.data).length > 0) {
			const csvZipFiles = [];
			const csvFiles = result.data.csv;
			const sasagase = result.data.sasagase;
			if (csvFiles) {
				for (const csv of csvFiles) {
					if (ev.isZipFile) {
						csvZipFiles.push(csv);
					} else {
						await saveCSVFile(csv);
					}
				}
			}
			if (sasagase) {
				alert(`${sasagase.orderIds.length} 件の特典をSasagaseに追加しました`);
			}
			if (csvZipFiles.length > 0) {
				const date = new Date(result.headers.date);
				await saveZIPFiles(csvZipFiles, date);
			}
		} else {
			alert('受付処理の対象が存在しませんでした');
		}
		reset();
		reload([]);
	};

	const {
		toOrderDetailURL,
	} = useYahoo(state.shop);

	// ダウンロード可能か(特典の送り先がsasagaseで登録済みであれば不可)
	const isTransactable = (rowData: APIResponses.GetRewardRequestsResponseValues) => {
		const reward = rewardMap && rewardMap.size > 0 && rewardMap.get(rowData.selectedRewardId);
		if (reward) {
			return !reward.isCoupon && (isRetransactableCSVFormat(reward.csvFormat) || !rowData.isTransacted);
		}
		return false;
	};

	return {
		tableProps: {
			rows,
			pageInfo,
			isLoading,
			methodFilter,
			loadMore,
			rewardMap,
			handleClickDetail,
			toOrderDetailURL,
			isTransactable
		},
		register,
		handleFilter,
		detailId,
		handleDetailClose,
		isShowTransact,
		checkedNum,
		handleClickTransact,
		handleCloseTransactModal
	};
};
export default useRequestList;
