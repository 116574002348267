import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { define, dynamic, is, type } from 'superstruct';
import { useAppState } from "../../../context";
import { Button } from '../../atoms/Button';
import { initValues } from '../RewardEdit/RewardEdit';
import { FormValues as RewardEditFormValues, RewardEditStruct, useRewardEdit } from '../RewardEdit/useRewardEdit';
import CampaignWizardS2AddReward from './CampaignWizardS2AddReward';
import CampaignWizardS2SelectReward, { CampaignWizardS2SelectRewardStruct } from './CampaignWizardS2SelectReward';
import { contextWizard } from './useCampaignWizard';

export const campaignWizardS2InitValues: CampaignWizardS2FormValue = {
	rewardIds: [],
	addReward: undefined,
};

export interface CampaignWizardS2FormValue {
	rewardIds: string[];
	addReward?: RewardEditFormValues;
}

export const CampaignWizardS2Struct = dynamic((values: any) => {
	const ids = (Array.isArray(values.rewardIds) ? values.rewardIds : [values.rewardIds]).filter(Boolean);
	const rewardIds = ids.filter((id: string) => !['item', 'coupon'].includes(id));

	return type({
		rewardIds: define<string[]>('rewardIds', () => {
			return (rewardIds.length > (values.rewardType === 'choice' ? 1 : 0)) || '特典を選択してください';
		}),
	});
});

interface CampaignWizardS2Props {
	onPrev: () => void;
	onClose: () => void;
}

export const CampaignWizardS2: React.FC<CampaignWizardS2Props> = (props) => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const { onPrev } = props;
	const { watch, getValues } = useFormContext();
	const [addReward, setAddReward] = React.useState<string>('');
	const [state] = useAppState();
	const shopId = state.params.shopId;

	const stepType = ['item', 'coupon'].includes(addReward) ? 'addReward' : 'selectReward';
	const rewardType = addReward || 'item';

	const toName = (name: string) => `addReward.${name}`;

	const {
		names,
		handleSaveNonNavigate,
		messages,
		presentProps,
		couponProps,
	} = useRewardEdit({ initValues, toName });

	const canNext = React.useMemo(() => {
		if (stepType === 'selectReward') {
			return is(getValues(), CampaignWizardS2SelectRewardStruct);
		} else {
			const values = getValues();
			return is((values.addReward || {}), RewardEditStruct(shopId));
		}
	}, [watch('rewardIds'), watch('addReward'), stepType]);

	const handleNext = async () => {
		const values = getValues();
		if (stepType === 'selectReward') {
			const rewardIds = (Array.isArray(values.rewardIds) ? values.rewardIds : [values.rewardIds]).filter(Boolean);
			ctx.setWizardValues(prev => ({
				...prev,
				rewardIds: rewardIds.filter((id: string) => !['item', 'coupon'].includes(id))
			}));
			if (is(values, CampaignWizardS2Struct) && !['item', 'coupon'].includes(addReward)) {
				ctx.setStepIdx(idx => idx + 1);
			}
		} else if (stepType === 'addReward') {
			if (is(values.addReward, RewardEditStruct(shopId))) {
				const res = await handleSaveNonNavigate(values.addReward);
				if (res) {
					setAddReward('');
				}
			}
		}
	};

	const handlePrev = () => {
		if (stepType === 'selectReward') {
			onPrev && onPrev();
		} else {
			setAddReward('');
		}
	};

	const handleAddReward = (rewardType: string) => {
		setAddReward(rewardType);
	};

	return (
		<>
			<div className="bl_panel__wizardMain">
				{stepType === 'selectReward' &&
					<CampaignWizardS2SelectReward setAddReward={handleAddReward} />
				}

				{stepType === 'addReward' &&
					<CampaignWizardS2AddReward
						names={names}
						rewardType={rewardType}
						messages={messages}
						presentProps={presentProps}
						couponProps={couponProps}
						/>
				}
			</div>
			<div className="bl_panel__wizardFooter">
				<Button className="el_largeBtn el_largeBtn__prev" onClick={handlePrev}>戻る</Button>
				<Button className="el_largeBtn" onClick={handleNext} disabled={!canNext}>次へ</Button>
				<Button className="el_largeBtn el_largeBtn__prev el_largeBtn__right" onClick={props.onClose}>キャンセル</Button>
			</div>
		</>
	);
}
export default CampaignWizardS2;
