import * as React from 'react';
import { useFormContext } from 'react-hook-form';

export type DaysField = number | string;

export const toDaysField = (date: number | undefined ): number | ""  => {
	return date || "";
}

export const changeValidValue = (ev: any, max: number, min: number) => {
	const i = parseInt(ev.target.value, 10);
	const _changeValidValue = (num: number): number => {
		if (num < min) {
			return min;
		} else if (num > max) {
			return max;
		} else {
			return num;
		}
	}

	ev.target.value = isNaN(i) ? "" : _changeValidValue(i);
};

interface InputDaysProps {
	name: string;
	minDate: number;
	maxDate: number;
	rules?: Record<string, unknown>;
	disabled?: boolean;
}
export const InputDays: React.FC<InputDaysProps> = (props) => {
	const { register, setValue, clearErrors } = useFormContext();
	const handleClickClear: React.MouseEventHandler = () => {
		clearErrors(props.name);
		setValue(props.name, '');
	};

	return (
		<>
			<input className="el_inlineInputM"
				type="text"
				name={props.name}
				ref={props.rules ? register(props.rules) : register}
				onChange={(ev) => changeValidValue(ev, props.maxDate, props.minDate)}
				max={props.maxDate}
				disabled={props.disabled}
				/><span> 日まで</span>
			<button className="el_btnTxt" type="button" onClick={handleClickClear}>クリア</button>
		</>
	);
}
export default InputDays;
