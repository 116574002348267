import * as React from 'react';

interface ButtonProps {
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	disabled?: boolean;
	children: React.ReactNode;
}
export const Button: React.FC<ButtonProps> = (props) => {
	return <button
		className={props.className}
		type={props.type ?? 'button'}
		onClick={props.onClick}
		disabled={props.disabled}
	>{props.children}</button>;
}
export default Button;
