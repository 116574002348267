import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAPI } from "../../../context";
import { useTimer } from "../../../lib";
import { FormValues } from "./TwoFactorAuthEdit";

interface TwoFactorAuthEditProps {
	initValues: FormValues;
}


export const useTwoFactorAuthEdit = (props: TwoFactorAuthEditProps) => {
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
		shouldUnregister: false,
	});
	const { reset } = formMethods;

	const callAPI = useAPI();
	const [result, setResult] = React.useState('');
	const [isTwoFactorAuth, setIsTwoFactorAuth] = React.useState<null | boolean>(null);
	const [qrCodeUrl, setQrCodeUrl] = React.useState('');
	const [secretKey, setSecretKey] = React.useState('');
	const setTimer = useTimer();

	React.useEffect(() => {
		return callAPI.user.getTwoFactorAuth(null, (_err, result) => {
			setIsTwoFactorAuth(result.data);
			if (!result.data) {
				void getQrCode();
			}
		});
	}, [isTwoFactorAuth]);

	const handleClickBack = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.go(-1);
	};

	const handleClickDisabledSave: SubmitHandler<FormValues> = async (values) => {
		try {
			const result = await callAPI.user.putDisableTwoFactorAuth({ pass: values.password });
			if (result?.data) {
				setResult('');
				setTimer(() => setResult(''), 6000);
				setIsTwoFactorAuth(false);
				reset(values);
				return true;
			} else {
				setResult('パスワードが正しくありません');
				return false;
			}
		} catch (err) {
			return false;
		}
	};

	const handleClickSave: SubmitHandler<FormValues> = async (values) => {
		try {
			const result = await callAPI.user.putTwoFactorAuth({ code: values.code });
			if (result?.data) {
				setResult('');
				setTimer(() => setResult(''), 6000);
				setIsTwoFactorAuth(true);
				reset(values);
				return true;
			} else {
				setResult('認証コードが正しくありません');
				return false;
			}
		} catch (err) {
			return false;
		}
	};

	const getQrCode = async () => {
		await callAPI.user.getTwoFactorAuthCode(null, (_err, result) => {
			if (result.data) {
				setQrCodeUrl(result.data.qrCodeUrl);
				setSecretKey(result.data.secretKey);
			}
		});
	}

	return {
		methods: formMethods,
		handleClickDisabledSave,
		handleClickSave,
		handleClickBack,
		isTwoFactorAuth,
		qrCodeUrl,
		secretKey,
		result,
	};

}
export default useTwoFactorAuthEdit;