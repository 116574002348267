import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, SubmitButton } from '../../organisms/Form';
import { FormIsDirtys } from './Management';
import useManagement from './useManagement';

const initValues = {
	name: '',
	mail: '',
};
export type FormValues = typeof initValues;

interface UserEditProps {
	setFormIsDirtys: React.Dispatch<React.SetStateAction<FormIsDirtys>>;
}

export const UserEdit: React.FunctionComponent<UserEditProps> = (props) => {
	const {
		methods,
		handleUserSave,
	} = useManagement();

	const { formState: { isDirty } } = methods;
	React.useEffect(() => {
		props.setFormIsDirtys(prev => ({ ...prev, userEdit: isDirty }));
	}, [isDirty]);

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>ユーザー情報</FormLabel>
				<Form methods={methods} onSubmit={handleUserSave} disableBlock>
					<RequiredDesc />
					<FormGroup className="bl_panel_row__indent">
						<FormGroup>
							<FormLabel required>ユーザー名</FormLabel>
							<Input type="text" name="name" rules={{ required: 'ユーザー名を入力してください' }} />
							<ErrorMessage name="name" />
						</FormGroup>
						<FormGroup>
							<FormLabel required>メールアドレス</FormLabel>
							<Input type="text" name="mail" rules={{
									required: 'メールアドレスを入力してください',
									pattern: {
										value: /\S+@\S+.\S+/,
										message: 'メールアドレスを入力してください'
									}
								}} />
							<ErrorMessage name="mail" />
						</FormGroup>
						<div className="el_largeBtnWrap ">
							<SubmitButton />
						</div>
					</FormGroup>
				</Form>
			</div>
		</>
	);
};
export default UserEdit;