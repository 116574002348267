import { css } from "@emotion/react";
import { PageInfo } from '@sasagase/types';
import * as React from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { UseFormMethods } from 'react-hook-form';
import { TableVirtuoso } from 'react-virtuoso';
import { ColumnProps } from './Column';
import { DataTableBody } from './DataTableBody';
import { DataTableBodyTd } from './DataTableBodyTd';

export interface ReorderResult {
	sourceIdx: number;
	destinationIdx: number;
}

export interface DataTableProps<T> {
	/** テーブル見出し・カラム情報 */
	children?: React.ReactNode;
	/** テーブル全体用Style */
	className?: string;
	/** 一覧表示用データ */
	rows: T | undefined;
	/** 一覧表示用データの主キー */
	dataKey: string;
	/** ページング情報 */
	pageInfo?: PageInfo;
	/** 読み込み中フラグ */
	isLoading: boolean;
	/** フィルター実行関数 */
	handleFilter?: () => void;
	/** フィルター値管理用Methods（useFormの戻り値） */
	methodFilter?: UseFormMethods<any>;
	/** テーブル下のページ情報表示用関数 */
	currentPageReportTemplate?: (pageInfo: PageInfo) => React.ReactNode;
	/** ページング方法（infiniteScroll:無限スクロール / pagination:ページネーション */
	paginationType?: 'infiniteScroll' | 'pagination';
	/** 無限スクロールでのページングの際のデータの続き取得用関数 */
	loadMore?: () => void;
	/** 並び替え可否フラグ ※全件表示 or ページネーションでの表示のみ利用可 */
	reorderable?: boolean;
	/** 並び替え後呼び出し関数 */
	handleReorder?: (result: ReorderResult) => void;
}

interface ColumnAdditionalProps {
	/** フィルター実行関数 */
	handleFilter?: () => void;
	/** フィルター値管理用Methods（useFormの戻り値） */
	methodFilter?: UseFormMethods;
	/** フィルター用フォーム表示中Column(field) */
	filteringColumn: string;
	/** フィルター用フォーム表示切り替え関数 */
	setFilteringColumn: (field: string | ((prev: string) => string)) => void;
}

export const DataTable = <T,>(props: DataTableProps<T>) => {
	const [filteringColumn, setFilteringColumn] = React.useState<string>('');

	const tableColumnStyle = React.useCallback((columns: ColumnProps[]) => {
		return columns.map((c, idx) => {
			const i = idx + 1;
			const [grow, shrink, width, headAlign, bodyAlign] = c.style.split(' ');
			return css`
				th:nth-of-type(${i}),
				td:nth-of-type(${i}) {
					flex: ${grow} ${shrink} ${width};
					${parseInt(shrink, 10) > 0 && `min-width: calc(${width} / 3);`}
				}
				th:nth-of-type(${i}) {
					text-align: ${headAlign};
				}
				td:nth-of-type(${i}) {
					justify-content:  ${bodyAlign};
				}
				${i == 1 && `
					td:nth-of-type(${i}).loading {
						flex: ${grow} ${shrink} 100%;
					}
				`}
			`;
		});
	}, []);

	const getColumns = (additionalProps: ColumnAdditionalProps) => {
		const columns = React.Children.toArray(props.children)
			.map(child => React.cloneElement(child as React.ReactElement, additionalProps))
			.filter(child => React.isValidElement<ColumnProps>(child));
		return columns;
	};
	const getColumnProps = (columns: React.ReactNode[]): ColumnProps[] => {
		const props = columns.map(c => React.isValidElement<ColumnProps>(c) ? c.props : undefined);
		return props.filter((p): p is ColumnProps => Boolean(p));
	}

	const handleDragEnd: OnDragEndResponder = (result) => {
		if (!result.destination) {
			return;
		} else if (props.handleReorder) {
			props.handleReorder({
				sourceIdx: result.source.index,
				destinationIdx: result.destination.index
			})
		}
	}

	const isVirtual = Boolean(props.paginationType === 'infiniteScroll' && props.loadMore);
	const columnAdditionalProps: ColumnAdditionalProps = {
		methodFilter: props.methodFilter,
		handleFilter: props.handleFilter,
		filteringColumn,
		setFilteringColumn
	};
	const columns = getColumns(columnAdditionalProps);
	const columnProps = getColumnProps(columns);

	return (
		<>
			{isVirtual &&
				<TableVirtuoso
					className={`bl_virtual_table ${props.className}`} css={tableColumnStyle(columnProps)}
					style={{ height: '' /* 指定しないとTableVirtuosoのデフォルト値が設定されるので、空文字を指定してCSSで設定している height を使わせる */ }}
					data={Array.isArray(props.rows) ? props.rows : [props.rows]}
					fixedHeaderContent={() => <tr>{columns}</tr>}
					computeItemKey={(idx, row) => `${idx }__${row[props.dataKey]}`}
					itemContent={(idx, row) => (
						<DataTableBodyTd
							columnProps={columnProps}
							row={row}
							idx={idx}
							dataKey={props.dataKey}
							isLoading={props.isLoading}
							/>
					)}
					totalCount={props.pageInfo ? props.pageInfo.recordsTotal : 0}
					endReached={() => props.loadMore ? props.loadMore() : undefined}
					increaseViewportBy={{bottom: 100, top: 0}}
					overscan={{main: 100, reverse: 0}}
					/>
			}
			{!isVirtual &&
				<table className={`bl_table ${props.className}`} css={tableColumnStyle(columnProps)}>
					<thead className="bl_table_head">
						<tr>
							{columns}
						</tr>
					</thead>
					<DataTableBody<T>
						columnProps={columnProps}
						rows={props.rows}
						dataKey={props.dataKey}
						isLoading={props.isLoading}
						reorderable={props.reorderable}
						handleDragEnd={handleDragEnd}
						/>
				</table>
			}
			{(props.currentPageReportTemplate && props.pageInfo) &&
				props.currentPageReportTemplate(props.pageInfo)
			}
		</>
	);
};