import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ERROR_CLASSNAME } from './const';

export interface InputPasswordProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	rules?: Record<string, unknown>;
}

export const InputPassword: React.FC<InputPasswordProps> = (props) => {
	const { register, errors } = useFormContext();
	const [pointerId, setPointerId] = React.useState<number | null>(null);
	const isActive = pointerId !== null;

	const classNames = [
		props.className,
		errors[props.name ?? ''] ? ERROR_CLASSNAME : undefined
	];

	const inputProps = {
		...props,
		ref: props.rules? register(props.rules) : register,
		className: classNames.filter(Boolean).join(' '),
	};

	const handlePointerDown = (ev: React.PointerEvent) => {
		if (ev.button === 0) {
			setPointerId(ev.pointerId);
		}
	};
	const handlePointerUp = (ev: React.PointerEvent) => {
		if (ev.pointerId === pointerId) {
			setPointerId(null);
		}
	};
	const handleContextMenu = (ev: React.MouseEvent) => {
		if (ev.nativeEvent instanceof PointerEvent && ev.nativeEvent.pointerType === 'touch') {
			// タッチパネル長押しでメニュー表示されがちなので、それだけキャンセルする
			ev.preventDefault();
		}
	};
	return (
		<div className="bl_password">
			<input type={isActive ? 'text' : 'password'} {...inputProps} />
			<span className={`el_showPassword ${isActive ? 'active' : ''}`}
				onPointerDown={handlePointerDown}
				onPointerUp={handlePointerUp}
				onPointerCancel={handlePointerUp}
				onPointerLeave={handlePointerUp}
				onContextMenu={handleContextMenu}
			></span>
		</div>
	);
}
export default InputPassword;