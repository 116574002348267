import { MailContent, YahooCampaign, bodyElementType } from '@sasagase/types';
import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useAPI, useAppState } from '../../../context';

export interface MailEditRowFormValues {
	body: string;
	bodyHeader: string;
	bodyElement: {
		type: string;
		content: string;
	}[];
	signature: string;
	email: string;
}
const nameList: ReadonlyArray<keyof MailEditRowFormValues> = ['body', 'bodyHeader', 'bodyElement', 'signature', 'email'] as const;

const defaultToName = (name: string) => name;

const bodyElementInitValues = {
	type: 'free_text',
	content: '',
};
interface useMailEditRowProps {
	toName?: (name: string) => string;
	applyValues?: Partial<MailEditRowFormValues>;
	disabled?: boolean;
	campaign?: YahooCampaign;
	isBodyOnlyTextarea?: boolean;
}

export const useMailEditRow = (props: useMailEditRowProps) => {
	const [state] = useAppState();
	const enableBodyElementType = bodyElementType.filter(type => !['campaign_url'].includes(type));
	const bodyElementTypeSelect = enableBodyElementType;
	const toName = props.toName || defaultToName;
	const names: Record<typeof nameList[number], string> = React.useMemo(() => {
		const ret: Record<string, string> = {};
		for (const name of nameList) {
			ret[name] = toName(name);
		}
		return ret;
	}, [toName]);

	const { getValues, control, watch, setValue, trigger } = useFormContext();
	const { fields, append, move, remove } = useFieldArray({
		control,
		name: names.bodyElement,
	});
	const callAPI = useAPI();
	const [previewValues, setPreviewValues] = React.useState<MailContent | null>(null);
	const [sendComplete, setSendComplete] = React.useState<boolean>(false);
	const [isClick, setIsClick] = React.useState(false);

	React.useEffect(() => {
		if (!props.applyValues) {
			return;
		}

		setValue(names.bodyHeader, props.applyValues.bodyHeader);
		// useFieldArray内のformの値に直接setValueすると以降でappendした値と並び替えができない
		remove();
		if (props.applyValues.bodyElement) {
			for (const v of props.applyValues.bodyElement) {
				append(v);
			}
		}
		setValue(names.signature, props.applyValues.signature);
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	}, [props.applyValues]);

	const handleClickAddBodyElement = () => {
		if (props.disabled) {
			return;
		}
		append(bodyElementInitValues);
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	};

	const handleClickDeleteBodyElement = (idx: number) => {
		remove(idx);
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	};

	const handleOnDragEnd = (result: any) => {
		if (result.destination) {
			if (result.source.index !== result.destination.index) {
				move(result.source.index, result.destination.index);
			}
		}
	};

	const getFormValue = () => {
		let bodyElementValue = [];
		let subjectValue = undefined;
		if (names.bodyElement.indexOf('.') != -1) {
			const [parent, child] = names.bodyElement.split('.');
			const childValues = getValues()[parent];
			bodyElementValue = childValues[child];
			subjectValue = childValues.subject;
		} else {
			bodyElementValue = getValues()[names.bodyElement];
		}
		return {
			subject: subjectValue,	// キャンペーン登録・編集時のフォームのみ参照可能
			body: getValues(names.body),
			bodyHeader: props.isBodyOnlyTextarea ? undefined : getValues(names.bodyHeader),
			bodyElement: props.isBodyOnlyTextarea ? undefined : (bodyElementValue || []),
			signature: getValues(names.signature),
			to: getValues(names.email),
			campaign: props.campaign
		};
	};

	const handleClickOpenPreview = async () => {
		const formValues = getFormValue();
		const res = await callAPI.user.getReviewMail({
				shopId: state.params.shopId,
				styleType: 'preview',
				opt: formValues
			});
		if (res && res.data) {
			const mailContent = new MailContent(res.data);
			setPreviewValues(mailContent);
		}
	};

	const handleClickClosePreview = () => {
		setPreviewValues(null);
	};

	const handleChangeType = (ev: React.ChangeEvent<HTMLSelectElement>) => {
		const contentKey = ev.target.name.replace('type', 'content');
		if (ev.target.value !== 'free_text') {
			setValue(contentKey, '');
		}
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	}

	const handleClickSendTest = async () => {
		if (props.disabled) {
			return;
		}
		const formValues = getFormValue();
		const to = formValues.to;
		const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
		if (!reg.test(to)) {
			alert(`メールアドレスを入力してください`);
		}

		setIsClick(true);
		const res = await callAPI.user.getReviewMail({
				shopId: state.params.shopId,
				styleType: 'testMail',
				opt: formValues
			});
		if (res && res.data) {
			const mailContent = MailContent.create({
				...res.data
			});
			const param = {
				shopId: state.params.shopId,
				mailContent
			};
			await callAPI.user.sendReviewMail(param, (err, result) => {
				if (err) {
					return;
				}
				if (result.data) {
					setSendComplete(true);
				}
			});
		}
	};

	const sendTestButtonString = sendComplete ? '送信完了' : 'テスト送信';
	const toBodyElements = fields.length ? watch(names.bodyElement, []) : [];

	return {
		bodyElementTypeSelect,
		names,
		fields,
		previewValues,
		isClick,
		handleClickAddBodyElement,
		handleClickDeleteBodyElement,
		handleOnDragEnd,
		handleClickOpenPreview,
		handleClickClosePreview,
		handleChangeType,
		handleClickSendTest,
		sendTestButtonString,
		toBodyElements
	};
};
export default useMailEditRow;