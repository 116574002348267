import { YahooReward } from '@sasagase/types';
import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputInlineDesc } from '../../molecules/InputInlineDesc';
import { FormGroup, InputRadio } from '../../organisms/Form';

interface CampaignWizardS2SelectSingleProps {
	rewards: YahooReward[];
	rewardType: 'item' | 'coupon';
	setAddReward: (rewardType: string) => void;
}
export const CampaignWizardS2SelectSingle: React.FC<CampaignWizardS2SelectSingleProps> = (props) => {
	const rewardName = props.rewardType === 'item' ? 'プレゼント品' : 'クーポン';

	const handleAddReward = (rewardType: string) => {
		props.setAddReward(rewardType);
	};

	return (
		<>
			<FormGroup>
				<FormLabel>レビュー特典にする{rewardName}を選択してください。</FormLabel>
				{props.rewards.map(reward =>
					<React.Fragment key={reward.id}><InputRadio name="rewardIds" value={reward.id} label={reward.name} /><br /></React.Fragment>
				)}
				<p className='mt_8'>
					<InputInlineDesc>※新しい{rewardName}を追加する場合はこちらから</InputInlineDesc><br />
					<Button className='el_btnTxt' onClick={() => handleAddReward(props.rewardType)}>⇒新しい{rewardName}を追加する</Button>
				</p>
			</FormGroup>
		</>
	);
}
export default CampaignWizardS2SelectSingle;
