import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { FileSelector } from '../../organisms/FileSelector';

interface ReviewUploadProps {
	handleSelectFiles: (files: FileList) => Promise<void>;
}

export const ReviewUpload: React.FunctionComponent<ReviewUploadProps> = (props) => {

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>レビューCSV アップロード（手動）</FormLabel>
				<FileSelector accept='.csv' onSelectFiles={props.handleSelectFiles}>
					<div className="el_fileDrop">
						<p>CSVのアップロードはこちらへ<br className="show_mobile" />ファイルをドロップ</p>
					</div>
				</FileSelector>
			</div>
		</>
	);
};
export default ReviewUpload;