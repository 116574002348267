
import { APIResponses } from '@sasagase/api-caller';
import { ItemGroup, getPageInfoAll } from '@sasagase/types';
import * as React from 'react';
import { useAPI, useAPIDataTableLoad, useAPILoad, useAppState } from '../../../context';
import { useListSelector } from '../../organisms/DataTable';

interface ExecutingSort {
	first: ItemGroup[],
	end: ItemGroup[],
}
export interface CampaignProgresses {
	ready: Set<string>,
	inProgress: Set<string>,
}

export const useGroupList = () => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const [groups, setGroups] = React.useState<undefined|ItemGroup[]>(undefined);

	const [campaignMap] = useAPILoad<APIResponses.GetCampaignListResponse, Map<string, CampaignProgresses>, any>(
		callAPI.yReview.getCampaignList,
		{
			initValue: new Map(),
			apiParams: { shopId },
			onFetch: (data) => {
				const map = new Map<string, CampaignProgresses>();
				data.forEach(campaign => {
					const state = campaign.state;
					const name = campaign.name;

					for (const group of campaign.itemGroups) {
						let campaignProgresses = map.get(group.id);
						if (!campaignProgresses) {
							campaignProgresses = { ready: new Set<string>(), inProgress: new Set<string>() };
							map.set(group.id, campaignProgresses)
						}
						if (state === "ready") {
							campaignProgresses.ready.add(name);
						} else if (state === "inProgress") {
							campaignProgresses.inProgress.add(name);
						}
					}
				});
				return map;
			},
		}
	);

	const [rows, { filter, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getGroups,
		{
			initValue: [],
			apiParams: { shopId },
			onFetch: (data) => {
				return [data, undefined];
			},
		}
	);

	const {
		getChecks,
		reset,
		refresh,
	} = useListSelector();

	React.useEffect(() => {
		if (!rows || !campaignMap) {
			return;
		}

		const sortObj: ExecutingSort = { first: [], end: [] };
		for (const obj of rows) {
			const group = ItemGroup.create(obj);
			if (campaignMap.has(group.id)) {
				sortObj.first.push(group);
			} else {
				sortObj.end.push(group);
			}
		}
		const sortedGroups = [...sortObj.first, ...sortObj.end];
		setGroups(sortedGroups);
		refresh();
	}, [rows, campaignMap]);

	const handleClickDelete = async () => {
		if (!groups) {
			return;
		}
		let seq;
		const checks = getChecks();
		const checkIds = Object.entries(checks).filter(([, check]) => check).map(([id,]) => id);
		const names = groups.filter(group => checkIds.includes(group.id)).map(group => group.name);
		if (names.length <= 0) {
			alert(`削除する商品グループを選択してください。`);
			return;
		}

		if (window.confirm(`以下の商品グループを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			for (const [id, check] of Object.entries(checks)) {
				if (!check) {
					continue;
				}
				const param = { shopId, groupId: id };
				try {
					const res = await callAPI.yReview.deleteGroup(param);
					seq = res?.data.seq;
				} catch (err: unknown) {
					const group = groups.find((group) => group.id === id);
					alert(`商品グループ ${group?.name} の削除に失敗しました。この商品グループは使用中です。`);
				}
			}
			reset();
			setGroups(undefined);
			filter({ seq }, []);
		}
	};

	const toEditURL = (id: string): string => {
		return state.params.basePath + '/reward/group/' + id;
	};
	const toCopyURL = (id: string): string => {
		return state.params.basePath + '/reward/group/' + id + '/copy';
	};

	return {
		tableProps: {
			rows: groups,
			pageInfo: getPageInfoAll(groups?.length || 0),
			isLoading,
		},
		handleClickDelete,
		campaignMap,
		toEditURL,
		toCopyURL,
	};
};
export default useGroupList;
