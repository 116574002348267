import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { FormResult } from '../../molecules/FormResult';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, InputPassword, SubmitButton } from '../../organisms/Form';
import { FormIsDirtys } from './Management';
import useManagement from './useManagement';

const initValues = {
	oldPass: '',
	newPass: '',
	newPassConfirm: '',
};
export type FormValues = typeof initValues;

interface PasswordEditProps {
	setFormIsDirtys: React.Dispatch<React.SetStateAction<FormIsDirtys>>;
}

export const PasswordEdit: React.FunctionComponent<PasswordEditProps> = (props) => {
	const {
		state,
		methods,
		handlePasswordSave,
		resultPasswordSave,
	} = useManagement(initValues);

	const { getValues, formState: { isDirty } } = methods;
	React.useEffect(() => {
		props.setFormIsDirtys(prev => ({ ...prev, passwordEdit: isDirty }));
	}, [isDirty]);

	return (
		<>
			<div className="bl_panel bl_panel__ttlInv">
				<FormLabel headline>パスワード設定</FormLabel>
				<Form methods={methods} onSubmit={handlePasswordSave} disableBlock>
					<RequiredDesc />
					<FormResult>{resultPasswordSave}</FormResult>
					<FormGroup className="bl_panel_row__indent">
						<FormGroup>
							<FormLabel required>現在のパスワード</FormLabel>
							<InputPassword name="oldPass"
								rules={{
									minLength: {
										value: 8,
										message: 'パスワードは8文字以上で入力してください。',
									},
								}} />
							<ErrorMessage name="oldPass" />
						</FormGroup>
						<FormGroup>
							<FormLabel required>新しいパスワード</FormLabel>
							<InputPassword name="newPass"
								rules={{
									minLength: {
										value: 8,
										message: 'パスワードは8文字以上で入力してください。',
									},
									validate: {
										condRequired: (input: string) => {
											const oldPass: string | undefined = getValues('oldPass');
											if (oldPass && oldPass.length > 0) {
												return input.length > 0 || '新しいパスワードを入力してください。';
											}
										}
									}
								}} />
							<ErrorMessage name="newPass" />
						</FormGroup>
						<FormGroup>
							<FormLabel required>新しいパスワード（確認用）</FormLabel>
							<InputPassword name="newPassConfirm"
								rules={{
									validate: (input: string) => input === getValues("newPass") || '確認用のパスワードが異なります',
								}} />
							<ErrorMessage name="newPassConfirm" />
						</FormGroup>
						<div className="el_largeBtnWrap">
							<SubmitButton>パスワード変更</SubmitButton>
						</div>
					</FormGroup>
				</Form>
				<div className="el_largeBtnWrap mt_16 ml_16">
					<a className="el_largeBtnBlueInv" href={`${state.params.basePath}/two-factor-auth-setting`}>2段階認証の設定</a>
				</div>
			</div>
		</>
	);
};
export default PasswordEdit;