import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { ErrorMessage, FormGroup, Input, InputRadio, Textarea } from '../../organisms/Form';
import RewardCouponEdit, { RewardCouponEditProps } from './RewardCouponEdit';
import RewardItemEdit, { RewardItemEditProps } from './RewardItemEdit';
import { FormValues } from './useRewardEdit';

interface RewardEditFormProps {
	names: Record<keyof FormValues, string>;
	rewardType: string;
	presentProps: RewardItemEditProps;
	couponProps: RewardCouponEditProps;
}

export const RewardEditForm: React.FunctionComponent<RewardEditFormProps> = (props) => {
	const {
		names,
		rewardType,
		presentProps,
		couponProps,
	} = props;

	return (
		<>
			<FormGroup>
				<FormLabel required>レビュー特典種類</FormLabel>
				<InputRadio name={names.rewardType} value="item" label="プレゼント品" />
				<InputRadio name={names.rewardType} value="coupon" label="クーポン" />
			</FormGroup>
			<FormGroup>
				<FormLabel required>レビュー特典名</FormLabel>
				<Input type="text" placeholder="プレゼント品名／XXX円OFFクーポン" name={names.name} />
				<ErrorMessage name={names.name} />
			</FormGroup>
			<FormGroup>
				<FormLabel>詳細説明文</FormLabel>
				<Textarea placeholder="レビュー特典の説明" name={names.description} />
				<ErrorMessage name={names.description} />
			</FormGroup>
			{rewardType == 'item' &&
				<RewardItemEdit
					{...presentProps}
					/>
			}
			{rewardType == 'coupon' &&
				<RewardCouponEdit
					{...couponProps}
					/>
			}
		</>
	);
};
export default RewardEditForm;