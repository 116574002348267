import { APIResponses } from '@sasagase/api-caller';
import { parseYahooReviewCSV } from '@sasagase/parse-review-csv';
import { RewardTargetsSummary, YahooReview } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAPIDataTableLoad, useAppState } from '../../../context';
import { useYahoo } from '../../../hooks';

async function filesToReviews(files: FileList): Promise<YahooReview[]> {
	const reviews: YahooReview[] = [];
	for (const file of Array.from(files)) {
		try {
			const buff = await file.arrayBuffer();
			// TODO: yahooでからダウンロードするとzipファイルになっているのでzip対応した方が良いかもしれない
			const fileReviews = await parseYahooReviewCSV(buff);
			reviews.push(...fileReviews);
		} catch (err) {
			alert(`CSVの読込みに失敗しました。(${file.name})\n\n${err.message}`);
			throw err;
		}
	}
	return reviews;
}

export const useTargetList = () => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [summary, setSummary] = React.useState<RewardTargetsSummary|null>(null);
	const [targets, setTargets] = React.useState<APIResponses.GetTargetsResponseValues[]>();
	const [checkDate, setCheckDate] = React.useState<number | null>(null);
	const methodFilter = useForm();
	const { getValues } = methodFilter;

	React.useEffect(() => {
		if (checkDate) {
			return;
		}

		const param = { shopId: state.params.shopId ?? '' };
		return callAPI.yahoo.getShop(param, (err, result) => {
			if (err) {
				throw err;
			}
			const shop = result.data;
			if (shop && shop.lastReviewCheckDate) {
				setCheckDate(shop.lastReviewCheckDate);
			}
		});
	}, [checkDate]);

	React.useEffect(() => {
		if (summary) {
			return;
		}
		const param = { shopId: state.params.shopId ?? '' };
		return callAPI.yReview.getTargetSummary(param, (err, result) => {
			if (err) {
				throw err;
			}
			const resSummary = result.data;
			if (resSummary) {
				setSummary(resSummary);
			}
		});
	}, [summary]);

	const [rows, { filter, reload, loadMore, pageInfo, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getTargets,
		{
			initValue: [],
			apiParams: { shopId: state.params.shopId, pagination: "1" },
			perPage: 1000,
			onFetch: (data) => {
				return [data.data, data.pageInfo];
			},
		}
	);

	React.useEffect(() => {
		if (rows == undefined) {
			return;
		}
		setTargets(rows);
	}, [rows]);

	const handleFilter = () => {
		const { orderNumber, status } = getValues();
		const filterParams: Record<string, unknown> = {};
		if (orderNumber) filterParams['orderNumber'] = orderNumber;
		if (status) filterParams['status'] = status;
		filter(filterParams, []);
	};

	const handleChangeCanSendMail = async (target: APIResponses.GetTargetsResponseValues, canSendMail: boolean) => {
		if (!rows) {
			return;
		}

		for (const order of target.orders) {
			const param = {
				shopId: state.params.shopId,
				orderNumber: order.number,
				canSendingMail: canSendMail
			};
			const res = await callAPI.yReview.putOrderCanSendingMail(param);
			if (!res || !res.data) {
				alert(`メール送信の変更に失敗しました`);
			}
		}
		// 変更が検知されないので再生成してセット
		const updatedRows = rows.map(row => {
			if (row.id === target.id) {
				return {
					...row,
					orders: row.orders.map(order => ({
						...order,
						canSendingMail: canSendMail
					}))
				};
			}
			return row;
		});
		setTargets(updatedRows);
	};

	const handleSelectFiles = async (files: FileList) => {
		const reviews = await filesToReviews(files);
		try {
			const params = {
				shopId: state.params.shopId,
				reviews
			};
			const result = await callAPI.yReview.postReviews(params);
			if (result) {
				alert(`CSVのアップロードが完了しました。\n\n新規レビュー ${result.data.newReviews}件\n特典対象 ${result.data.targets}件`);
				if (result.data.targets <= 0) {
					return;
				}
				reload([]);
			}
		} catch (error) {
			alert(`アップロードしたCSVファイルが破損しています。`)
		}
	};

	const {
		toOrderDetailURL,
	} = useYahoo(state.shop);

	return {
		tableProps: {
			rows: targets,
			pageInfo,
			isLoading,
			handleFilter,
			methodFilter,
			loadMore,
			onChangeCanSendMail: handleChangeCanSendMail,
			toOrderDetailURL,
		},
		summary,
		checkDate,
		handleSelectFiles
	};
};
export default useTargetList;
