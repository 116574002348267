import { superstructResolver } from '@hookform/resolvers/superstruct';
import { CSVColumn, CSVFormat, CSV_FORMAT, CSV_VALUE_TYPE_DESCS, NIL, YahooCouponReward, YahooItemReward, YahooRewardBase } from '@sasagase/types';
import iconv from 'iconv-lite';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Struct, define, dynamic, enums, is, optional, pattern, record, size, string, type, unknown } from 'superstruct';
import { v4 as uuid } from 'uuid';
import { useAPI, useAppState } from "../../../context";
import { isAvailableCSVType, isUserCSVField } from '../../../lib/shopCustom';

export interface FormValues {
	rewardType: string; // 'item' | 'coupon'
	name: string;
	description: string;
	image: string;
	limitedType: string; // 'unlimited' | 'limited'
	quantity: string;
	csvFormat: string;
	csvClientType: string; // 'orderer' | 'user'
	csvUserSettings: Record<string, Record<string, string>>;
	optionAttributes?: Record<string, unknown>;
	issueFreq: string;
	categoryId: string;
	discountType: string; // '1' | '2' | '3'
	discountPrice: string;
	discountRate: string;
	validPeriod: string;
	validDays: string;
	dispFlg: string; // 'true' | 'false'
	couponImageType: string; // 'input' | 'template' | 'upload'
	couponImageInput: string;
	couponImageTemplate: string;
	couponImageUpload: string;
	couponImageName: string;
	linkUrl: string;
	canCombineUse: string; // 'true' | 'false'
	countUserUseLimit: string;
	countAllUseLimit: string;
	orderType: string; // '0' | '1' | '2'
	orderPrice: string;
	orderCount: string;
	itemDesignation: string; // '3' | '1' | '4'
	targetItemIdStrings: string;
	targetItemTagStrings: string;
}

export const nameList: ReadonlyArray<keyof FormValues> = [
	'rewardType',
	'name',
	'description',
	'image',
	'limitedType',
	'quantity',
	'csvFormat',
	'csvClientType',
	'csvUserSettings',
	'optionAttributes',
	'issueFreq',
	'categoryId',
	'discountType',
	'discountPrice',
	'discountRate',
	'validPeriod',
	'validDays',
	'dispFlg',
	'couponImageType',
	'couponImageInput',
	'couponImageTemplate',
	'couponImageUpload',
	'couponImageName',
	'linkUrl',
	'canCombineUse',
	'countUserUseLimit',
	'countAllUseLimit',
	'orderType',
	'orderPrice',
	'orderCount',
	'itemDesignation',
	'targetItemIdStrings',
	'targetItemTagStrings',
] as const;

const defaultToName = (name: string) => name;

function requiredString(val: unknown): boolean {
	return is(val, size(string(), 1, Infinity));
}

const csvRule = (format: CSVFormat, col: CSVColumn) => {
	const desc = CSV_VALUE_TYPE_DESCS[col.valueType || 'any'];
	return (data: unknown) => {
		const val = String(data);
		if (!desc.regex.test(val)) {
			return `${desc.name} を入力してください`;
		}
		if (format.lengthEncode) {
			const buff = iconv.encode(val, format.lengthEncode);
			if (col.length != null && buff.length > col.length) {
				return desc.length(col.length) + '以下で入力してください';
			}
		}
		return true;
	};
};

export const RewardEditStruct = (shopId?: string) => {
	return dynamic<any>((values: any) => {
		if (values.rewardType === 'item') {
			const availableCSVFormats = CSV_FORMAT.filter((csv) => isAvailableCSVType(shopId ?? '', csv.type));
			const userFieldCSVFormats = availableCSVFormats.filter((csv) => csv.columns.some(isUserCSVField));
			const csvUserSettingsStruct = userFieldCSVFormats.reduce((struct, csv) => {
				struct[csv.id] = type({
					...Object.fromEntries(
						csv.columns.filter(isUserCSVField).map(col => [col.name, define(`csvUserSettings.${csv.id}.${col.name}`, csvRule(csv, col))])
					),
				});
				return struct;
			}, {} as Record<string, Struct<any, any>>);

			return type({
				rewardType: enums(['item', 'coupon']),
				name: define<string>('name', (val) => requiredString(val) || '特典名を入力してください'),
				description: string(),
				image: string(),
				limitedType: enums(['unlimited', 'limited']),
				quantity: define<string>('quantity', (val: any) => values.limitedType == 'unlimited' || parseInt(val, 10) >= 0 || '個数を入力してください'),
				csvFormat: string(),
				csvClientType: enums(['orderer', 'user']),
				csvUserSettings: type({
					...csvUserSettingsStruct,
				}),
				optionAttributes: optional(record(string(), unknown())),
			});
		} else {
			return type({
				rewardType: enums(['item', 'coupon']),
				name: define<string>('name', (val) => requiredString(val) || '特典名を入力してください'),
				description: string(),
				issueFreq: enums(['individual', 'daily', 'weekly', 'monthly']),
				categoryId: define<string>('categoryId', (val) => requiredString(val) || 'カテゴリを選択してください'),
				discountType: define<string>('discountType', (val) => {
					return (values.itemDesignation != '3' && val == '3') ? '送料無料の場合、クーポン対象商品を指定できません' :
						is(val, enums(['1', '2', '3'])) || '値引き設定を選択してください';
				}),
				discountPrice: define<string>('discountPrice', (val: any) => {
					const v = parseInt(val, 10);
					return values.discountType != '1' ||
						(v >= 1 && v <= 99999999 && String(v).length <= 8) ||
						'定額値引き額を半角数字8文字以内で入力してください'
				}),
				discountRate: define<string>('discountRate', (val: any) => {
					const v = parseInt(val, 10);
					return values.discountType != '2' ||
						(v >= 1 && v <= 99 && String(v).length <= 8) ||
						'定率値引き割合を1〜99の半角数字で入力してください'
				}),
				validPeriod: enums(['days', 'nextMonth', 'secondNextMonth']),
				validDays: define<string>('validDays', (val: any) => {
					if (values.validPeriod === 'days') {
						const v = parseInt(val, 10);
						return (v >= 1 && v <= 89) ||
							'クーポン有効期間を1〜89の範囲で入力してください'
					} else {
						return true;
					}
				}),
				couponImageType: define<string>('couponImageType', (val) => {
					return is(val, enums(['input', 'template', 'upload'])) || 'クーポン画像の指定方法を選択してください';
				}),
				couponImageInput: define<string>('couponImageInput', (val) => {
					return values.couponImageType != 'input' || (val != '') || '画像ファイル名を入力してください'
				}),
				couponImageTemplate: string(),
				couponImageUpload: define<string>('couponImageUpload', (val) => {
					return values.couponImageType != 'upload' || (val != '') || 'オリジナルを使用するを選択する場合、画像のアップロードは必須となります。上記より画像のアップロードをお願いします'
				}),
				couponImageName: string(),
				linkUrl: define<string>('linkUrl', (val) => {
					return val == '' ||
						is(val, pattern(string(),  /^https:\/\/store\.shopping\.yahoo\.co\.jp\/.*$/)) ||
						'リンク先URLを正しく入力してください';
				}),
				canCombineUse: define<string>('canCombineUse', (val) => is(val, enums(['true', 'false'])) || '併用可否を選択してください'),
				countUserUseLimit: define<string>('countUserUseLimit', (val: any) => {
					const v = parseInt(val, 10);
					return val == '' ||
						(v >= 1 && v <= 2147483647) ||
						'ユーザーごとの利用可能回数を1以上の半角数字で入力してください'
				}),
				countAllUseLimit: define<string>('countAllUseLimit', (val: any) => {
					const v = parseInt(val, 10);
					return val == '' ||
						(v >= 1 && v <= 2147483647) ||
						'全ユーザーの利用可能回数を1以上の半角数字で入力してください'
				}),
				orderType: define<string>('orderType', (val) => is(val, enums(['0', '1', '2'])) || '注文金額/個数条件を選択してください'),
				orderPrice: define<string>('orderPrice', (val: any) => {
					const v = parseInt(val, 10);
					return values.orderType != '1' ||
						(v >= 1 && v <= 999999999) ||
						'注文金額条件の額を1〜999999999の半角数字で入力してください'
				}),
				orderCount: define<string>('orderCount', (val: any) => {
					const v = parseInt(val, 10);
					return values.orderType != '2' ||
						(v >= 1 && v <= 99) ||
						'注文個数条件の個数を1〜99の半角数字で入力してください'
				}),
				itemDesignation: define<string>('itemDesignation', () => {
					return (values.itemDesignation != '3' && values.discountType == '3') ? '送料無料の場合、クーポン対象商品を指定できません' :
						is(values.itemDesignation, enums(['3', '1', '4'])) || '対象商品の条件を選択してください';
				}),
				targetItemIdStrings: define<string>('targetItemIdStrings', (val) => {
					return values.itemDesignation != '1' || requiredString(val) || 'クーポンの適用対象の商品コードを入力してください';
				}),
				targetItemTagStrings: define<string>('targetItemTagStrings', (val) => {
					return values.itemDesignation != '4' || requiredString(val) || 'クーポンの適用対象の商品タグを入力してください';
				}),
			});
		}
	});
};

function getImageName(template: string, discountType: string, discountFactor: string): string {
	const specifyFactor = discountFactor ? '_' + discountFactor : '';
	return `t_${template}_${discountType.charAt(0)}${specifyFactor}.jpg`;
}

type RewardEditParams = {
	rewardId?: string;
	flag?: string;
}

interface useRewardEditProps {
	initValues: FormValues;
	toName?: (name: string) => string;
}

export const useRewardEdit = (props: useRewardEditProps) => {
	const params = useParams<RewardEditParams>();
	const isNew = params.rewardId == 'new';
	const isCopy = params.flag === 'copy';

	const location = useLocation();
	const urlParamsTab = new URLSearchParams(location.search).get("tab");
	const urlParamSeq = new URLSearchParams(location.search).get("seq");
	if (urlParamsTab === "present") {
		props.initValues.rewardType = "item";
	} else if (urlParamsTab === "coupon") {
		props.initValues.rewardType = "coupon";
	}

	const toName = props.toName || defaultToName;
	const names: Record<typeof nameList[number], string> = React.useMemo(() => {
		const ret: Record<string, string> = {};
		for (const name of nameList) {
			ret[name] = toName(name);
		}
		return ret;
	}, [toName]);

	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const navigate = useNavigate();
	const [messages, setMessages] = React.useState<string[]>([]);
	const [imageFile, setImageFile] = React.useState<File|undefined>(undefined);
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
		shouldUnregister: false,
		mode: 'onBlur',
		resolver: superstructResolver(RewardEditStruct(shopId)),
	});
	const { getValues, setValue, watch, reset } = formMethods;

	const toReward = (id: string, values: Partial<FormValues>): YahooItemReward | YahooCouponReward => {
		if (values.rewardType == 'item') {
			const isUnlimited = values.limitedType == 'unlimited';

			const reward = new YahooItemReward({
				id,
				name: values.name,
				description: values.description,
				isCoupon: false,
				image: values.image,
				quantity: isUnlimited ? undefined : parseInt(values.quantity || '', 10),
				isUnlimited,
				csvFormat: values.csvFormat,
				csvClientType: values.csvClientType,
				csvUserSettings: values.csvUserSettings,
				optionAttributes: values.optionAttributes,
			});
			return reward;
		} else {
			const dispFlg = false; // 「公開しない」に固定

			const reward = new YahooCouponReward({
				id: id || NIL,
				name: values.name,
				description: values.description,
				isCoupon: true,
				issueFreq: values.issueFreq,
				categoryId: values.categoryId,
				discountType: values.discountType,
				discountPrice: values.discountType == '1' ? parseInt(values.discountPrice || '', 10) : undefined,
				discountRate: values.discountType == '2' ? parseInt(values.discountRate || '', 10) : undefined,
				validPeriod: values.validPeriod,
				validDays: parseInt(values.validDays || '0', 10),
				dispFlg: dispFlg,
				couponImageType: values.couponImageType,
				couponImageInput: values.couponImageType == 'input' ? values.couponImageInput : undefined,
				couponImageTemplate: values.couponImageType == 'template' ? values.couponImageTemplate : undefined,
				couponImageUpload: values.couponImageType == 'upload' ? values.couponImageUpload : undefined,
				couponImageName: values.couponImageName,
				linkUrl: values.linkUrl,
				canCombineUse: values.canCombineUse == 'true',
				countUserUseLimit: values.countUserUseLimit ? parseInt(values.countUserUseLimit, 10) : undefined,
				countAllUseLimit: values.countAllUseLimit ? parseInt(values.countAllUseLimit, 10) : undefined,
				orderType: values.orderType,
				orderPrice: values.orderType == '1' ? parseInt(values.orderPrice || '', 10) : undefined,
				orderCount: values.orderType == '2' ? parseInt(values.orderCount || '', 10) : undefined,
				itemDesignation: values.itemDesignation,
				targetItemIdStrings: values.itemDesignation == '1' ? String(values.targetItemIdStrings).split(/[,\t\n]/).map(str => str.trim()).filter(Boolean).join(',') : undefined,
				targetItemTagStrings: values.itemDesignation == '4' ? String(values.targetItemTagStrings).split(/[,\t\n]/).map(str => str.trim()).filter(Boolean).join(',') : undefined,
			});
			return reward;
		}
	}

	const toValues = (reward: YahooItemReward | YahooCouponReward): FormValues => {
		const values = {
			...props.initValues,
			name: reward.name,
			description: reward.description,
		};
		if (reward.isCoupon && reward instanceof YahooCouponReward) {
			return {
				...values,
				rewardType: 'coupon',
				issueFreq: reward.issueFreq,
				categoryId: reward.categoryId,
				discountType: reward.discountType, // 'price' | 'rate' | 'shipping'
				discountPrice: String(reward.discountPrice || ''),
				discountRate: String(reward.discountRate || ''),
				validPeriod: reward.validPeriod,
				validDays: String(reward.validDays),
				dispFlg: reward.dispFlg ? 'true' : 'false', // 'true' | 'false'
				couponImageType: reward.couponImageType, // 'input' | 'template' | 'upload'
				couponImageInput: reward.couponImageInput || '',
				couponImageTemplate: reward.couponImageTemplate || '',
				couponImageUpload: reward.couponImageUpload || '',
				couponImageName: reward.couponImageName || '',
				linkUrl: reward.linkUrl || '',
				canCombineUse: reward.canCombineUse ? 'true' : 'false', // 'true' | 'false'
				countUserUseLimit: String(reward.countUserUseLimit || ''),
				countAllUseLimit: String(reward.countAllUseLimit || ''),
				orderType: String(reward.orderType), // '0' | '1' | '2'
				orderPrice: String(reward.orderPrice || ''),
				orderCount: String(reward.orderCount || ''),
				itemDesignation: String(reward.itemDesignation),
				targetItemIdStrings: String(reward.targetItemIdStrings || ''),
				targetItemTagStrings: String(reward.targetItemTagStrings || ''),
			};
		} else if (reward instanceof YahooItemReward) {
			return {
				...values,
				rewardType: 'item',
				image: reward.image,
				limitedType: reward.isUnlimited ? 'unlimited' : 'limited',
				quantity: String(reward.quantity || ''),
				csvFormat: reward.csvFormat,
				csvClientType: reward.csvClientType,
				csvUserSettings: reward.csvUserSettings,
				optionAttributes: reward.optionAttributes,
			};
		} else {
			throw false;
		}
	};

	React.useEffect(() => {
		if (!params.rewardId || isNew) {
			return;
		}
		const param = {
			shopId,
			rewardId: params.rewardId,
			seq: urlParamSeq ?? undefined,
		}
		return callAPI.yReview.getReward(param, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const reward = YahooRewardBase.create(resData as Record<string, unknown>);
				if (isCopy) {
					reward.name += ' のコピー';
				}
				reset(toValues(reward));
			}
		});
	}, [shopId, params.rewardId]);

	const generateTemplateImage = async () => {
		const discountType = getValues(names.discountType);
		const discountPrice = getValues(names.discountPrice);
		const discountRate = getValues(names.discountRate);
		const couponImageTemplate = getValues(names.couponImageTemplate);
		// 値引きプランに応じてテンプレート画像を生成
		const template = String(couponImageTemplate);
		const type = {
			'1': 'price',
			'2': 'rate',
			'3': 'shipping',
		}[String(discountType)] ?? '';
		const factor = type == 'price' ? String(discountPrice) : 
						type == 'rate' ? String(discountRate) : 
						'';
		const image = await callAPI.yReview.getRewardTemplateImage({
				shopId,
				template,
				discountType: type,
				discountFactor: factor,
			});
		if (!image || !image.data) {
			return undefined;
		}

		// 追加画像への登録で必要
		const name = getImageName(template, type, factor);
		const file = new File([image.data], name, { type: image.headers['content-type'] });
		return file;
	};
	const handleSave: SubmitHandler<FormValues> = async (values) => {
		let saveFile = imageFile;
		if (values.couponImageType == "template") {
			// Submit時にテンプレート画像を生成
			saveFile = await generateTemplateImage();
		}
		const res = await handleSaveReward(values, saveFile);
		setMessages([]);
		if (!res.result && res.errors && res.errors.length > 0) {
			const msg: string[] = [];
			res.errors.map((err) => {
				msg.push(err.message);
			});
			setMessages(msg);
			window.scrollTo(0, 0);
		}
		return res.result;
	};
	const handleSaveNonNavigate: SubmitHandler<FormValues> = async (values) => {
		// TODO: テンプレート画像はサーバ側で生成する
		let saveFile = imageFile;
		if (values.couponImageType == "template") {
			// Submit時にテンプレート画像を生成
			saveFile = await generateTemplateImage();
		}
		const res = await handleSaveReward(values, saveFile, false);
		setMessages([]);
		if (!res.result && res.errors && res.errors.length > 0) {
			const msg: string[] = [];
			res.errors.map((err) => {
				msg.push(err.message);
			});
			setMessages(msg);
			window.scrollTo(0, 0);
		}
		return res.result;
	};

	interface SaveResult {
		result: boolean;
		errors?: {
			code: string,
			message: string,
		}[];
	}
	const handleSaveReward = async (values: FormValues, file: File | undefined, canNavigate = true): Promise<SaveResult> => {
		const save: Record<string, SaveResult> = {
			success: { result: true },
			failure: { result: false, errors: [{ code: '', message: 'レビュー特典の保存に失敗しました。' }] }
		};

		if (!params.rewardId) {
			return save.failure;
		}

		const id = isNew || isCopy ? uuid() : params.rewardId;
		const reward = toReward(id, values);

		try {
			if (isNew || isCopy) {
				const res = await callAPI.yReview.postReward({
						shopId,
						reward,
						file
					});
				if (res && canNavigate) {
					const params = new URLSearchParams({ seq: res.data.seq });
					const to = `${state.params.basePath}/reward/reward/${id}?` + params.toString();
					navigate(to, {state: {newId: res.data}, replace: true});
				}
			} else {
				await callAPI.yReview.putReward({
						shopId,
						rewardId: reward.id,
						reward,
						file
					});
			}
			return save.success;
		} catch (err) {
			return save.failure;
		}
	};

	const handleChangeFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.files?.length) {
			return;
		}
		const format = /^((.{0,20}).*)(\.(?:jpg|jpeg|png|gif))$/i;
		if (!format.test(ev.target.files[0].name)) {
			alert('規定外のファイル形式です。画像ファイル(gif、jpg、jpeg、png)を選択してください。');
			return;
		}
		const id = await callAPI.storage.postImage({
				shopId,
				file: ev.target.files[0]
			});
		if (id) {
			if (ev.target.name == 'image') {
				setValue(names.image, id.data);
			} else {
				setValue(names.couponImageUpload, id.data);
			}
			// Coupon,追加画像アップロードAPIで必要
			if (getValues(names.rewardType) == 'coupon') {
				/**
				 * 追加画像のファイル名・ファイル形式の仕様
				 * ファイル名：半角英数字、ハイフン（ - ）、アンダーバー（ _ ）、ピリオド（ . ）のみ使用可
				 * ファイル形式：GIF、JPEG、PNG形式のみ
				 *   拡張子は.gif/.jpg/.jpe/.jpeg /.pngのみ
				 */
				const match = id.data.replace(/-/g, '').match(format);
				if (match) {
					const fileName = match[2] + match[3].toLowerCase();
					const file = new File([ev.target.files[0]], fileName, { type: ev.target.files[0].type });
					setImageFile(file);
				}
			}
		}
		ev.target.value = '';
	};
	const handleChangeCouponImageType = async (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.value) {
			return;
		}
		// 画像ファイル名指定の場合、追加画像アップロード用に保持している画像ファイルを削除
		if (ev.target.value == 'input') {
			setImageFile(undefined);
		}
	};
	const handleClickDeleteImage = (name: typeof nameList[number]) => () => {
		setValue(names[name], '');
	};
	const nonNegative: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
		const i = parseInt(ev.target.value, 10);
		ev.target.value = isNaN(i) ? "" : Math.max(0, i).toString();
	};
	const toUrlParam = (param: string | string[]): string => {
		if (Array.isArray(param)) {
			return "";
		} else if (param === "item") {
			return "present";
		} else if (param === "coupon") {
			return "coupon";
		} else {
			return "";
		}
	};
	const rewardType: string = watch(names.rewardType);
	const returnListUrl = `${state.params.basePath}/reward/reward?tab=${toUrlParam(rewardType)}`;
	const imageUrl = watch(names.image) ? `/storage/${shopId}/${watch(names.image)}` : undefined;
	const couponImageUploadUrl = watch(names.couponImageUpload) ? `/storage/${shopId}/${watch(names.couponImageUpload)}` : undefined;

	return {
		names,
		methods: formMethods,
		rewardType,
		handleSave,
		handleSaveNonNavigate,
		messages,
		presentProps: {
			names,
			imageUrl,
			handleChangeFile,
			handleClickDeleteImage,
			nonNegative,
		},
		couponProps: {
			names,
			methods: formMethods,
			couponImageUploadUrl,
			couponImageUrl: '', // TODO:Yahooの追加画像へのURLを生成
			handleChangeFile,
			handleChangeCouponImageType,
			handleClickDeleteImage,
			nonNegative,
		},
		returnListUrl,
	};
};
export default useRewardEdit;