import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useAPI, useAppState } from '../../../context';

export interface GroupEditRowFormValues {
	isAll: string;
	inputSkus: string;
	skus: string;
	inputExcludeSkus: string;
	excludeSkus: string;
	childGroupIds: string[];
	excludeChildGroupIds: string[];
	group: Record<string, boolean>;
	excludeGroup: Record<string, boolean>;
	itemName: string;
	upper: string;
	lower: string;
	excludeItemName: string;
	excludeUpper: string;
	excludeLower: string;
}
export const nameList: ReadonlyArray<keyof GroupEditRowFormValues> = [
	'isAll',
	'inputSkus',
	'skus',
	'inputExcludeSkus',
	'excludeSkus',
	'childGroupIds',
	'excludeChildGroupIds',
	'group',
	'excludeGroup',
	'itemName',
	'upper',
	'lower',
	'excludeItemName',
	'excludeUpper',
	'excludeLower'
] as const;

const defaultToName = (name: string) => name;

export const groupTagName = 'span';
export const getGroupTag = (id: string, name: React.ReactNode) => {
	return `<${groupTagName} contenteditable="${false}" data-groupid="${id}"><${groupTagName} data-removeid="${id}"></${groupTagName}>${name}</${groupTagName}>`;
};

interface useGroupEditRowProps {
	groupNames: Record<string, string>;
	toName?: (name: string) => string;
}

export const useGroupEditRow = (props: useGroupEditRowProps) => {
	const { register, watch, setValue, getValues } = useFormContext();
	const toName = props.toName || defaultToName;

	const callAPI = useAPI();
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const names: Record<typeof nameList[number], string> = React.useMemo(() => {
		const ret: Record<string, string> = {};
		for (const name of nameList) {
			ret[name] = toName(name);
		}
		return ret;
	}, [toName]);

	React.useEffect(() => {
		register(names.childGroupIds);
		register(names.excludeChildGroupIds);
	}, [register, names]);

	const childGroupIds: GroupEditRowFormValues['childGroupIds'] = watch(names.childGroupIds);
	const excludeChildGroupIds: GroupEditRowFormValues['excludeChildGroupIds'] = watch(names.excludeChildGroupIds);

	React.useEffect(() => {
		const prev = getValues(names.skus);
		setValue(names.inputSkus, childGroupIds.map(id => getGroupTag(id, props.groupNames[id])) + prev);
		const prevExclude = getValues(names.excludeSkus);
		setValue(names.inputExcludeSkus, excludeChildGroupIds.map(id => getGroupTag(id, props.groupNames[id])) + prevExclude);
	}, []);

	const handleSearchItems = async (itemName: string, upper: number, lower: number) => {
		const res = await callAPI.yahoo.searchItems({
				shopId,
				itemName,
				itemPriceFrom: upper,
				itemPriceTo: lower
			});
		return res ? res.data : [];
	}

	return {
		names,
		isAll: watch(names.isAll) == 'true',
		handleSearchItems
	};
};
export default useGroupEditRow;