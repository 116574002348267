import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppState } from '../context';
import ContextProvider from './ContextProvider';
import When from './When';
import { LayoutLoggedIn, LayoutLoggedOut } from './organisms/Layout';
import { AccountActivate } from './pages/AccountActivate';
import { AccountPasswordReset } from './pages/AccountPasswordReset';
import { AccountRecover } from './pages/AccountRecover';
import { AccountRegist } from './pages/AccountRegist';
import { CampaignEdit } from './pages/CampaignEdit';
import { CampaignList } from './pages/CampaignList';
import { Contact } from './pages/Contact';
import { Exclusion } from './pages/Exclusion';
import { GroupEdit } from './pages/GroupEdit';
import { GroupList } from './pages/GroupList';
import { Guide } from './pages/Guide';
import { Login } from './pages/Login';
import { MailSetting } from './pages/MailSetting';
import { Management } from './pages/Management';
import { NotCoveredEdit } from './pages/NotCoveredEdit';
import { NotCoveredList } from './pages/NotCoveredList';
import { Notfound } from './pages/Notfound';
import { RequestList } from './pages/RequestList';
import { RewardEdit } from './pages/RewardEdit';
import { RewardList } from './pages/RewardList';
import { ShopSetting } from './pages/ShopSetting';
import { SmtpSetting } from './pages/SmtpSetting';
import { SystemAlert } from './pages/SystemAlert';
import { TargetList } from './pages/TargetList';
import { TemplateEdit } from './pages/TemplateEdit';
import { TemplateList } from './pages/TemplateList';
import { Top } from './pages/Top';
import { TwoFactorAuthEdit } from './pages/TwoFactorAuth/TwoFactorAuthEdit';
import { UserEdit } from './pages/UserEdit';
import { UserList } from './pages/UserList';
import { YahooSetting } from './pages/YahooSetting';

const DefaultRedirect: React.FunctionComponent = () => <Navigate to=".." replace />;
interface DefaultShopRedirectProps {}
const DefaultShopRedirect: (props: DefaultShopRedirectProps) => React.ReactElement = () => {
	const [state] = useAppState();
	const firstShopId = state.user?.getEnableCaps('yReview_login')[0]?.entityId;

	if (!firstShopId) {
		return <div>無効なIDかログイン権限がありません。</div>;
	}
	return <Navigate to={firstShopId} replace />;
};

const App: React.FunctionComponent = () => {
	return (
		<ContextProvider>
			<When not loggedin>
				<Routes>
					<Route path="/account" element={<LayoutLoggedOut />}>
						<Route path="recover" element={<AccountRecover />} />
						<Route path="reset/:token" element={<AccountPasswordReset />} />
						<Route path="activate/:token" element={<AccountActivate />} />
						<Route path="regist/:token" element={<AccountRegist />} />
					</Route>
					<Route path="/notfound" element={<Notfound />} />
					<Route path="*" element={<Login />} />
				</Routes>
			</When>
			<When loggedin>
				<Routes>
					<Route path="/" element={<Navigate to="/review" replace />} />
					<Route path="/review" element={<DefaultShopRedirect />} />
					<Route path="/review/:shopId" element={<LayoutLoggedIn />}>
						<Route index element={<Top />} />
						<Route path="guide/*" element={<Guide />} />
						<Route path="contact" element={<Contact />} />
						<Route path="management" element={<Management />} />
						<Route path="two-factor-auth-setting" element={<TwoFactorAuthEdit />} />
						<Route path="reward/target" element={<TargetList />} />
						<Route path="reward/request" element={<RequestList />} />
						<Route path="reward/reward" element={<RewardList />} />
						<Route path="reward/reward/:rewardId/:flag" element={<RewardEdit />} />
						<Route path="reward/reward/:rewardId" element={<RewardEdit />} />
						<Route path="reward/group" element={<GroupList />} />
						<Route path="reward/group/:groupId/:flag" element={<GroupEdit />} />
						<Route path="reward/group/:groupId" element={<GroupEdit />} />
						<Route path="reward/campaign" element={<CampaignList />} />
						<Route path="reward/campaign/:campaignId/:flag" element={<CampaignEdit />} />
						<Route path="reward/campaign/:campaignId" element={<CampaignEdit />} />
						<Route path="mail/smtp-setting" element={<SmtpSetting />} />
						<Route path="mail/setting" element={<MailSetting />} />
						<Route path="mail/template" element={<TemplateList />} />
						<Route path="mail/template/:templateId/:flag" element={<TemplateEdit />} />
						<Route path="mail/template/:templateId" element={<TemplateEdit />} />
						<Route path="mail/not-covered" element={<NotCoveredList />} />
						<Route path="mail/not-covered/:notCoveredId/:flag" element={<NotCoveredEdit />} />
						<Route path="mail/not-covered/:notCoveredId" element={<NotCoveredEdit />} />
						<Route path="mail/exclusion" element={<Exclusion />} />
						<Route path="setting/user" element={<UserList />} />
						<Route path="setting/user/:userId" element={<UserEdit />} />
						<Route path="account/yahoo-setting" element={<YahooSetting />} />
						<Route path="account/shop-setting" element={<ShopSetting />} />
						<Route path="account/system-alert" element={<SystemAlert />} />
						<Route path="*" element={<DefaultRedirect />} />
					</Route>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</When>
		</ContextProvider>
	);
};
export default hot(module)(App);
