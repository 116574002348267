import { CapabilityType, MASTER_ENTITY_ID, User } from '@sasagase/types';
import * as React from 'react';

interface SideElementProps {
	entityId: string;
	user?: User | null;
	caps: CapabilityType[];
	children: React.ReactNode;
}

export const SideElement: React.FunctionComponent<SideElementProps> = (props) => {
	let isCap, isCapMaster;
	for(const cap of props.caps) {
		isCap = props.user?.hasEnableCaps(cap, props.entityId);
		isCapMaster = props.user?.hasEnableCaps(cap, MASTER_ENTITY_ID);
		if (isCap || isCapMaster) break;
	}

	if(isCap || isCapMaster) {
		return <>{props.children}</>;
	} else {
		return null;
	}
}