import * as React from 'react';
import GuideMain from './GuideMain';
import GuideNavigation from './GuideNavigation';
import GuideSub from './GuideSub';
import useGuide from './useGuide';

interface GuideProps {}

export const Guide: React.FunctionComponent<GuideProps> = () => {
	const {
		lastPath,
		loadTargetPath,
		basePath,
		folderPath,
		isFullSize,
		isTop,
		isMarkdown,
		markDownTitle,
	} = useGuide();

	return (
		<>
			<div className="ly_main__guide">
				<div className="bl_row">
					{(isFullSize || !isTop) && <div className="bl_col bl_col__3">
						<GuideNavigation folderPath={folderPath} path={loadTargetPath} paramPath={lastPath} isMarkdown={isMarkdown} />
					</div>}

					<div className="bl_col bl_col__9">
						{isMarkdown &&
							<GuideMain path={loadTargetPath} title={markDownTitle} folderPath={folderPath} basePath={basePath} />
						}
						{!isMarkdown &&
							<GuideSub path={lastPath} title={markDownTitle} basePath={basePath} folderPath={folderPath} />
						}
					</div>

				</div>
			</div>
		</>
	);
};
export default Guide;