import { css } from "@emotion/react";
import * as React from 'react';

interface FormIndentProps {
	className?: string;
	children: React.ReactNode;
}
export const FormIndent: React.FC<FormIndentProps> = (props) => {

	const style = css`
		margin-left: 26px !important;
	`;

	return (
		<div css={style} className={props.className}>
			{props.children}
		</div>
	);
}
export default FormIndent;
