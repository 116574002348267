import { NIL, Review } from "@sasagase/types";
import { cast, columns } from "../libs";

const COLUMN_NAME: Record<string, string> = {
	'レビュータイプ': 'type',
	'商品名': 'targetName',
	'レビュー詳細URL': 'url',
	'評価': 'points',
	'投稿時間': 'date',
	'タイトル': 'title',
	'レビュー本文': 'content',
	'注文番号': 'orderNumber',
};
const REVIEW_TYPE: Record<string, string> = {
	'ショップレビュー': 'shop',
	'商品レビュー': 'item',
};
const COLUMN_CAST: Record<string, (value: string) => any> = {
	'type': (v) => REVIEW_TYPE[v] || v,
	'points': Number,
	'date': (v) => new Date(v).getTime(),
};

export const rakuten = {
	parseOptions: {
		columns: columns(COLUMN_NAME),
		cast: cast(COLUMN_CAST),
	},
	toReview: (record: Record<string, unknown>) => {
		const [, type, rac] = typeof record.url == 'string' && record.url.match(/\/(item|shop)\/[^/]*\/[0-9]*_([0-9]*)\//) || [];
		return Review.create({
			...record,
			id: NIL,
			rac: type == 'shop' ? null : parseInt(rac, 10),
		});
	},
};
export default rakuten;
