import { css } from "@emotion/react";
import * as React from 'react';
import { cssVariable } from '../../../css/variable';
import { PageDesc } from '../PageDesc';

interface RequiredDescProps {
}
export const RequiredDesc: React.FC<RequiredDescProps> = () => {

	const style = css`
		color: ${cssVariable.red};
	`;

	return <PageDesc><span css={style}>*</span> は必須項目です。</PageDesc>;
}
export default RequiredDesc;
