import * as React from 'react';

interface HeadlineProps {
	as?: 'h1' | 'h2' | 'h3' | 'h4',	// default: h1
	className?: string;
	id?: string;
	children?: React.ReactNode;
}
export const Headline: React.FC<HeadlineProps> = (props) => {
	const Tag = props.as ? props.as : 'h1';
	return <Tag id={props.id} className={props.className}>{props.children}</Tag>;
}
export default Headline;
