import { superstructResolver } from '@hookform/resolvers/superstruct';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { intersection } from 'superstruct';
import CampaignWizardConfirm from './CampaignWizardConfirm';
import CampaignWizardS1, { CampaignWizardS1FormValue, CampaignWizardS1Struct, campaignWizardS1InitValues } from './CampaignWizardS1';
import CampaignWizardS2, { CampaignWizardS2FormValue, CampaignWizardS2Struct, campaignWizardS2InitValues } from './CampaignWizardS2';
import CampaignWizardS3, { CampaignWizardS3FormValue, CampaignWizardS3Struct, campaignWizardS3InitValues } from './CampaignWizardS3';
import CampaignWizardS4, { CampaignWizardS4FormValue, CampaignWizardS4Struct, campaignWizardS4InitValues } from './CampaignWizardS4';
import CampaignWizardS5, { CampaignWizardS5FormValue, CampaignWizardS5Struct, campaignWizardS5InitValues } from './CampaignWizardS5';

type WizardComponent = React.FC<{
	onPrev: () => void;
	onClose: () => void;
}>;

interface Step {
	component: WizardComponent;
	initValues: Partial<CampaignWizardFormValues>;
}
const CampaignWizardStruct = intersection([
	CampaignWizardS1Struct,
	CampaignWizardS2Struct,
	CampaignWizardS3Struct,
	CampaignWizardS4Struct,
	CampaignWizardS5Struct,
]);

export type CampaignWizardFormValues =
	CampaignWizardS1FormValue &
	CampaignWizardS2FormValue &
	CampaignWizardS3FormValue &
	CampaignWizardS4FormValue &
	CampaignWizardS5FormValue;

const campaignWizardInitValues: CampaignWizardFormValues = {
	...campaignWizardS1InitValues,
	...campaignWizardS2InitValues,
	...campaignWizardS3InitValues,
	...campaignWizardS4InitValues,
	...campaignWizardS5InitValues,
};

export const campaignWizardContent: Step[] = [
	{ component: CampaignWizardS1, initValues: campaignWizardS1InitValues },
	{ component: CampaignWizardS2, initValues: campaignWizardS2InitValues },
	{ component: CampaignWizardS3, initValues: campaignWizardS3InitValues },
	{ component: CampaignWizardS4, initValues: campaignWizardS4InitValues },
	{ component: CampaignWizardS5, initValues: campaignWizardS5InitValues },
	{ component: CampaignWizardConfirm, initValues: {} },
];

interface WizardContextValue {
	values: CampaignWizardFormValues;
	setStepIdx: (idx: number | ((prev: number) => number)) => void;
	setWizardValues: (values: CampaignWizardFormValues | ((prev: CampaignWizardFormValues) => CampaignWizardFormValues)) => void;
}

export const contextWizard = React.createContext<WizardContextValue | null>(null);

interface useCampaignWizardProps {
	initStep?: number;
}

export const useCampaignWizard = (props: useCampaignWizardProps) => {
	const [stepIdx, setStepIdx] = React.useState<number>(props.initStep || 0);
	const [wizardValues, setWizardValues] = React.useState<CampaignWizardFormValues>(campaignWizardInitValues);

	const methods = useForm<CampaignWizardFormValues>({
		defaultValues: campaignWizardInitValues,
		shouldUnregister: false,
		mode: 'onChange',
		resolver: superstructResolver(CampaignWizardStruct),
	});
	const { getValues, reset } = methods;

	const contextValues = React.useMemo(() => ({
		values: wizardValues,
		setStepIdx,
		setWizardValues,
	}), [wizardValues]);

	const stepNow = campaignWizardContent[stepIdx];
	const WizardComponent = stepNow.component;

	const handlePrev = () => {
		reset({
			...getValues(),
			...stepNow.initValues
		});
		setStepIdx(idx => idx - 1);
		setWizardValues((prev: CampaignWizardFormValues) => ({
			...prev,
			...stepNow.initValues
		}));
	};

	return {
		methods,
		campaignWizardContent,
		stepIdx,
		WizardComponent,
		handlePrev,
		contextValues,
	};
};
export default useCampaignWizard;