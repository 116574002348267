
import { APIResponses } from '@sasagase/api-caller';
import { getPageInfoAll } from '@sasagase/types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAPIDataTableLoad, useAppState } from '../../../context';
import { useListSelector } from '../../organisms/DataTable';
import { filterInitValue } from './TemplateListTable';

export const useTemplateList = () => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const [templates, setTemplates] = React.useState<APIResponses.GetTemplatesResponse | undefined>();
	const methodFilter = useForm({
		defaultValues: filterInitValue,
	});
	const { getValues } = methodFilter;

	const [rows, { filter, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getTemplates,
		{
			initValue: [],
			apiParams: { shopId },
			onFetch: (data) => {
				return [data, undefined];
			},
		}
	);

	const {
		getChecks,
		reset,
		refresh,
	} = useListSelector();

	React.useEffect(() => {
		if (!state.params.shopId || rows == undefined) {
			return;
		}

		setTemplates(rows);
		refresh();
	}, [state.params.shopId, rows]);

	const handleFilter = () => {
		const { filterCategory } = getValues();
		if (filterCategory) {
			setTemplates(rows?.filter(row => row.category === filterCategory));
		} else {
			setTemplates(rows);
		}
	};

	const handleClickFavorite = async () => {
		if (!rows) {
			return;
		}
		const checks = getChecks();
		let seq;
		for (const [id, check] of Object.entries(checks)) {
			if (!check) {
				continue;
			}
			const template = rows.find((template) => template.id === id);
			if (template) {
				const param = { shopId, templateId: id, template: { isFavorite: !template.isFavorite } };
				const res = await callAPI.yReview.putTemplate(param);
				if (!res || !res.data) {
					alert(`送信メールテンプレート ${template.name} のお気に入りに失敗しました。`);
				} else {
					seq = res.data.seq;
				}
			}
		}
		filter({ seq }, []);
		reset();
	}

	const handleClickDelete = async () => {
		if (!rows) {
			return;
		}
		const checks = getChecks();
		const checkIds = Object.entries(checks).filter(([, check]) => check).map(([id,]) => id);
		const names = rows.filter(template => checkIds.includes(template.id)).map(template => template.name);
		if (names.length <= 0) {
			alert(`削除するテンプレートを選択してください。`);
			return;
		}

		if (window.confirm(`以下のテンプレートを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			let seq;
			for (const [id, check] of Object.entries(checks)) {
				if (!check) {
					continue;
				}
				const template = rows.find((template) => template.id === id);
				if (template) {
					const param = { shopId, templateId: id };
					const res = await callAPI.yReview.deleteTemplate(param);
					if (!res || !res.data) {
						alert(`送信メールテンプレート ${template.name} の削除に失敗しました。この送信メールテンプレートは使用中です。`);
					} else {
						seq = res.data.seq;
					}
				}
			}
			filter({ seq }, []);
			reset();
		}
	};

	const toEditURL = (id: string): string => {
		return state.params.basePath + '/mail/template/' + id;
	};
	const toCopyURL = (id: string): string => {
		return state.params.basePath + '/mail/template/' + id + '/copy';
	};

	return {
		tableProps: {
			rows: templates,
			pageInfo: getPageInfoAll(rows?.length || 0),
			isLoading,
			handleFilter,
			methodFilter,
		},
		handleClickDelete,
		handleClickFavorite,
		toEditURL,
		toCopyURL,
	};
};
export default useTemplateList;
