import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, Input, SubmitButton } from '../../organisms/Form';
import useCampaignEdit, { CampaignEditFormValues } from './useCampaignEdit';

export const initValues: CampaignEditFormValues = {
	priority: 0,

	name: '',
	begin: '',
	end: '',
	isNeverEnd: 'false',
	deadlineDate: '',
	deadlineDays: '',
	isCutCheck: 'never',
	reviewRequired: 'item',
	canManyRewards: 'false',

	rewards: [{ val: '' }],
	destType: 'shipping',
	applicationClosingDays: '',
	shouldCloseApplication: 'false',
	itemGroup: {
		isAll: 'false',
		inputSkus: '',
		skus: '',
		inputExcludeSkus: '',
		excludeSkus: '',
		childGroupIds: [],
		excludeChildGroupIds: [],
		group: {},
		excludeGroup: {},
		itemName: '',
		upper: '',
		lower: '',
		excludeItemName: '',
		excludeUpper: '',
		excludeLower: '',
	},
	followMail: {
		disable: 'false',
		template: '',
		subject: '',
		bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
		bodyElement: [],
		signature: '',
		email: '',
	},
	requestMail: {
		disable: 'false',
		template: '',
		subject: '',
		bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
		bodyElement: [],
		signature: '',
		email: '',
	},
	receivedMail: {
		disable: 'false',
		template: '',
		subject: '',
		bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
		bodyElement: [],
		signature: '',
		email: '',
	},
};

interface CampaignEditProps {
	initStep?: number;
}

export const CampaignEdit: React.FunctionComponent<CampaignEditProps> = (props) => {
	const {
		methods,
		handleSave,
		returnListUrl,
		STEPS,
		rewards,
		groups,
		templates,
		toStateString,
		reviewFormCond,
		isUpdate,
		beforeCampaign,
		editingCampaign,
	} = useCampaignEdit({ initValues });

	const [stepIdx, setStepIdx] = React.useState(props.initStep || 0);

	if (!rewards || !groups || !templates || !editingCampaign) {
		return null; // loading
	}

	const StepComponent = STEPS[stepIdx].component;

	return (
		<>
			<PageTitle>キャンペーン登録</PageTitle>
			<PageDesc>キャンペーンの新規登録、確認・編集ができます。</PageDesc>
			<RequiredDesc />
			<Form methods={methods} onSubmit={handleSave} isErrorSubmit>
				<div className="bl_row">
					<div className="bl_col bl_col__8 bl_col__fullWidth bl_col__mobileFullWidth">
						<div className="bl_panel bl_panel__bt">
							<FormGroup>
								<FormLabel required>キャンペーン名</FormLabel>
								<Input type="text" name="name" />
								<ErrorMessage name="name" />
							</FormGroup>
							<FormGroup>
								<FormLabel>状況</FormLabel>
								{(beforeCampaign && isUpdate) && <>
									{toStateString(beforeCampaign.getState())}
									&nbsp;→&nbsp;
								</>}
								{toStateString(editingCampaign.getState())}
							</FormGroup>
						</div>
					</div>
				</div>
				<div className="bl_row">
					<div className="bl_col bl_col__8 bl_col__fullWidth bl_col__mobileFullWidth">
						<ul className="bl_panelTab bl_panelTabFixed">
							{STEPS.map((step, idx) =>
								<li key={step.name}
									className={`bl_panelTab_item ${idx == stepIdx ? 'is_active' : ''} ${step.isError && 'is_error'}`}
									data-privilege={step.name}
									onClick={() => {
										setStepIdx(idx);
										window.scrollTo(0, window.scrollY);
									}}
									>{step.title}</li>
							)}
						</ul>
						<div className="bl_panel">
							<StepComponent rewards={rewards} groups={groups} templates={templates} reviewFormCond={reviewFormCond} campaign={editingCampaign} />
							<div className='bl_panelBtmFixed'>
								<div className="el_largeBtnWrap">
									<SubmitButton />
								</div>
								<Link className="el_backLink" to={returnListUrl}>≪一覧へ戻る</Link>
							</div>
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};
export default CampaignEdit;
