import { css } from "@emotion/react";
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { cssVariable } from '../../../css/variable';

interface ErrorMessageProps {
	name: string;
	message?: string;
}
export const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
	const { errors } = useFormContext();

	const style = css`
		font-size: 12px !important;
		margin-top: 4px !important;
		color: ${cssVariable.red};
	`;

	return <HookFormErrorMessage
		errors={errors}
		{...props}
		render={({message}) =>
			<p css={style}>{message}</p>
		}
	/>;
}
export default ErrorMessage;
