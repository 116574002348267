import { css } from "@emotion/react";
import * as React from 'react';
import { cssVariable } from '../../../css/variable';
import { Desc } from '../../atoms/Desc';

interface PageDescProps {
	children: React.ReactNode;
}
export const PageDesc: React.FC<PageDescProps> = (props) => {

	const style = css`
		color: ${cssVariable.main1};
	`;

	return <Desc css={style}>{props.children}</Desc>;
}
export default PageDesc;
