
import * as React from 'react';
import { ListSelectorContext } from '../../organisms/DataTable';
import { CampaignList as CampaignListBase } from './CampaignList';

export const CampaignList: React.FunctionComponent = () => {
	return (
		<>
			<ListSelectorContext>
				<CampaignListBase />
			</ListSelectorContext>
		</>
	);
}
export default CampaignList;