import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './component/App';

function getRoot(): HTMLElement {
	const root = document.getElementById('root');
	if (root) {
		return root;
	}

	const div = document.createElement('div');
	div.id = 'root';
	document.body.appendChild(div);
	return div;
}

window.addEventListener('load', () => {
	ReactDOM.render((
			<BrowserRouter>
				<App />
			</BrowserRouter>
		), getRoot());
});
