import { Notices } from '@sasagase/types';
import * as React from 'react';
import { useAPI, useAPIDataTableLoad, useAppState } from '../../../context';
import { SummaryData } from './ReviewSummary';

const NOTICE_DISP_NUM = 3;

export const useTopWarnings = () => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [warnings, setWarnings] = React.useState<Notices.Content[]|null>(null);

	React.useEffect(() => {
		setWarnings(null);
	}, [state.params.shopId]);

	React.useEffect(() => {
		if (warnings) {
			return;
		}
		const params = { shopId: state.params.shopId };
		return callAPI.notice.getWarnings(params, (err, res) => {
			if (err) {
				return;
			}
			const ns: Notices.Content[] = res.data.map((obj: Record<string, unknown>) => Notices.Content.create(obj));
			setWarnings(ns);
		});
	}, [warnings]);

	return {
		warnings,
		basePath: state.params?.basePath
	};
};

export const useTop = () => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const [notices, setNotices] = React.useState<Notices.Content[]|null>(null);
	const [reviewSummaryData, setReviewSummaryData] = React.useState<SummaryData|null>(null);

	const [rows, { filter, pageInfo, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getCampaignReviewNum,
		{
			apiParams: { shopId: state.params.shopId },
			onFetch: (data) => {
				return [data, undefined];
			},
		}
	);

	React.useEffect(() => {
		filter({ shopId: state.params.shopId }, []);
		setNotices(null);
		setReviewSummaryData(null);
	}, [state.params.shopId]);

	React.useEffect(() => {
		if (notices) {
			return;
		}
		return callAPI.notice.getNotices({ shopId }, (err, res) => {
			if (err) {
				return;
			}
			const ns: Notices.Content[] = res.data.map((obj: Record<string, unknown>) => Notices.Content.create(obj));
			setNotices(ns.slice(0, NOTICE_DISP_NUM));
		});
	}, [notices]);

	React.useEffect(() => {
		if (reviewSummaryData) {
			return;
		}
		return callAPI.yReview.getTopReviewSummary({ shopId }, (err, res) => {
			if (err) {
				return;
			}
			setReviewSummaryData(res.data);
		});
	}, [reviewSummaryData]);

	return {
		rows,
		pageInfo,
		isLoading,
		notices,
		reviewSummaryData
	};
};
export default useTop;
