import * as React from 'react';
import { isUserCSVField, useAvailableCSVFormats, useAvailableOptions } from '../../../lib/shopCustom';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { ErrorMessage, FormGroup, FormInputWrap, Input, InputCheck, InputRadio, Select } from '../../organisms/Form';
import { ImageUpload } from '../../organisms/ImageUpload';
import { RewardItemCSVUserSettingEdit } from './RewardItemCSVUserSettingEdit';
import { FormValues, nameList } from './useRewardEdit';

export interface RewardItemEditProps {
	names: Record<keyof FormValues, string>;
	imageUrl?: string;
	handleChangeFile: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	handleClickDeleteImage: (name: typeof nameList[number]) => () => void;
	nonNegative: React.ChangeEventHandler<HTMLInputElement>;
}

export const RewardItemEdit: React.FunctionComponent<RewardItemEditProps> = (props) => {
	const {
		names,
		imageUrl,
		handleChangeFile,
		handleClickDeleteImage,
		nonNegative,
	} = props;

	const availableCSVFormats = useAvailableCSVFormats();
	const userFieldCSVFormats = availableCSVFormats.filter((csv) => csv.columns.some(isUserCSVField));
	const availableOptions = useAvailableOptions();

	return (
		<>
			<FormGroup>
				<FormGroup>
					<FormLabel>プレゼント品画像</FormLabel>
					<ImageUpload
						imageUrl={imageUrl}
						name={names.image}
						onChange={handleChangeFile}
						onDelete={handleClickDeleteImage('image')}
						inputDesc={<>
							※推奨画像サイズは600ピクセル×600ピクセル以内です<br />
							※ファイル形式は、GIF、JPEG、PNG形式のみです
						</>}
						/>
					<ErrorMessage name={names.image} />
				</FormGroup>
				<FormGroup>
					<FormLabel required>個数の指定</FormLabel>
					<InputRadio name={names.limitedType} value="unlimited" label="無制限" /><br />
					<InputRadio name={names.limitedType} value="limited" label={
							<FormInputWrap>
								<span>個数を指定</span>
								<Input className="el_inlineInputL" type="text" name={names.quantity} onChange={nonNegative} />
								<span>個限定</span>
							</FormInputWrap>
						} />
					<ErrorMessage name={names.quantity} message="個数を入力してください" />
					<InputDesc>※「個数を指定」した場合には、在庫数が0になった時点でユーザーは当該のプレゼント品の選択ができなくなります。また、キャンペーンの特典がすべて選択できない状態 (特典設定したプレゼント品の在庫数がすべて0の状態)になった時点で当該キャンペーンは自動的に終了となります。クレーム等の原因になりかねませんので、個数を指定する場合は十分ご注意ください。</InputDesc>
				</FormGroup>
				<FormGroup>
					<FormLabel required>送り先CSV形式</FormLabel>
					<div className="el_selectWrap">
						<Select name={names.csvFormat} options={Object.fromEntries(availableCSVFormats.map(csv => [csv.id, csv.name]))} />
					</div>
				</FormGroup>
				<FormGroup>
					<FormLabel required>CSV依頼主項目</FormLabel>
					<InputRadio name={names.csvClientType} value="orderer" label="注文者と同じ" />
					<InputRadio name={names.csvClientType} value="user" label="ユーザ設定値" />
					<InputDesc>※「商品購入時と同じ配送先」に特典を送る場合であって、特典対象注文の「注文者」と「お届け先」が異なったときに、配送の依頼主に設定する値を指定します。</InputDesc>
				</FormGroup>
				<FormGroup>
					<FormLabel>CSVユーザ設定値</FormLabel>
					{userFieldCSVFormats.map(csv =>
						<RewardItemCSVUserSettingEdit key={csv.id} names={names} csv={csv} />
					)}
				</FormGroup>
				{Object.keys(availableOptions).length > 0 &&
					<FormGroup>
						<FormLabel>特典申込フォームオプション</FormLabel>
						<div className="bl_panel_indent">
							{availableOptions.link &&
								<>
									<label className="bl_label">
										<span>特典詳細ページURL</span>
										<Input className="el_inlineInput textL" placeholder='https://shopping.geocities.jp/' type="text" name={`${names.optionAttributes}.link`} rules={{
											pattern: /^\s*https?:\/\/([^/]+\.)?shopping\.geocities\.jp\//,
											setValueAs: (val: any) => val.trim(),
										}} />
									</label>
									<br/>
									<ErrorMessage name="optionAttributes.link" message="YahooトリプルのURLのみ設定可能です" />
								</>
							}
							{availableOptions.kifuda && <InputCheck className="el_checkMark" name={`${names.optionAttributes}.kifuda`}>木札</InputCheck>}
							{availableOptions.maruKifuda && <InputCheck className="el_checkMark" name={`${names.optionAttributes}.maruKifuda`}>丸木札(女の子用)</InputCheck>}
							{availableOptions.maruKifudaM && <InputCheck className="el_checkMark" name={`${names.optionAttributes}.maruKifudaM`}>丸木札(男の子用)</InputCheck>}
							{availableOptions.namaebata && <InputCheck className="el_checkMark" name={`${names.optionAttributes}.namaebata`}>名前旗</InputCheck>}
							{availableOptions.namaekonori && <InputCheck className="el_checkMark" name={`${names.optionAttributes}.namaekonori`}>konori吹き流しパーツ名入れ</InputCheck>}
							{availableOptions.namaemagnet && <InputCheck className="el_checkMark" name={`${names.optionAttributes}.namaemagnet`}>お名前マグネット</InputCheck>}
						</div>
					</FormGroup>
				}
			</FormGroup>
		</>
	);
};
export default RewardItemEdit;