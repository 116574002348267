import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface TextareaProps {
	name: string;
	rules?: Record<string, unknown>;
	className?: string;
	rows?: number;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
}
export const Textarea: React.FC<TextareaProps> = (props) => {
	const { register } = useFormContext();

	return (
		<textarea
			name={props.name}
			ref={props.rules? register(props.rules) : register}
			className={props.className ?? 'el_textarea'}
			rows={props.rows ?? 3}
			defaultValue={props.defaultValue}
			disabled={props.disabled}
			placeholder={props.placeholder} />
	);
}
export default Textarea;
