import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { ErrorMessage, FormGroup, Textarea } from '../../organisms/Form';
import { contextWizard } from './useCampaignWizard';

export const CampaignWizardS3InputSkus: React.FC = () => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const wizardValues = ctx.values;
	const skuName = wizardValues.groupType == 'include' ? 'skus' : 'excludeSkus';
	const skuTtl = wizardValues.groupType == 'include' ? '対象商品' : '除外商品';

	return (
		<FormGroup>
			<FormLabel>{skuTtl}の商品コードを入力してください</FormLabel>
			<Textarea name={skuName} rows={20} />
			<ErrorMessage name={skuName} />
			<InputDesc>※半角改行またはコンマ区切り</InputDesc>
		</FormGroup>
	);
}
export default CampaignWizardS3InputSkus;
