import * as React from 'react';
import { ErrorMessage, Form, Input, SubmitButton } from '../../organisms/Form';
import useAccountRecover from './useAccountRecover';

const initValues = {
	mail: '',
};
export type FormValues = typeof initValues;

export const AccountRecover: React.FunctionComponent = () => {
	const {
		methods,
		handleRecover,
		result,
	} = useAccountRecover({initValues});

	return (
		<>
			<div className="bl_inviteForm">
				<div className="bl_loginFormRow">
					{result === null &&
						<p className="txt_blue">
							パスワードをお忘れですか？&nbsp;メールアドレスを入力してください。新しいパスワードを発行するリンクを送信いたします。
						</p>
					}
					{result === true &&
						<p className="txt_blue">
							<b>確認メールの送信が完了しました。</b><br />
							入力されたメールアドレス宛に「【ラクラクあつまレビュー】パスワード再設定手続き」という件名のメールを送信しました。<br />
							メールの内容に従って、次のステップへ進んでください。
						</p>
					}
					{result === false &&
						<p className="el_errorMessage">
							メールアドレスに誤りがあるか、このメールアドレスは登録されていません。
						</p>
					}
				</div>
				<Form methods={methods} onSubmit={handleRecover}>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel" htmlFor="mail">メールアドレス</label>
						<Input id="mail" type="email" name="mail" placeholder="aaa@aa.co.jp"
							rules={{
								required: 'メールアドレスを入力してください',
								pattern: {
									value: /\S+@\S+.\S+/,
									message: 'メールアドレスを入力してください'
								}
							}} />
						<ErrorMessage name="mail" />
					</div>
					<SubmitButton className="el_largeBtn__fullWidth" disabled={result === true}>
						{result === true && <>確認メール送信完了</>}
						{!result && <>新しいパスワードを取得</>}
					</SubmitButton>
				</Form>
			</div>
		</>
	);
};
export default AccountRecover;