import { yReview } from "@sasagase/types";
import { cast, columns } from "../libs";

// yahooは商品レビューしかCSVをダウンロードできない

const COLUMN_NAME: Record<string, string> = {
	'評価日': 'date',
	'評価点数': 'point',
	'商品名': 'name',
	'商品コード': 'sku',
	'注文ID': 'orderNumber',
	'コメントタイトル': 'title',
	'コメント内容': 'content',
};
const COLUMN_CAST: Record<string, (value: string) => any> = {
	'point': Number,
	'date': (v) => {
		const [, year, month, day] = v.match(/(\d{4})(\d{2})(\d{2})$/) ?? [];
		return `${year}/${month}/${day}`;
	},
};

export const yahoo = {
	parseOptions: {
		columns: columns(COLUMN_NAME),
		cast: cast(COLUMN_CAST),
		relaxQuotes: true,
	},
	toReview: (record: Record<string, unknown>) => yReview.schemaReview.parse(record),
};
export default yahoo;
