import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAvailableCSVFormats } from '../../../lib/shopCustom';
import { Modal } from '../../organisms/Modal';

export interface RequestTransactModalCloseEvent {
	isCancel: boolean;
	csvFormat?: string;
	isZipFile: boolean;
	isFormatGroup?: string;
}

interface RequestTransactModalProps {
	onClose?: (ev: RequestTransactModalCloseEvent) => void;
}

export const RequestTransactModal: React.FunctionComponent<RequestTransactModalProps> = (props) => {
	const { register, getValues } = useForm({
		defaultValues: {
			csvFormat: '',
			isFormatGroup: '',
		},
	});
	const availableCSVFormats = useAvailableCSVFormats();

	const handleCancel = () => {
		props.onClose?.({
			isCancel: true,
			isZipFile: false,
		});
	};

	const handleTransact = () => {
		const csvFormat = getValues('csvFormat') || undefined;
		const isZipFile = getValues('isZipFile') ? true : false;
		const isFormatGroup = getValues('isFormatGroup') || undefined;
		props.onClose?.({
			isCancel: false,
			csvFormat,
			isZipFile,
			isFormatGroup,
		});
	};

	return (
		<Modal onClickBackground={handleCancel}>
			<div className="bl_modal_header">
				<h1 className="bl_modal_ttl">特典受付処理</h1>
				<button className="el_closeBtn" onClick={handleCancel}></button>
			</div>
			<div className="bl_panel_row mt_16 mb_0">
				<h3 className="el_lv3Heading">送り先CSV形式</h3>
				<div className="el_selectWrap">
					<select name="csvFormat" ref={register}>
						<option value="">変更しない</option>
						{availableCSVFormats.map(csv =>
							<option key={csv.id} value={csv.id}>{csv.name}</option>
						)}
					</select>
				</div>
				<h3 className="el_lv3Heading mt_16 ">ダウンロード形式</h3>
				<label className="bl_label">
					<input className="el_checkMark" type="checkbox" name="isZipFile" ref={register} />
					zipで圧縮してダウンロード
				</label>
				<h3 className="el_lv3Heading mt_16">まとめてダウンロード</h3>
				<label className="bl_label">
					<input className="el_checkMark" type="checkbox" name="isFormatGroup" ref={register} />
					同じ送り先CSV形式を1ファイルにまとめてダウンロード
				</label>
			</div>
			<div className="bl_panel_row mt_16 mb_0">
				<button className="el_btn mr_8" type="button" onClick={handleTransact}>実行</button>
				<button className="el_btnInv mr_8" type="button" onClick={handleCancel}>キャンセル</button>
			</div>
		</Modal>
	);
}
export default RequestTransactModal;