import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, FormIndent, FormInputWrap, Input, InputDate, InputRadio, SubmitButton } from '../../organisms/Form';
import useUserEdit from './useUserEdit';

const initValues = {
	name: '',
	mail: '',
	yReview_reviewCheckTool: 'hide' as const,
	yReview_labor: 'hide' as const,
	yReview_management: 'hide' as const,
	yReview_system: 'hide' as const,
	account_expired: 'disabled' as const,
	expired_at: null,
	account_state: 'active' as const
};
export interface FormValues {
	name: string;
	mail: string;
	yReview_reviewCheckTool: 'hide' | 'read';
	yReview_labor: 'hide' | 'read' | 'write';
	yReview_management: 'hide' | 'read' | 'write';
	yReview_system: 'hide' | 'write';
	account_expired: 'disabled' | 'enabled';
	expired_at: Date | null;
	account_state: 'active' | 'suspend';
}

export const UserEdit: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		returnListUrl,
	} = useUserEdit({ initValues });

	const { getValues, setValue } = methods;

	return (
		<>
			<PageTitle>ユーザーアカウント設定</PageTitle>
			<PageDesc>ユーザーアカウントの新規作成、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<RequiredDesc />
						<Form methods={methods} onSubmit={handleSave}>
							<FormGroup>
								<FormLabel>ユーザー名</FormLabel>
								<Input type="text" name="name" readOnly />
							</FormGroup>
							<FormGroup>
								<FormLabel>メールアドレス</FormLabel>
								<Input type="text" name="mail" readOnly />
							</FormGroup>
							<FormGroup>
								<FormLabel required>権限</FormLabel>
								{/* Yahoo版はショップによって可変のため一旦非表示 
								<FormGroup>
									<FormLabel item>レビューチェックツール</FormLabel>
									<InputRadio name="yReview_reviewCheckTool" value="hide" label="非表示" />
									<InputRadio name="yReview_reviewCheckTool" value="read" label="表示" />
								</FormGroup> */}
								<FormGroup>
									<FormLabel item>レビュー待ち状況・特典申込受付</FormLabel>
									<InputRadio name="yReview_labor" value="hide" label="非表示" />
									<InputRadio name="yReview_labor" value="read" label="閲覧のみ" />
									<InputRadio name="yReview_labor" value="write" label="編集可" />
								</FormGroup>
								<FormGroup>
									<FormLabel item>レビュー特典登録・メール設定</FormLabel>
									<InputRadio name="yReview_management" value="hide" label="非表示" />
									<InputRadio name="yReview_management" value="read" label="閲覧のみ" />
									<InputRadio name="yReview_management" value="write" label="編集可" />
								</FormGroup>
								<FormGroup className="mb_32">
									<FormLabel item>ユーザーアカウント設定・Yahooシステム設定</FormLabel>
									<InputRadio name="yReview_system" value="hide" label="非表示" />
									<InputRadio name="yReview_system" value="write" label="編集可" />
									<InputDesc>※ユーザーアカウント設定・Yahooシステム設定は、「編集可」を選択するとその権限を付与されたユーザーも、ユーザーアカウント設定および Yahooシステム設定内の情報を自由に閲覧・編集できるようになりますのでご注意ください。</InputDesc>
								</FormGroup>
							</FormGroup>
							<FormGroup>
								<FormLabel required>アカウント有効期限</FormLabel>
								<InputRadio name="account_expired" value="disabled" label="設定しない" onChange={() => setValue('expired_at', null)} /><br />
								<InputRadio name="account_expired" value="enabled" label="設定する" /><br />
								<FormIndent>
									<FormInputWrap>
										<InputDate
											mode="date"
											name="expired_at"
											rules={{
												validate: (date: any) => {
													return (getValues('account_expired') == 'disabled' || date >= new Date()) || 'アカウント有効期限は現在より過去は設定できません。'
												}
											}}
											popperClassName="datePickerPopper"
											minDate={new Date()}
											/>&nbsp;まで
									</FormInputWrap>
								</FormIndent>
								<ErrorMessage name="expired_at" />
							</FormGroup>
							<FormGroup>
								<FormLabel required>アカウントのアクション</FormLabel>
								<InputRadio name="account_state" value="active" label="アクティブにする" /><br />
								<InputRadio name="account_state" value="suspend" label="停止する" />
							</FormGroup>
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
							<Link className="el_backLink" to={returnListUrl}>≪一覧へ戻る</Link>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default UserEdit;