import * as React from 'react';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { ErrorMessage, FormGroup, Textarea } from '../../organisms/Form';
import OriginalTagModal from './OriginalTagModal';
import { MailEditRowFormValues } from './useMailEditRow';

interface InputBodySingleProps {
	disabled?: boolean;
	names: Record<keyof MailEditRowFormValues, string>;
}

export function InputBodySingle(props: InputBodySingleProps): React.ReactElement {
	const {
		names,
	} = props;

	const [enableTagModal, setEnableTagModal] = React.useState<boolean>(false);

	return (
		<>
			{enableTagModal && <OriginalTagModal onClose={() => setEnableTagModal(false)} />}
			<FormGroup>
				<FormLabel required>メール本文</FormLabel>
				<InputDesc noMargin>
					フリーテキスト内には独自タグを入力できます。
					<Button className="el_btnBlueInv" type="button" onClick={() => setEnableTagModal(true)} disabled={enableTagModal}>独自タグ一覧</Button>
				</InputDesc>
				<Textarea name={names.body} rows={3} rules={{ required: 'メール本文を入力してください' }} disabled={props.disabled} />
				<ErrorMessage name={names.body} />
			</FormGroup>
		</>
	);
}
export default InputBodySingle;