type Key = string | symbol;
type KeySelector<T, K extends Key> = (element: T, index: number) => K;

export function groupObject<T, K extends Key>(items: T[], keySelector: KeySelector<T, K>): Record<K, T[] | undefined>;
export function groupObject<T, KL extends K, K extends Key>(items: T[], keySelector: KeySelector<T, K>, keys: readonly KL[]): Record<KL, T[]> & Record<K, T[] | undefined>;
export function groupObject<T, KL extends K, K extends Key>(items: T[], keySelector: KeySelector<T, K>, keys: readonly KL[] = []): Record<KL, T[]> & Record<K, T[] | undefined> {
	const map = new Map<K, T[]>(keys.map(key => [key, []]));
	for (const [i, elem] of items.entries()) {
		const key = keySelector(elem, i);
		
		let array = map.get(key);
		if (!array) {
			array = [];
			map.set(key, array);
		}
		array.push(elem);
	}
	return Object.fromEntries(map) as Record<KL, T[]> & Record<K, T[] | undefined>;
}
export default groupObject;
