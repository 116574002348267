import { User } from '@sasagase/types';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAPI } from "../../../context";

type AccountActivateParams = {
	token?: string;
}

export const useAccountActivate = () => {
	const params = useParams<AccountActivateParams>();
	const navigate = useNavigate();
	const notFoundPath = '/notfound';
	const callAPI = useAPI();

	React.useEffect(() => {
		if (!params.token) {
			return;
		}

		const activateUser = async (token: string) => {
			try {
				const resActivate = await callAPI.user.activateToken({ token });
				if (!resActivate || !resActivate.data) {
					navigate(notFoundPath);
					return;
				}

				const resUser = await callAPI.user.getUserByToken({ token });
				if (!resUser || !resUser.data) {
					navigate(notFoundPath);
					return;
				}

				const user = User.create(resUser.data);
				if (!user.setPass) {
					navigate(`/account/regist/${token}`);
					return;
				}

			} catch (err) {
				navigate(notFoundPath);
				return;
			}
		};

		void activateUser(params.token);
	}, [params.token]);

	const handleClickStart = () => {
		navigate("/");
	}

	return {
		handleClickStart
	};
};
export default useAccountActivate;