import * as React from 'react';
import { Link } from 'react-router-dom';
import { useItemGroupEdit } from '../../../hooks';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { GroupListTable } from './GroupListTable';
import { useGroupList } from './useGroupList';

export const GroupList: React.FunctionComponent = () => {
	const {
		tableProps,
		handleClickDelete,
		campaignMap,
		toEditURL,
		toCopyURL,
	} = useGroupList();

	const {
		getItemGroupRequireDesc
	} = useItemGroupEdit();

	return (
		<>
			<PageTitle>対象商品グループ登録</PageTitle>
			<PageDesc>キャンペーン対象商品グループの新規登録、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<Link className="el_btn el_btn__plus" type="button" to={toEditURL('new')}>新規追加</Link>
							<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
						</div>
						<GroupListTable
							{...tableProps}
							campaignMap={campaignMap}
							toEditURL={toEditURL}
							toCopyURL={toCopyURL}
							getItemGroupRequireDesc={getItemGroupRequireDesc}
							/>
					</div>
				</div>
			</div>
		</>
	);
}
export default GroupList;