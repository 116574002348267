import * as React from 'react';
import { define, dynamic, enums, is, type } from 'superstruct';
import { FormLabel } from '../../molecules/FormLabel';
import { ErrorMessage, FormGroup, InputRadio } from '../../organisms/Form';

export const CampaignWizardS3SelectGroupTypeStruct = dynamic((values: any) => {
	const groupType = values.groupType || '';

	return type({
		groupType: define<string>('groupType', () => is(groupType, enums(['include', 'all', 'exclude'])) || 'レビュー特典の対象商品の指定方法を選択してください'),
	});
});

export const CampaignWizardS3SelectGroupType: React.FC = () => {
	return (
		<FormGroup>
			<FormLabel>レビュー特典の対象商品の指定方法を選択してください</FormLabel>
			<InputRadio name="groupType" value="include" label="個別で商品(商品コード)を指定する" /><br />
			<InputRadio name="groupType" value="all" label="全商品を対象にする" /><br />
			<InputRadio name="groupType" value="exclude" label="全商品を対象にするが一部除外する商品(商品コード)を指定する" /><br />
			<ErrorMessage name='groupType' />
		</FormGroup>
	);
}
export default CampaignWizardS3SelectGroupType;
