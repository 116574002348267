import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { ErrorMessage, Form, FormGroup, FormIndent, Input, SubmitButton } from '../../organisms/Form';
import { MailEditRow } from '../../organisms/MailEditRow';
import useNotCoveredEdit from './useNotCoveredEdit';

const initValues = {
	name: '',
	subject: '',
	body: '',
	signature: '',
	email: '',
	isEnable: 'false',
	isNonYahooMemberEnabled: 'false'
};

export const NotCoveredEdit: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		returnListUrl,
	} = useNotCoveredEdit({ initValues });

	return (
		<>
			<PageTitle>特典対象外商品用メール</PageTitle>
			<PageDesc>特典対象外の商品を購入したお客様に送信するメールの新規作成、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<FormLabel headline>設定</FormLabel>
						<FormIndent><RequiredDesc /></FormIndent>
						<Form methods={methods} onSubmit={handleSave}>
							<FormIndent>
								<FormGroup>
									<FormLabel required>テンプレート名</FormLabel>
									<Input type="text" name="name" placeholder="【グループ名】を入力" rules={{ required: 'グループ名を入力してください' }} />
									<ErrorMessage name="name" />
								</FormGroup>
								<FormGroup>
									<FormLabel required>メール件名</FormLabel>
									<Input type="text" name="subject" rules={{ required: 'メール件名を入力してください' }} />
									<ErrorMessage name="subject" />
								</FormGroup>
							</FormIndent>
							<MailEditRow isBodyOnlyTextarea />
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
							<Link className="el_backLink" to={returnListUrl}>≪一覧へ戻る</Link>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default NotCoveredEdit;