import { User } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAPI, useAppState, userSetter } from "../../../context";
import { FormValues as PasswordEditFormValues } from './PasswordEdit';
import { FormValues as UserEditFormValues } from './UserEdit';

interface ChangeUserParams {
	name: string;
	mail: string;
}

export const useManagement = (initValues?: UserEditFormValues | PasswordEditFormValues) => {
	const [state, setState] = useAppState();
	initValues ??= {
		name: state.user?.name ?? '',
		mail: state.user?.mail ?? '',
	};
	const callAPI = useAPI();
	const [resultPasswordSave, setResultPasswordSave] = React.useState<string>('');
	const formMethods = useForm<UserEditFormValues | PasswordEditFormValues>({
		defaultValues: {
			...initValues,
		}
	});

	const toSaveAttr = (values: Partial<UserEditFormValues>): ChangeUserParams => {
		return {
			name: values.name ?? '',
			mail: values.mail ?? '',
		};
	};

	const handleUserSave: SubmitHandler<UserEditFormValues> = async (values) => {
		try {
			const params = {
				user: toSaveAttr(values),
			}
			const res = await callAPI.user.changeUser(params);
			if (res?.data) {
				const user = new User({
					capabilities: state.user?.capabilities,
					id: state.user?.id,
					name: values.name,
					mail: values.mail,
				});
				setState(userSetter(user));
			}
			return res ? Boolean(res.data) : false;
		} catch (err) {
			return false;
		}
	};

	const handlePasswordSave: SubmitHandler<PasswordEditFormValues> = async (values) => {
		try {
			const params = {
				oldPass: values.oldPass,
				newPass: values.newPass
			}
			setResultPasswordSave('');
			const res = await callAPI.user.changePassword(params);
			if (!res?.data) {
				setResultPasswordSave('現在のパスワードに誤りがあります');
			}
			return res ? Boolean(res.data) : false;
		} catch (err) {
			return false;
		}
	};

	return {
		state,
		methods: formMethods,
		handleUserSave,
		handlePasswordSave,
		resultPasswordSave,
	};
};
export default useManagement;