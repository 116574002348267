import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { PageInfo } from '@sasagase/types';
import * as React from 'react';
import { Column, DataTable } from '../../organisms/DataTable';

interface TotalReviewTableProps {
	rows?: APIResponses.GetCampaignReviewNumResponse;
	pageInfo?: PageInfo;
	isLoading: boolean;
}

export const TotalReviewTable: React.FunctionComponent<TotalReviewTableProps> = (props) => {

	// テーブル固有のCSS指定
	// TODO: ここ以外で指定できないか要検討
	const tableStyle = css`
		height: 792px;

		th,
		td {
			padding: 16px 8px;
		}

		td:nth-of-type(2),
		td:nth-of-type(3) {
			font-weight: bold;
			font-size: 18px;

			.el_unit {
				margin-left: 8px;
				font-size: 14px;
			}
		}

		&::-webkit-scrollbar-track {
			margin-top: 50px;
		}
	`;

	const reviewNumBodyTemplate = (reviewNum: number) => {
		return <>{reviewNum}<span className="el_unit">件</span></>
	}
	const itemReviewNumBodyTemplate = (rowData: any) => {
		return reviewNumBodyTemplate(rowData.itemReviewNum);
	}
	const shopReviewNumBodyTemplate = (rowData: any) => {
		return reviewNumBodyTemplate(rowData.shopReviewNum);
	}

	return (
		<DataTable css={tableStyle}
			rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}>
			<Column style="0 1 532px left flex-start" field="name" header="キャンペーン名"></Column>
			<Column style="1 0 94px center flex-end" field="itemReviewNum" header="商品" bodyTemplate={itemReviewNumBodyTemplate}></Column>
			<Column style="1 0 94px center flex-end" field="shopReviewNum" header="ショップ" bodyTemplate={shopReviewNumBodyTemplate}></Column>
		</DataTable>
	);
}
export default TotalReviewTable;