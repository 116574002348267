import * as React from 'react';
import { Modal } from '../../organisms/Modal';
import { CampaignWizard } from '../CampaignEdit';

interface CampaignWizardModalProps {
	onClose: () => void;
}

export const CampaignWizardModal: React.FunctionComponent<CampaignWizardModalProps> = (props) => {
	const handleClickClose = () => {
		if (window.confirm(`ウィザードを閉じると入力フォームの内容が削除されます。よろしいですか？`)) {
			props.onClose();
		}
	};

	return (
		<Modal onClickBackground={handleClickClose} className='bl_modal__campaignWizard'>
			<div className="bl_modal_header">
				<h1 className="bl_modal_ttl">キャンペーン登録</h1>
				<button type="button" className="el_closeBtn" onClick={handleClickClose}></button>
			</div>
			<CampaignWizard onClose={props.onClose} />
		</Modal>
	);
}
export default CampaignWizardModal;