import { useAPI, useAppState } from "../../../context";
import windowOpen from "../../../lib/windowOpen";

const AUTHORIZATION_ENDPOINT = 'https://auth.login.yahoo.co.jp/yconnect/v2/authorization';
const CALLBACK_PATH = '/callback/yahoo';

export interface UseYahooIDConnectOptions {
	width?: number;
	height?: number;
}

export function useYahooIDConnect(opt: UseYahooIDConnectOptions = {}) {
	const [state] = useAppState();
	const callAPI = useAPI();

	const {
		width = 500,
		height = 400,
	} = opt;
	const openYahooAuthorization = async () => {
		const shopId = state.shop?.id;
		if (!shopId) {
			alert('店舗情報の取得に失敗しました。');
			return;
		}

		// このURLがyahooアプリケーションの「コールバックURL」に設定されている必要がある
		const uri = new URL(CALLBACK_PATH, location.origin).toString();

		const res = await callAPI.yahoo.beginOAuth({ shopId, uri }).catch(() => null);
		if (!res) {
			alert('Yahoo! ID連携の開始に失敗しました。')
			return;
		}
		const session = res.data;

		// https://developer.yahoo.co.jp/yconnect/v2/authorization_code/authorization.html
		const url = new URL(AUTHORIZATION_ENDPOINT);
		const params = new URLSearchParams({
			'response_type': 'code',
			'bail': '1',
			'scope': 'openid',
			'display': 'popup',
			'prompt': 'login',
			'client_id': session.appId,
			'redirect_uri': uri,
			'state': session.state,
			'nonce': session.nonce,
			'code_challenge': session.codeChallenge,
			'code_challenge_method': 'S256'
		});
		url.search = params.toString();

		windowOpen({
			url,
			width,
			height,
			left: (window.screen.availWidth - width) / 2,
			top: (window.screen.availHeight - height) / 2,
			noopener: true,
		});
	};
	return {
		openYahooAuthorization,
	};
}
export default useYahooIDConnect;
