import { ItemGroup } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useAPI, useAppState } from "../../../context";
import { useItemGroupEdit } from '../../../hooks';
import { FormValues } from './GroupEdit';

type GroupEditParams = {
	groupId?: string;
	shopId?: string;
	flag?: string;
}

interface useGroupEditProps {
	initValues: FormValues;
}

export const useGroupEdit = (props: useGroupEditProps) => {
	const params = useParams<GroupEditParams>();
	const isNew = params.groupId == 'new';
	const isCopy = params.flag === 'copy';

	const [state] = useAppState();
	const shopId = state.params.shopId;
	const location = useLocation();
	const urlParamSeq = new URLSearchParams(location.search).get("seq");
	const callAPI = useAPI();
	const navigate = useNavigate();
	const [groups, setGroups] = React.useState<ItemGroup[] | null>(null);
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
		shouldUnregister: false,
	});
	const { reset } = formMethods;

	const {
		sanitizeSku,
		getItemGroupRequireDesc
	} = useItemGroupEdit();

	const toInstance = (values: Partial<FormValues>, id: string, map: Map<string, ItemGroup>): ItemGroup => {
		return ItemGroup.create({
			id,
			name: values.name,
			isRef: false,
			isAll: values.isAll == 'true',
			sku: values.isAll == 'true' ? [] :
				String(values.skus).split(/\s*[,\t\n]\s*/).map(sku => sanitizeSku(sku.trim())).filter(Boolean),
			excludeSku: String(values.excludeSkus).split(/\s*[,\t\n]\s*/).map(sku => sanitizeSku(sku.trim())).filter(Boolean),
			childGroups: values.isAll == 'true' ? [] :
				values.childGroupIds?.map(id => map.get(id)),
			excludeChildGroups: values.excludeChildGroupIds?.map(id => map.get(id)),
		});
	};

	const toValues = (group: ItemGroup | undefined, isNew: boolean, isCopy: boolean): FormValues => {
		if (isNew || !group) {
			return props.initValues;
		}
		return {
			...props.initValues,
			name: isCopy ? group.name + ' のコピー' : group.name,
			isAll: group.isAll ? 'true' : 'false',
			inputSkus: group.sku.join(','),
			skus: group.sku.join(','),
			inputExcludeSkus: group.excludeSku.join(','),
			excludeSkus: group.excludeSku.join(','),
			childGroupIds: group.childGroups.map(group => group.id),
			excludeChildGroupIds: group.excludeChildGroups.map(group => group.id),
		};
	};

	React.useEffect(() => {
		if (!shopId) {
			return;
		}
		return callAPI.yReview.getGroups({ shopId, seq: urlParamSeq ?? undefined }, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const groups: ItemGroup[] = result.data.map((obj: Record<string, unknown>) => new ItemGroup(obj));
				setGroups(groups);

				const self = groups.find(group => group.id == params.groupId);
				reset(toValues(self, isNew, isCopy));
			}
		});
	}, [shopId, params.groupId]);

	const handleSave: SubmitHandler<FormValues> = async (values): Promise<boolean> => {
		if (!groups || !params.shopId || !params.groupId) {
			return false;
		}

		try {
			const id = isNew || isCopy ? uuid() : params.groupId;
			const map = new Map(groups.map(g => [g.id, g]));
			const group = toInstance(values, id, map);

			if (isNew || isCopy) {
				const res = await callAPI.yReview.postGroup({ shopId, group });
				if (res) {
					const params = new URLSearchParams({ seq: res.data.seq });
					const to = `${state.params.basePath}/reward/group/${id}?` + params.toString();
					navigate(to, {state: {newId: id}, replace: true});
					return true;
				}
			} else {
				await callAPI.yReview.putGroup({ shopId, groupId: params.groupId, group });
				return true;
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	const returnListUrl = `${state.params.basePath}/reward/group`;

	const groupNames: Record<string, string> = {};
	for (const group of (groups || [])) {
		if (!group.hasDecendant(params.groupId)) {
			const desc = getItemGroupRequireDesc(group);
			groupNames[group.id] = `${group.name}【${desc.join(',')}】`;
		}
	}

	return {
		methods: formMethods,
		handleSave,
		returnListUrl,
		groupNames,
	};
};
export default useGroupEdit;