import { useFormContext } from 'react-hook-form';
import { date, define, dynamic, enums, is, number, optional, size, string, type, union } from 'superstruct';
import { DateFields, DaysField, toDate } from '../../organisms/Form';

export const DEADLINEDAYS_MIN = 14;
export const DEADLINEDAYS_MAX = 700;

export type cutCheck = 'date' | 'days' | 'never';
type cutCheckObj = {
	"isCutDate": boolean,
	"isCutDays": boolean,
}
export interface CampaignEditFormStep1FormValue {
	priority: number;

	name: string;
	begin: DateFields;
	end: DateFields;
	isNeverEnd: 'true' | 'false';
	deadlineDate: DateFields;
	deadlineDays: DaysField;
	isCutCheck: cutCheck;
	reviewRequired: 'item' | 'shop' | 'both' | 'any';
	canManyRewards: 'true' | 'false';

	inProgressDate?: number;
	waitingReviewDate?: number;
	finishedDate?: number;
	cancelledDate?: number;
}


export const toCutCheck = (data: cutCheckObj): cutCheck => {
	if (data.isCutDate) {
		return 'date';
	} else if (data.isCutDays) {
		return 'days';
	} else {
		return 'never';
	}
}

function requiredString(val: unknown): boolean {
	return is(val, size(string(), 1, Infinity));
}
const dateValidator = (targetDate: Date, compareResult: boolean, ErrorMessage: string) => {
	if (targetDate.getTime()) {
		return compareResult || ErrorMessage;
	} else {
		return '有効な日付を入力してください';
	}
}

export const CampaignEditFormStep1Struct = dynamic((values: any) => {
	const isNeverEnd = values.isNeverEnd == 'true';
	const isDeadlineDate = values.isCutCheck == 'date';
	const isDeadlineDays = values.isCutCheck == 'days';

	const begin = toDate(values.begin);
	const end = toDate(values.end);
	const deadlineDate = toDate(values.deadlineDate);
	const deadlineDays = Number(values.deadlineDays);

	const anyDateField = union([string(), date(), number()]);
	const anyDaysField = union([string(), number()]);

	const isStarted = Boolean(values.inProgressDate);
	const isFinishedCamp = Boolean(values.waitingReviewDate);
	const isFinishedAll = Boolean(values.finishedDate || values.cancelledDate);

	return type({
		priority: number(),

		name: define<string>('name', (val) => requiredString(val) || 'キャンペーン名を入力してください'),
		begin: isStarted ? anyDateField :
			define<DateFields>('begin', () =>
				dateValidator(begin, begin > new Date(), '現在以降の日時を指定してください')
			),
		end: isFinishedCamp || isNeverEnd ? anyDateField :
			define<DateFields>('end', () => {
				const endValid = dateValidator(end, end >= begin, '開始日以降の日時を指定してください')
				// キャンペーン実施中で終了日時に過去の日時は不可
				const futureDateValid = isStarted && !isFinishedCamp ? dateValidator(end, end > new Date(), '現在日以降の日時を指定してください') : true;
				return endValid === true ? futureDateValid === true ? true : futureDateValid : endValid;
			}),

		deadlineDate: isFinishedAll || !isDeadlineDate ? anyDateField :
			define<DateFields>('deadline', () => {
				const endValid = dateValidator(deadlineDate, deadlineDate >= end, 'キャンペーン終了以降の日時を指定してください');
				// キャンペーン実施中で終了日時に過去の日時は不可
				const futureDateValid = isStarted && !isFinishedCamp ? dateValidator(deadlineDate, deadlineDate > new Date(), '現在日以降の日時を指定してください') : true;
				return endValid === true ? futureDateValid === true ? true : futureDateValid : endValid;
			}),
		deadlineDays: isFinishedAll || !isDeadlineDays ? anyDaysField :
			define<DaysField>('deadline', () =>
				!deadlineDays ? "有効な日数を入力してください" :
				deadlineDays < DEADLINEDAYS_MIN ? '最小指定日数よりも短い期間は指定できません。14日以上を指定してください。' :
				deadlineDays > DEADLINEDAYS_MAX ? '最大指定日数よりも長い期間は指定できません。700日以下を指定してください。' : true
			),
		isNeverEnd: enums(['true', 'false']),
		isCutCheck: enums(['date', 'days', 'never']),
		reviewRequired: enums(['item', 'shop', 'both', 'any']),
		canManyRewards: enums(['true', 'false']),

		inProgressDate: optional(number()),
		waitingReviewDate: optional(number()),
		finishedDate: optional(number()),
		cancelledDate: optional(number()),
	});
});

export const useCampaignEditStep1 = () => {
	const { watch } = useFormContext();

	const isNeverEnd = watch('isNeverEnd') == 'true';
	const isCutDate = watch('isCutCheck') == 'date';
	const isCutDays = watch('isCutCheck') == 'days';
	const isStarted = Boolean(watch('inProgressDate'));
	const isFinishedCamp = Boolean(watch('waitingReviewDate'));
	const isFinishedAll = Boolean(watch('finishedDate') || watch('cancelledDate'));

	return {
		isNeverEnd,
		isCutDate,
		isCutDays,
		isStarted,
		isFinishedCamp,
		isFinishedAll,
	};
}
export default useCampaignEditStep1;
