import * as React from 'react';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { InputInlineDesc } from '../../molecules/InputInlineDesc';
import { LinkGuide } from '../../molecules/LinkGuide';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { ErrorMessage, Form, FormGroup, Input, InputCheck, SubmitButton, changeValidValue } from '../../organisms/Form';
import useSystemAlert from './useSystemAlert';

const initValues = {
	isSendAlert: false,
	alertContentType: [],
	alertDestMail: '',
	daysBeforeTokenExpire: '',
	daysBeforePublicKeyExpire: '',
	daysNotUploadedReviewCsv: ''
};
export type FormValues = {
	isSendAlert: boolean;
	alertContentType: string[],
	alertDestMail: string,
	daysBeforeTokenExpire: string,
	daysBeforePublicKeyExpire: string,
	daysNotUploadedReviewCsv: string,
};

export const SystemAlert: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		sortMails,
		handleOnChange,
		isDisabled,
	} = useSystemAlert(initValues);

	const { watch } = methods;

	return (
		<>
			<PageTitle>システムアラート設定</PageTitle>
			<PageDesc>システム内で発生するアラートの通知方法の設定を行います。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<Form methods={methods} onSubmit={handleSave}>
							<FormGroup>
								<FormLabel headline className="mb_0">
									通知設定
									<LinkGuide guidePath='registration_setting/system_alert' link='メール通知可否' className='el_linkIcon__guideF' />
								</FormLabel>
								<table className="bl_table bl_table__systemAlert">
									<tbody className="bl_table_body">
										<tr>
											<td>メール通知する</td>
											<td>
												<label className="el_toggleBtn__onoff">
													<InputCheck name="isSendAlert" rules={{
														validate: {
															require: (v: boolean) => {
																const types = watch('alertContentType');
																if (Boolean(v) === false && (Array.isArray(types) ? types : [types]).length > 0) {
																	return '「メール通知する」を選択してください。';
																} else if (Boolean(v) && (Array.isArray(types) ? types : [types]).length <= 0) {
																	return '通知内容を選択してください。';
																}
															},
														},
													}} onChange={() => handleOnChange('isSendAlert')} />
													<span />
												</label>
											</td>
										</tr>
									</tbody>
								</table>
								<FormLabel>通知先メールアドレス</FormLabel>
								<Input type="email" multiple name="alertDestMail" placeholder="aaaaaaaa@aaa.co.jp"
									rules={{
											validate: {
												required: (v: string) => {
													if (watch('isSendAlert')) {
														return v != '' || '通知先メールアドレスを入力してください';
													}
												},
												regexg: (v: string) => {
													if (watch('isSendAlert')) {
														const addresses: string[] = sortMails(v);
														return addresses.every(ad => ad.match(/\S+@\S+\.\S+/i)) || '通知先メールアドレスを入力してください';
													}
												}
											},
										}}
									disabled={isDisabled('isSendAlert')}
									/>
								<ErrorMessage  name="alertDestMail" />
								<InputDesc>※通知先が複数必要な場合はカンマ区切りで入力可能です。</InputDesc>
							</FormGroup>
							<FormGroup>
								<FormLabel headline>
									通知内容
									<LinkGuide guidePath='registration_setting/system_alert' link='通知内容' className='el_linkIcon__guideF' />
								</FormLabel>

								<FormLabel className="mb_0">注文情報の取得</FormLabel>
								<table className="bl_table bl_table__systemAlert">
									<tbody className="bl_table_body">
										<tr>
											<td>
												<span>認可情報の有効期限切れの </span>
												<Input className="el_inlineInputM" name="daysBeforeTokenExpire" rules={{
														validate: {
															required: (v: string) => {
																const types = watch('alertContentType');
																if ((Array.isArray(types) ? types : [types]).includes('tokenExpiryNotice')) {
																	return v != '' || '認可情報の有効期限切れの事前通知日数を入力してください。';
																}
															},
															validated: (v: string) => {
																const types = watch('alertContentType');
																if ((Array.isArray(types) ? types : [types]).includes('tokenExpiryNotice')) {
																	const vi = parseInt(v, 10);
																	return (1 <= vi && vi <= 14) || '認可情報の有効期限切れの事前通知日数は 1〜14 の範囲で入力してください。';
																}
															}
														}
													}} onChange={(ev) => changeValidValue(ev, 14, 1)} max={14}
													disabled={isDisabled('tokenExpiryNotice')} />
												<span> 日前に通知する</span>
												<ErrorMessage name="daysBeforeTokenExpire" />
											</td>
											<td>
												<label className="el_toggleBtn__onoff">
													<InputCheck name="alertContentType[]" value="tokenExpiryNotice" onChange={() => handleOnChange('alertContentType')} />
													<span />
												</label>
											</td>
										</tr>
										<tr>
											<td>認可情報の有効期限切れを通知する</td>
											<td>
												<label className="el_toggleBtn__onoff">
													<InputCheck name="alertContentType[]" value="tokenExpired" onChange={() => handleOnChange('alertContentType')} />
													<span />
												</label>
											</td>
										</tr>
										<tr>
											<td>
												<span>公開鍵の有効期限切れの </span>
												<Input className="el_inlineInputM" name="daysBeforePublicKeyExpire" rules={{
														validate: {
															required: (v: string) => {
																const types = watch('alertContentType');
																if ((Array.isArray(types) ? types : [types]).includes('publicKeyExpiryNotice')) {
																	return v != '' || '公開鍵の有効期限切れの事前通知日数を入力してください。';
																}
															},
															validated: (v: string) => {
																const types = watch('alertContentType');
																if ((Array.isArray(types) ? types : [types]).includes('publicKeyExpiryNotice')) {
																	const vi = parseInt(v, 10);
																	return (1 <= vi && vi <= 14) || '公開鍵の有効期限切れの事前通知日数は 1〜14 の範囲で入力してください。';
																}
															}
														}
													}} onChange={(ev) => changeValidValue(ev, 14, 1)} max={14}
													disabled={isDisabled('publicKeyExpiryNotice')} />
												<span> 日前に通知する</span>
												<ErrorMessage name="daysBeforePublicKeyExpire" />
											</td>
											<td>
												<label className="el_toggleBtn__onoff">
													<InputCheck name="alertContentType[]" value="publicKeyExpiryNotice" onChange={() => handleOnChange('alertContentType')} />
													<span />
												</label>
											</td>
										</tr>
										<tr>
											<td>公開鍵の有効期限切れを通知する</td>
											<td>
												<label className="el_toggleBtn__onoff">
													<InputCheck name="alertContentType[]" value="publicKeyExpired" onChange={() => handleOnChange('alertContentType')} />
													<span />
												</label>
											</td>
										</tr>
									</tbody>
								</table>
								<InputDesc>
									※認可情報の有効期限が切れた場合、<InputInlineDesc alert className="txt_bold">注文情報の取得が停止</InputInlineDesc>されます。<br />
									※公開鍵の有効期限が切れた場合、<InputInlineDesc alert className="txt_bold">認可情報の有効期限が短く制限</InputInlineDesc>されます。<br />
									※認可情報・公開鍵の有効期限をご確認の上、事前に更新手続きをお願いします。
								</InputDesc>

								<FormLabel className="mt_32 mb_0">クーポンの発行・レビューの監視（Yahoo!ストアクリエイターProへのログイン）</FormLabel>
								<table className="bl_table bl_table__systemAlert">
									<tbody className="bl_table_body">
										<tr>
											<td>Yahoo!ストアクリエイターProへログインできない場合に通知する</td>
											<td>
												<label className="el_toggleBtn__onoff">
													<InputCheck name="alertContentType[]" value="storeAccountExpired" onChange={() => handleOnChange('alertContentType')} />
													<span />
												</label>
											</td>
										</tr>
									</tbody>
								</table>
								<FormLabel className="mt_32 mb_0">レビューの監視（レビューCSVの手動アップロード）</FormLabel>
								<table className="bl_table bl_table__systemAlert">
									<tbody className="bl_table_body">
										<tr>
											<td>
												<span>レビューCSVが </span>
												<Input className="el_inlineInputM" name="daysNotUploadedReviewCsv" rules={{
														validate: {
															required: (v: string) => {
																const types = watch('alertContentType');
																if ((Array.isArray(types) ? types : [types]).includes('notUploadedReviweCsv')) {
																	return v != '' || 'レビューCSVのアップロードで通知が必要な日数を入力してください。';
																}
															},
															validated: (v: string) => {
																const types = watch('alertContentType');
																if ((Array.isArray(types) ? types : [types]).includes('notUploadedReviweCsv')) {
																	const vi = parseInt(v, 10);
																	return (1 <= vi && vi <= 90) || 'レビューCSVのアップロードで通知が必要な日数は 1〜90 の範囲で入力してください。';
																}
															}
														}
													}} onChange={(ev) => changeValidValue(ev, 90, 1)} max={90}
													disabled={isDisabled('notUploadedReviweCsv')} />
												<span> 日間アップロードされていない場合に通知する</span>
												<ErrorMessage name="daysNotUploadedReviewCsv" />
											</td>
											<td>
												<label className="el_toggleBtn__onoff">
													<InputCheck name="alertContentType[]" value="notUploadedReviweCsv" onChange={() => handleOnChange('alertContentType')} />
													<span />
												</label>
											</td>
										</tr>
									</tbody>
								</table>
								<InputDesc>
									※「ストアクリエイターProへログインできない」状態となった場合、<InputInlineDesc alert className="txt_bold">クーポンの自動発行が停止された状態</InputInlineDesc>となります。<br />
									※また、「ストアクリエイターProへログインできない」もしくは「レビューCSVのアップロードがされていない」状態が続いた場合、<InputInlineDesc alert className="txt_bold">レビューの監視が停止された状態</InputInlineDesc>となります。<br />
									※クーポンの自動発行やレビューの監視のため、Yahooシステム設定のYahoo!ストアクリエイターpro連携設定を更新お願いします。<br />
									※レビューCSVを手動でアップロードされる場合、指定期間レビューCSVのアップロードがされていなければ通知を行いますので定期的なアップロードにご利用ください。
								</InputDesc>
								<ErrorMessage name="isSendAlert" />
								<ErrorMessage name="alertContentType" />
							</FormGroup>
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default SystemAlert;