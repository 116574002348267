import { MailShopEntity, YahooMailNotCovered, YahooShopEntity } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useAPI, useAppState } from "../../../context";

export interface FormValues {
	name: string;
	subject: string;
	body: string;
	signature: string;
	email: string;
	isEnable: string;
	isNonYahooMemberEnabled: string;
}

type NotCoveredEditParams = {
	notCoveredId?: string;
	flag?: string;
}

interface useNotCoveredEditProps {
	initValues: FormValues;
}

export const useNotCoveredEdit = (props: useNotCoveredEditProps) => {
	const params = useParams<NotCoveredEditParams>();
	const isNew = params.notCoveredId == 'new';
	const isCopy = params.flag === 'copy';
	const notCoveredId = params.notCoveredId;

	const [state] = useAppState();
	const shopId = state.params.shopId;
	const location = useLocation();
	const urlParamSeq = new URLSearchParams(location.search).get("seq");
	const callAPI = useAPI();
	const [shopMail, setShopMail] = React.useState<string|undefined>(undefined);
	const navigate = useNavigate();
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
		shouldUnregister: true,
	});
	const { reset } = formMethods;

	const toInstance = (values: Partial<FormValues>, id: string): YahooMailNotCovered => {
		return YahooMailNotCovered.create({
			id,
			name: values.name,
			subject: values.subject,
			body: values.body,
			signature: values.signature,
			isEnable: values.isEnable === 'true',
			isNonYahooMemberEnabled: values.isNonYahooMemberEnabled === 'true'
		});
	};

	const toValues = (notCovered: YahooMailNotCovered | undefined, isNew: boolean, isCopy: boolean): FormValues => {
		if (isNew || !notCovered) {
			return props.initValues;
		}
		return {
			...props.initValues,
			name: isCopy ? notCovered.name + ' のコピー' : notCovered.name,
			subject: notCovered.subject,
			body: notCovered.body,
			signature: notCovered.signature,
			isEnable: notCovered.isEnable ? 'true' : 'false',
			isNonYahooMemberEnabled: notCovered.isNonYahooMemberEnabled ? 'true' : 'false'
		};
	};

	React.useEffect(() => {
		if (shopMail !== undefined) {
			return;
		}
		return callAPI.mail.getShop({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const shop = new MailShopEntity(resData);
				setShopMail(shop.mail || '');
			}
		});
	}, [shopId]);

	React.useEffect(() => {
		if (shopMail === undefined || !notCoveredId || isNew) {
			return;
		}
		return callAPI.yReview.getNotCovered({ shopId, notCoveredId, seq: urlParamSeq ?? undefined }, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const notCovered = new YahooMailNotCovered(resData);
				reset({
					...toValues(notCovered, isNew, isCopy),
					email: shopMail,
				});
			}
		});
	}, [shopMail, shopId, notCoveredId]);

	React.useEffect(() => {
		if (shopMail === undefined || !isNew) {
			return;
		}
		return callAPI.yReview.getShop({ shopId }, (err, result) => {
			if (err) {
				return;
			}
			const resData = result.data;
			if (resData) {
				const shopEntity = new YahooShopEntity(resData);
				reset({
					...props.initValues,
					signature: shopEntity.signature,
					email: shopMail,
				});
			}
		});
	}, [shopMail]);

	const handleSave: SubmitHandler<FormValues> = async (values): Promise<boolean> => {
		if (!notCoveredId) {
			return false;
		}

		try {
			const id = isNew || isCopy ? uuid() : notCoveredId;
			const notCovered = toInstance(values, id);

			if (isNew || isCopy) {
				const res = await callAPI.yReview.postNotCovered({ shopId, notCovered });
				if (res) {
					const params = new URLSearchParams({ seq: res.data.seq });
					const to = `${state.params.basePath}/mail/not-covered/${id}?` + params.toString();
					navigate(to, {state: {newId: id}, replace: true});
					return true;
				}
			} else {
				await callAPI.yReview.putNotCovered({ shopId, notCoveredId, notCovered });
				return true;
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	const returnListUrl = `${state.params.basePath}/mail/not-covered`;

	return {
		methods: formMethods,
		handleSave,
		returnListUrl,
	};
};
export default useNotCoveredEdit;