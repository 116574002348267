import { css } from "@emotion/react";
import { PageInfo, User, UserState } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Column, DataTable } from '../../organisms/DataTable';

interface UserListTableProps {
	rows?: User[];
	pageInfo?: PageInfo;
	isLoading: boolean;
	shopId: string;
	toEditURL: (userId: string) => string,
	handleClickDelete: (userId: string) => void,
}

export const UserListTable: React.FunctionComponent<UserListTableProps> = (props) => {
	const { shopId, toEditURL, handleClickDelete } = props;

	// テーブル固有のCSS指定
	const tableStyle = css`
		height: 556px;

		th:nth-of-type(4) {
			padding-left: 50px;
		}

		td:nth-of-type(1) {
			padding-left: 39px;
		}

		.bl_table_body {
			.el_admin {
				background-position: left 17px center;
			}
		}
	`;

	const toDateString = (milliepoc: number | undefined): string => {
		if (!milliepoc) {
			return '';
		}
		return dayjs(milliepoc).format('YYYY年MM月DD日 HH:mmまで');
	};
	const toStateString = (state: UserState) => {
		return {
			'inviting': '招待中',
			'invitation_expired': '招待有効期限切れ',
			'pending': '承認待ち',
			'active': 'アクティブ中',
			'suspend': '停止中',
			'expired': 'アカウント有効期限切れ',
			'none': '',
		}[state] || state;
	};
	const toEditButton = (rowData: any) => {
		if (['inviting', 'invitation_expired'].includes(rowData.getState(shopId))) {
			return <span className="el_btnTerm">編集</span>
		} else {
			return <Link className="el_btnInv" type="button" to={toEditURL(rowData.id)}>編集</Link>
		}
	};

	const adminClassNameTemplate = (rowData: any) => {
		return rowData.isAdmin(shopId) ? 'el_admin' : '';
	}
	const expireBodyTemplate = (rowData: any) => {
		return toDateString(rowData.getExpire(shopId));
	};
	const stateBodyTemplate = (rowData: any) => {
		return (
			<span>{toStateString(rowData.getState(shopId))}</span>
		);
	};
	const buttonGroupBodyTemplate = (rowData: any) => {
		return (
			<div className="bl_table_btnWrap">
				{toEditButton(rowData)}
				<button className="el_btnInv" type="button" onClick={() => handleClickDelete(rowData.id)}>削除</button>
			</div>
		);
	};
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	};
	const twoFactorTemplate = (rowData: any) => {
		return rowData.twoFactorAuth ? '設定中' : '';
	};

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 0 224px center flex-start" tdClassNameTemplate={adminClassNameTemplate} field="name">ユーザー名</Column>
				<Column style="1 1 849px center flex-start" field="mail">メールアドレス</Column>
				<Column style="0 0 180px center center" field="expire" bodyTemplate={expireBodyTemplate}>アカウント有効期限</Column>
				<Column style="0 0 134px left center" field="state" bodyTemplate={stateBodyTemplate}>状態</Column>
				<Column style="0 0 134px left center" field="state" bodyTemplate={twoFactorTemplate}>2段階認証</Column>
				<Column style="0 0 154px left center" field="buttonGroup" bodyTemplate={buttonGroupBodyTemplate}></Column>
			</DataTable>
		</>
	);
}
export default UserListTable;