import { CastingContext } from "csv-parse";

export function columns(columnNameMap: Record<string, string>) {
	return (columns: string[]) => {
		return columns.map(column => columnNameMap[column] || column);
	}
}

export function cast(columnCastMap: Record<string, (value: string) => any>) {
	return function (val: string, context: CastingContext) {
		if (context.header) {
			return val;
		}
		return columnCastMap[context.column]?.(val) ?? val;
	};
}
