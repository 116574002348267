import * as React from 'react';
import { SubmitHandler, UseFormMethods } from 'react-hook-form';
import { FormLabel } from '../../molecules/FormLabel';
import { ErrorMessage, Form, FormGroup, Input, Select, Textarea } from '../../organisms/Form';
import { FormValues } from './Contact';
import { CONTACT_SUBJECTS } from './useContact';

interface ContactInputProps {
	methods: UseFormMethods<FormValues>;
	shopName?: string;
	onConfirm: SubmitHandler<any>;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ContactInput(props: ContactInputProps): React.ReactElement {
	const { methods, shopName, onConfirm } = props;

	return (
		<>
			<Form methods={methods}>
				<div className="bl_panel bl_panel__bt">
					<div className="bl_panel_row">
						<h3 className="el_lv3Heading">お問い合わせ店舗</h3>
						{shopName}
					</div>
					<FormGroup>
						<FormLabel id="name" required>担当者名</FormLabel>
						<Input type="text" name="name" rules={{ required: '担当者名を入力してください' }} />
						<ErrorMessage name="name" />
					</FormGroup>

					<FormGroup>
						<FormLabel id="email" required>メールアドレス</FormLabel>
						<Input type="text" name="email" rules={{
							required: 'メールアドレスを入力してください',
							pattern: {
								value: /\S+@\S+.\S+/,
								message: 'メールアドレスを入力してください'
							}
						}} />
						<ErrorMessage name="email" />
					</FormGroup>
					<FormGroup>
						<FormLabel id="subject" required>お問い合わせ種別</FormLabel>
						<div className="el_selectWrap">
							<Select name="subject"
									options={Object.fromEntries([['', '']].concat(Object.entries(CONTACT_SUBJECTS).map(([k, v]) => [k, v])))}
									rules={{ required: 'お問い合わせ種別を選択してください' }} />
						</div>
						<ErrorMessage name="subject" />
					</FormGroup>
					<FormGroup>
						<FormLabel id="content" required>お問い合わせ内容</FormLabel>
						<Textarea name="content" rows={5}
							rules={{ required: 'お問い合わせ内容を入力してください' }}
						/>
						<ErrorMessage name="content" />
					</FormGroup>
					<FormGroup>
						<FormLabel id="orderNumber">注文番号</FormLabel>
						<Textarea name="orderNumber" rows={3} />
						<p className="txt_blue fs_12 mt_16">
							※注文の状況確認として、レビュー待ちや各種メールの送信、特典の申込み状況などを確認されたい場合は該当の注文番号を記載してください<br />
							※複数ある場合は改行区切り等で入力お願いします
						</p>
						<ErrorMessage name="orderNumber" />
					</FormGroup>
					<div className="el_largeBtnWrap">
						<button className="el_largeBtn" type="button" onClick={onConfirm}>確認画面へ</button>
					</div>
				</div>
			</Form>
		</>
	);
}
export default ContactInput;