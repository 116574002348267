import { APIResponses } from '@sasagase/api-caller';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAPIDataTableLoad, useAppState } from '../../../context';
import { ReorderResult, useListSelector } from '../../organisms/DataTable';

type ListItem<T> = {
	isShow: boolean;
	isUnsaved?: boolean;
	item: T;
};

export const useCampaignList = () => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const [items, setItems] = React.useState<ListItem<APIResponses.GetCampaignListResponseValues>[] | null>(null);
	const methodFilter = useForm();
	const { getValues } = methodFilter;

	const [rows, { filter, pageInfo, isLoading }] = useAPIDataTableLoad(
		callAPI.yReview.getCampaignList,
		{
			apiParams: { shopId: state.params.shopId },
			onFetch: (data) => {
				return [data, undefined];
			},
		}
	);

	const {
		getChecks,
		reset,
		refresh,
	} = useListSelector();

	React.useEffect(() => {
		if (!state.params.shopId || rows == undefined) {
			return;
		}

		setItems(rows.map((row) => ({ isShow: true, item: row })) || []);
		refresh();
	}, [state.params.shopId, rows]);

	const filterItem = (predicate: (item: APIResponses.GetCampaignListResponseValues) => boolean) => {
		setItems(prevItems => {
			if (!prevItems) {
				return null;
			}
			return prevItems.map(prev => ({
				...prev,
				isShow: predicate(prev.item),
			}));
		});
	};

	const handleFilter = () => {
		// 並び替えのため、全件取得してフロント側でフィルタリングを行う
		const { name, state } = getValues();
		filterItem((item) => Boolean(item.id));	// reset
		if (name) {
			filterItem((item) => item.name.includes(name));
		}
		if (state) {
			filterItem((item) => !state || item.state == state);
		}
	};

	// itemsから表示するキャンペーンを抽出(react-beautiful-dndのDraggableに連続したidxを指定する必要があるので)
	const campaigns = items && items.filter(item => item.isShow).map(item => item.item) || undefined;

	// 順番入れ替えの保存(priorityが降順になるようにする)
	React.useEffect(() => {
		if (!items || items.length < 2 || !state.params.shopId) {
			return;
		}
		const promises = [];
		for (const [idx, item] of items.entries()) {
			if (!item.isUnsaved) {
				continue;
			}
			const prevPri = items[idx - 1]?.item.priority ?? Math.max(items[1].item.priority + 2, Date.now());
			const nextPri = items[idx + 1]?.item.priority ?? Math.min(items[items.length - 2].item.priority - 1, 0);
			const campaign = item.item;
			campaign.priority = Math.floor((prevPri + nextPri) / 2);

			// prevPri と nextPri の差が1の時に nextPri と同じ値になるので
			// その場合は次のキャンペーンも priority を更新する
			if (campaign.priority === nextPri && items[idx + 1]) {
				items[idx + 1].isUnsaved = true;
			}

			const p = callAPI.yReview.setCampaignPriority({
					shopId,
					campaignId: campaign.id,
					priority: campaign.priority
				});
			promises.push(p);
		}
		if (promises.length) {
			setItems(prev => prev && prev.map(item => item.isUnsaved && {
				...item,
				isUnsaved: false,
			} || item));
		}
	}, [items]);

	const handleClickDelete = async () => {
		if (!state.params.shopId) {
			throw null;
		}

		const checks = getChecks();
		const checkIds = Object.entries(checks).filter(([, check]) => check).map(([id,]) => id);
		const names = items?.filter(campaign => checkIds.includes(campaign.item.id)).map(campaign => campaign.item.name) || [];
		if (names.length <= 0) {
			alert(`削除するキャンペーンを選択してください。`);
			return;
		}
		if (window.confirm(`以下のキャンペーンを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			let seq;
			for (const { item: campaign } of items || []) {
				if (!checkIds.includes(campaign.id)) {
					continue;
				}
				try {
					const res = await callAPI.yReview.deleteCampaign({
							shopId,
							campaignId: campaign.id
						});
					if (res && res.data) {
						seq = res.data.seq
					}
				} catch (err) {
					if (err.status !== 400) {
						alert(`キャンペーン ${campaign.name} の削除に失敗しました。`);
					}
				}
			}
			reset();
			setItems(null);
			filter({ seq }, []);
		}
	};

	const handleReorder = (result: ReorderResult) => {
		if (!items || !campaigns || result.destinationIdx === result.sourceIdx) {
			return;
		}
		// resultのindexは campaigns のインデックスなので items のインデックスに変換する
		const srcCamp = campaigns[result.sourceIdx];
		const destCamp = campaigns[result.destinationIdx];
		const src = items.findIndex(item => item.item === srcCamp);
		const dest = items.findIndex(item => item.item === destCamp);
		if (src >= 0 && dest >= 0) {
			moveItem(src, dest);
		}
	};

	const moveItem = (src: number, dest: number) => {
		setItems(prev => {
			if (!prev || src === dest) {
				return prev;
			}
			if (src < dest) {
				return [
					...prev.slice(0, src),
					...prev.slice(src + 1, dest + 1),
					{ ...prev[src], isUnsaved: true },
					...prev.slice(dest + 1),
				];
			} else {
				return [
					...prev.slice(0, dest),
					{ ...prev[src], isUnsaved: true },
					...prev.slice(dest, src),
					...prev.slice(src + 1),
				];
			}
		});
	};

	return {
		state,
		tableProps: {
			rows: campaigns,
			pageInfo,
			isLoading,
			handleFilter,
			methodFilter,
			handleReorder,
		},
		handleClickDelete,
	};
};
export default useCampaignList;
