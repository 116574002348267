import ja from 'date-fns/locale/ja';
import * as React from 'react';
import { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { toBR } from '../../../lib';
import { PageDesc } from '../../molecules/PageDesc';
import { PageTitle } from '../../molecules/PageTitle';
import { RequiredDesc } from '../../molecules/RequiredDesc';
import { Form, SubmitButton } from '../../organisms/Form';
import RewardEditForm from './RewardEditForm';
import useRewardEdit from './useRewardEdit';
registerLocale('ja', ja);

export const initValues = {
	rewardType: 'item', // 'item' | 'coupon'
	name: '',
	description: '',
	image: '',
	limitedType: 'unlimited', // 'unlimited' | 'limited'
	quantity: '',
	csvFormat: 'general_v2',
	csvClientType: 'user', // 'orderer' | 'user'
	csvUserSettings: {},
	issueFreq: 'individual',
	categoryId: '',
	discountType: '', // '1' | '2' | '3'
	discountPrice: '',
	discountRate: '',
	validPeriod: 'days',
	validDays: '',
	dispFlg: '', // 'true' | 'false'
	couponImageType: 'input', // 'input' | 'template' | 'upload'
	couponImageInput: '',
	couponImageTemplate: '',
	couponImageUpload: '',
	couponImageName: '',
	linkUrl: '',
	canCombineUse: 'false', // 'true' | 'false'
	countUserUseLimit: '',
	countAllUseLimit: '',
	orderType: '0', // '0' | '1' | '2'
	orderPrice: '',
	orderCount: '',
	itemDesignation: '3', // '3' | '1' | '4'
	targetItemIdStrings: '',
	targetItemTagStrings: '',
};

export const RewardEdit: React.FunctionComponent = () => {
	const {
		names,
		methods,
		rewardType,
		handleSave,
		messages,
		presentProps,
		couponProps,
		returnListUrl,
	} = useRewardEdit({initValues});

	return (
		<>
			<PageTitle>レビュー特典登録</PageTitle>
			<PageDesc>プレゼント品・クーポンの新規登録、確認・編集ができます。</PageDesc>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						{messages.length > 0 &&
							<div className="bl_panel_row">
								<h3 className="el_lv3Heading el_errorMessage">エラー</h3>
								{messages.map((msg, idx) =>
									<p key={`msg_${idx}`} className="el_errorMessage fs_14">{toBR(msg)}</p>
								)}
							</div>
						}
						<RequiredDesc />
						<Form methods={methods} onSubmit={handleSave}>
							<RewardEditForm
								names={names}
								rewardType={rewardType}
								presentProps={presentProps}
								couponProps={couponProps}
								/>
							<div className="el_largeBtnWrap">
								<SubmitButton />
							</div>
							<Link className="el_backLink" to={returnListUrl}>≪一覧へ戻る</Link>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};
export default RewardEdit;