import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';

export const LoggedOut: React.FunctionComponent = () => {
	return (
		<div className="ly_reissue">
			<div className="ly_reissue_inner">
			<h1 className="el_entryTitle"><Link to="/"><img src="/assets/img/logo-w.svg" alt="ラクラクあつまレビュー" /></Link></h1>
				<Outlet />
			</div>
		</div>
	);
};
export default LoggedOut;