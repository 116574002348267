import * as React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import Input from './Input';
import InputPassword, { InputPasswordProps } from './InputPassword';
import { ERROR_CLASSNAME } from './const';

interface InputPasswordOptionalProps extends InputPasswordProps {
	errors: DeepMap<Record<string, any>, FieldError>;
	hide?: boolean;
	storedValue?: string;
}

export const InputPasswordOptional = React.forwardRef<HTMLInputElement, InputPasswordOptionalProps>(function InputPasswordOptional({ errors, hide, storedValue, ...props }, ref): React.ReactElement|null {
	const { name = '' } = props;
	const error: FieldError | undefined = errors[name];

	const passProps = {
		...props,
		className: error ? ERROR_CLASSNAME : undefined,
		placeholder: !storedValue ? '(未設定)' :
			hide ? `${"*".repeat(storedValue.length)} (設定済み)` : '',
	};
	if (hide) {
		return (
			<>
				<InputPassword {...passProps} ref={ref} />
				{storedValue && <p className="txt_blue fs_12 mt_8">※入力された場合のみ更新します。</p>}
			</>
		);
	}

	return (
		<Input {...passProps} />
	);
});
export default InputPasswordOptional;