import * as React from 'react';
import { Form } from '../../organisms/Form';
import { contextWizard, useCampaignWizard } from './useCampaignWizard';

interface CampaignWizardProps {
	onClose: () => void;
	initStep?: number;
}

export const CampaignWizard: React.FC<CampaignWizardProps> = (props) => {
	const {
		methods,
		campaignWizardContent,
		stepIdx,
		WizardComponent,
		handlePrev,
		contextValues,
	} = useCampaignWizard(props);

	return (
		<>
			<ol className="bl_progressTracker mt_16">
				{campaignWizardContent.map((step, idx) =>
					<li key={`step_${idx}`} className={"bl_progressTracker_item" + (idx == stepIdx ? ' is_active' : '')}>
						<span className="bl_progressTracker_step">Step{idx + 1}</span>
					</li>
				)}
			</ol>
			<div className="bl_panel__wizard">
				<contextWizard.Provider value={contextValues}>
					<Form methods={methods}>
						<WizardComponent
							onPrev={handlePrev}
							onClose={props.onClose}
							/>
					</Form>
				</contextWizard.Provider>
			</div>
		</>
	);
}
export default CampaignWizard;
