import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { toBR } from '../../../lib';
import { RewardCouponEditProps } from '../RewardEdit/RewardCouponEdit';
import { initValues } from '../RewardEdit/RewardEdit';
import { RewardEditForm } from '../RewardEdit/RewardEditForm';
import { RewardItemEditProps } from '../RewardEdit/RewardItemEdit';
import { FormValues } from '../RewardEdit/useRewardEdit';

interface CampaignWizardS2AddRewardProps {
	names: Record<keyof FormValues, string>;
	rewardType: string;
	messages: string[];
	presentProps: RewardItemEditProps;
	couponProps: RewardCouponEditProps;
}

export const CampaignWizardS2AddReward: React.FC<CampaignWizardS2AddRewardProps> = (props) => {
	const { watch, getValues, reset } = useFormContext();
	const rewardType = watch('addReward.rewardType') || props.rewardType;
	const { names, messages, presentProps, couponProps  } = props;

	React.useEffect(() => {
		reset({
			...getValues(),
			addReward: {
				...initValues,
				rewardType: props.rewardType
			},
		});
	}, []);

	return (
		<>
			{messages.length > 0 &&
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading el_errorMessage">エラー</h3>
					{messages.map((msg, idx) =>
						<p key={`msg_${idx}`} className="el_errorMessage fs_14">{toBR(msg)}</p>
					)}
				</div>
			}
			<RewardEditForm
				names={names}
				rewardType={rewardType}
				presentProps={presentProps}
				couponProps={couponProps}
				/>
		</>
	);
}
export default CampaignWizardS2AddReward;
