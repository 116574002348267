import * as React from 'react';
import { Headline } from '../../atoms/Headline';

interface PageTitleProps {
	children?: React.ReactNode;
}
export const PageTitle: React.FC<PageTitleProps> = (props) => {

	const cName = 'el_pageTtl';

	return <Headline className={cName}>{props.children}</Headline>;
}
export default PageTitle;
