import { Api } from '@sasagase/api-caller';
import * as React from 'react';
import { providers, SetStateActions, StateSetTuple } from '../context';
import { userSetter } from '../context/setter';
import { ContextState } from '../context/state';
import { isProduction } from '../lib/isProduction';

const initState = {};

interface ContextProviderProps {
	children?: React.ReactNode;
}
const ContextProvider: React.FunctionComponent<ContextProviderProps> = (props) => {
	const [state, setState] = React.useState<ContextState>(initState);
	const setStateCanArray = React.useCallback((value: SetStateActions) => {
		if (Array.isArray(value)) {
			setState(prev => {
				for (const v of value) {
					if (typeof v == 'function') {
						prev = v(prev);
					} else {
						prev = v;
					}
				}
				return prev;
			});
		} else {
			setState(value);
		}
	}, [setState]);
	const callAPI = new Api(result => {
		switch (result.status) {
			case 400: {
				alert(result.data.error ?? result.data);
			} break;
			case 401: {
				setState(userSetter(null));
			} break;
			case 403: {
				alert(`${result.data} 権限が必要です`);
			} break;
		}
	});
	const stateSets = React.useMemo<StateSetTuple>(() => [state, setStateCanArray], [state, setStateCanArray]);

	if (!isProduction) {
		console.log(state);
	}

	return (
		<providers.api value={callAPI}>
			<providers.state value={stateSets}>
				{props.children}
			</providers.state>
		</providers.api>
	);
};
export default ContextProvider;
