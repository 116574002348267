import { css } from "@emotion/react";
import { APIResponses } from '@sasagase/api-caller';
import { MailNotCovered, PageInfo, YahooMailNotCoveredEnabledOption } from '@sasagase/types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Column, DataTable } from '../../organisms/DataTable';

interface NotCoveredListTableProps {
	rows?: APIResponses.GetNotCoveredsResponse;
	pageInfo?: PageInfo;
	isLoading: boolean;
	onChangeEnable: (id: string, notCovered: Partial<MailNotCovered>) => void;
	toEditURL: (rewardId: string) => string,
	toCopyURL: (rewardId: string) => string,
}

export const NotCoveredListTable: React.FunctionComponent<NotCoveredListTableProps> = (props) => {
	const { onChangeEnable, toEditURL, toCopyURL } = props;

	// テーブル固有のCSS指定
	const tableStyle = css`
		height: 688px;

		th:nth-of-type(6) {
			padding-left: 40px;
		}
	`;

	const handleChangeEnable = (notCovered: APIResponses.GetNotCoveredsResponseValues, type: keyof YahooMailNotCoveredEnabledOption) => async (e: React.ChangeEvent<HTMLInputElement>) => {
		onChangeEnable(notCovered.id, { [type]: e.currentTarget.checked });
	}
	const isEnableBodyTemplate = (rowData: any) => {
		return (
			<label className="el_toggleBtn el_toggleBtn__send">
				<input type="checkbox" checked={rowData.isEnable} onChange={handleChangeEnable(rowData, 'isEnable')} />
				<span />
			</label>
		);
	};
	const isNonYahooMemberEnabledBodyTemplate = (rowData: any) => {
		return (
			<label className="el_toggleBtn el_toggleBtn__send">
				<input type="checkbox" checked={rowData.isNonYahooMemberEnabled} onChange={handleChangeEnable(rowData, 'isNonYahooMemberEnabled')} />
				<span />
			</label>
		);
	};
	const editBodyTemplate = (rowData: any) => {
		return <>
			<div className="bl_table_btnWrap">
				<Link className="el_btnInv" type="button" to={toEditURL(rowData.id)}>編集</Link>
				<Link className="el_btnInv" type="button" to={toCopyURL(rowData.id)}>複製</Link>
			</div>
		</>;
	};
	const pageReportTemplate = (pageInfo: PageInfo) => {
		return <><p className="el_resultCount">全{pageInfo.recordsTotal ?? '-'}件</p></>;
	};

	return (
		<>
			<DataTable css={tableStyle}
				rows={props.rows} dataKey="id" pageInfo={props.pageInfo} isLoading={props.isLoading}
				currentPageReportTemplate={pageReportTemplate}
				>
				<Column style="0 0 48px center center" field="id" listSelector></Column>
				<Column style="0 1 410px center flex-start" field="name">テンプレート名</Column>
				<Column style="0 1 720px center flex-start" field="subject">メール件名</Column>
				<Column style="0 0 165px center center" field="isEnable" bodyTemplate={isEnableBodyTemplate}>Yahoo会員に送信</Column>
				<Column style="0 0 165px center center" field="isNonYahooMemberEnabled" bodyTemplate={isNonYahooMemberEnabledBodyTemplate}>非Yahoo会員に送信</Column>
				<Column style="0 0 152px left center" field="edit" bodyTemplate={editBodyTemplate}></Column>
			</DataTable>
		</>
	);
}
export default NotCoveredListTable;