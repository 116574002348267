import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Footer from '../navigation/Footer';
import Header from '../navigation/Header';
import Side from '../navigation/Side';
import { useLoggedIn } from './useLoggedIn';

export const LoggedIn: React.FunctionComponent = () => {
	const {
		state,
		param,
		alert,
		isOpen,
		isOpenMobile,
		handleClickBurger,
		handleSizeClose
	} = useLoggedIn();

	// 不正なshopIdははじく
	if (!param.shopId || !state.user?.getEnableCaps('yReview_login', param.shopId)) {
		return <Navigate to=".." />;
	}

	const basePath = state.params?.basePath;
	if (!basePath) {
		return null;
	}

	return (
		<div className={state.modal ? 'show_detail' : ''}>
			<Header onClickBurger={handleClickBurger} basePath={basePath} />
			<div className="ly_body">
				<main className="ly_main">
					{alert &&
						<div className='ly_main_error'>
							<h2>通信エラー</h2>
							<p>{alert.getContent()}</p>
						</div>
					}
					<div className='ly_main_content'>
						<Outlet />
					</div>
				</main>
				<Side isOpen={isOpen} isOpenMobile={isOpenMobile} basePath={basePath} onClose={handleSizeClose} />
			</div>
			<Footer />
		</div>
	);
};
export default LoggedIn;