import { Reward } from '@sasagase/types';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { nonNegative } from '../../../lib';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { InputInlineDesc } from '../../molecules/InputInlineDesc';
import { ErrorMessage, FormGroup, FormInputWrap, Input, InputRadio, Select } from '../../organisms/Form';
import { ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED, useCampaignEditStep2 } from './useCampaignEditStep2';

interface CampaignEditFormStep2Props {
	rewards: Reward[];
	reviewFormCond: () => Record<string, boolean>;
}

export function CampaignEditFormStep2(props: CampaignEditFormStep2Props): React.ReactElement | null {
	const { watch } = useFormContext();

	const {
		rewardFields,
		shouldCloseApplication,
		isManyRewardOriginal,
		handleClickAddReward,
		handleClickPreview,
		handleRemoveReward,
		handleChangeReward,
	} = useCampaignEditStep2(props);

	const { canChooseReward, canChooseDest, hasItemReward, disabledReviewForm, disabledDestType } = props.reviewFormCond();

	const chooseRewardIds: string[] = (watch('rewards') as { val: string }[]).map(r => r.val);
	const choiceRewards = rewardFields.map((reward, idx) => {
		return props.rewards.filter((reward) => reward.id === chooseRewardIds[idx] || !chooseRewardIds.includes(reward.id));
	});

	// キャンペーン開催中は特典の送り先を申込みフォームで選択 ⇔ 商品購入時と同じ配送先 or 注文者の住所 に変更不可
	const isStarted = Boolean(watch('inProgressDate'));
	const isFinishedCamp = Boolean(watch('waitingReviewDate'));
	const destTypeOriginal = watch('destTypeOriginal');
	let disabledDestTypeForm = false;
	let disabledDestTypeShippingOrderer = false;
	if (isStarted && !isFinishedCamp) {
		if (destTypeOriginal === 'form') {
			disabledDestTypeShippingOrderer = true;
		}
		if (destTypeOriginal === 'shipping' || destTypeOriginal === 'orderer') {
			disabledDestTypeForm = true;
		}
	}
	// 開催中で変更前の特典送り先が申込フォーム以外で単一の特典、または選択可能な特典をすべて追加済みは追加ボタン押下不可
	const disabledAddReward = (isStarted && !isFinishedCamp && disabledDestTypeForm && !isManyRewardOriginal)
		|| (props.rewards.length == chooseRewardIds.length) ? true : false;

	const alerts = {
		destType: canChooseReward ? 'レビュー特典が複数設定されたため、申込みフォームでお客様に選択いただきます' : 
			!hasItemReward && 'レビュー特典がクーポンのみのため特典送り先情報は不要となります',
		shouldCloseApplication: (hasItemReward && !canChooseDest) ? '申込みフォームを利用しません' :
			!canChooseReward && '申込みフォームを利用しません',
	}

	return (
		<>
			<ul className="bl_pageLink">
				<li className="bl_pageLink_item"><a href="#rewards">レビュー特典</a></li>
				<li className="bl_pageLink_item"><a href="#destType">特典送り先（プレゼント品の場合）</a></li>
				<li className="bl_pageLink_item"><a href="#shouldCloseApplication">申込受付期間</a></li>
			</ul>
			<FormGroup>
				<FormLabel id="rewards" required>レビュー特典</FormLabel>
				{rewardFields.map((reward, idx) =>
					<div key={reward.id} className="bl_campaign_selectReward mb_16">
						<Select name={`rewards[${idx}].val`}
							options={Object.fromEntries([['', '']].concat(choiceRewards[idx].map(r => [r.id, r.name])))}
							defaultValue={reward.val} onChange={handleChangeReward(idx)} />
						<Button className="el_remove el_btnInv" onClick={handleRemoveReward(idx)}>削除</Button>
					</div>
				)}
				<ErrorMessage name="rewards" />
				<Button className="el_btnInv" onClick={handleClickAddReward} disabled={props.rewards.length == chooseRewardIds.length || disabledAddReward}>追加</Button>
				&nbsp;&nbsp;
				<Button className="el_btnBlueInv" onClick={handleClickPreview} disabled={disabledReviewForm}>申込みフォームのプレビューを表示</Button>
				{disabledAddReward && <>
					<p style={{ color: 'red' }}>※{ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED}</p>
				</>}
				<p className="mt_8">
					<InputInlineDesc>
						※追加したレビュー特典を削除したい場合、空白を選択すれば削除可能です。<br />
						※以下の条件で申込みフォームが有効となり、お客様が申込みフォームにアクセスしレビュー特典もしくは特典送り先を選ぶフローとなります<br />
					</InputInlineDesc>
				</p>
				<p className="mt_2 ml_8">
					<InputInlineDesc>
						・レビュー特典を複数設定された場合<br />
						・レビュー特典にプレゼント品、且つ特典送り先を「申込みフォームでお客様が選択」を設定された場合<br />
					</InputInlineDesc>
				</p>
				<p className="mt_2">
					<InputInlineDesc>
						※上記以外の条件では申込みフォームが無効となり、レビューの記載が確認できた時点でレビュー特典付与(申込受付完了メール送信)となります<br />
					</InputInlineDesc>
				</p>
			</FormGroup>
			<FormGroup>
				<FormLabel id="destType">特典送り先（プレゼント品の場合）</FormLabel>
				{disabledDestType && <>
					<InputDesc alert noMargin>※{alerts.destType}</InputDesc>
					{/* 以下表示のみ */}
					{canChooseReward && <>
						<label className="bl_label">
							<input type="radio" checked readOnly />
							<span>申込みフォームでお客様が選択</span>
						</label>
					</>}
				</>}
				{!disabledDestType && <>
					<InputRadio name="destType" value="form" label="申込みフォームでお客様が選択" disabled={disabledDestType || disabledDestTypeForm} /><br />
					<InputRadio name="destType" value="shipping" label={<>商品購入時と同じ配送先 <InputInlineDesc alert>※申込みフォームを利用しません</InputInlineDesc></>} disabled={disabledDestType || disabledDestTypeShippingOrderer} /><br />
					<InputRadio name="destType" value="orderer" label={<>注文者の住所 <InputInlineDesc alert>※申込みフォームを利用しません</InputInlineDesc></>} disabled={disabledDestType || disabledDestTypeShippingOrderer} />
				</>}
				<ErrorMessage  name="destType" />
				{(disabledDestTypeForm || disabledDestTypeShippingOrderer) && <>
					<p style={{ color: 'red' }}>※{ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED}</p>
				</>}
			</FormGroup>
			<FormGroup>
				<FormLabel id="shouldCloseApplication">申込受付期間</FormLabel>
				{disabledReviewForm &&
					<InputDesc alert noMargin>※この項目は現在の設定では使用されません（{alerts.shouldCloseApplication}）</InputDesc>
				}
				{!disabledReviewForm && <>
					<InputRadio name="shouldCloseApplication" value="true" label={<>
							<FormInputWrap>
								<span>期限を設定する&#12288;レビュー完了メール送付後から</span><Input className="el_inlineInputM" type="text" name="applicationClosingDays" onChange={nonNegative} disabled={disabledReviewForm} /><span> 日間有効</span>
							</FormInputWrap>
						</>} disabled={disabledReviewForm} /><br />
					{shouldCloseApplication &&
						<ErrorMessage name="applicationClosingDays" />
					}
					<InputRadio name="shouldCloseApplication" value="false" label="無期限" disabled={disabledReviewForm} />
				</>}
			</FormGroup>
		</>
	);
}
export default CampaignEditFormStep2;