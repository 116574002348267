import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAPI, useAppState } from "../../../context";
import { FormValues } from './MailSetting';

export const useMailSetting = (initValues: FormValues) => {
	const [state] = useAppState();
	const callAPI = useAPI();
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const { reset } = formMethods;

	const toSaveAttr = (values: Partial<FormValues>,) => {
		const sendFollowHours = values.datePicker instanceof Date ? values.datePicker.getHours().toString() : "0"
		const sendFollowMinutes = values.datePicker instanceof Date ? values.datePicker.getMinutes().toString() : "0"
		return {
			signature: values.signature,
			sendFollowAfterShippingDays: parseInt(values.sendFollowAfterShippingDays || '0', 10),
			sendFollowHours: parseInt(sendFollowHours, 10),
			sendFollowMinutes: parseInt(sendFollowMinutes, 10),
		};
	}

	const toValues = (shopEntity: Record<string, any>): FormValues => {
		return {
			...initValues,
			signature: shopEntity.signature,
			sendFollowAfterShippingDays: shopEntity.sendFollowAfterShippingDays.toString(),
			datePicker: new Date(0, 0, 0, shopEntity.sendFollowHours, shopEntity.sendFollowMinutes)
		};
	};

	React.useEffect(() => {
		return callAPI.yReview.getShop({ shopId: state.params.shopId }, (err, result) => {
			if (err || result.data === null) {
				return;
			}
			const shop = result.data;
			reset(toValues(shop));
		});
	}, [state.params.shopId]);

	const handleSave: SubmitHandler<FormValues> = async (values) => {
		try {
			const params = {
				shopId: state.params.shopId,
				shop: toSaveAttr(values),
			};
			await callAPI.yReview.putShop(params);
			return true;
		} catch (err) {
			return false;
		}
	};

	const numberLimit: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
		const i = parseInt(ev.target.value, 10);
		ev.target.value = isNaN(i) ? "" : Math.min(Math.max(0, i), 21).toString();
	};

	return {
		state,
		methods: formMethods,
		handleSave,
		numberLimit,
	};
};
export default useMailSetting;