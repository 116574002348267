import * as React from 'react';
import { Link } from 'react-router-dom';

interface ContactCompletedProps {
	basePath: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ContactCompleted(props: ContactCompletedProps): React.ReactElement {
	return (
		<>
			<div className="bl_panel bl_panel__bt">
				<div className="bl_panel_row">
					<h2 className="el_lv2Heading">フォーム送信完了</h2>
					<p className="mb_16">お問い合わせを承りました</p>
					<p className="">
						この度はお問い合わせいただきまして、 誠にありがとうございます。<br />
						ご入力いただいたメールアドレス宛にお問い合わせ内容の確認メールをお送りいたしました。<br />
						お問い合わせいただいた内容を確認後、 2 ~ 3 営業日以内に担当者よりご連絡いたします。<br />
						今後とも何卒よろしくお願い申し上げます。
					</p>
				</div>
				<Link className="el_backLink" to={props.basePath}>≪TOPへ戻る</Link>
			</div>
		</>
	);
}
export default ContactCompleted;