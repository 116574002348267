import * as React from 'react';
import { ErrorMessage, Form, Input, InputPassword, SubmitButton } from '../../organisms/Form';
import useAccountRegist from './useAccountRegist';

const initValues = {
	name: '',
	password: '',
	password_confirm: '',
};
export type FormValues = typeof initValues;

export const AccountRegist: React.FunctionComponent = () => {
	const {
		methods,
		handleSave,
		handleClickStart,
		inviteMail,
		saveResult,
	} = useAccountRegist({initValues});

	const { getValues } = methods;

	const message = saveResult ?
		'アカウントの登録が完了しました。' :
		`こんにちは。${inviteMail} アカウントが作成されました。続けるにはユーザー名を入力してパスワードを作成してください。`;

	return (
		<>
			<div className="bl_inviteForm">
				<div className="bl_loginFormRow">
					<p className="txt_blue">{message}</p>
				</div>
				{!saveResult &&
					<Form methods={methods} onSubmit={handleSave}>
						<div className="bl_loginFormRow">
							<label className="bl_blueLabel">メールアドレス</label>
							<Input type="text" readOnly={true} value={inviteMail} />
						</div>
						<div className="bl_loginFormRow">
							<label className="bl_blueLabel">ユーザー名</label>
							<Input type="text" name="name" rules={{ required: 'ユーザー名を入力してください。' }} />
							<ErrorMessage name="name" />
						</div>
						<div className="bl_loginFormRow">
							<label className="bl_blueLabel">パスワード</label>
							<InputPassword name="password"
								rules={{
										required: 'パスワードを入力してください。',
										minLength: {
											value: 8,
											message: 'パスワードは8文字以上で入力してください。',
										},
									}}
								/>
							<ErrorMessage name="password" />
						</div>
						<div className="bl_loginFormRow">
							<label className="bl_blueLabel">パスワード（確認用）</label>
							<InputPassword name="password_confirm"
								rules={{
										required: '確認用のパスワードを入力してください。',
										validate: (input: string) => input === getValues("password") || '入力したパスワードと一致していません。',
									}}
								/>
							<ErrorMessage name="password_confirm" />
						</div>
						<SubmitButton className="el_largeBtn__fullWidth">アカウント登録</SubmitButton>
					</Form>
				}
				{saveResult &&
					<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClickStart}>ラクラクあつまレビュー を始める</button>
				}
			</div>
		</>
	);
};
export default AccountRegist;