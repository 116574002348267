import { YahooShopEntity } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAPILoad, useAppState } from '../../../context';

interface FormValues {
	email: string;
	deleteEmail: string[] | string;
}

interface useExclusionMailProps {
	initValues: FormValues
}

export const useExclusionMail = (props: useExclusionMailProps) => {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const callAPI = useAPI();
	const [excludeMails, setExcludeMail] = React.useState<string[]>([]);

	const formMethods = useForm({
		defaultValues: props.initValues,
		shouldUnregister: false
	});
	const { handleSubmit, getValues, setValue } = formMethods;

	const [shop] = useAPILoad(
		callAPI.yReview.getShop,
		{
			apiParams: { shopId },
			onFetch: (data) => {
				return data && YahooShopEntity.create(data);
			},
	});

	React.useEffect(() => {
		if (!shop) {
			return;
		}
		setExcludeMail(shop.excludeMailAddress || []);
	}, [shop]);

	const handleClickAddMail = handleSubmit(async () => {
		const addEmail = getValues('email');
		if (!excludeMails || excludeMails.includes(addEmail)) {
			return;
		}

		try {
			const param = {
				shopId,
				shop: {
					excludeMailAddress: [
						...excludeMails,
						addEmail,
					]
				}
			};
			const res = await callAPI.yReview.putShop(param);
			if (res && res.data) {
				setExcludeMail(prev => ([ ...(prev || []), addEmail ]));
				setValue("email", "");
			}
		} catch (err) {
			return;
		}
	});

	const handleClickDeleteMail = async () => {
		if (!excludeMails) {
			return false;
		}

		let deleteEmail = getValues("deleteEmail");
		deleteEmail = Array.isArray(deleteEmail) ? deleteEmail : [deleteEmail];
		if (!deleteEmail || deleteEmail.length <= 0) {
			alert(`削除する除外メールアドレスを選択してください。`);
			return;
		}

		if (window.confirm(`以下の除外メールアドレスを削除してよろしいですか？\n\n${deleteEmail.join(`\n`)}`)) {
			const excludeMailAddress = excludeMails.filter((mail: string) => !deleteEmail.includes(mail));
			try {
				const param = {
					shopId,
					shop: { excludeMailAddress }
				};
				const res = await callAPI.yReview.putShop(param);
				if (res && res.data) {
					setExcludeMail(excludeMailAddress);
					setValue("deleteEmail", false);
					alert(`以下の除外メールアドレスを削除しました。\n\n` + deleteEmail.join("\n"));
				}
			} catch (err) {
				alert(`除外メールアドレスの削除に失敗しました。`);
			}
		}
	}

	return {
		methods: formMethods,
		excludeMails,
		handleClickAddMail,
		handleClickDeleteMail,
	};
};
export default useExclusionMail;
