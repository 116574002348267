import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAPI, useAppState } from '../../../context';
import { FormValues } from './Contact';

export const CONTACT_SUBJECTS: Record<string, string> = {
	initSetting: '初期設定（Yahoo!システム・店舗・メール設定）について',
	reviewSetting: 'レビュー施策（レビュー特典・キャンペーン）について',
	order: '注文の状況確認について',
	contract: '契約・料金について',
	trouble: '不具合・トラブルについて',
	request: 'ご意見・ご要望',
	other: 'その他',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useContact = (initValues: FormValues) => {
	const [state] = useAppState();
	const shopName = state.shop?.name ?? '';
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
		shouldUnregister: false,
	});
	const { handleSubmit } = formMethods;

	const callAPI = useAPI();
	const [formType, setFormType] = React.useState<'input'|'confirm'|'completed'>('input');
	const [btnClass, setBtnClass] = React.useState<string>('');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleClickConfirm = handleSubmit(async (values)  => {
		setFormType('confirm');
	});
	const handleClickBack = ()  => {
		setFormType('input');
	};

	const handleClickSend = handleSubmit(async (values) => {
		const form = {
			...values,
			shopId: state.shop?.id ?? '',
			shopName,
			loginUserName: state.user?.name,
			loginUserMail: state.user?.mail,
			subjectTitle: CONTACT_SUBJECTS[values.subject],
			navigator: JSON.stringify({
				userAgent: window.navigator.userAgent,
				language: window.navigator.language,
			}, undefined, '  '),
		};

		await callAPI.user.postContact(form, (err, result) => {
			if (result.data) {
				setFormType('completed');
			} else {
				setBtnClass('is_error');
			}
			return result.data;
		});
	});

	return {
		state,
		formType,
		methods: formMethods,
		btnClass,
		handleClickConfirm,
		handleClickBack,
		handleClickSend,
	};
}
export default useContact;