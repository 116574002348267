import { MailTemplate } from '@sasagase/types';
import { toArrayMap } from '@sasagase/util';
import dayjs from 'dayjs';
import * as React from 'react';
import { define, dynamic, enums, is, type } from 'superstruct';
import { useAppState } from "../../../context";
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { PageDesc } from '../../molecules/PageDesc';
import { DateFields, FormGroup } from '../../organisms/Form';
import { initValues } from './CampaignEdit';
import useCampaignEdit, { CampaignEditFormValues } from './useCampaignEdit';
import { MailFormValue } from './useCampaignEditStep4';
import { CampaignWizardFormValues, contextWizard } from './useCampaignWizard';

export const campaignWizardConfirmInitValues = {
	reviewRequired: '',
};

export interface CampaignWizardConfirmFormValue {
	reviewRequired: 'item' | 'shop' | 'both' | 'any';
}

export const CampaignWizardConfirmStruct = dynamic(() => {
	return type({
		reviewRequired: define<string>('reviewRequired', (val) => is(val, enums(['item', 'shop', 'both', 'any'])) || 'レビューの条件を選択してください'),
	});
});

interface CampaignWizardConfirmProps {
	onPrev: () => void;
	onClose: () => void;
}

export const CampaignWizardConfirm: React.FC<CampaignWizardConfirmProps> = (props) => {
	const ctx = React.useContext(contextWizard);
	if (!ctx) return null;

	const { onPrev, onClose } = props;
	const wizardValues = ctx.values;
	const [state] = useAppState();
	const shopId = state.params.shopId;

	const {
		handleSave,
		rewards,
		templates,
	} = useCampaignEdit({
		initValues,
		params: { shopId, campaignId: 'new' },
		disableNavigateAfterSubmitted: true,
	});

	if (!rewards || !templates) {
		return null; // loading
	}

	const selectMailTemplates = (wizardValues: CampaignWizardFormValues) => {
		const rewardSelects = rewards.filter(r => wizardValues.rewardIds.includes(r.id));
		const canChooseReward = rewardSelects.length > 1;
		const mailCategories = toArrayMap(templates, (t) => t.category);
		const followMails = (mailCategories.review_request || [])
			// 並び替え：商品名(特典選択：「複数」、特典固定：「1つのみ」が含まれるもの) > お気に入り
			.sort((a, b) =>
				(canChooseReward ? (a.name.indexOf('複数') ? -1 : 1) : (a.name.indexOf('1つのみ') ? -1 : 1)) ||
				(a.isFavorite === b.isFavorite) ? 0 : a.isFavorite ? -1 : 1
			);
		const requestMails = canChooseReward ?
			(mailCategories.review_completed || [])
				// 並び替え：商品名(「複数」が含まれるもの) > お気に入り
				.sort((a, b) =>
					(a.name.indexOf('複数') ? -1 : 1) ||
					(a.isFavorite === b.isFavorite) ? 0 : a.isFavorite ? -1 : 1
				)
				// 絞り込み：申込みフォームURLが含まれるもの
				.filter(t =>
					t.body.some(tb => tb.type === 'apply_form_url')
				)
			: [];
		const receivedMails = (mailCategories.application_completed || [])
			// 並び替え：商品名(特典選択：「複数」、特典固定：「1つのみ」が含まれるもの) > お気に入り
			.sort((a, b) =>
				(canChooseReward ? (a.name.indexOf('複数') ? -1 : 1) : (a.name.indexOf('1つのみ') ? -1 : 1)) ||
				(a.isFavorite === b.isFavorite) ? 0 : a.isFavorite ? -1 : 1
			)
			// 絞り込み：特典にクーポンが含まれる場合、クーポンURLが含まれるもの
			.filter(t => {
				if (rewardSelects.some(r => r.isCoupon)) {
					return t.body.some(tb => tb.type === 'coupon_url')
				}
			});

		return {
			followMail: followMails[0],
			requestMail: requestMails[0],
			receivedMail: receivedMails[0],
		}
	};

	const toMailValues = (template: MailTemplate | undefined): MailFormValue => {
		const bodyHeaderData = template?.body.find((val) => val.type === "header");
		return {
			template: '',
			disable: 'false',
			subject: '',
			bodyHeader: bodyHeaderData ? bodyHeaderData.content : '',
			bodyElement: template?.body.filter((val) => val.type !== "header") || [],
			signature: template?.signature || '',
			email: ''
		};
	};

	const toCampaignValues = (wizardValues: CampaignWizardFormValues): CampaignEditFormValues => {
		const rewardSelects = rewards.filter(r => wizardValues.rewardIds.includes(r.id));
		const rewardNames = rewardSelects.map(r => r.name);
		const name = wizardValues.rewardType === 'choice' ?
			'レビュー投稿で選べるプレゼント' :
			`レビュー投稿で${rewardNames[0]}プレゼント`;

		const {
			followMail,
			requestMail,
			receivedMail
		} = selectMailTemplates(wizardValues);

		return {
			...initValues,
			name: name,
			begin: wizardValues.begin,
			end: wizardValues.end,
			deadlineDate: wizardValues.deadlineDate,
			deadlineDays: wizardValues.deadlineDays,
			isNeverEnd: wizardValues.isNeverEnd,
			isCutCheck: wizardValues.isCutCheck,
			reviewRequired: wizardValues.reviewRequired,
			// canManyRewards: wizardValues.canManyRewards,

			rewards: wizardValues.rewardIds.map(rId => ({ val: rId })),
			// destType: wizardValues.destType,
			applicationClosingDays: wizardValues.applicationClosingDays,
			shouldCloseApplication: wizardValues.shouldCloseApplication,
			itemGroup: {
				...initValues.itemGroup,
				isAll: wizardValues.isAll ? 'true' : 'false',
				skus: wizardValues.skus,
				excludeSkus: wizardValues.excludeSkus,
				childGroupIds: [],
				excludeChildGroupIds: [],
			},
			followMail: toMailValues(followMail),
			requestMail: toMailValues(requestMail),
			receivedMail: toMailValues(receivedMail),
		};
	};

	const handleClickSave = async () => {
		const values = toCampaignValues(wizardValues);
		const result = await handleSave(values);
		if (result) {
			onClose();
		}
	};

	const v = toCampaignValues(wizardValues);
	const { rewardIds } = wizardValues;
	const rewardSelects = (rewards || []).filter(reward => rewardIds.includes(reward.id));
	const canChooseReward = rewardSelects.length > 1;
	const disabledReviewForm = !canChooseReward;

	const {
		followMail,
		requestMail,
		receivedMail
	} = selectMailTemplates(wizardValues);

	const dateFormat = (date: DateFields, sec: number): string => {
		return dayjs(date).second(sec).format("YYYY 年 MM 月 DD 日 HH 時 mm 分 ss 秒");
	}

	const isValidDateFormat = (date: DateFields): boolean => {
		return !Number.isNaN(new Date(date).getTime());
	}

	return (
		<>
			<div className="bl_panel__wizardMain">
				<PageDesc>以下の内容で登録します</PageDesc>
				<FormGroup>
					<FormLabel>キャンペーン名</FormLabel>
					<p>{v.name}</p>
				</FormGroup>
				<FormGroup>
					<FormLabel>キャンペーン実施期間</FormLabel>
					<p>{v.isNeverEnd == 'false' ? '期限を設定する' : '無期限'}</p>
					<p>開始 {isValidDateFormat(v.begin) ? dateFormat(v.begin, 0) : ''}</p>
					{v.isNeverEnd == 'false' &&
						<p>終了 {isValidDateFormat(v.end) ? dateFormat(v.end, 59) : ''}</p>
					}
				</FormGroup>
				<FormGroup>
					<FormLabel>レビュー受付期間</FormLabel>
					<p>{v.isCutCheck == 'date' ? '期限を設定する（対象日時まで）' : v.isCutCheck == 'days' ? '期限を設定する（商品発送後の指定日数期間）' : '無期限'}</p>
						{v.isCutCheck == 'date' &&
							<p>終了 {isValidDateFormat(v.deadlineDate) ? dateFormat(v.deadlineDate, 59) : ''}</p>
						}
						{v.isCutCheck == 'days' &&
							<p>商品発送後 {v.deadlineDays ? v.deadlineDays + " 日まで" : ""}</p>
						}
				</FormGroup>
				<FormGroup>
					<FormLabel>対象条件</FormLabel>
					<p>{
						{
							'item': '商品レビューを記入',
							'shop': 'ショップレビューを記入',
							'both': '商品・ショップレビューの両方を記入',
							'any': '商品・ショップレビューのどちらかを記入',
						}[v.reviewRequired]
					}</p>
				</FormGroup>
				<FormGroup>
					<FormLabel>レビュー特典</FormLabel>
					{v.rewards.map((reward, idx) =>
						<p key={idx}>{rewards.find(r => r.id == reward.val)?.name}</p>
					)}
				</FormGroup>
				{!disabledReviewForm &&
					<FormGroup>
						<FormLabel>申込受付期間</FormLabel>
						{v.shouldCloseApplication == 'true' &&
							<p>期限を設定する レビュー完了メール送付後から {v.applicationClosingDays} 日間有効</p>
						}
						{v.shouldCloseApplication == 'false' &&
							<p>無期限</p>
						}
					</FormGroup>
				}
				<FormGroup>
					<FormLabel>対象商品設定</FormLabel>
					<FormLabel item>全商品対象</FormLabel>
					<p>{v.itemGroup.isAll == 'true' ? '全商品を対象にする' : '個別で商品を指定する'}</p>
					{v.itemGroup.skus && <>
						<FormLabel item>対象商品コード</FormLabel>
						<textarea className='mb_16' name="" rows={8} readOnly value={v.itemGroup.skus.split(/[\n\t,]/g).filter(Boolean).join(',')} />
					</>}
					{v.itemGroup.excludeSkus && <>
						<FormLabel item>除外商品コード</FormLabel>
						<textarea className='mb_16' name="" rows={8} readOnly value={v.itemGroup.excludeSkus.split(/[\n\t,]/g).filter(Boolean).join(',')} />
					</>}
				</FormGroup>
				<FormGroup>
					<FormLabel>メール設定</FormLabel>
					<FormLabel item>フォローメール</FormLabel>
					{followMail && <p>「{followMail.name}」を適用します</p>}
					{!followMail && <p>条件に合うテンプレートが見つかりませんでした</p>}
				</FormGroup>
				{!disabledReviewForm && <FormGroup>
					<FormLabel item>レビュー完了メール</FormLabel>
					{requestMail && <p>「{requestMail.name}」を適用します</p>}
					{!requestMail && 
						<p>
							条件に合うテンプレートが見つかりませんでした<br />
							お客様にレビュー特典を選択いただく際の「申込みフォーム URL」をメール本文に含めて入力してください
						</p>
					}
				</FormGroup>}
				<FormGroup>
					<FormLabel item>申込受付完了メール</FormLabel>
					{receivedMail && <p>「{receivedMail.name}」を適用します</p>}
					{!receivedMail && <>
						<p>条件に合うテンプレートが見つかりませんでした</p>
						{rewardSelects.some(r => r.isCoupon) &&
							<p>レビュー特典にクーポンが含まれますので、「クーポン URL」をメール本文に含めて入力してください</p>
						}
					</>}
				</FormGroup>
			</div>
			<div className="bl_panel__wizardFooter">
				<Button className="el_largeBtn el_largeBtn__prev" onClick={onPrev}>戻る</Button>
				<Button className="el_largeBtn" onClick={handleClickSave}>保存</Button>
				<Button className="el_largeBtn el_largeBtn__prev el_largeBtn__right" onClick={props.onClose}>キャンセル</Button>
			</div>
		</>
	);
}
export default CampaignWizardConfirm;
