import { User } from '@sasagase/types';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAPI } from "../../../context";
import { FormValues } from './AccountRegist';

interface useAccountRegistProps {
	initValues: FormValues;
}
type AccountRegistParams = {
	token?: string;
}

export const useAccountRegist = (props: useAccountRegistProps) => {
	const params = useParams<AccountRegistParams>();
	const navigate = useNavigate();
	const notFoundPath = '/notfound';
	const callAPI = useAPI();
	const [inviteMail, setInviteMail] = React.useState<string>('');
	const [saveResult, setSaveResult] = React.useState<boolean>(false);
	const formMethods = useForm<FormValues>({
		defaultValues: props.initValues,
	});

	React.useEffect(() => {
		if (!params.token) {
			return;
		}

		return callAPI.user.getUserByToken({ token: params.token }, (err, result) => {
			if (err || !result) {
				navigate(notFoundPath);
				return;
			}

			const user = new User(result.data);
			if (user.mail === undefined || !user.hasEnableToken('yReview_login')) {
				navigate(notFoundPath);
				return;
			}
			if (user.setPass) {
				setSaveResult(user.setPass);
			}
			setInviteMail(user.mail);
		});
	}, [params.token]);

	const handleSave: SubmitHandler<FormValues> = async (values) => {
		if (!params.token) {
			return;
		}

		try {
			const apiParams = {
				token: params.token,
				name: values.name,
				pass: values.password,
			};
			const result = await callAPI.user.putInviteUser(apiParams);
			if (result && result.data) {
				setSaveResult(true);
			}
		} catch (err) {
			setSaveResult(false);
			return;
		}
	};

	const handleClickStart = () => {
		navigate("/");
	};

	return {
		methods: formMethods,
		handleSave,
		handleClickStart,
		inviteMail,
		saveResult,
	};
};
export default useAccountRegist;