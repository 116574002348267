import { couponCategory } from '@sasagase/types';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { InputInlineDesc } from '../../molecules/InputInlineDesc';
import { ErrorMessage, FormGroup, FormInputWrap, Input, InputRadio, Textarea } from '../../organisms/Form';
import { ImageUpload } from '../../organisms/ImageUpload';
import { FormValues, nameList } from './useRewardEdit';

export interface RewardCouponEditProps {
	names: Record<keyof FormValues, string>;
	methods: UseFormMethods<FormValues>;
	couponImageUploadUrl?: string;
	couponImageUrl?: string;
	handleChangeFile: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	handleChangeCouponImageType: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	handleClickDeleteImage: (name: typeof nameList[number]) => () => void;
	nonNegative: React.ChangeEventHandler<HTMLInputElement>;
}

export const RewardCouponEdit: React.FunctionComponent<RewardCouponEditProps> = (props) => {
	const {
		names,
		methods: { watch },
		couponImageUploadUrl,
		couponImageUrl,
		handleChangeFile,
		handleChangeCouponImageType,
		handleClickDeleteImage,
		nonNegative,
	} = props;

	return (
		<>
			<FormGroup>
				<FormLabel required>クーポン発行タイミング</FormLabel>
				<InputRadio name={names.issueFreq} value="individual" label="特典申込み時にお客様毎に発行する" /><br />
				<InputRadio name={names.issueFreq} value="daily" label="毎日発行し、同日のクーポン利用者へ配布する" /><br />
				<InputRadio name={names.issueFreq} value="weekly" label="毎週発行し、同週のクーポン利用者へ配布する" /><br />
				<InputRadio name={names.issueFreq} value="monthly" label="毎月発行し、同月のクーポン利用者へ配布する" />
				<ErrorMessage name={names.issueFreq} />
			</FormGroup>
			<FormGroup>
				<FormLabel required>カテゴリ</FormLabel>
				<div className='bl_labelBox'>
					{Object.entries(couponCategory).map(([value, name]) => 
						<InputRadio key={value} name={names.categoryId} value={value} label={name} />
					)}
				</div>
				<ErrorMessage name={names.categoryId} />
			</FormGroup>
			<FormGroup>
				<FormLabel required>値引き</FormLabel>
				<InputRadio name={names.discountType} value="1" label={
						<FormInputWrap>
							<span>定額値引き</span><Input className="el_inlineInputL" type="text" name={names.discountPrice} max={99999999} onChange={nonNegative} /><span>円 <InputInlineDesc>※半角数字8文字以内</InputInlineDesc></span>
						</FormInputWrap>
					} /><br />
				<InputRadio name={names.discountType} value="2" label={
						<FormInputWrap>
							<span>定率値引き</span><Input className="el_inlineInputL" type="text" name={names.discountRate} onChange={nonNegative} /><span>% <InputInlineDesc>※1〜99の半角数字</InputInlineDesc></span>
						</FormInputWrap>
					} /><br />
				<InputRadio name={names.discountType} value="3" label="送料無料" />
				<ErrorMessage name={names.discountPrice} />
				<ErrorMessage name={names.discountRate} />
				<ErrorMessage name={names.discountType} />
			</FormGroup>
			<FormGroup>
				<FormLabel required>クーポン有効期間</FormLabel>
				<InputRadio name={names.validPeriod} value="days" label={
						<FormInputWrap>
							<span>レビュー記入の2 時間後から</span><span className="sp_block"><Input className="el_inlineInputM" type="text" name={names.validDays} onChange={nonNegative} />日間有効<br className="show_mobile" /> <InputInlineDesc>※有効期間は最大89 日間です。</InputInlineDesc></span>
						</FormInputWrap>
					} /><br />
				<InputRadio name={names.validPeriod} value="nextMonth" label="発行から 翌月末まで有効" /><br />
				<InputRadio name={names.validPeriod} value="secondNextMonth" label="発行から 翌々月末まで有効" />
				<ErrorMessage name={names.validDays} />
			</FormGroup>
			<FormGroup>
				<FormLabel required>クーポン画像</FormLabel>
				<InputRadio name={names.couponImageType} value="input" label="画像ファイル名指定" onChange={handleChangeCouponImageType} />
				<div className='pl_26'>
					<Input type="text" name={names.couponImageInput} />
					<ErrorMessage name={names.couponImageInput} message='画像ファイル名を入力してください。' />
					<InputDesc>※ストアエディターの追加画像に登録されているファイル名を指定してください。600×600pxの画像を設定してください。</InputDesc>
				</div>
				<InputRadio name={names.couponImageType} value="template" label="テンプレートを使用する" onChange={handleChangeCouponImageType} />
				{/* テンプレート選択部分は一旦廃止 */}
				<Input type="hidden" name={names.couponImageTemplate} value="a" />
				<div className="bl_imgUpload pl_26">
					<div className="bl_imgUpload_imgWrap">
						<img src="/assets/img/tpl_a_p888.jpg" />
					</div>
					<div className="bl_imgUpload_imgWrap">
						<img src="/assets/img/tpl_a_r88.jpg" />
					</div>
					<div className="bl_imgUpload_imgWrap">
						<img src="/assets/img/tpl_a_s.jpg" />
					</div>
				</div>
				<br />
				<InputRadio name={names.couponImageType} value="upload" label="オリジナルを使用する" onChange={handleChangeCouponImageType} />
				<ImageUpload
						wrapClassName='pl_26'
						imageUrl={couponImageUploadUrl}
						name={names.couponImageUpload}
						onChange={handleChangeFile}
						onDelete={handleClickDeleteImage('couponImageUpload')}
						inputDesc={<>
							※推奨画像サイズは600ピクセル×600ピクセル以内です<br />
							※ファイル形式は、GIF、JPEG、PNG形式のみです
						</>}
						/>
				<ErrorMessage name={names.couponImageUpload} />
				<ErrorMessage name={names.couponImageType} />
				{couponImageUrl &&
					<>
						<label className="bl_label">登録中のクーポン画像</label>
						<div className="bl_imgUpload pl_26">
							<div className="bl_imgUpload_imgWrap">
								<img src={couponImageUrl} />
							</div>
						</div>
					</>
				}
			</FormGroup>
			<FormGroup>
				<FormLabel>リンク先URL</FormLabel>
				<Input type="text" name={names.linkUrl} />
				<ErrorMessage name={names.linkUrl} />
			</FormGroup>
			<FormGroup>
				<FormLabel>併用可否</FormLabel>
				<InputRadio name={names.canCombineUse} value="true" label="併用可" />
				<InputRadio name={names.canCombineUse} value="false" label="併用不可" />
				<ErrorMessage name={names.canCombineUse} />
			</FormGroup>
			<FormGroup>
				<FormLabel>ユーザーごとの利用可能回数</FormLabel>
				<Input className="el_inlineInputM" type="text" name={names.countUserUseLimit} onChange={nonNegative} />回 <InputInlineDesc>※1以上の半角数字（指定しない場合は無制限）</InputInlineDesc>
				<ErrorMessage name={names.countUserUseLimit} />
			</FormGroup>
			<FormGroup>
				<FormLabel>全ユーザーの利用可能回数</FormLabel>
				<Input className="el_inlineInputM" type="text" name={names.countAllUseLimit} onChange={nonNegative} />回 <InputInlineDesc>※1以上の半角数字（指定しない場合は無制限）</InputInlineDesc>
				<ErrorMessage name={names.countAllUseLimit} />
			</FormGroup>
			<FormGroup>
				<FormLabel>注文金額/個数条件</FormLabel>
				<InputRadio name={names.orderType} value="0" label="指定しない"/><br />
				<InputRadio name={names.orderType} value="1" label={<>
						注文金額が<span className="mobile_block mobile_indent_26"><Input className="el_inlineInputL" type="text" name={names.orderPrice} onChange={nonNegative} />円以上の場合にクーポン利用可能 <InputInlineDesc>※半角数字9文字以内</InputInlineDesc></span>
					</>} /><br />
				<InputRadio name={names.orderType} value="2" label={<>
						注文個数が<span className="mobile_block mobile_indent_26"><Input className="el_inlineInputL" type="text" name={names.orderCount} onChange={nonNegative} />個以上の場合にクーポン利用可能 <InputInlineDesc>※1〜99の半角数字</InputInlineDesc></span>
					</>} />
				<ErrorMessage name={names.orderType} />
				<ErrorMessage name={names.orderPrice} />
				<ErrorMessage name={names.orderCount} />
			</FormGroup>
			<FormGroup>
				<FormLabel required>対象商品</FormLabel>
				<InputRadio name={names.itemDesignation} value="3" label="ストア内全商品" /><br />
				<InputRadio name={names.itemDesignation} value="1" label="クーポン適用商品（最大1,000商品）を指定" /><br />
				<InputRadio name={names.itemDesignation} value="4" label="商品タグ（最大10件）を指定" /><br />
				{watch('itemDesignation') == '1' && <>
					<p><InputInlineDesc>
						クーポンの適用対象とする商品コードをカンマ区切りで入力してください。<br />
						※100商品以上指定した場合、発行までに時間がかかる可能性があります。
					</InputInlineDesc></p>
					<Textarea name={names.targetItemIdStrings} />
					<ErrorMessage name={names.targetItemIdStrings} />
				</>}
				{watch('itemDesignation') == '4' && <>
					<p><InputInlineDesc>
						クーポンの適用対象とする商品タグをカンマ区切りで入力してください。<br />
						※発行までに時間がかかる可能性があります。
					</InputInlineDesc></p>
					<Textarea name={names.targetItemTagStrings} />
					<ErrorMessage name={names.targetItemTagStrings} />
				</>}
				<ErrorMessage name={names.itemDesignation} />
			</FormGroup>
		</>
	);
};
export default RewardCouponEdit;