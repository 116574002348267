import { BodyElementType, bodyElementTypeNames } from '@sasagase/types';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ArrayField } from 'react-hook-form';
import { Button } from '../../atoms/Button';
import { FormLabel } from '../../molecules/FormLabel';
import { InputDesc } from '../../molecules/InputDesc';
import { ErrorMessage, FormGroup, Input, Select, Textarea } from '../../organisms/Form';
import OriginalTagModal from './OriginalTagModal';
import { MailEditRowFormValues } from './useMailEditRow';

interface InputBodyDetailProps {
	disabled?: boolean;
	bodyElementTypeSelect: BodyElementType[];
	names: Record<keyof MailEditRowFormValues, string>;
	fields: Partial<ArrayField<Record<string, any>, "id">>[];
	handleClickAddBodyElement: () => void;
	handleClickDeleteBodyElement: (idx: number) => void;
	handleOnDragEnd: (result: any) => void;
	handleChangeType: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
	toBodyElements: any;
}

export function InputBodyDetail(props: InputBodyDetailProps): React.ReactElement {
	const {
		bodyElementTypeSelect,
		names,
		fields,
		handleClickAddBodyElement,
		handleClickDeleteBodyElement,
		handleOnDragEnd,
		handleChangeType,
		toBodyElements,
	} = props;

	const [enableTagModal, setEnableTagModal] = React.useState<boolean>(false);

	return (
		<>
			{enableTagModal && <OriginalTagModal onClose={() => setEnableTagModal(false)} />}
			<FormGroup>
				<FormLabel required>メール本文</FormLabel>
				<InputDesc noMargin>
					フリーテキスト内には独自タグを入力できます。
					<Button className="el_btnBlueInv" type="button" onClick={() => setEnableTagModal(true)} disabled={enableTagModal}>独自タグ一覧</Button>
				</InputDesc>
				<Input type="text" name={names.bodyHeader} disabled={props.disabled} />
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId={`element-${names.bodyElement}`}>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{fields.map((bodyElement, idxBody) => {
									return (
										<Draggable key={bodyElement.id} draggableId={`element-${bodyElement.id}`} index={idxBody} isDragDisabled={props.disabled}>
											{(provided) => (
												<div className="bl_sortableField" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<div className="bl_sortableField_knob"></div>
													<div className="bl_sortableField_txtWrap">
														<div className="bl_sortableField_txtWrap_header">
															<div className="el_selectWrap">
																<Select name={`${names.bodyElement}[${idxBody}].type`}
																	defaultValue={bodyElement.type}
																	options={Object.fromEntries(bodyElementTypeSelect.filter(et => bodyElementTypeNames[et]).map(et => [et, bodyElementTypeNames[et]]))}
																	disabled={props.disabled}
																	onChange={handleChangeType}
																	/>
															</div>
															<Button className="el_btnBlueInv" onClick={() => handleClickDeleteBodyElement(idxBody)} disabled={props.disabled}>削除</Button>
														</div>
														<Textarea name={`${names.bodyElement}[${idxBody}].content`} 
															className={(toBodyElements[idxBody] || bodyElement).type == 'free_text' ? '' : 'bl_displayNone'}
															defaultValue={bodyElement.content}
															disabled={props.disabled}
															/>
													</div>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<div className="bl_addSentence" onClick={handleClickAddBodyElement}>
					<p>文章を追加</p>
				</div>
				<ErrorMessage name={names.bodyElement} />
			</FormGroup>
		</>
	);
}
export default InputBodyDetail;