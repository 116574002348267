import { ItemGroup } from '@sasagase/types';
import * as React from 'react';
import { array, boolean, define, dynamic, record, string, type } from 'superstruct';
import { useItemGroupEdit } from '../../../hooks';
import { GroupEditRowFormValues } from '../../organisms/GroupEditRow';

export interface CampaignEditFormStep3FormValue {
	itemGroup: GroupEditRowFormValues;
}

export const CampaignEditFormStep3Struct = type({
	itemGroup: dynamic((values: any) => {
		const isNotEmpty = values.isAll == 'true' || Boolean(values.skus.split(/[\n\t,]/g).filter(Boolean).length || values.childGroupIds.length);
		return type({
			isAll: string(),
			inputSkus: string(),
			skus: define<string>('item is not empty', () => isNotEmpty || '対象商品を設定してください'),
			inputExcludeSkus: string(),
			excludeSkus: string(),
			childGroupIds: array(string()),
			excludeChildGroupIds: array(string()),
			group: record(string(), boolean()),
			excludeGroup: record(string(), boolean()),
			itemName: string(),
			upper: string(),
			lower: string(),
			excludeItemName: string(),
			excludeUpper: string(),
			excludeLower: string(),
		});
	}),
});

interface useCampaignEditStep3Props {
	groups: ItemGroup[];
}

export const useCampaignEditStep3 = (props: useCampaignEditStep3Props) => {
	const {
		getItemGroupRequireDesc
	} = useItemGroupEdit();

	const groupNames = React.useMemo(() => {
		const names: Record<string, string> = {};
		for (const group of props.groups) {
			const desc = getItemGroupRequireDesc(group);
			names[group.id] = `${group.name}【${desc.join(',')}】`;
		}
		return names;
	}, [props.groups]);

	const toName = (name: string) => `itemGroup.${name}`;

	return {
		groupNames,
		toName
	};
}
export default useCampaignEditStep3;